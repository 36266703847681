/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Donation_paymentForm = {
    readonly total: number;
    readonly " $refType": "Donation_paymentForm";
};
export type Donation_paymentForm$data = Donation_paymentForm;
export type Donation_paymentForm$key = {
    readonly " $data"?: Donation_paymentForm$data;
    readonly " $fragmentRefs": FragmentRefs<"Donation_paymentForm">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Donation_paymentForm",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
        }
    ],
    "type": "LicensingPaymentForm",
    "abstractKey": null
} as any;
(node as any).hash = '8b2b09d58a76077734839e437af9db3b';
export default node;
