import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import styles from "components/animal/ExpiringSoon.module.scss";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { ExpiringSoon_account } from "generatedQueries/ExpiringSoon_account.graphql";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { useHistory } from "react-router-dom";
import { Button, CardText } from "reactstrap";
import { ILicenceRenewalLocationState } from "routes/licence/LicenceRenewal";
import { routes } from "routes/routes";
import { WarningCard } from "shared/components/WarningCard";

interface IProps {
    maxAnimalsToDisplay: number;
}

interface IGraphQlProps {
    account: ExpiringSoon_account;
}

type Props = IProps & IGraphQlProps;

const InternalExpiringSoon: FC<Props> = ({
    account,
    maxAnimalsToDisplay,
}) => {
    const intl = useIntl();
    const history = useHistory();

    if (!account || !account.expiringSoon?.edges?.length) {
        return null;
    }

    const animalsCount = account.expiringSoon.edges.length;

    return (
        <WarningCard message={animalMessages.expiringAnimalsWarningTitle}>
            <ul className={classNames(styles.animalList, "card-text")}>
                {
                    account.expiringSoon.edges.map((edge, index) => {
                        if (index > maxAnimalsToDisplay) {
                            return null;
                        }

                        if (index === maxAnimalsToDisplay) {
                            const additionalPets = animalsCount - maxAnimalsToDisplay;
                            return (
                                <li key={index}>
                                    <Message
                                        message={additionalPets === 1 ? animalMessages.expiringAnimalsOneMoreLabel : animalMessages.expiringAnimalsMoreLabel}
                                        values={{ additionalPets }} />
                                </li>
                            );
                        }

                        const values = {
                            animalName: edge.node.name,
                            expiryDate: intl.formatDate(new Date(edge.node.licenceExpiry!), { day: "numeric", month: "long", year: "numeric" }),
                        };

                        return (
                            <li key={index}>
                                <Message message={animalMessages.expiringAnimalLabel} values={values} />
                            </li>
                        );
                    })
                }
            </ul>
            <CardText>
                <Button color="primary" onClick={selectRenewalsAndRedirect(account.expiringSoon)}>
                    <Message message={animalMessages.expiringAnimalsRenewNowButtonLabel} />
                </Button>
            </CardText>
        </WarningCard>
    );

    function selectRenewalsAndRedirect(animals: ExpiringSoon_account["expiringSoon"]) {
        return () => {
            const state: ILicenceRenewalLocationState = {
                animalIds: animals?.edges?.map(edge => edge.node.id) ?? [],
                hasReviewedAccountDetails: false,
            };
            history.push(routes.pets.index.create({}), state);
        };
    }
};

export const ExpiringSoon = createFragmentContainer(InternalExpiringSoon, {
    account: graphql`
        fragment ExpiringSoon_account on Account @argumentDefinitions(
            filter: {type: "LicensingAnimalFilter"}
        ) {
            expiringSoon: animals(filter: $filter) {
                edges {
                    node {
                        id
                        name
                        licenceExpiry
                    }
                }
            }
        }
    `,
});
