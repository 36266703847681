import { ValidationErrorPane } from "components/shared/ValidationErrorPane";
import classNames from "classnames";
import { Message } from "shared/components/Message";
import { FieldProps, FormValues, useField } from "informed";
import React from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { Input } from "reactstrap";
import Button from "reactstrap/lib/Button";
import styles from "shared/components/formInputs/InlineRadioButton.module.scss";
import { withDefaultValidation } from "shared/components/formInputs/withDefaultValidation";

type Field = string | boolean | number;

export interface IOption {
    message: MessageDescriptor;
    value: Field;
}

interface IProps {
    options: IOption[];
    labelledBy?: string;
}

type Props<T extends FormValues = FormValues> = FieldProps<Field, T> & IProps;

export const RadioButtonInternal = <T extends FormValues>(props: Props<T>) => {
    const { formatMessage } = useIntl();
    const { fieldState, fieldApi, render } = useField({ ...props });

    const { options, labelledBy, initialValue, field } = props;
    const fieldValue = fieldApi.getValue();
    const hasValue = fieldValue !== null && fieldValue !== undefined;

    let selectedValue: Field | undefined;
    if (hasValue) {
        selectedValue = fieldValue;
    } else {
        selectedValue = initialValue;
    }

    const selectedIndex = options.findIndex(option => option.value === selectedValue);

    return render(
        <div
            className={classNames("input-group", fieldState.touched && ["was-validated", fieldState.error ? "invalid" : "valid"])}
            role="radiogroup"
            aria-labelledby={labelledBy}
            tabIndex={0}
        >
            <div
                className="btn-group"
            >
                {
                    options.map((option: IOption, index: number) => {
                        const onOptionClick = () => onClick(option.value);

                        const isActive = index === selectedIndex;
                        const color = isActive ? "primary" : "light";
                        const className = index === 0
                            ? styles.leftButton
                            : index === options.length - 1
                                ? styles.rightButton
                                : undefined;

                        return (
                            <Button
                                id={`${field}-${index}`}
                                key={index}
                                color={color}
                                onClick={onOptionClick}
                                active={isActive}
                                aria-label={formatMessage(option.message)}
                                size="sm"
                                className={classNames(!!fieldState.error && [styles.invalid, className])}
                                role="radio"
                                aria-checked={isActive}
                                data-value={option.value}
                                tabIndex={0}
                            >
                                <Message
                                    message={option.message}
                                />
                            </Button>
                        );
                    })
                }
            </div>
            <Input hidden={true} invalid={!!fieldState.error} />
            <ValidationErrorPane field={field} />
        </div>
    );

    function onClick(option: Field) {
        fieldApi.setValue(option);
        fieldApi.setTouched(true);
    }
};

export const InlineRadioButton = withDefaultValidation<Field, FormValues>()(RadioButtonInternal);
