/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PaymentForm_configuration = {
    readonly currency: string;
    readonly organisationContact: string;
    readonly " $refType": "PaymentForm_configuration";
};
export type PaymentForm_configuration$data = PaymentForm_configuration;
export type PaymentForm_configuration$key = {
    readonly " $data"?: PaymentForm_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"PaymentForm_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentForm_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '074a16476fccd20f8925e5ffd73729cb';
export default node;
