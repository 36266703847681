import { ErrorPane } from "components/shared/ErrorPane";
import { validationMessages } from "components/shared/validationMessages";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import styles from "components/jurisdiction/JurisdictionModal.module.scss";
import { Message } from "shared/components/Message";
import { JurisdictionModal_configuration } from "generatedQueries/JurisdictionModal_configuration.graphql";
import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { Col, FormText, Row } from "reactstrap";
import { ConfirmModal } from "shared/components/ConfirmModal";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { ISelectOption, ReactSelect } from "shared/components/ReactSelect";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("components.jurisdiction.JurisdictionModal", {
    fieldLabel: "Jurisdiction",
    formHint: "If you are unsure what to select here, please contact {organisationContact} to confirm which jurisdiction to select before proceeding.",
    jurisdictionModalTitle: "Select a Jurisdiction",
    jurisdictionModalPrompt: "We found multiple jurisdictions for your address, please select the jurisdiction for your address below.",
    saveButton: "Save",
});

interface IGraphQlProps {
    configuration: JurisdictionModal_configuration | null;
}

interface IProps {
    isSaving: boolean;
    show: boolean;
    jurisdictions: { jurisdictionId: number, name: string }[];
    onCancelled: () => void;
    onConfirmed: (jurisdictionId: number) => void;
}

const InternalJurisdictionModal: FC<IProps & IGraphQlProps> = ({
    isSaving,
    configuration,
    show,
    jurisdictions,
    onCancelled,
    onConfirmed,
}) => {
    const { formatMessage } = useIntl();
    const [selectedJurisdiction, setSelectedJurisdiction] = useState<number>();
    const [isNoJurisdictionSelectedWarningDisplayed, setIsNoJurisdictionSelectedWarningDisplayed] = useState(false);
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.setCustomValidity(isNoJurisdictionSelectedWarningDisplayed ? "error" : "");
        }
    }, [isNoJurisdictionSelectedWarningDisplayed, ref]);

    return <ConfirmModal
        show={show}
        modalTitle={messages.jurisdictionModalTitle}
        titleId="jurisdictionModalTitle"
        onCancelled={onModalCancelled}
        onConfirmed={onModalConfirmed}
        isConfirming={isSaving}
        confirmButtonLabel={messages.saveButton}
    >
        <Row>
            <Col>
                <Message message={messages.jurisdictionModalPrompt} />
            </Col>
        </Row>
        <Row className="mt-2">
            <Col>
                <FormLabel
                    message={messages.fieldLabel}
                    required={true}
                    for="jurisdiction"
                >
                    <div
                        className={classNames(styles.jurisdiction, (isNoJurisdictionSelectedWarningDisplayed || !!selectedJurisdiction) && ["was-validated", !!selectedJurisdiction ? "valid" : "invalid"])}
                    >
                        <ReactSelect
                            isSearchable={true}
                            value={selectedJurisdiction}
                            options={jurisdictions.map(j => ({ label: j.name, value: j.jurisdictionId }))}
                            onSelected={onJurisdictionSelected}
                            inputId="jurisdiction"
                        />
                        <input type="text" className={classNames("form-control", styles.hidden)} ref={ref} />
                        <ErrorPane errors={isNoJurisdictionSelectedWarningDisplayed ? [validationMessages.requiredError] : undefined} />
                    </div>
                </FormLabel>
                <FormText color="muted">
                    <Message
                        message={messages.formHint}
                        values={{
                            organisationContact: configuration?.organisationContact ?? formatMessage(sharedMessages.defaultOrganisationContactMessage),
                        }}
                    />
                </FormText>
            </Col>
        </Row>
    </ConfirmModal>;

    function onJurisdictionSelected(option: ISelectOption) {
        if (typeof option.value !== "number") {
            throw new Error("Select option is not a number, Jurisdiction IDs can only be numbers");
        }

        setSelectedJurisdiction(option.value);
        setIsNoJurisdictionSelectedWarningDisplayed(false);
    }

    function onModalConfirmed() {
        if (selectedJurisdiction === undefined) {
            setIsNoJurisdictionSelectedWarningDisplayed(true);
            return;
        }

        onConfirmed(selectedJurisdiction);
    }

    function onModalCancelled() {
        setIsNoJurisdictionSelectedWarningDisplayed(false);
        setSelectedJurisdiction(undefined);

        onCancelled();
    }
};

export const JurisdictionModal = createFragmentContainer(InternalJurisdictionModal, {
    configuration: graphql`
        fragment JurisdictionModal_configuration on Configuration {
            organisationContact
        }`,
});
