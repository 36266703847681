import styles from "components/layout/ProfileIcon.module.scss";
import md5 from "md5";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { mapMessages } from "shared/mapMessages";

const messages = mapMessages("components.layout.ProfileIcon", {
    iconAlternateText: "Avatar",
});

interface IProps {
    email: string | null | undefined;
}

export const ProfileIcon: FC<IProps> = ({
    email,
}) => {
    const { formatMessage } = useIntl();
    const url = !!email && !!email.trim()
        ? `https://www.gravatar.com/avatar/${md5(email.trim().toLowerCase())}?s=40&d=mp`
        : "https://www.gravatar.com/avatar/00000000000000000000000000000000?s=40&d=mp&f=y";

    return (
        <img
            className={styles.icon}
            src={url}
            alt={formatMessage(messages.iconAlternateText)}
        />
    );
};
