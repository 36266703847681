import { ValidationErrorPane } from "components/shared/ValidationErrorPane";
import { validationMessages } from "components/shared/validationMessages";
import { combine, isPhoneNumber, maxLength, minLength, required, Validator } from "components/shared/validations";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import styles from "components/phonenumber/PhoneNumber.module.scss";
import { accountMessages } from "domain/account/accountMessages";
import { IAccount } from "domain/account/models/account";
import { IPhoneNumber, isPhoneNumberType, phoneNumberTypes } from "domain/account/models/phoneNumber";
import { PhoneNumber_configuration } from "generatedQueries/PhoneNumber_configuration.graphql";
import { FieldProps, FormError, FormValues, useField } from "informed";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { OnChangeValue } from "react-select";
import MaskedInput, { conformToMask, maskArray } from "react-text-mask";
import { Input } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { withDefaultValidation } from "shared/components/formInputs/withDefaultValidation";
import { ISelectOption, isSelectOption, ReactSelect } from "shared/components/ReactSelect";

const PLACEHOLDER_CHARACTER = "_";

interface IProps {
    showRemoveButton?: boolean;
    index: number;
    onRemoveClicked?: () => void;
}

interface IGraphQlProps {
    configuration: PhoneNumber_configuration;
}

type Props<T extends FormValues = FormValues> = IProps & IGraphQlProps & FieldProps<IPhoneNumber, T>;

const PhoneNumberInternal = <T extends FormValues>(props: Props<T>) => {
    const { fieldState, fieldApi, render, ref } = useField({ ...props });
    const { formatMessage } = useIntl();
    const [mask] = useState(() => getMask(props.configuration));
    const selectOptions = useMemo<ISelectOption[]>(() => {
        return phoneNumberTypes.map(phoneNumberType => ({
            ...phoneNumberType,
            label: formatMessage(phoneNumberType.label),
        }));
    }, [formatMessage]);

    const {
        onRemoveClicked,
        showRemoveButton,
        configuration,
        index,
    } = props;

    useEffect(() => {
        if (hasSetCustomValidityFunc(ref.current)) {
            ref.current.setCustomValidity(fieldState.error || "");
        }
        else if (isMaskedInputRef(ref.current) && hasSetCustomValidityFunc(ref.current.inputElement)) {
            ref.current.inputElement.setCustomValidity(fieldState.error || "");
        }
    }, [fieldState.error, ref]);

    return render(
        <div className="form-group">
            <div className={classNames("input-group", fieldState.touched && ["was-validated", fieldState.error ? "invalid" : "valid"])}>
                <div className="input-group-prepend form-select">
                    <ReactSelect
                        inputId={`phoneNumberType-${index}`}
                        className={classNames("input-phone-number-type", styles.type)}
                        isSearchable={true}
                        value={(fieldState.value && fieldState.value.type) || undefined}
                        options={selectOptions}
                        onSelected={onTypeChange}
                    />
                </div>
                {
                    !mask &&
                    <Input
                        className={classNames("form-control", styles.number)}
                        type="tel"
                        value={(fieldState.value && fieldState.value.number) || ""}
                        onChange={onNumberChange}
                        innerRef={ref}
                        name={props.field}
                    />
                }
                {
                    mask &&
                    <MaskedInput
                        className={classNames("form-control", styles.number)}
                        value={conformToMask((fieldState.value && fieldState.value.number) || "", mask, {}).conformedValue}
                        mask={mask}
                        onChange={onNumberChange}
                        onClick={onClick}
                        name={props.field}
                        ref={ref}
                        showMask={true}
                        placeholderChar={PLACEHOLDER_CHARACTER}
                    />
                }
                {
                    showRemoveButton &&
                    <div className="input-group-append">
                        <Button
                            type="button"
                            onClick={onRemoveClicked}
                            title={formatMessage(accountMessages.removePhoneNumberTooltip)}>
                            <FontAwesomeIcon icon={faTrashAlt} className="icon-button" />
                        </Button>
                    </div>
                }
                <ValidationErrorPane field={props.field} />
            </div>
        </div>
    );

    function onTypeChange(option: OnChangeValue<ISelectOption, false>) {
        const newType = (isSelectOption(option) && isPhoneNumberType(option.value)) ? option.value : null;
        fieldApi.setValue({ ...fieldState.value, type: newType });
        fieldApi.setTouched(true);
    }

    function onNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        fieldApi.setValue({ ...fieldState.value, number: event.target.value });
        fieldApi.setTouched(true);
    }

    function onClick(event: React.MouseEvent<HTMLInputElement>) {
        const position = event.currentTarget.value.indexOf(PLACEHOLDER_CHARACTER);

        if (configuration && configuration.licensingPhoneNumberMask && position >= 0) {
            const valueSelected = event.currentTarget.value.substring(0, event.currentTarget.selectionEnd || undefined);

            const match = configuration.licensingPhoneNumberMask
                .find(m => !!(m.isRegex && valueSelected.match(new RegExp(m.value))));

            if (!match) {
                event.currentTarget.setSelectionRange(0, 0);
            } else if (event.currentTarget.selectionStart === null || event.currentTarget.selectionStart > position) {
                event.currentTarget.setSelectionRange(position, position);
            }
        }
    }
};

function getMask(configuration: PhoneNumber_configuration | null): maskArray {
    if (!configuration ||
        !configuration.licensingPhoneNumberMask ||
        !configuration.licensingPhoneNumberMask.length) {
        return false;
    }

    return configuration.licensingPhoneNumberMask.map(maskCharacter => maskCharacter.isRegex ? new RegExp(maskCharacter.value) : maskCharacter.value);
}

function hasSetCustomValidityFunc(current: unknown): current is { setCustomValidity: (error: string) => void } {
    return typeof (current as { setCustomValidity: (error: string) => void })?.setCustomValidity === "function";
}

function isMaskedInputRef(current: unknown): current is MaskedInput {
    if (!current) {
        return false;
    }

    return (current as MaskedInput).inputElement !== undefined;
}

function isValidPhoneNumber(value: string | null): FormError {
    const isPhoneNumberFormError = isPhoneNumber(value);
    if (isPhoneNumberFormError) {
        return isPhoneNumberFormError;
    }

    const sanitizedPhoneNumber = value?.replace(/[-_ ()]/g, "") ?? "";
    return sanitizedPhoneNumber === "0".repeat(sanitizedPhoneNumber.length)
        ? validationMessages.phoneNumberError
        : undefined;
}

export const phoneValidate: Validator = (value: IPhoneNumber | undefined, values: IAccount) => {
    if (!value) {
        return;
    }

    const numberErrors = combine(required, minLength(10), maxLength(20), isValidPhoneNumber)(value.number);
    if (numberErrors) {
        return numberErrors as FormError;
    }

    const typeErrors = value.type === undefined && accountMessages.phoneTypeMissingError as unknown as FormError;
    if (typeErrors) {
        return typeErrors;
    }

    return (value.type &&
        values.phoneNumbers &&
        (values.phoneNumbers.filter(phoneNumber => phoneNumber.type === value.type).length > 1)
        && accountMessages.duplicatePhoneTypeError as unknown as FormError)
        || undefined;
};

const PhoneNumberFragment = createFragmentContainer(PhoneNumberInternal, {
    configuration: graphql`
        fragment PhoneNumber_configuration on Configuration {
            licensingPhoneNumberMask {
                value
                isRegex
            }
        }`,
});

export const PhoneNumber = withDefaultValidation<IPhoneNumber, FormValues>(phoneValidate)(PhoneNumberFragment);
