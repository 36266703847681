import { LoadingMessage } from "components/shared/LoadingMessage";
import { IPayPalPayFlowResultEventPayload, payPalPayflowResultMessage } from "components/payment/PayPalPayflow/messageActions";
import { parseQueryStringValues } from "components/payment/PayPalPayflow/parseQueryStringValues";
import React, { FC, useEffect } from "react";
import { useLocation } from "react-router";
import { mapMessages } from "shared/mapMessages";

const messages = mapMessages("components.payment.paypalpayflowiframereturn", {
    processing: "Processing...",
});

export const PayPalPayflowIframeReturn: FC = () => {
    const location = useLocation();

    useEffect(() => {
        const payload = parseQueryStringValues<IPayPalPayFlowResultEventPayload>(
            location.search,
            {
                authCode: undefined,
                result: undefined,
                pnRef: "",
                amt: undefined,
                respMsg: undefined,
                secureToken: "",
                secureTokenId: "",
                transTime: undefined,
            });

        window.parent.postMessage(payPalPayflowResultMessage(payload), window.location.origin);
    });

    return <LoadingMessage message={messages.processing} />;
};
