import { AccountDetails } from "components/account/details/AccountDetails";
import { AccountTitle } from "components/account/details/components/AccountTitle";
import React, { FC } from "react";

export const Account: FC = () => {
    return <>
        <AccountTitle />
        <AccountDetails />
    </>;
};
