/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountSubtitle_account = {
    readonly name: string | null;
    readonly " $refType": "AccountSubtitle_account";
};
export type AccountSubtitle_account$data = AccountSubtitle_account;
export type AccountSubtitle_account$key = {
    readonly " $data"?: AccountSubtitle_account$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountSubtitle_account">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountSubtitle_account",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        }
    ],
    "type": "Account",
    "abstractKey": null
} as any;
(node as any).hash = 'fac828f1fc927f3d9fbc745c7d58fe89';
export default node;
