import { IntlShape } from "react-intl";
import { Reducer } from "redux";
import { IntlAction } from "store/intl/intlActions";

export const intlReducer: Reducer<IntlShape | null> = (state = null, action: IntlAction) => {
    switch (action.type) {
        case "INTL_UPDATE":
            return action.payload;
        default:
            return state;
    }
};
