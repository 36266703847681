import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import graphql from "babel-plugin-relay/macro";
import styles from "components/animal/AnimalStatus.module.scss";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { AnimalStatus_animal } from "generatedQueries/AnimalStatus_animal.graphql";
import { AnimalStatus_configuration } from "generatedQueries/AnimalStatus_configuration.graphql";
import moment from "moment";
import React, { FC, useRef, useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { Tooltip } from "reactstrap";
import { sharedMessages } from "shared/sharedMessages";

interface IGraphQlProps {
    animal: AnimalStatus_animal;
    configuration: AnimalStatus_configuration | null;
}

const AnimalStatusInternal: FC<IGraphQlProps> = ({
    animal,
    configuration,
}) => {
    const intl = useIntl();
    const divRef = useRef<HTMLDivElement>(null);
    const [licenceStatusPopoverDisplayedFor, setLicenceStatusPopoverDisplayedFor] = useState<string>();

    if (animal.status === "BLOCKED") {
        return <>
            <div
                ref={divRef}
                className={styles.blocked}>
                <Message message={animalMessages.blockedLabel} />
                &nbsp;
                <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
            <Tooltip
                placement="bottom"
                isOpen={displayPopover(animal.id)}
                target={divRef}
                toggle={toggleStatusPopover(animal.id)}>
                <Message
                    message={animalMessages.blockedTooltip}
                    values={{ OrganisationContact: configuration?.organisationContact ?? intl.formatMessage(sharedMessages.defaultOrganisationContactMessage) }} />
            </Tooltip>
        </>;
    }

    if (animal.status === "REJECTED" || animal.status === "CANCELLED") {
        let message: MessageDescriptor;

        switch (animal.status) {
            case "REJECTED":
                message = animalMessages.rejectedLabel;
                break;

            case "CANCELLED":
                message = animalMessages.cancelledLabel;
                break;

            default:
                throw new Error("Unexpected animal status");
        }

        return <strong><Message message={message} /></strong>;
    }

    if (!animal.currentLicence) {
        return <Message message={animalMessages.petListLicenceStatusUnpaidLabel} />;
    }

    const licence = animal.currentLicence;

    if (licence.status === "PENDING") {
        return <Message message={animalMessages.petListLicenceStatusPendingLabel} />;
    }

    if (licence.isLifetime || (licence.expiryDate && moment(licence.expiryDate).isAfter())) {
        return <Message message={animalMessages.petListLicenceStatusLicencedLabel} />;
    }

    return <Message message={animalMessages.petListLicenceStatusExpiredLabel} />;

    function toggleStatusPopover(animalId: string | undefined) {
        return () => {
            setLicenceStatusPopoverDisplayedFor(!licenceStatusPopoverDisplayedFor ? animalId : undefined);
        };
    }

    function displayPopover(animalId: string | undefined) {
        if (!animalId) {
            return false;
        }

        return animalId === licenceStatusPopoverDisplayedFor;
    }
};

export const AnimalStatus = createFragmentContainer(AnimalStatusInternal, {
    animal: graphql`
        fragment AnimalStatus_animal on LicensingAnimal {
            id
            status
            currentLicence {
                id
                status
                isLifetime
                expiryDate
            }
        }`,
    configuration: graphql`
        fragment AnimalStatus_configuration on Configuration {
            organisationContact
        }`,
});
