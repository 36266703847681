import { ActionType, createAction } from "typesafe-actions";

export interface IAuthoriseCodeRequestBody {
    code: string;
    redirectUri: string;
}

const logout = createAction("USER_LOGOUT");
const logoutComplete = createAction("USER_LOGOUT_COMPLETE");

const setToken = createAction("USER_SET_TOKEN", resolve => (token: string) => resolve(token));
const setPostLoginRedirect = createAction("USER_SET_POST_LOGIN_REDIRECT", resolve => (redirectType: string | null) => resolve(redirectType));

export const userActions = {
    logout,
    logoutComplete,
    setPostLoginRedirect,
    setToken,
};

export type UserAction = ActionType<typeof userActions>;
