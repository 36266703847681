import { InfoMessage } from "components/shared/InfoMessage";
import { Spinner } from "components/shared/Spinner";
import * as SolidFontAwesomeIcons from "@fortawesome/free-solid-svg-icons";
import graphql from "babel-plugin-relay/macro";
import styles from "components/layout/Header.module.scss";
import { HeaderLogo } from "components/layout/HeaderLogo";
import { HeaderNavItem } from "components/layout/HeaderNavItem";
import { ProfileIcon } from "components/layout/ProfileIcon";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { homeMessages } from "domain/home/homeMessages";
import { licenceMessages } from "domain/licence/licenceMessages";
import { paymentMessages } from "domain/payment/paymentMessages";
import { IUserState } from "domain/user/combinedUserReducer";
import { Header_account } from "generatedQueries/Header_account.graphql";
import { Header_configuration } from "generatedQueries/Header_configuration.graphql";
import { WhenAuthorised } from "infrastructure/routing";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { createFragmentContainer } from "react-relay";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import Button from "reactstrap/lib/Button";
import Collapse from "reactstrap/lib/Collapse";
import Nav from "reactstrap/lib/Nav";
import Navbar from "reactstrap/lib/Navbar";
import NavbarBrand from "reactstrap/lib/NavbarBrand";
import NavbarToggler from "reactstrap/lib/NavbarToggler";
import NavItem from "reactstrap/lib/NavItem";
import ReactStrapNavLink from "reactstrap/lib/NavLink";
import { routes } from "routes/routes";
import { mapMessages } from "shared/mapMessages";
import { RootState } from "store/store";

const messages = mapMessages("components.layout.Header", {
    accountMenuTitle: "Account Menu",
    accountNavbarTitle: "My Account",
});

interface ICustomMenuItem {
    icon: string;
    text: string;
    url: string;
}

interface ICustomMenuItemsConfiguration {
    menuItems: ICustomMenuItem[];
}

interface IGraphProps {
    account: Header_account | null;
    configuration: Header_configuration | null;
}

const InternalHeader: FC<IGraphProps> = ({
    account,
    configuration,
}) => {
    const { formatMessage } = useIntl();
    const userState = useSelector<RootState, IUserState>(state => state.user);
    const [isExpanded, setExpanded] = useState(false);

    const {
        isLoggedIn,
    } = userState;

    const customMenuItems: ICustomMenuItem[] = getCustomMenuItems();

    const location = useLocation();

    return <>
        <Navbar className="mb-2" light={true} sticky="top" color="light" expand={(!!configuration && !isLoggedIn) ? true : "md"}>
            <Container>
                <NavbarBrand tag={Link} to={routes.home.create({})}>
                    {
                        !configuration
                            ? <Spinner />
                            : <HeaderLogo configuration={configuration} />
                    }
                </NavbarBrand>
                {
                    !isLoggedIn &&
                    <>
                        {
                            customMenuItems.length > 0 &&
                            <Nav navbar={true}>
                                {customMenuItems.map((customMenuItem, index) =>
                                    <NavItem key={index}>
                                        <HeaderNavItem
                                            isExternal={true}
                                            to={customMenuItem.url}
                                            icon={customMenuItem.icon && SolidFontAwesomeIcons[customMenuItem.icon]}
                                            message={customMenuItem.text}
                                            onClick={close} />
                                    </NavItem>
                                )}
                            </Nav>
                        }
                        <Nav navbar={true} className="ml-auto">
                            <Button
                                color="link"
                                className="nav-link ml-3"
                                outline={true}
                                tag={Link}
                                to={routes.user.login.create({})}>
                                <Message message={homeMessages.loginLabel} />
                            </Button>
                        </Nav>
                    </>
                }
                {
                    isLoggedIn &&
                    <>
                        <NavbarToggler onClick={toggle} />
                        <Collapse navbar={true} isOpen={isExpanded}>
                            <Nav navbar={true} className="float-right text-right">
                                {
                                    account &&
                                    <>
                                        <ReactStrapNavLink className="d-md-none" disabled={true}>
                                            {account.email}
                                        </ReactStrapNavLink>
                                        <DropdownItem className="d-md-none" divider={true} />
                                    </>
                                }
                                <WhenAuthorised roles={["Customer"]}>
                                    {
                                        account &&
                                        <>
                                            <NavItem className="d-md-none">
                                                <ReactStrapNavLink tag={NavLink} to={account.status === "NEW" ? routes.account.edit.create({}) : routes.account.index.create({})} onClick={close}>
                                                    <Message message={messages.accountNavbarTitle} />
                                                </ReactStrapNavLink>
                                            </NavItem>
                                            {
                                                account.areDetailsComplete &&
                                                <>
                                                    <NavItem>
                                                        <HeaderNavItem
                                                            className={"addLicenceNavbar"}
                                                            to={routes.licence.options.create({})}
                                                            icon={SolidFontAwesomeIcons.faAddressCard}
                                                            message={licenceMessages.addLicencePageTitle}
                                                            onClick={close}
                                                            isExternal={false} />
                                                    </NavItem>
                                                    <NavItem>
                                                        <HeaderNavItem
                                                            className={"petNavbar"}
                                                            to={routes.pets.index.create({})}
                                                            icon={SolidFontAwesomeIcons.faPaw}
                                                            message={animalMessages.petNavbarTitle}
                                                            onClick={close}
                                                            isExternal={false} />
                                                    </NavItem>
                                                    <NavItem>
                                                        <HeaderNavItem
                                                            className={"paymentNavbar"}
                                                            to={routes.payment.index.create({})}
                                                            icon={SolidFontAwesomeIcons.faCartArrowDown}
                                                            message={paymentMessages.paymentNavbarTitle}
                                                            onClick={close}
                                                            isExternal={false} />
                                                    </NavItem>
                                                </>
                                            }
                                        </>
                                    }
                                </WhenAuthorised>
                                {
                                    customMenuItems.map((customMenuItem, index) =>
                                        <NavItem key={index}>
                                            <HeaderNavItem isExternal={true} to={customMenuItem.url} icon={customMenuItem.icon && SolidFontAwesomeIcons[customMenuItem.icon]} message={customMenuItem.text} onClick={close} />
                                        </NavItem>
                                    )
                                }
                                <NavItem className="d-md-none">
                                    <ReactStrapNavLink tag={NavLink} to={routes.user.logout.create({})} onClick={close}>
                                        <Message message={homeMessages.logoutLabel} />
                                    </ReactStrapNavLink>
                                </NavItem>
                            </Nav>
                            <Nav navbar={true} className="ml-auto d-none d-md-block">
                                {
                                    !configuration && <div className={styles.spinner}><div className="m-auto"><Spinner size="lg" /></div></div>
                                }
                                {
                                    configuration && isLoggedIn &&
                                    <UncontrolledDropdown inNavbar={true}>
                                        <DropdownToggle
                                            className="p-0"
                                            nav={true}
                                            title={formatMessage(messages.accountMenuTitle)}
                                        >
                                            <ProfileIcon email={account?.email} />
                                        </DropdownToggle>
                                        <DropdownMenu className="text-right" right={true}>
                                            {
                                                account && <>
                                                    <DropdownItem tag="span" className="navbar-text" disabled={true}>
                                                        {account.email}
                                                    </DropdownItem>
                                                    <DropdownItem divider={true} />
                                                    <WhenAuthorised roles={["Customer"]}>
                                                        <DropdownItem tag="div" className="p-0">
                                                            <NavLink className="nav-link nav-item px-4 py-2" to={account.status === "NEW" ? routes.account.edit.create({}) : routes.account.index.create({})}>
                                                                <div className="py-1">
                                                                    <Message message={messages.accountNavbarTitle} />
                                                                </div>
                                                            </NavLink>
                                                        </DropdownItem>
                                                    </WhenAuthorised>
                                                </>
                                            }
                                            <DropdownItem tag="div" className="p-0">
                                                <NavLink className="nav-link nav-item px-4 py-2" to={routes.user.logout.create({})}>
                                                    <div className="py-1">
                                                        <Message message={homeMessages.logoutLabel} />
                                                    </div>
                                                </NavLink>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                            </Nav>
                        </Collapse>
                    </>
                }
            </Container>
        </Navbar>
        <WhenAuthorised roles={["Customer"]}>
            {
                location.pathname !== routes.account.edit.template() && !account?.areDetailsComplete &&
                <div className="text-center">
                    <InfoMessage
                        message={homeMessages.detailsAreIncompleteMessage}
                        messageValues={{
                            a: msg => <Link to={routes.account.edit.create({})}>
                                <button
                                    type="button"
                                    className={styles.linkButton}>
                                    {msg}
                                </button>
                            </Link>,
                        }} />
                </div>
            }
        </WhenAuthorised>
    </>;

    function toggle() {
        setExpanded(!isExpanded);
    }

    function close() {
        setExpanded(false);
    }

    function getCustomMenuItems(): ICustomMenuItem[] {
        if (!configuration?.menuItems?.value) {
            return [];
        }

        const parsedSiteCustomisationValue = JSON.parse(configuration.menuItems.value);
        return isValidCustomMenuItemsConfiguration(parsedSiteCustomisationValue) ? parsedSiteCustomisationValue.menuItems : [];
    }
};

function isValidCustomMenuItemsConfiguration(parsedSiteCustomisationValue: object): parsedSiteCustomisationValue is ICustomMenuItemsConfiguration {
    return !!parsedSiteCustomisationValue && (parsedSiteCustomisationValue as ICustomMenuItemsConfiguration).menuItems !== undefined;
}

export const Header = createFragmentContainer(InternalHeader, {
    account: graphql`
        fragment Header_account on Account {
            email,
            status,
            areDetailsComplete
        }`,
    configuration: graphql`
        fragment Header_configuration on Query {
            menuItems: siteCustomisation(application: "licensing", name: "menuItems") {
                value
            }
            ...HeaderLogo_configuration
        }`,
});
