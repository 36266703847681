import { intersection, keys } from "lodash";
import { FieldPath, FieldPathValue, FieldValues, RegisterOptions, Validate } from "react-hook-form";
import { IntlShape, MessageDescriptor } from "react-intl";
import { mapMessages } from "shared/mapMessages";
import { TranslatedRegisterOptions } from "shared/validations/useTranslatedValidations";

export const messages = mapMessages("shared.validations.validations", {
    emailPattern: "Invalid email address",
    greaterThan: "Must be greater than {greaterThan}",
    greaterThanOrEqual: "Must be greater than or equal to {greaterThan}",
    notANumber: "Must be a valid number",
    notAWholeNumber: "Must be a whole number",
    passwordStrengthError: "The password entered does not meet the minimum requirements. Must have at least one lower case, one upper case, one special character, one number and at least 8 characters in length.",
    required: "Required",
});

const standardValidations = [
    "required",
    "min",
    "max",
    "maxLength",
    "minLength",
    "pattern",
];

export function combine<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    ...params: TranslatedRegisterOptions<TFieldValues, TFieldName>[]): TranslatedRegisterOptions<TFieldValues, TFieldName> {
    return params.reduce((prev, current) => {
        const matchingKeys = intersection(keys(prev), keys(current));
        if (matchingKeys.filter(k => standardValidations.some(s => s === k)).length > 0) {
            throw new Error("Cannot combine standard set of validations");
        }

        const validate = combineCustomValidations(prev.validate, current.validate);

        return {
            ...prev,
            ...current,
            validate,
        };
    });
}

function combineCustomValidations<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    first: RegisterOptions<TFieldValues, TFieldName>["validate"],
    second: RegisterOptions<TFieldValues, TFieldName>["validate"]
): RegisterOptions<TFieldValues, TFieldName>["validate"] {
    if (!first && !second) {
        return undefined;
    }

    if (!first) {
        return second;
    }

    if (!second) {
        return first;
    }

    const firstIsFunction = typeof first === "function";
    const secondIsFunction = typeof second === "function";

    if (firstIsFunction && secondIsFunction) {
        return (value) => first(value) || second(value);
    }

    if (!firstIsFunction && !secondIsFunction) {
        if (intersection(keys(first), keys(second)).length > 0) {
            throw new Error("Cannot combine object of callback functions for validate when they have the same keys");
        }

        return {
            ...first,
            ...second,
        };
    }

    throw new Error("Cannot combine function and object of callback functions for validate");
}

export function isEmail<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(message?: MessageDescriptor): TranslatedRegisterOptions<TFieldValues, TFieldName> {
    return {
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: message ?? messages.emailPattern,
        },
    };
}

export function isPassword<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    passwordValidationRegex: string | undefined,
    message?: MessageDescriptor
): TranslatedRegisterOptions<TFieldValues, TFieldName> {
    return {
        pattern: {
            value: passwordValidationRegex ? new RegExp(passwordValidationRegex) : /^.*(?=.{8,})((?=.*[!@#$%^&*()\\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            message: message ?? messages.passwordStrengthError,
        },
    };
}

export function required<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(message?: MessageDescriptor): TranslatedRegisterOptions<TFieldValues, TFieldName> {
    return {
        required: message ?? messages.required,
    };
}

export function isNumber<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    formatMessage: IntlShape["formatMessage"],
    message?: MessageDescriptor
): TranslatedRegisterOptions<TFieldValues, TFieldName> {
    return {
        validate: {
            isNumber(v) {
                return typeof v !== "number" || isNaN(v)
                    ? formatMessage(message ?? messages.notANumber)
                    : undefined;
            },
        },
    };
}

export function isGreaterThan<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    formatMessage: IntlShape["formatMessage"],
    greaterThan: number,
    message?: MessageDescriptor
): TranslatedRegisterOptions<TFieldValues, TFieldName> {
    return {
        validate: {
            isGreaterThan(v) {
                return v <= greaterThan
                    ? formatMessage(message ?? messages.greaterThan, { greaterThan })
                    : undefined;
            },
        },
    };
}

export function isGreaterThanOrEqual<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    formatMessage: IntlShape["formatMessage"],
    greaterThan: number,
    message?: MessageDescriptor
): TranslatedRegisterOptions<TFieldValues, TFieldName> {
    return {
        validate: {
            isGreaterThanOrEqual(v) {
                return v < greaterThan
                    ? formatMessage(message ?? messages.greaterThanOrEqual, { greaterThan })
                    : undefined;
            },
        },
    };
}

export function isWholeNumber<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    formatMessage: IntlShape["formatMessage"],
    message?: MessageDescriptor
): TranslatedRegisterOptions<TFieldValues, TFieldName> {
    return {
        validate: {
            isWholeNumber(v) {
                return typeof v === "number" && v % 1 !== 0
                    ? formatMessage(message ?? messages.notAWholeNumber)
                    : undefined;
            },
        },
    };
}

export function customValidator<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    validate: Validate<FieldPathValue<TFieldValues, TFieldName>> | Record<string, Validate<FieldPathValue<TFieldValues, TFieldName>>>
): TranslatedRegisterOptions<TFieldValues, TFieldName> {
    return {
        validate,
    };
}
