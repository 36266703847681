import { ValidationErrorPane } from "components/shared/ValidationErrorPane";
import classNames from "classnames";
import { FieldProps, FormValues, useField } from "informed";
import React, { ReactNode, useEffect } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { InputGroup, InputGroupAddon } from "reactstrap";
import { withDefaultValidation } from "shared/components/formInputs/withDefaultValidation";

interface IProps {
    placeholderMessage?: MessageDescriptor;
    children?: {
        append?: ReactNode,
        prepend?: ReactNode,
    };
}

type Props<T extends FormValues> = IProps & FieldProps<string, T>;

export const TextBoxInput = <T extends FormValues>(props: Props<T>) => {
    const { formatMessage } = useIntl();
    const { fieldState, fieldApi, render, ref, userProps } = useField({ ...props });
    const { maskedValue } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const {
        children,
        append,
        onBlur,
        onChange,
        initialValue,
        title,
        id,
        placeholderMessage,
        ...rest
    } = userProps;

    useEffect(() => {
        if (ref.current) {
            ref.current.setCustomValidity(fieldState.error || "");
        }
    }, [ref, fieldState.error]);

    return render(
        <div className={classNames({ "was-validated": fieldState.touched || fieldState.error }, props.className)}>
            {
                ((children?.prepend) || (children?.append))
                    ?
                    <InputGroup className="flex-wrap">
                        {
                            children?.prepend &&
                            <InputGroupAddon addonType="prepend">
                                {children.prepend}
                            </InputGroupAddon>
                        }
                        <input
                            {...rest}
                            id={id}
                            className="form-control"
                            name={props.field}
                            ref={ref}
                            value={maskedValue || ""}
                            onChange={onChangeEvent}
                            onBlur={onBlurEvent}
                            placeholder={placeholderMessage && formatMessage(placeholderMessage)}
                        />
                        {
                            children?.append &&
                            <InputGroupAddon addonType="append">
                                {children.append}
                            </InputGroupAddon>
                        }
                        <ValidationErrorPane field={props.field} className="w-100" />
                    </InputGroup>
                    :
                    <>
                        <input
                            {...rest}
                            id={id}
                            className="form-control"
                            name={props.field}
                            ref={ref}
                            value={maskedValue || ""}
                            onChange={onChangeEvent}
                            onBlur={onBlurEvent}
                        />
                        <ValidationErrorPane field={props.field} />
                    </>
            }
        </div>
    );

    function onChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(event.target.value);
        setTouched(true);
        if (onChange) {
            onChange(event);
        }
    }

    function onBlurEvent(event: React.FocusEvent<HTMLInputElement>) {
        setTouched(true);

        if (onBlur) {
            onBlur(event);
        }
    }
};

export const TextBox = withDefaultValidation()(TextBoxInput);
