import graphql from "babel-plugin-relay/macro";
import { resendSignUpCodeMutation, resendSignUpCodeMutationResponse } from "generatedQueries/resendSignUpCodeMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation resendSignUpCodeMutation($input: ResendLicensingSignUpCodeInput!) {
        resendLicensingSignUpCode(input: $input) {
            wasSuccessful
            deliveryMedium
            error
        }
    }`;

export function resendSignUpCode(
    environment: IEnvironment,
    email: string,
    onCompleted: (response: resendSignUpCodeMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<resendSignUpCodeMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                input: {
                    email,
                },
            },
        }
    );
}
