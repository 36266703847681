/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmailQueryVariables = {};
export type EmailQueryResponse = {
    readonly account: {
        readonly email: string;
    } | null;
};
export type EmailQuery = {
    readonly response: EmailQueryResponse;
    readonly variables: EmailQueryVariables;
};



/*
query EmailQuery {
  account {
    email
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "EmailQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "EmailQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "85973d397f0c6690b88df3194ad0cabc",
            "id": null,
            "metadata": {},
            "name": "EmailQuery",
            "operationKind": "query",
            "text": "query EmailQuery {\n  account {\n    email\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'ee50b9c761548923383b7c1240142170';
export default node;
