import { Message } from "shared/components/Message";
import { EmailIconLink } from "components/shared/TextLinks";
import { faDog } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { Card, CardBody, CardHeader, CardSubtitle, Col, Row } from "reactstrap";
import { ErrorIdentifier } from "./ErrorIdentifier";
import { fatalErrorMessages } from "./errorMessages";
import styles from "./ErrorPage.module.scss";
import { ErrorPageModalButton } from "./ErrorPageModalButton";

export interface IErrorPageMessages {
    title?: MessageDescriptor;
    subTitle?: MessageDescriptor;
    instructions?: MessageDescriptor;
    urgentInstructions?: MessageDescriptor;
    team?: MessageDescriptor;
    errorIdentifier?: React.ReactNode;
    closeModalButton?: React.ReactNode;
}

export const ErrorPage: FC<IErrorPageMessages> = ({
    title,
    subTitle,
    instructions,
    urgentInstructions,
    team,
    errorIdentifier,
    closeModalButton,
}) => {

    const messages = {
        title: title ?? fatalErrorMessages.defaultTitleLabel,
        subTitle: subTitle ?? fatalErrorMessages.defaultSubTitleLabel,
        intstructions: instructions ?? fatalErrorMessages.defaultInstructionsLabel,
        urgentInstructions: urgentInstructions ?? fatalErrorMessages.defaultUrgentInstructionsLabel,
        team: team ?? fatalErrorMessages.defaultTeamLabel,
    };

    const supportWebpage = "https://www.shelterbuddy.com/support.html";

    return (
        <Card className={classNames("rounded-bottom", styles.errorCard)}>
            <CardHeader className={classNames("rounded-0", styles.errorHeader)}>
                {
                    <div className={styles.title}>
                        <h5>
                            <Row>
                                <Col xs={11}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;<Message message={messages.title} />
                                </Col>
                                <Col>
                                    {
                                        closeModalButton
                                    }
                                </Col>
                            </Row>

                        </h5>
                        <CardSubtitle>
                            <Message message={messages.subTitle} />
                        </CardSubtitle>
                    </div >
                }
            </CardHeader>
            <CardBody>
                {
                    errorIdentifier
                }
                <Message message={messages.intstructions} values={{
                    contact: <EmailIconLink displayText={fatalErrorMessages.emailLabel} link={fatalErrorMessages.emailLabel.defaultMessage} />,
                }} />
                <br />
                <Message message={messages.urgentInstructions} />
                <br />
                <a href={supportWebpage} target="_blank" rel="noreferrer">
                    <Message message={fatalErrorMessages.supportWebpageLabel} />
                </a>
                <br /><br />
                <Message message={messages.team} values={{
                    icon: <FontAwesomeIcon icon={faDog} />,
                }} />
            </CardBody>
        </Card>
    );
};

export {
    ErrorPageModalButton,
    ErrorIdentifier,
    fatalErrorMessages,
};
