/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LicensingPhoneNumberType = "HOME" | "MOBILE" | "WORK" | "%future added value";
export type SyncStatus = "ACTIVE" | "BLOCKED" | "CANCELLED" | "DUPLICATE" | "NEW" | "PENDING" | "REJECTED" | "%future added value";
export type PersonNameSuffixFilter = {
    AND?: Array<PersonNameSuffixFilter> | null;
    OR?: Array<PersonNameSuffixFilter> | null;
    isShown?: boolean | null;
};
export type AccountDetailsFormQueryVariables = {
    suffixesPageSize?: number | null;
    suffixesAfter?: string | null;
    suffixesWhere?: PersonNameSuffixFilter | null;
};
export type AccountDetailsFormQueryResponse = {
    readonly account: {
        readonly id: string;
        readonly status: SyncStatus;
        readonly firstName: string | null;
        readonly middleName: string | null;
        readonly lastName: string | null;
        readonly personNameSuffixId: number | null;
        readonly dateOfBirth: string | null;
        readonly isSelfIdentifiedAsSeniorCitizen: boolean | null;
        readonly driverLicence: string | null;
        readonly phoneNumbers: ReadonlyArray<{
            readonly type: LicensingPhoneNumberType;
            readonly number: string;
        }>;
    } | null;
    readonly configuration: {
        readonly " $fragmentRefs": FragmentRefs<"Hideable_configuration" | "PhoneNumber_configuration">;
    } | null;
    readonly features: {
        readonly isSelfIdentifyAsSeniorCitizenEnabled: boolean;
    } | null;
    readonly isSeniorCitizenMessage: {
        readonly value: string | null;
    } | null;
    readonly isSeniorCitizenLabel: {
        readonly value: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"PersonNameSuffixes_query">;
};
export type AccountDetailsFormQuery = {
    readonly response: AccountDetailsFormQueryResponse;
    readonly variables: AccountDetailsFormQueryVariables;
};



/*
query AccountDetailsFormQuery(
  $suffixesPageSize: Int
  $suffixesAfter: String
  $suffixesWhere: PersonNameSuffixFilter
) {
  account {
    id
    status
    firstName
    middleName
    lastName
    personNameSuffixId
    dateOfBirth
    isSelfIdentifiedAsSeniorCitizen
    driverLicence
    phoneNumbers {
      type
      number
      id
    }
  }
  configuration {
    ...Hideable_configuration
    ...PhoneNumber_configuration
  }
  features {
    isSelfIdentifyAsSeniorCitizenEnabled
  }
  isSeniorCitizenMessage: siteCustomisation(application: "licensing", name: "isSeniorCitizenMessage") {
    value
  }
  isSeniorCitizenLabel: siteCustomisation(application: "licensing", name: "isSeniorCitizenLabel") {
    value
  }
  ...PersonNameSuffixes_query
}

fragment Hideable_configuration on Configuration {
  hiddenFields
}

fragment PersonNameSuffixes_query on Query {
  personNameSuffixes(first: $suffixesPageSize, after: $suffixesAfter, where: $suffixesWhere) {
    edges {
      node {
        id
        displayId
        name
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment PhoneNumber_configuration on Configuration {
  licensingPhoneNumberMask {
    value
    isRegex
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "suffixesAfter"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "suffixesPageSize"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "suffixesWhere"
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "middleName",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "personNameSuffixId",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateOfBirth",
        "storageKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSelfIdentifiedAsSeniorCitizen",
        "storageKey": null
    } as any, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "driverLicence",
        "storageKey": null
    } as any, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
    } as any, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "number",
        "storageKey": null
    } as any, v14 = {
        "alias": null,
        "args": null,
        "concreteType": "Features",
        "kind": "LinkedField",
        "name": "features",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSelfIdentifyAsSeniorCitizenEnabled",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v15 = {
        "kind": "Literal",
        "name": "application",
        "value": "licensing"
    } as any, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    } as any, v17 = [
        (v16 /*: any*/)
    ], v18 = {
        "alias": "isSeniorCitizenMessage",
        "args": [
            (v15 /*: any*/),
            {
                "kind": "Literal",
                "name": "name",
                "value": "isSeniorCitizenMessage"
            }
        ],
        "concreteType": "SiteCustomisation",
        "kind": "LinkedField",
        "name": "siteCustomisation",
        "plural": false,
        "selections": (v17 /*: any*/),
        "storageKey": "siteCustomisation(application:\"licensing\",name:\"isSeniorCitizenMessage\")"
    } as any, v19 = {
        "alias": "isSeniorCitizenLabel",
        "args": [
            (v15 /*: any*/),
            {
                "kind": "Literal",
                "name": "name",
                "value": "isSeniorCitizenLabel"
            }
        ],
        "concreteType": "SiteCustomisation",
        "kind": "LinkedField",
        "name": "siteCustomisation",
        "plural": false,
        "selections": (v17 /*: any*/),
        "storageKey": "siteCustomisation(application:\"licensing\",name:\"isSeniorCitizenLabel\")"
    } as any, v20 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "suffixesAfter"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "suffixesPageSize"
        } as any,
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "suffixesWhere"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AccountDetailsFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingAccountPhoneNumber",
                            "kind": "LinkedField",
                            "name": "phoneNumbers",
                            "plural": true,
                            "selections": [
                                (v12 /*: any*/),
                                (v13 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Hideable_configuration"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PhoneNumber_configuration"
                        }
                    ],
                    "storageKey": null
                },
                (v14 /*: any*/),
                (v18 /*: any*/),
                (v19 /*: any*/),
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PersonNameSuffixes_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AccountDetailsFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingAccountPhoneNumber",
                            "kind": "LinkedField",
                            "name": "phoneNumbers",
                            "plural": true,
                            "selections": [
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hiddenFields",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingConfigMaskCharacter",
                            "kind": "LinkedField",
                            "name": "licensingPhoneNumberMask",
                            "plural": true,
                            "selections": [
                                (v16 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isRegex",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v14 /*: any*/),
                (v18 /*: any*/),
                (v19 /*: any*/),
                {
                    "alias": null,
                    "args": (v20 /*: any*/),
                    "concreteType": "PersonNameSuffixConnection",
                    "kind": "LinkedField",
                    "name": "personNameSuffixes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PersonNameSuffixEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PersonNameSuffix",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "displayId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v20 /*: any*/),
                    "filters": [
                        "where"
                    ],
                    "handle": "connection",
                    "key": "PersonNameSuffixes_query_personNameSuffixes",
                    "kind": "LinkedHandle",
                    "name": "personNameSuffixes"
                }
            ]
        },
        "params": {
            "cacheID": "df58ce91047c0e63a3d8473166454591",
            "id": null,
            "metadata": {},
            "name": "AccountDetailsFormQuery",
            "operationKind": "query",
            "text": "query AccountDetailsFormQuery(\n  $suffixesPageSize: Int\n  $suffixesAfter: String\n  $suffixesWhere: PersonNameSuffixFilter\n) {\n  account {\n    id\n    status\n    firstName\n    middleName\n    lastName\n    personNameSuffixId\n    dateOfBirth\n    isSelfIdentifiedAsSeniorCitizen\n    driverLicence\n    phoneNumbers {\n      type\n      number\n      id\n    }\n  }\n  configuration {\n    ...Hideable_configuration\n    ...PhoneNumber_configuration\n  }\n  features {\n    isSelfIdentifyAsSeniorCitizenEnabled\n  }\n  isSeniorCitizenMessage: siteCustomisation(application: \"licensing\", name: \"isSeniorCitizenMessage\") {\n    value\n  }\n  isSeniorCitizenLabel: siteCustomisation(application: \"licensing\", name: \"isSeniorCitizenLabel\") {\n    value\n  }\n  ...PersonNameSuffixes_query\n}\n\nfragment Hideable_configuration on Configuration {\n  hiddenFields\n}\n\nfragment PersonNameSuffixes_query on Query {\n  personNameSuffixes(first: $suffixesPageSize, after: $suffixesAfter, where: $suffixesWhere) {\n    edges {\n      node {\n        id\n        displayId\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment PhoneNumber_configuration on Configuration {\n  licensingPhoneNumberMask {\n    value\n    isRegex\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '8433b0c432267cd5f9923ad0550474a0';
export default node;
