/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AddressFormJurisdictionQueryVariables = {
    state?: string | null;
    suburb?: string | null;
    postcode?: string | null;
};
export type AddressFormJurisdictionQueryResponse = {
    readonly licensingJurisdictionSearch: ReadonlyArray<{
        readonly jurisdictionId: number;
        readonly name: string;
    }> | null;
};
export type AddressFormJurisdictionQuery = {
    readonly response: AddressFormJurisdictionQueryResponse;
    readonly variables: AddressFormJurisdictionQueryVariables;
};



/*
query AddressFormJurisdictionQuery(
  $state: String
  $suburb: String
  $postcode: String
) {
  licensingJurisdictionSearch(state: $state, suburb: $suburb, postcode: $postcode) {
    jurisdictionId
    name
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "postcode"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "state"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "suburb"
    } as any, v3 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "postcode",
                    "variableName": "postcode"
                },
                {
                    "kind": "Variable",
                    "name": "state",
                    "variableName": "state"
                },
                {
                    "kind": "Variable",
                    "name": "suburb",
                    "variableName": "suburb"
                }
            ],
            "concreteType": "LicensingJurisdiction",
            "kind": "LinkedField",
            "name": "licensingJurisdictionSearch",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "jurisdictionId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AddressFormJurisdictionQuery",
            "selections": (v3 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AddressFormJurisdictionQuery",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "cacheID": "c97fd89b0698a5041d553a4c218aed2b",
            "id": null,
            "metadata": {},
            "name": "AddressFormJurisdictionQuery",
            "operationKind": "query",
            "text": "query AddressFormJurisdictionQuery(\n  $state: String\n  $suburb: String\n  $postcode: String\n) {\n  licensingJurisdictionSearch(state: $state, suburb: $suburb, postcode: $postcode) {\n    jurisdictionId\n    name\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '23dbfe872404319fb6fafd0c8eb69600';
export default node;
