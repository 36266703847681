import Flow from "@flowjs/flow.js";
import flowjs from "flowjs";
import { getApiGatewayUrl } from "infrastructure/environment";
import { ILogger } from "infrastructure/logger";
import { getToken } from "infrastructure/token";
import { UploadStatus } from "infrastructure/useUpload";
import { flatten } from "lodash";
import { Dispatch, SetStateAction } from "react";
import urljoin from "url-join";

export type FlowFile = flowjs.FlowFile | flowjs.FlowFile[];

export function filesAddedHandler(
    allowedFileExtensions: string[],
    setStatus: Dispatch<SetStateAction<UploadStatus>>,
    logger: ILogger,
    getFileName: ((index: number | undefined) => string) | undefined) {
    return (files: FlowFile) => {
        if (!filesAreValid()) {
            logger.information(
                "An invalid file was provided for upload",
                {
                    allowedFileExtensions,
                    files: getFileNames(files),
                });
            setStatus("invalid");
            return false;
        }

        if (getFileName) {
            if (!Array.isArray(files)) {
                const newFileName = `${getFileName(undefined)}.${files.getExtension()}`;
                logger.debug("Renaming file", { oldFileName: files.name, newFileName });
                files.name = newFileName;
            } else {
                files.forEach((file, index) => {
                    const newFileName = `${getFileName(index)}.${file.getExtension()}`;
                    logger.debug("Renaming file", { oldFileName: file.name, newFileName, index });
                    file.name = newFileName;
                });
            }
        }

        return true;

        function filesAreValid() {
            if (!Array.isArray(files)) {
                return allowedFileExtensions.indexOf(files.getExtension()) >= 0;
            }
            return !files.some(file => allowedFileExtensions.indexOf(file.getExtension()) === -1);
        }
    };
}

export function filesSubmittedHandler(
    instance: flowjs.Flow,
    setStatus: Dispatch<SetStateAction<UploadStatus>>,
    setProgress: Dispatch<SetStateAction<number | undefined>>,
    logger: ILogger) {
    return (files: FlowFile[]) => {
        if (!files.length) {
            return;
        }

        logger.verbose("Upload started", { files: flatten(files.map(file => getFileNames(file))) });

        instance.upload();

        setProgress(0);
        setStatus("started");
    };
}

export function progressHandler(instance: flowjs.Flow, setProgress: Dispatch<SetStateAction<number>>) {
    return () => {
        setProgress(instance.progress());
    };
}

export function fileSuccessHandler(
    setStatus: Dispatch<SetStateAction<UploadStatus>>,
    setProgress: Dispatch<SetStateAction<number | undefined>>,
    setStorageId: Dispatch<SetStateAction<number | null>>,
    logger: ILogger) {
    return (file: FlowFile, message: string, chunk: flowjs.FlowChunk) => {
        const newStorageId = parseInt(message, 10);

        if (!isNaN(newStorageId)) {
            logger.verbose("Upload succeeded", { uploadMessage: message, newStorageId, files: getFileNames(file) });
            setProgress(undefined);
            setStatus("complete");
            setStorageId(newStorageId);
        } else {
            onErrorHandler(setStatus, setProgress, logger, "fileSuccess")(message, file, chunk);
        }
    };
}

export function onFileErrorHandler(
    setStatus: Dispatch<SetStateAction<UploadStatus>>,
    setProgress: Dispatch<SetStateAction<number | undefined>>,
    logger: ILogger) {
    return (file: FlowFile, message: string, chunk: flowjs.FlowChunk) => {
        onErrorHandler(setStatus, setProgress, logger, "fileError")(message, file, chunk);
    };
}

export function onErrorHandler(
    setStatus: Dispatch<SetStateAction<UploadStatus>>,
    setProgress: Dispatch<SetStateAction<number | undefined>>,
    logger: ILogger,
    event: string) {
    return (message: string, file: FlowFile, chunk: flowjs.FlowChunk) => {
        setProgress(undefined);

        if (chunk.xhr.status === 401) {
            logger.information("Upload failed as user is not authenticated", { uploadMessage: message, files: getFileNames(file) });
            setStatus("unauthenticated");
        } else {
            logger.error(
                "Upload failed",
                {
                    event,
                    status: chunk.xhr.status,
                    statusText: chunk.xhr.statusText,
                    uploadMessage: message,
                    files: getFileNames(file),
                });
            setStatus("failed");
        }
    };
}

export function uploadStartHandler(setStorageId: Dispatch<SetStateAction<number | null>>) {
    return () => {
        setStorageId(null);
    };
}

export function getFlow(
    categoryStaticId: string,
    allowedFileExtensions: string[],
    setStatus: Dispatch<SetStateAction<UploadStatus>>,
    setProgress: Dispatch<SetStateAction<number | undefined>>,
    setStorageId: Dispatch<SetStateAction<number | null>>,
    logger: ILogger,
    getFileName?: (index: number | undefined) => string) {
    const instance = new Flow({
        allowDuplicateUploads: true,
        headers: { "Authorization": `Bearer ${getToken()}` },
        query: { categoryStaticId },
        target: urljoin(getApiGatewayUrl(), "storage/flowjs"),
        withCredentials: true,
    });

    instance.on("filesAdded", filesAddedHandler(allowedFileExtensions, setStatus, logger, getFileName));
    instance.on("filesSubmitted", filesSubmittedHandler(instance, setStatus, setProgress, logger));
    instance.on("progress", progressHandler(instance, setProgress));
    instance.on("fileSuccess", fileSuccessHandler(setStatus, setProgress, setStorageId, logger));
    instance.on("fileError", onFileErrorHandler(setStatus, setProgress, logger));
    instance.on("error", onErrorHandler(setStatus, setProgress, logger, "error"));
    instance.on("uploadStart", uploadStartHandler(setStorageId));

    return instance;
}

function getFileNames(files: FlowFile) {
    return Array.isArray(files)
        ? files.map(file => file.name)
        : files.name;
}
