import { ErrorMessage } from "components/shared/ErrorMessage";
import { ConfirmForgotLicensingPasswordError } from "generatedQueries/confirmForgotPasswordMutation.graphql";
import { ForgotLicensingPasswordError } from "generatedQueries/sendForgotPasswordMutation.graphql";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { routes } from "routes/routes";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";
import { messages as passwordStrengthMessages } from "shared/passwordStrengthValidator";

export const messages = mapMessages("components.user.forgotpassword", {
    errorChangingPassword: "There was an error while changing the password.",
    errorSendingForgotPassword: "There was an error while sending the forgot password email.",
    forgotPasswordCodeMismatch: "The code provided is not correct, please check the code before proceeding again.",
    forgotPasswordDeliveryFailed: "Forgot password delivery failed, please try again later.",
    signUpNotConfirmed: "You have not completed the sign up, please check your email for the confirmation, or visit the <a>confirmation page</a> to send it again.",
});

export type GeneralForgotPasswordErrorCode = "ERRORSENDING" | "CHANGEPASSWORDERROR";

interface IProps {
    error: undefined | GeneralForgotPasswordErrorCode | ConfirmForgotLicensingPasswordError | ForgotLicensingPasswordError;
}

export const ForgotPasswordError: FC<IProps> = ({
    error,
}) => <>
        {
            error === "CODEDELIVERYFAILED" &&
            <ErrorMessage message={messages.forgotPasswordDeliveryFailed} />
        }
        {
            error === "USERNOTCONFIRMED" &&
            <WarningMessage
                message={messages.signUpNotConfirmed}
                messageValues={{
                    a: msg => <Link to={routes.user.confirm.create({})}>{msg}</Link>,
                }} />
        }
        {
            error === "PASSWORDINVALID" &&
            <WarningMessage message={passwordStrengthMessages.passwordStrengthError} />
        }
        {
            error === "CODEMISMATCH" &&
            <WarningMessage message={messages.forgotPasswordCodeMismatch} />
        }
        {
            error === "ERRORSENDING" &&
            <ErrorMessage message={messages.errorSendingForgotPassword} />
        }
        {
            error === "CHANGEPASSWORDERROR" &&
            <ErrorMessage message={messages.errorChangingPassword} />
        }
    </>;
