/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ConfirmLicensingAccountSignUpError = "ALREADYCONFIRMED" | "CODEEXPIRED" | "USERNOTFOUND" | "%future added value";
export type ConfirmLicensingSignUpInput = {
    code: string;
    email: string;
};
export type confirmSignUpMutationVariables = {
    input: ConfirmLicensingSignUpInput;
};
export type confirmSignUpMutationResponse = {
    readonly confirmLicensingSignUp: {
        readonly wasSuccessful: boolean;
        readonly error: ConfirmLicensingAccountSignUpError | null;
    } | null;
};
export type confirmSignUpMutation = {
    readonly response: confirmSignUpMutationResponse;
    readonly variables: confirmSignUpMutationVariables;
};



/*
mutation confirmSignUpMutation(
  $input: ConfirmLicensingSignUpInput!
) {
  confirmLicensingSignUp(input: $input) {
    wasSuccessful
    error
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ConfirmLicensingAccountSignUp",
            "kind": "LinkedField",
            "name": "confirmLicensingSignUp",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "wasSuccessful",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "confirmSignUpMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "confirmSignUpMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "eea4c3d926c707c99e22cdaadc0a75c1",
            "id": null,
            "metadata": {},
            "name": "confirmSignUpMutation",
            "operationKind": "mutation",
            "text": "mutation confirmSignUpMutation(\n  $input: ConfirmLicensingSignUpInput!\n) {\n  confirmLicensingSignUp(input: $input) {\n    wasSuccessful\n    error\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '63e0aeb53b19ad0965c55617d17ae1f3';
export default node;
