/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicensingSignUpInput = {
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    password: string;
    recaptcha: string;
};
export type signUpMutationVariables = {
    input: LicensingSignUpInput;
};
export type signUpMutationResponse = {
    readonly licensingSignUp: {
        readonly success: boolean;
        readonly failureReason: string | null;
    } | null;
};
export type signUpMutation = {
    readonly response: signUpMutationResponse;
    readonly variables: signUpMutationVariables;
};



/*
mutation signUpMutation(
  $input: LicensingSignUpInput!
) {
  licensingSignUp(input: $input) {
    success
    failureReason
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "LicensingSignUpResult",
            "kind": "LinkedField",
            "name": "licensingSignUp",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "failureReason",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "signUpMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "signUpMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "5a216cbd29dc0c9ff5f89004394f22ab",
            "id": null,
            "metadata": {},
            "name": "signUpMutation",
            "operationKind": "mutation",
            "text": "mutation signUpMutation(\n  $input: LicensingSignUpInput!\n) {\n  licensingSignUp(input: $input) {\n    success\n    failureReason\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'acdeb15c62f3a01dc6b3a05bf2c0bff3';
export default node;
