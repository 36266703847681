import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { HomeContent } from "components/home/HomeContent";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { userActions } from "domain/user/userActions";
import { HomeQuery } from "generatedQueries/HomeQuery.graphql";
import { parse } from "query-string";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { routes } from "routes/routes";
import { Query } from "shared/components/Query";
import { sharedMessages } from "shared/sharedMessages";
import { RootState } from "store/store";

interface IStateProps {
    isLoggedIn: boolean;
}

interface IDispatchProps {
    setPostLoginRedirect: (redirectType: string) => void;
}

type Props = RouteComponentProps<{}> & IStateProps & IDispatchProps;

interface IRedirectQueryString {
    redirect: string;
}

function isRedirectQueryString(queryString: {}): queryString is IRedirectQueryString {
    return typeof (queryString as IRedirectQueryString).redirect === "string";
}

export const ConnectedHome: FC<Props> = ({
    location,
    isLoggedIn,
    setPostLoginRedirect,
}) => {
    const queryString = parse(location.search);

    useEffect(() => {
        if (isRedirectQueryString(queryString) && queryString.redirect && !isLoggedIn) {
            setPostLoginRedirect(queryString.redirect);
        }
    });

    if (isRedirectQueryString(queryString) && queryString.redirect && isLoggedIn) {
        return <Redirect to={routes.pets.index.create({})} />;
    }

    return <Query<HomeQuery>
        query={graphql`
            query HomeQuery {
                configuration {
                    ...HomeContent_configuration
                }
                landingPage: siteCustomisation(application: "licensing", name: "landingPage") {
                    ...HomeContent_landingPage
                }
            }`}
        variables={{}}>
        {({ error, props }) => {
            if (error) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            if (!props || !props.configuration) {
                return <LoadingMessage />;
            }

            return <HomeContent
                showRedirectMessageWhenLoggedOut={!!(isRedirectQueryString(queryString) && queryString.redirect)}
                configuration={props.configuration}
                landingPage={props.landingPage} />;
        }}
    </Query>;
};

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => bindActionCreators({
    setPostLoginRedirect: userActions.setPostLoginRedirect,
}, dispatch);

export const Home = connect(mapStateToProps, mapDispatchToProps)(ConnectedHome);
