import classNames from "classnames";
import { asField, FieldContext, FieldProps } from "informed";
import moment from "moment";
import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useIntl } from "react-intl";
import styles from "./DatePicker.module.scss";
import { DatePickerHeader, IDatePickerHeaderProps } from "./DatePickerHeader";
import { DatePickerInput } from "./DatePickerInput";
import { datePickerMessages } from "./datePickerMessages";
import { WithDefaultValidation } from "./WithDefaultValidation";

type ReactDatePickerPropsToIgnore = "onChange" | "customTimeInput";

type Props<T> = Omit<ReactDatePickerProps & FieldProps<string, T> & FieldContext<string>, ReactDatePickerPropsToIgnore>;

export function DatePickerControl<T>(props: Props<T>) {
    const intl = useIntl();

    const {
        autoComplete,
        field,
        fieldState,
        fieldApi,
        ...rest
    } = props;

    const customInput = <DatePickerInput data-cy={field} field={field} error={fieldState.error} />;

    return <div className={classNames(styles.wide, { "was-validated": fieldState.touched })}>
        <ReactDatePicker
            data-cy={`${field}Picker`}
            name={field}
            dayClassName={dayClassName}
            customInput={customInput}
            customInputRef="forwardRef"
            className="form-control"
            selected={fieldState.value ? moment(fieldState.value).toDate() : null}
            onSelect={onChange}
            onChange={onChange}
            onChangeRaw={onChangeRaw}
            placeholderText={moment.localeData().longDateFormat("L").toLocaleUpperCase()}
            dateFormat={moment.localeData().longDateFormat("L").replace("DD", "dd").replace("D", "d").replace("YYYY", "yyyy").replace("YY", "yy")}
            nextMonthButtonLabel={intl.formatMessage(datePickerMessages.nextMonthButtonLabel)}
            previousMonthButtonLabel={intl.formatMessage(datePickerMessages.previousMonthButtonLabel)}
            renderCustomHeader={renderHeader}
            disabledKeyboardNavigation={true}
            strictParsing={true}
            autoComplete={autoComplete ?? "off"}
            {...rest} />
    </div>;

    function renderHeader(headerProps: IDatePickerHeaderProps) {
        return <DatePickerHeader {...headerProps} />;
    }

    function dayClassName(date: Date) {
        return `day-${moment(date).format("YYYY-MM-DD")}`;
    }

    function onChange(date: Date | null) {
        const value = date && moment(date).format("YYYY-MM-DD");
        fieldApi.setValue(value || "");
        fieldApi.setTouched(true);
    }

    function onChangeRaw(event: React.FocusEvent<HTMLInputElement>) {
        const supportedFormats = [
            moment.localeData().longDateFormat("l"),
            moment.localeData().longDateFormat("L"),
            moment.localeData().longDateFormat("l").replace("YYYY", "YY"),
            moment.localeData().longDateFormat("L").replace("YYYY", "YY"),
            moment.localeData().longDateFormat("ll"),
            moment.localeData().longDateFormat("LL"),
        ];

        const date = moment(event.target.value, supportedFormats, true);

        onChange(date.isValid() ? date.toDate() : null);
    }
}

export const DatePicker = WithDefaultValidation()(asField<Omit<ReactDatePickerProps, ReactDatePickerPropsToIgnore>, string>(DatePickerControl));
