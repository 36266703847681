import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { WizardContext } from "shared/components/Wizard";

type RenderPropChildren = ((props: { onNext: (skip?: number) => void, onPrevious: (skip?: number) => void }) => React.ReactNode);

export interface IWizardPageProps {
    children: RenderPropChildren;
    completeIcon?: React.ReactNode;
    title: string | MessageDescriptor;
    description?: string | MessageDescriptor;
    icon?: React.ReactNode;
}

export const WizardPage: FC<IWizardPageProps> = props =>
    <WizardContext.Consumer>{props.children}</WizardContext.Consumer>;
