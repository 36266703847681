import { Message } from "shared/components/Message";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import React, { FC, ReactElement } from "react";
import { MessageDescriptor } from "react-intl";
import { Alert } from "reactstrap";

interface IProps {
    className?: string;
    headerValues?: { [key: string]: string | PrimitiveType | ReactElement | FormatXMLElementFn };
    heading?: MessageDescriptor;
    message: MessageDescriptor;
    messageValues?: { [key: string]: string | PrimitiveType | ReactElement | FormatXMLElementFn };
}

export const ErrorMessage: FC<IProps> = (props) => {
    return <Alert className={props.className} color="danger" fade={false}>
        {props.heading && <h4 className="alert-heading"><Message message={props.heading} values={props.headerValues} /></h4>}
        <Message message={props.message} values={props.messageValues} />
    </Alert>;
};
