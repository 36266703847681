import React, { ComponentType } from "react";
import { Environment, useRelayEnvironment } from "react-relay";

export interface IRelayEnvironment {
    environment: Environment;
}

export const withEnvironment = <TComponentProps extends IRelayEnvironment>(WrappedComponent: ComponentType<TComponentProps>)
    : ComponentType<Omit<TComponentProps, keyof IRelayEnvironment>> =>
    props => {
        const environment = useRelayEnvironment();
        return <WrappedComponent {...{ environment, ...props } as unknown as TComponentProps} />;
    };
