/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnimalStatus_configuration = {
    readonly organisationContact: string;
    readonly " $refType": "AnimalStatus_configuration";
};
export type AnimalStatus_configuration$data = AnimalStatus_configuration;
export type AnimalStatus_configuration$key = {
    readonly " $data"?: AnimalStatus_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"AnimalStatus_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnimalStatus_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '5080d473f6f1e04c6e62c7bf8d8cb7a6';
export default node;
