import { IntlShape, MessageDescriptor } from "react-intl";
import { AddToastPayload, BasicToastrOptions, LightToastrOptions, toastType } from "react-redux-toastr";

declare type ToastOptions = BasicToastrOptions | LightToastrOptions | undefined;
declare interface ToastMessage {
    type: toastType;
    message: MessageDescriptor;
    intl: IntlShape | null | undefined;
}

const successOptions: ToastOptions = { removeOnHover: true, timeOut: 3000, progressBar: true, showCloseButton: false };
const errorOptions: ToastOptions = { removeOnHover: false, timeOut: 0, progressBar: false, showCloseButton: true };

const selectOptions = (type: toastType): ToastOptions => type === "error" ? errorOptions : successOptions;

export const createToastMessage = (toast: ToastMessage): AddToastPayload => {
    const message = toast.intl ? toast.intl.formatMessage(toast.message) : toast.message.defaultMessage;
    return { type: toast.type, message, options: selectOptions(toast.type) };
};
