import { defineMessages } from "react-intl";

const idPrefix = "domain.home.";

export const homeMessages = defineMessages({
    clickToSearchExistingPetsInfoMessage: {
        defaultMessage: "Click {link} to search for your existing licensed pet.",
        id: idPrefix + "clicktosearchexistingpets-message",
    },
    detailsAreIncompleteMessage: {
        defaultMessage: "Account details are required. <a>Click here</a> to complete your account details.",
        id: idPrefix + "details-are-incomplete-message",
    },
    loginLabel: {
        defaultMessage: "Sign in / Create an account",
        id: idPrefix + "login-label",
    },
    logoutLabel: {
        defaultMessage: "Sign out",
        id: idPrefix + "logout-label",
    },
    noPetsInfoMessage: {
        defaultMessage: "It looks like you don't have any pets.",
        id: idPrefix + "nopets-message",
    },
});
