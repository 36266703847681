/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicenseTypeProviderQueryVariables = {
    jurisdictionId: number;
    animalTypeId: string;
    isDesexed: boolean;
    isMicrochipped: boolean;
    dateOfBirth?: string | null;
    commencementDate: string;
    isRenewal: boolean;
};
export type LicenseTypeProviderQueryResponse = {
    readonly onlineLicenceTypes: ReadonlyArray<{
        readonly licenceTypeId: number;
        readonly name: string | null;
        readonly isLifetime: boolean;
        readonly expiryMonths: number | null;
        readonly expiryDate: {
            readonly calculatedExpiryDate: string;
        } | null;
        readonly amount: number;
        readonly currency: string | null;
    }> | null;
};
export type LicenseTypeProviderQuery = {
    readonly response: LicenseTypeProviderQueryResponse;
    readonly variables: LicenseTypeProviderQueryVariables;
};



/*
query LicenseTypeProviderQuery(
  $jurisdictionId: Int!
  $animalTypeId: ID!
  $isDesexed: Boolean!
  $isMicrochipped: Boolean!
  $dateOfBirth: LocalDate
  $commencementDate: LocalDate!
  $isRenewal: Boolean!
) {
  onlineLicenceTypes(jurisdictionId: $jurisdictionId, animalTypeId: $animalTypeId, isDesexed: $isDesexed, isMicrochipped: $isMicrochipped, dateOfBirth: $dateOfBirth) {
    licenceTypeId
    name
    isLifetime
    expiryMonths
    expiryDate {
      calculatedExpiryDate(commencementDate: $commencementDate, isRenewal: $isRenewal)
    }
    amount
    currency
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "animalTypeId"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "commencementDate"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "dateOfBirth"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "isDesexed"
    } as any, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "isMicrochipped"
    } as any, v5 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "isRenewal"
    } as any, v6 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "jurisdictionId"
    } as any, v7 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "animalTypeId",
                    "variableName": "animalTypeId"
                },
                {
                    "kind": "Variable",
                    "name": "dateOfBirth",
                    "variableName": "dateOfBirth"
                },
                {
                    "kind": "Variable",
                    "name": "isDesexed",
                    "variableName": "isDesexed"
                },
                {
                    "kind": "Variable",
                    "name": "isMicrochipped",
                    "variableName": "isMicrochipped"
                },
                {
                    "kind": "Variable",
                    "name": "jurisdictionId",
                    "variableName": "jurisdictionId"
                }
            ],
            "concreteType": "LicenceType",
            "kind": "LinkedField",
            "name": "onlineLicenceTypes",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "licenceTypeId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isLifetime",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expiryMonths",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenceExpiryDate",
                    "kind": "LinkedField",
                    "name": "expiryDate",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "commencementDate",
                                    "variableName": "commencementDate"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "isRenewal",
                                    "variableName": "isRenewal"
                                }
                            ],
                            "kind": "ScalarField",
                            "name": "calculatedExpiryDate",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/),
                (v6 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "LicenseTypeProviderQuery",
            "selections": (v7 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v6 /*: any*/),
                (v0 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v2 /*: any*/),
                (v1 /*: any*/),
                (v5 /*: any*/)
            ],
            "kind": "Operation",
            "name": "LicenseTypeProviderQuery",
            "selections": (v7 /*: any*/)
        },
        "params": {
            "cacheID": "7e00ed4c62c016972f02f35375a35f7c",
            "id": null,
            "metadata": {},
            "name": "LicenseTypeProviderQuery",
            "operationKind": "query",
            "text": "query LicenseTypeProviderQuery(\n  $jurisdictionId: Int!\n  $animalTypeId: ID!\n  $isDesexed: Boolean!\n  $isMicrochipped: Boolean!\n  $dateOfBirth: LocalDate\n  $commencementDate: LocalDate!\n  $isRenewal: Boolean!\n) {\n  onlineLicenceTypes(jurisdictionId: $jurisdictionId, animalTypeId: $animalTypeId, isDesexed: $isDesexed, isMicrochipped: $isMicrochipped, dateOfBirth: $dateOfBirth) {\n    licenceTypeId\n    name\n    isLifetime\n    expiryMonths\n    expiryDate {\n      calculatedExpiryDate(commencementDate: $commencementDate, isRenewal: $isRenewal)\n    }\n    amount\n    currency\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'c8ee9485472fdea98c61ab7c31358e1e';
export default node;
