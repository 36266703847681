import { IPaymentFormChildProps } from "components/payment/PaymentForm";
import { StandardPaymentButtonLayout } from "components/payment/StandardPaymentButtonLayout";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { paymentMessages } from "domain/payment/paymentMessages";
import { LicensingPaymentMethodRequestInput } from "generatedQueries/processPaymentMethodMutation.graphql";
import isEqual from "lodash/isEqual";
import React, { FC, useEffect, useState } from "react";
import { CardElement, injectStripe, ReactStripeElements } from "react-stripe-elements";
import { FormText } from "reactstrap";
import { sharedMessages } from "shared/sharedMessages";

type Props = IPaymentFormChildProps & ReactStripeElements.InjectedStripeProps;

const StripeFormInternal: FC<Props> = ({
    stripe,
    paymentReady,
    requestState,
    paymentSucceeded,
    payButton,
    errorMessages,
}) => {
    const [token, setToken] = useState<string | null>(null);
    const [validationError, setValidationError] = useState<stripe.Error | null>(null);
    const [tokenError, setTokenError] = useState<stripe.Error | null>(null);

    useEffect(() => {
        if (paymentSucceeded) {
            setToken(null);
            setValidationError(null);
        }
    }, [paymentSucceeded]);

    useEffect(() => {
        const paymentMethodRequest: LicensingPaymentMethodRequestInput | null = token && !validationError
            ? {
                stripePaymentRequest: {
                    tokenId: token,
                },
            }
            : null;

        if (!isEqual(paymentMethodRequest, requestState)) {
            paymentReady(paymentMethodRequest);
        }
    }, [paymentReady, requestState, token, validationError]);

    return (
        <>
            <div className="form-group mb-3 p-2">
                <CardElement onReady={autofocus} onChange={onChange} />
                {
                    validationError &&
                    <FormText color="danger">
                        <Message message={paymentMessages.validationWarningMessage} />
                    </FormText>
                }
                {
                    tokenError &&
                    <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} className={"mt-2"} />
                }
            </div>
            <StandardPaymentButtonLayout payButton={payButton} errorMessages={errorMessages} />
        </>
    );

    function autofocus(el: stripe.elements.Element) {
        el.focus();
    }

    async function onChange(event: stripe.elements.ElementChangeResponse) {
        if (!stripe || !event.complete || event.error) {
            setToken(null);
            setValidationError(event.error || null);

            return;
        }

        const tokenResponse = await stripe.createToken();

        if (!tokenResponse || !tokenResponse.token || tokenResponse.error) {
            setToken(null);
            setTokenError((tokenResponse && tokenResponse.error) || null);

            return;
        }

        setToken(tokenResponse.token.id);
    }
};

export const StripeForm = injectStripe(StripeFormInternal);
