import { Message } from "shared/components/Message";
import { IValidationMessage, ValidationErrors, ValidationMessage } from "components/shared/validations";
import * as React from "react";
import { MessageDescriptor } from "react-intl";

const isMessageWithErrors = (error: ValidationMessage): error is IValidationMessage => !!(error as IValidationMessage).message;
const isMessage = (error: ValidationMessage): error is MessageDescriptor => !!(error as MessageDescriptor).defaultMessage;
export const isElement = (element: string | JSX.Element): element is JSX.Element => (element as JSX.Element).props !== undefined;

export const ErrorPane = (props: { errors: ValidationErrors, style?: React.CSSProperties, className?: string; }) => {
    if (!props.errors) {
        return null;
    }

    const errors = Array.isArray(props.errors) ? props.errors : [props.errors];
    const messages = errors
        .filter(error => !Array.isArray(error))
        .map((error, index) => isMessageWithErrors(error)
            ? <Message key={index} message={error.message} values={error.values} />
            : isMessage(error) ? <Message key={index} message={error} /> : error);

    if (!messages.length) {
        return null;
    }

    sortErrorMessages(messages);

    return <div className={props.className ? `invalid-feedback ${props.className}` : "invalid-feedback"} style={props.style}>
        {formatErrorsForDisplay(messages)}
    </div>;
};

export const sortErrorMessages = (elements: (string | JSX.Element)[]) => {

    if (!elements || elements.length === 1) {
        return elements;
    }

    return (elements as (string | JSX.Element)[]).sort((a, b) => {

        if (isElement(a) && isElement(b)) {
            if (a.type === Message && b.type === Message) {
                return a.props.message.defaultMessage.length - b.props.message.defaultMessage.length;
            }
        } else if (!isElement(a) && isElement(b)) {
            if (b.type === Message) {
                return a.length - b.props.message.defaultMessage.length;
            }
        } else if (isElement(a) && !isElement(b)) {
            if (a.type === Message) {
                return a.props.message.defaultMessage.length - b.length;
            }
        } else if (!isElement(a) && !isElement(b)) {
            return a.length - b.length;
        }

        // Do not sort unknown component.
        return 0;
    });
};

export const formatErrorsForDisplay = (elements: (string | JSX.Element)[]): (string | JSX.Element)[] => {

    if (!elements || elements.length === 1) {
        return elements;
    }

    const displayElements: JSX.Element[] = [];

    elements.forEach((element, index) => {
        displayElements.push(<span key={index}> {element} <br /></span>);
    });

    return displayElements;
};
