/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicensingAnimalIdentificationType = "LICENCE" | "MICROCHIP" | "TATTOO" | "%future added value";
export type BulkLicenceRenewalFormQueryVariables = {};
export type BulkLicenceRenewalFormQueryResponse = {
    readonly account: {
        readonly animals: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                    readonly animalTypeId: string;
                    readonly isDesexed: boolean;
                    readonly rabiesVaccination: {
                        readonly expiryDate: string;
                    } | null;
                    readonly licenceTypeId: number | null;
                    readonly licenceType: {
                        readonly expiryDate: {
                            readonly day: number;
                            readonly month: number;
                            readonly years: number;
                        } | null;
                        readonly expiryMonths: number | null;
                        readonly isLifetime: boolean;
                    } | null;
                    readonly identifications: ReadonlyArray<{
                        readonly type: LicensingAnimalIdentificationType;
                    }> | null;
                    readonly dateOfBirth: string;
                };
            }> | null;
        } | null;
        readonly physicalAddress: {
            readonly jurisdictionId: number | null;
        } | null;
    } | null;
};
export type BulkLicenceRenewalFormQuery = {
    readonly response: BulkLicenceRenewalFormQueryResponse;
    readonly variables: BulkLicenceRenewalFormQueryVariables;
};



/*
query BulkLicenceRenewalFormQuery {
  account {
    animals {
      edges {
        node {
          id
          name
          animalTypeId
          isDesexed
          rabiesVaccination {
            expiryDate
            id
          }
          licenceTypeId
          licenceType {
            expiryDate {
              day
              month
              years
            }
            expiryMonths
            isLifetime
          }
          identifications {
            type
            id
          }
          dateOfBirth
        }
      }
    }
    physicalAddress {
      jurisdictionId
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "animalTypeId",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDesexed",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expiryDate",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "licenceTypeId",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "LicenceType",
        "kind": "LinkedField",
        "name": "licenceType",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "LicenceExpiryDate",
                "kind": "LinkedField",
                "name": "expiryDate",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "day",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "month",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "years",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiryMonths",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLifetime",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateOfBirth",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jurisdictionId",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "BulkLicenceRenewalFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingAnimalConnection",
                            "kind": "LinkedField",
                            "name": "animals",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingAnimalEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "LicensingAnimal",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v0 /*: any*/),
                                                (v1 /*: any*/),
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LicensingRabiesVaccination",
                                                    "kind": "LinkedField",
                                                    "name": "rabiesVaccination",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LicensingAnimalIdentification",
                                                    "kind": "LinkedField",
                                                    "name": "identifications",
                                                    "plural": true,
                                                    "selections": [
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v8 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingStreetAddress",
                            "kind": "LinkedField",
                            "name": "physicalAddress",
                            "plural": false,
                            "selections": [
                                (v9 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "BulkLicenceRenewalFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingAnimalConnection",
                            "kind": "LinkedField",
                            "name": "animals",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingAnimalEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "LicensingAnimal",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v0 /*: any*/),
                                                (v1 /*: any*/),
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LicensingRabiesVaccination",
                                                    "kind": "LinkedField",
                                                    "name": "rabiesVaccination",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v0 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LicensingAnimalIdentification",
                                                    "kind": "LinkedField",
                                                    "name": "identifications",
                                                    "plural": true,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        (v0 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v8 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingStreetAddress",
                            "kind": "LinkedField",
                            "name": "physicalAddress",
                            "plural": false,
                            "selections": [
                                (v9 /*: any*/),
                                (v0 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "737b992a66d8de8e81023fbb86f93827",
            "id": null,
            "metadata": {},
            "name": "BulkLicenceRenewalFormQuery",
            "operationKind": "query",
            "text": "query BulkLicenceRenewalFormQuery {\n  account {\n    animals {\n      edges {\n        node {\n          id\n          name\n          animalTypeId\n          isDesexed\n          rabiesVaccination {\n            expiryDate\n            id\n          }\n          licenceTypeId\n          licenceType {\n            expiryDate {\n              day\n              month\n              years\n            }\n            expiryMonths\n            isLifetime\n          }\n          identifications {\n            type\n            id\n          }\n          dateOfBirth\n        }\n      }\n    }\n    physicalAddress {\n      jurisdictionId\n      id\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'c58c8a2db87306988a1cb6237dfc229f';
export default node;
