/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ConvergeProvider_configuration = {
    readonly elavonConvergeLibraryUri: string;
    readonly currency: string;
    readonly organisationContact: string;
    readonly " $refType": "ConvergeProvider_configuration";
};
export type ConvergeProvider_configuration$data = ConvergeProvider_configuration;
export type ConvergeProvider_configuration$key = {
    readonly " $data"?: ConvergeProvider_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"ConvergeProvider_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConvergeProvider_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "elavonConvergeLibraryUri",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '0b96fce302e1cc04259f106ffc60f1b4';
export default node;
