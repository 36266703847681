import graphql from "babel-plugin-relay/macro";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import { phoneNumberTypes } from "domain/account/models/phoneNumber";
import { AccountReadonlyContactDetails_account } from "generatedQueries/AccountReadonlyContactDetails_account.graphql";
import React, { FC } from "react";
import { createFragmentContainer } from "react-relay";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";
import { FormLabel } from "shared/components/formInputs/FormLabel";

export interface IAccountReadonlyContactDetails {
    account: AccountReadonlyContactDetails_account;
}

const InternalAccountReadonlyContactDetails: FC<IAccountReadonlyContactDetails> = ({
    account,
}) => (
    <>
        <FormLabel message={accountMessages.phoneNumbersLabel} required={false}>
            <ListGroup>
                {
                    account.phoneNumbers?.map(phoneNumber => {
                        const typeLabel = phoneNumberTypes.find(t => t.value === phoneNumber.type);

                        return <ListGroupItem key={phoneNumber.id}>
                            <Message
                                message={accountMessages.phoneNumberDisplayLabel}
                                values={{
                                    number: phoneNumber.number,
                                    type: (
                                        <Badge pill={true}>
                                            <Message message={typeLabel ? typeLabel.label : accountMessages.unknownPhoneNumberTypeLabel} />
                                        </Badge>
                                    ),
                                }} />
                        </ListGroupItem>;
                    })
                }
            </ListGroup>
        </FormLabel>
    </>
);

export const AccountReadonlyContactDetails = createFragmentContainer(InternalAccountReadonlyContactDetails, {
    account: graphql`
        fragment AccountReadonlyContactDetails_account on Account {
            driverLicence
            dateOfBirth
            phoneNumbers {
                id
                type
                number
            }
        }`,
});
