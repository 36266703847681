import graphql from "babel-plugin-relay/macro";
import { LicensingPhoneNumberType, updateAccountMutation, updateAccountMutationResponse } from "generatedQueries/updateAccountMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

export interface IUpdateAccountInput {
    dateOfBirth?: string | null;
    isSelfIdentifiedAsSeniorCitizen?: boolean | null;
    driverLicence?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    phoneNumbers: IPhoneNumberInput[];
}

export interface IPhoneNumberInput {
    number?: string | null;
    type: LicensingPhoneNumberType;
}

const mutation = graphql`
    mutation updateAccountMutation($account: LicensingAccountInput!) {
        updateLicensingAccount(account: $account) {
            id
        }
    }`;

export function updateAccount(
    environment: IEnvironment,
    account: IUpdateAccountInput,
    onCompleted: (response: updateAccountMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<updateAccountMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                account: {
                    ...account,
                    phoneNumbers: account.phoneNumbers.map(p => {
                        return {
                            ...p,
                        };
                    }),
                },
            },
        }
    );
}
