import { defineMessages } from "react-intl";

const idPrefix = "domain.user.";

export const userMessages = defineMessages({
    authorisingDescr: {
        defaultMessage: "Authorizing...",
        id: idPrefix + "authorising-descr",
    },
    authorisingError: {
        defaultMessage: "An error occurred while attempting to authorize with the server.  Please try again.",
        id: idPrefix + "authorising-error",
    },
    confirmEmailLabel: {
        defaultMessage: "Confirm Email",
        id: idPrefix + "confirmemail-label",
    },
    didntReceiveCodeMessage: {
        defaultMessage: "Didn't receive a code?",
        id: idPrefix + "didntreceivecode-message",
    },
    emailSuccessfullyChangedMessage: {
        defaultMessage: "Your email address has been changed successfully.  Please log in with your new email address.",
        id: idPrefix + "emailsuccessfullychanged-message",
    },
    emailSuccessfullyChangedTitle: {
        defaultMessage: "Email changed successfully",
        id: idPrefix + "emailsuccessfullychanged-title",
    },
    emailSuccessfullyVerifiedMessage: {
        defaultMessage: "Your new email address has been successfully verified.",
        id: idPrefix + "emailsuccessfullyverified-message",
    },
    incorrectVerificationCodeMessage: {
        defaultMessage: "The code you provided was incorrect or expired.  Please check the code and try again, or use the link below to send a new verification code",
        id: idPrefix + "incorrectverificationcode-message",
    },
    resendItToLabel: {
        defaultMessage: "Resend it to {email}",
        id: idPrefix + "resenditto-label",
    },
    resendVerificationEmailSucceededMessage: {
        defaultMessage: "An email with your verification code has been sent.  If you don't receive it shortly, check your spam folder.",
        id: idPrefix + "resendverificationemailsucceeded-message",
    },
    verificationCodeLabel: {
        defaultMessage: "Verification code",
        id: idPrefix + "verificationcode-label",
    },
    verifyLeadMessage: {
        defaultMessage: "We've sent an email to {email} with a verification code.",
        id: idPrefix + "verifylead-message",
    },
    verifyOptionIncorrectEmailLabel: {
        defaultMessage: "My email address is incorrect",
        id: idPrefix + "verifyoptionincorrectemail-label",
    },
    verifyOptionNotReceivedLabel: {
        defaultMessage: "I haven't received a code",
        id: idPrefix + "verifyoptionnotreceived-label",
    },
    verifyOptionReceivedLabel: {
        defaultMessage: "I've received my code",
        id: idPrefix + "verifyoptionreceived-label",
    },
    verifyYourAccountTitle: {
        defaultMessage: "Verify your account",
        id: idPrefix + "verifyyouraccount-title",
    },
});
