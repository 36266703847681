/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DefaultLayoutQueryVariables = {};
export type DefaultLayoutQueryResponse = {
    readonly features: {
        readonly isPublicLicenceSearchEnabled: boolean;
    } | null;
    readonly siteDisabled: {
        readonly value: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"Header_configuration">;
};
export type DefaultLayoutQuery = {
    readonly response: DefaultLayoutQueryResponse;
    readonly variables: DefaultLayoutQueryVariables;
};



/*
query DefaultLayoutQuery {
  features {
    isPublicLicenceSearchEnabled
  }
  siteDisabled: siteCustomisation(application: "licensing", name: "CustomerSiteDisabled") {
    value
  }
  ...Header_configuration
}

fragment HeaderLogo_configuration on Query {
  configuration {
    logoUri
  }
  logoCustomization: siteCustomisation(application: "licensing", name: "logo") {
    value
  }
}

fragment Header_configuration on Query {
  menuItems: siteCustomisation(application: "licensing", name: "menuItems") {
    value
  }
  ...HeaderLogo_configuration
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "concreteType": "Features",
        "kind": "LinkedField",
        "name": "features",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPublicLicenceSearchEnabled",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v1 = {
        "kind": "Literal",
        "name": "application",
        "value": "licensing"
    } as any, v2 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        } as any
    ], v3 = {
        "alias": "siteDisabled",
        "args": [
            (v1 /*: any*/),
            {
                "kind": "Literal",
                "name": "name",
                "value": "CustomerSiteDisabled"
            }
        ],
        "concreteType": "SiteCustomisation",
        "kind": "LinkedField",
        "name": "siteCustomisation",
        "plural": false,
        "selections": (v2 /*: any*/),
        "storageKey": "siteCustomisation(application:\"licensing\",name:\"CustomerSiteDisabled\")"
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "DefaultLayoutQuery",
            "selections": [
                (v0 /*: any*/),
                (v3 /*: any*/),
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Header_configuration"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "DefaultLayoutQuery",
            "selections": [
                (v0 /*: any*/),
                (v3 /*: any*/),
                {
                    "alias": "menuItems",
                    "args": [
                        (v1 /*: any*/),
                        {
                            "kind": "Literal",
                            "name": "name",
                            "value": "menuItems"
                        }
                    ],
                    "concreteType": "SiteCustomisation",
                    "kind": "LinkedField",
                    "name": "siteCustomisation",
                    "plural": false,
                    "selections": (v2 /*: any*/),
                    "storageKey": "siteCustomisation(application:\"licensing\",name:\"menuItems\")"
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "logoUri",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "logoCustomization",
                    "args": [
                        (v1 /*: any*/),
                        {
                            "kind": "Literal",
                            "name": "name",
                            "value": "logo"
                        }
                    ],
                    "concreteType": "SiteCustomisation",
                    "kind": "LinkedField",
                    "name": "siteCustomisation",
                    "plural": false,
                    "selections": (v2 /*: any*/),
                    "storageKey": "siteCustomisation(application:\"licensing\",name:\"logo\")"
                }
            ]
        },
        "params": {
            "cacheID": "7209a1edd5fca404c1d549b9b903683f",
            "id": null,
            "metadata": {},
            "name": "DefaultLayoutQuery",
            "operationKind": "query",
            "text": "query DefaultLayoutQuery {\n  features {\n    isPublicLicenceSearchEnabled\n  }\n  siteDisabled: siteCustomisation(application: \"licensing\", name: \"CustomerSiteDisabled\") {\n    value\n  }\n  ...Header_configuration\n}\n\nfragment HeaderLogo_configuration on Query {\n  configuration {\n    logoUri\n  }\n  logoCustomization: siteCustomisation(application: \"licensing\", name: \"logo\") {\n    value\n  }\n}\n\nfragment Header_configuration on Query {\n  menuItems: siteCustomisation(application: \"licensing\", name: \"menuItems\") {\n    value\n  }\n  ...HeaderLogo_configuration\n}\n"
        }
    } as any;
})();
(node as any).hash = 'e6e3380641357242cf6974a1aa7166d7';
export default node;
