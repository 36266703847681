import { createRoute } from "infrastructure/routing";
import React, { FC } from "react";
import { Switch } from "react-router";
import { AnimalEdit } from "routes/pets/AnimalEdit";
import { AnimalList } from "routes/pets/AnimalList";
import { routes } from "routes/routes";

export const RouteIndex: FC = () => (
    <Switch>
        {createRoute(routes.pets.pet, { component: AnimalEdit })}
        {createRoute(routes.pets.index, { component: AnimalList })}
    </Switch>
);
