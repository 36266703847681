import { UserAction, userActions } from "domain/user/userActions";
import { clearToken } from "infrastructure/token";
import { ActionsObservable, combineEpics } from "redux-observable";
import { filter } from "rxjs/internal/operators/filter";
import { map } from "rxjs/internal/operators/map";
import { isActionOf } from "typesafe-actions";

const logoutEpic = (action$: ActionsObservable<UserAction>) => action$.pipe(
    filter(isActionOf(userActions.logout)),
    map(() => {
        clearToken();
        return userActions.logoutComplete();
    }));

export const userEpics = combineEpics(logoutEpic);
