import { createRoute } from "infrastructure/routing";
import React, { FC } from "react";
import { Switch } from "react-router";
import { Payment } from "routes/payment/Payment";
import { routes } from "routes/routes";

export const RouteIndex: FC = () => (
    <Switch>
        {createRoute(routes.payment.index, { component: Payment })}
    </Switch>
);
