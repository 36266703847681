import graphql from "babel-plugin-relay/macro";
import { Message } from "shared/components/Message";
import { lostPetMessages } from "domain/lostPet/lostPetMessages";
import { AnimalPublicTagSearchResultRow_configuration } from "generatedQueries/AnimalPublicTagSearchResultRow_configuration.graphql";
import { AnimalPublicTagSearchResultRow_searchResult } from "generatedQueries/AnimalPublicTagSearchResultRow_searchResult.graphql";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { Badge, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { sharedMessages } from "shared/sharedMessages";

interface IGraphQlProps {
    configuration: AnimalPublicTagSearchResultRow_configuration | null;
    searchResult: AnimalPublicTagSearchResultRow_searchResult;
}

const AnimalPublicTagSearchResultRowInternal: FC<IGraphQlProps> = ({
    searchResult,
    configuration,
}) => {
    const intl = useIntl();

    return (
        <Card className="mt-3">
            <CardHeader>
                <Message
                    message={lostPetMessages.petResultHeading}
                    values={{
                        animalName: searchResult.animalName,
                        b: src => <strong>{src}</strong>,
                        tagNumber: searchResult.tagNumber,
                    }} />
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={6}>
                        <dl>
                            <dt><Message message={lostPetMessages.ownerNameResultLabel} /></dt>
                            <dd>{searchResult.ownerName}</dd>
                            <dt><Message message={lostPetMessages.ownerPhoneNumbersResultLabel} /></dt>
                            <dd>
                                {
                                    !searchResult.mobilePhoneNumber &&
                                    !searchResult.homePhoneNumber &&
                                    <Message
                                        message={lostPetMessages.noPhoneNumbersMessage}
                                        values={{ organisationContact: configuration?.organisationContact ?? intl.formatMessage(sharedMessages.defaultOrganisationContactMessage) }} />
                                }
                                {
                                    !!searchResult.mobilePhoneNumber &&
                                    <Message
                                        message={lostPetMessages.phoneResultMessage}
                                        values={{
                                            number: searchResult.mobilePhoneNumber,
                                            type: (
                                                <Badge pill={true}>
                                                    <Message message={lostPetMessages.mobilePhonePillLabel} />
                                                </Badge>
                                            ),
                                        }} />
                                }
                                {
                                    !!searchResult.mobilePhoneNumber &&
                                    !!searchResult.homePhoneNumber &&
                                    <br />
                                }
                                {
                                    !!searchResult.homePhoneNumber &&
                                    <Message
                                        message={lostPetMessages.phoneResultMessage}
                                        values={{
                                            number: searchResult.homePhoneNumber,
                                            type: (
                                                <Badge pill={true}>
                                                    <Message message={lostPetMessages.homePhonePillLabel} />
                                                </Badge>
                                            ),
                                        }} />
                                }
                            </dd>
                        </dl>
                    </Col>
                    <Col md={6}>
                        <dl>
                            <dt><Message message={lostPetMessages.animalTypeResultLabel} /></dt>
                            <dd>{searchResult.animalTypeDescription}</dd>
                            <dt><Message message={lostPetMessages.breedResultLabel} /></dt>
                            <dd>
                                {
                                    searchResult.secondaryBreedDescription
                                        ? `${searchResult.primaryBreedDescription}, ${searchResult.secondaryBreedDescription}`
                                        : searchResult.primaryBreedDescription
                                }
                            </dd>
                        </dl>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export const AnimalPublicTagSearchResultRow = createFragmentContainer(AnimalPublicTagSearchResultRowInternal, {
    configuration: graphql`
        fragment AnimalPublicTagSearchResultRow_configuration on Configuration {
            organisationContact
        }`,
    searchResult: graphql`
        fragment AnimalPublicTagSearchResultRow_searchResult on PublicTagSearchResult {
            tagNumber
            animalName
            animalTypeDescription
            primaryBreedDescription
            secondaryBreedDescription
            ownerName
            mobilePhoneNumber
            homePhoneNumber
        }
    `,
});
