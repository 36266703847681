import { createToastMessage } from "components/shared/toast";
import { ToastAction, toastActions } from "domain/toast/toastActions";
import { actions as toastrActions } from "react-redux-toastr";
import { ActionsObservable, combineEpics } from "redux-observable";
import { Observable } from "rxjs/internal/Observable";
import { filter } from "rxjs/internal/operators/filter";
import { map } from "rxjs/internal/operators/map";
import { withLatestFrom } from "rxjs/internal/operators/withLatestFrom";
import { sharedMessages } from "shared/sharedMessages";
import { createToastEpic } from "store/createToastEpic";
import { RootState } from "store/store";
import { isActionOf } from "typesafe-actions";

const saveSuccessful = createToastEpic(toastActions.toastSuccess, sharedMessages.saveSuccessfulMessage);

const saveFailure = createToastEpic(toastActions.toastFailure, sharedMessages.saveFailedErrorMessage, "error");

const customSuccessToast = (action$: ActionsObservable<ToastAction>, state$: Observable<RootState>) => action$.pipe(
    filter(isActionOf(toastActions.customSuccessToast)),
    withLatestFrom(state$),
    filter(([, state]) => !!state.intl),
    map(([action, state]) => {
        return toastrActions.add(createToastMessage({ type: "success", message: action.payload, intl: state.intl }));
    })
);

const customFailureToast = (action$: ActionsObservable<ToastAction>, state$: Observable<RootState>) => action$.pipe(
    filter(isActionOf(toastActions.customFailureToast)),
    withLatestFrom(state$),
    filter(([, state]) => !!state.intl),
    map(([action, state]) => {
        return toastrActions.add(createToastMessage({ type: "error", message: action.payload, intl: state.intl }));
    })
);

export const toastEpics = combineEpics(
    saveSuccessful,
    saveFailure,
    customSuccessToast,
    customFailureToast);
