/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type changeEmailMutationVariables = {
    email: string;
};
export type changeEmailMutationResponse = {
    readonly changeLicensingAccountEmail: {
        readonly email: string;
    } | null;
    readonly licensingAccountSignOut: {
        readonly wasSuccessful: boolean;
    } | null;
};
export type changeEmailMutation = {
    readonly response: changeEmailMutationResponse;
    readonly variables: changeEmailMutationVariables;
};



/*
mutation changeEmailMutation(
  $email: String!
) {
  changeLicensingAccountEmail(email: $email) {
    email
    id
  }
  licensingAccountSignOut {
    wasSuccessful
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "email"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "LicensingSignOut",
        "kind": "LinkedField",
        "name": "licensingAccountSignOut",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wasSuccessful",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "changeEmailMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "changeLicensingAccountEmail",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                (v3 /*: any*/)
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "changeEmailMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "changeLicensingAccountEmail",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v3 /*: any*/)
            ]
        },
        "params": {
            "cacheID": "6b368573a7a7df60b385ca73810d5412",
            "id": null,
            "metadata": {},
            "name": "changeEmailMutation",
            "operationKind": "mutation",
            "text": "mutation changeEmailMutation(\n  $email: String!\n) {\n  changeLicensingAccountEmail(email: $email) {\n    email\n    id\n  }\n  licensingAccountSignOut {\n    wasSuccessful\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '14165e9a34f2a8727e3731b4463ce5f7';
export default node;
