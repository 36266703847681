import graphql from "babel-plugin-relay/macro";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { homeMessages } from "domain/home/homeMessages";
import { NoPets_account } from "generatedQueries/NoPets_account.graphql";
import React, { FC } from "react";
import { createFragmentContainer } from "react-relay";
import { Link } from "react-router-dom";
import { CardText } from "reactstrap";
import { routes } from "routes/routes";
import { InformationCard } from "shared/components/InformationCard";

interface IGraphQlProps {
    account: NoPets_account;
}

const NoPetsInternal: FC<IGraphQlProps> = ({ account }) => {
    if (!account || account.hasAnimals) {
        return null;
    }

    return (
        <InformationCard message={homeMessages.noPetsInfoMessage}>
            <CardText>
                <Message
                    message={homeMessages.clickToSearchExistingPetsInfoMessage}
                    values={{
                        "link":
                            <Link to={routes.pets.index.create({})}>
                                <Message message={animalMessages.petsPageTitle} />
                            </Link>,
                    }} />
            </CardText>
        </InformationCard>
    );
};

export const NoPets = createFragmentContainer(NoPetsInternal, {
    account: graphql`
        fragment NoPets_account on Account {
            hasAnimals
        }
    `,
});
