/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AddressFormQueryVariables = {};
export type AddressFormQueryResponse = {
    readonly account: {
        readonly physicalAddress: {
            readonly id: string;
            readonly addressLine: string | null;
            readonly suburb: string | null;
            readonly state: string | null;
            readonly postcode: string | null;
            readonly jurisdictionId: number | null;
        } | null;
        readonly mailingAddress: {
            readonly __typename: string;
            readonly id?: string;
            readonly postcode?: string | null;
            readonly state?: string | null;
            readonly suburb?: string | null;
            readonly jurisdictionId?: number | null;
            readonly addressLine?: string | null;
            readonly deliveryType?: {
                readonly displayId: number;
            } | null;
            readonly deliveryNumber?: string | null;
        } | null;
    } | null;
    readonly configuration: {
        readonly isAddressSearchEnabled: boolean;
        readonly " $fragmentRefs": FragmentRefs<"JurisdictionModal_configuration">;
    } | null;
};
export type AddressFormQuery = {
    readonly response: AddressFormQueryResponse;
    readonly variables: AddressFormQueryVariables;
};



/*
query AddressFormQuery {
  account {
    physicalAddress {
      id
      addressLine
      suburb
      state
      postcode
      jurisdictionId
    }
    mailingAddress {
      __typename
      ... on LicensingAddress {
        __isLicensingAddress: __typename
        id
        postcode
        state
        suburb
      }
      ... on LicensingStreetAddress {
        jurisdictionId
        addressLine
        id
      }
      ... on LicensingDeliveryAddress {
        deliveryType {
          displayId
          id
        }
        deliveryNumber
        id
      }
    }
    id
  }
  configuration {
    isAddressSearchEnabled
    ...JurisdictionModal_configuration
  }
}

fragment JurisdictionModal_configuration on Configuration {
  organisationContact
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addressLine",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "suburb",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postcode",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jurisdictionId",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "LicensingStreetAddress",
        "kind": "LinkedField",
        "name": "physicalAddress",
        "plural": false,
        "selections": [
            (v0 /*: any*/),
            (v1 /*: any*/),
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v5 /*: any*/)
        ],
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v8 = {
        "kind": "InlineFragment",
        "selections": [
            (v0 /*: any*/),
            (v4 /*: any*/),
            (v3 /*: any*/),
            (v2 /*: any*/)
        ],
        "type": "LicensingAddress",
        "abstractKey": "__isLicensingAddress"
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayId",
        "storageKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliveryNumber",
        "storageKey": null
    } as any, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAddressSearchEnabled",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AddressFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "mailingAddress",
                            "plural": false,
                            "selections": [
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v1 /*: any*/)
                                    ],
                                    "type": "LicensingStreetAddress",
                                    "abstractKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "DeliveryType",
                                            "kind": "LinkedField",
                                            "name": "deliveryType",
                                            "plural": false,
                                            "selections": [
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v10 /*: any*/)
                                    ],
                                    "type": "LicensingDeliveryAddress",
                                    "abstractKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        (v11 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "JurisdictionModal_configuration"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AddressFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "mailingAddress",
                            "plural": false,
                            "selections": [
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v1 /*: any*/),
                                        (v0 /*: any*/)
                                    ],
                                    "type": "LicensingStreetAddress",
                                    "abstractKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "DeliveryType",
                                            "kind": "LinkedField",
                                            "name": "deliveryType",
                                            "plural": false,
                                            "selections": [
                                                (v9 /*: any*/),
                                                (v0 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v10 /*: any*/),
                                        (v0 /*: any*/)
                                    ],
                                    "type": "LicensingDeliveryAddress",
                                    "abstractKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        (v11 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "organisationContact",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "4732aec04d66a26f9ecc34a6ac4c7a2d",
            "id": null,
            "metadata": {},
            "name": "AddressFormQuery",
            "operationKind": "query",
            "text": "query AddressFormQuery {\n  account {\n    physicalAddress {\n      id\n      addressLine\n      suburb\n      state\n      postcode\n      jurisdictionId\n    }\n    mailingAddress {\n      __typename\n      ... on LicensingAddress {\n        __isLicensingAddress: __typename\n        id\n        postcode\n        state\n        suburb\n      }\n      ... on LicensingStreetAddress {\n        jurisdictionId\n        addressLine\n        id\n      }\n      ... on LicensingDeliveryAddress {\n        deliveryType {\n          displayId\n          id\n        }\n        deliveryNumber\n        id\n      }\n    }\n    id\n  }\n  configuration {\n    isAddressSearchEnabled\n    ...JurisdictionModal_configuration\n  }\n}\n\nfragment JurisdictionModal_configuration on Configuration {\n  organisationContact\n}\n"
        }
    } as any;
})();
(node as any).hash = '29041c0010a424174f7e9e7bf9162280';
export default node;
