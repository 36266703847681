import { ErrorMessage } from "components/shared/ErrorMessage";
import { LicensingPaymentResultCode } from "generatedQueries/processPaymentMethodMutation.graphql";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

export const messages = mapMessages("components.payment.PaymentFailedMessage", {
    amountAuthorizedDiscrepancyError:
        "The amount authorized did not match the total amount to be paid. Please refresh the cart before attempting to pay again. If the problem persists get in touch with {OrganisationContact}.",
    amountAuthorizedDiscrepancyTitle: "Payment discrepancy",
    blockedAnimalRenewalError: "An animal that has been blocked cannot be renewed",
    blockedAnimalRenewalTitle: "One or more licenses cannot be renewed",
    cardErrorMessage: "Please check the card details entered and confirm there are funds available on the card used before continuing.",
    customerSiteDisabledError:
        "The customer site has been disabled and is not able to take payments at this time. Please check back later or contact {OrganisationContact} to find out when it will be back online.",
    customerSiteDisabledTitle: "Customer site disabled",
    expiredRabiesVaccinationError: "Please check the vaccination date/effective period to ensure it has not lapsed, and update if required.",
    expiredRabiesVaccinationTitle: "Rabies vaccination is expired",
    invalidLicenceTypeError: "Please check the selected license before proceeding. If the problem persists get in touch with the site owner.",
    invalidLicenceTypeTitle: "One or more licenses are not valid",
    lifetimeLicenceCannotBeRenewedError: "Please remove any pets that are already licensed with a lifetime before attempting to pay again.",
    lifetimeLicenceCannotBeRenewedTitle: "Lifetime licences cannot be renewed",
    paymentDeclinedMessage: "Payment declined",
    pendingLicenceRenewalError:
        "An animal that has a pending license cannot be renewed. Please contact {OrganisationContact} to check the progress of your current license before attempting again.",
    pendingLicenceRenewalTitle: "One or more licenses cannot be renewed at this time",
    petDiscrepancyError: "One or more pet's are not valid. Please refresh the cart before attempting to pay again. If the problem persists get in touch with the site owner.",
    petDiscrepancyTitle: "Invalid Pet",
    rejectedLicenceRenewalError:
        "An animal that has a rejected license cannot be renewed. Please check your email for instructions or contact {OrganisationContact} on how to proceed.",
    rejectedLicenceRenewalTitle: "One or more licenses cannot be renewed at this time",
    totalAmountDiscrepancyError:
        "There is a discrepancy between the amount to be paid and the amount that needs to be paid. Please refresh the cart before attempting to pay again. If the problem persists get in touch with the site owner.",
    totalAmountDiscrepancyTitle: "Payment amount discrepancy",
});

interface IProps {
    code: Exclude<LicensingPaymentResultCode, "SUCCEEDED"> | null;
    organisationContact: string;
}

export const PaymentFailedMessage: FC<IProps> = ({
    code,
    organisationContact,
}) => {
    if (!code) {
        return null;
    }

    let message: MessageDescriptor;
    let heading: MessageDescriptor;

    switch (code) {
        case "DECLINED":
            message = messages.cardErrorMessage;
            heading = messages.paymentDeclinedMessage;
            break;

        case "LICENCETYPEDISCREPANCY":
        case "LICENCETYPENOTSUPPORTED":
            message = messages.invalidLicenceTypeError;
            heading = messages.invalidLicenceTypeTitle;
            break;

        case "LIFETIMELICENCERENEWAL":
            message = messages.lifetimeLicenceCannotBeRenewedError;
            heading = messages.lifetimeLicenceCannotBeRenewedTitle;
            break;

        case "PETDISCREPANCY":
            message = messages.petDiscrepancyError;
            heading = messages.petDiscrepancyTitle;
            break;

        case "TOTALAMOUNTDISCREPANCY":
            message = messages.totalAmountDiscrepancyError;
            heading = messages.totalAmountDiscrepancyTitle;
            break;

        case "BLOCKEDANIMAL":
            message = messages.blockedAnimalRenewalError;
            heading = messages.blockedAnimalRenewalTitle;
            break;

        case "REJECTEDLICENCE":
            message = messages.rejectedLicenceRenewalError;
            heading = messages.rejectedLicenceRenewalTitle;
            break;

        case "AMOUNTAUTHORISEDDOESNOTMATCHTOTALAMOUNT":
            message = messages.amountAuthorizedDiscrepancyError;
            heading = messages.amountAuthorizedDiscrepancyTitle;
            break;

        case "PENDINGLICENSE":
            message = messages.pendingLicenceRenewalError;
            heading = messages.pendingLicenceRenewalTitle;
            break;

        case "CUSTOMERSITEISDISABLED":
            message = messages.customerSiteDisabledError;
            heading = messages.customerSiteDisabledTitle;
            break;

        case "EXPIREDRABIESVACCINATION":
            message = messages.expiredRabiesVaccinationError;
            heading = messages.expiredRabiesVaccinationTitle;
            break;

        default:
            message = sharedMessages.requestFailedBody;
            heading = sharedMessages.requestFailedTitle;
            break;
    }

    return <ErrorMessage message={message} heading={heading} messageValues={{ OrganisationContact: organisationContact }} />;
};
