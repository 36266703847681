import { AnimalAction } from "domain/animal/animalActions";
import { IAnimalTagSearchResultRow } from "domain/animal/models/animalTagSearchResultRow";
import { combineReducers, Reducer } from "redux";

const animalFound: Reducer = (state: IAnimalTagSearchResultRow | null = null, action: AnimalAction) => {
    if (action.type === "ANIMAL_FOUND" || action.type === "ANIMAL_CLEAR_ANIMAL_FOUND") {
        return action.payload;
    }

    return state;
};

export interface IAnimalState {
    animalFound: IAnimalTagSearchResultRow;
}

export const combinedAnimalReducer = combineReducers<IAnimalState, AnimalAction>({
    animalFound,
});
