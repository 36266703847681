import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { PayPalPayflowFields } from "components/payment/PayPalPayflow/PayPalPayflowFields";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { PayPalPayflowFormQuery } from "generatedQueries/PayPalPayflowFormQuery.graphql";
import React, { FC } from "react";
import { Col, Container, Row } from "reactstrap";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";

export const messages = mapMessages("components.payment.paypalpayflowfields", {
    errorLoadingHppDetails: "There was an error loading the details to display PayPal",
    cardNumberPlaceholder: "Card number",
    cardNumberLabel: "Credit or debit card number",
    cvvLabel: "Credit or debit card CVC/CCV",
    cvvPlaceholder: "CVC",
    expiryDateLabel: "Credit or debit card expiration date",
    expiryDatePlaceholder: "MM / YY",
});

export const PayPalPayflowForm: FC = () =>
    <Container fluid={true}>
        <Row>
            <Col>
                <Query<PayPalPayflowFormQuery>
                    query={query}
                    variables={{}}>
                    {({ error, props }) => {
                        if (error || (props && !props.configuration)) {
                            return <ErrorMessage message={messages.errorLoadingHppDetails} />;
                        }

                        if (!props) {
                            return <LoadingMessage />;
                        }

                        return <PayPalPayflowFields configuration={props.configuration!} />;
                    }}
                </Query>
            </Col>
        </Row>
    </Container>;

const query = graphql`
    query PayPalPayflowFormQuery {
        configuration {
            ...PayPalPayflowFields_configuration
        }
    }`;
