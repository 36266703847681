import { DatePicker } from "components/shared/datePicker/DatePicker";
import { LoadingMessage } from "components/shared/LoadingMessage";
import { combine, maxDateOfBirth, maxLength, minDateOfBirth, past, required } from "components/shared/validations";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import graphql from "babel-plugin-relay/macro";
import { PersonNameSuffixes } from "components/account/PersonNameSuffixes";
import { PhoneNumber } from "components/phonenumber/PhoneNumber";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import { IUpdateAccountInput, updateAccount } from "domain/account/updateAccount";
import { toastActions } from "domain/toast/toastActions";
import { AccountDetailsFormQuery, AccountDetailsFormQueryResponse } from "generatedQueries/AccountDetailsFormQuery.graphql";
import { updateAccountMutationResponse } from "generatedQueries/updateAccountMutation.graphql";
import { ArrayField } from "informed";
import moment from "moment";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { ErrorHandlingMutationForm } from "shared/components/ErrorHandlingMutationForm";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { FormSwitch } from "shared/components/formInputs/FormSwitch";
import { TextBox } from "shared/components/formInputs/TextBox";
import { HideableContainer, HideableFields } from "shared/components/Hideable";
import { Query } from "shared/components/Query";
import { SiteCustomisation } from "shared/components/SiteCustomisation";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("components.account.AccountDetailsForm", {
    addPhoneNumberButton: "Add Phone Number",
});

interface IProps {
    onNext: () => void;
    isNewAccount: boolean;
}

export const AccountDetailsForm: FC<IProps> = ({
    onNext,
    isNewAccount,
}) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();

    return <Row className="justify-content-center">
        <Col xl={5} lg={6} md={7} sm={9}>
            <Query<AccountDetailsFormQuery>
                query={graphql`
                    query AccountDetailsFormQuery(
                        $suffixesPageSize: Int,
                        $suffixesAfter: String,
                        $suffixesWhere: PersonNameSuffixFilter,
                    ) {
                        account {
                            id
                            status
                            firstName
                            middleName
                            lastName
                            personNameSuffixId
                            dateOfBirth
                            isSelfIdentifiedAsSeniorCitizen
                            driverLicence
                            phoneNumbers {
                                type
                                number
                            }
                        }
                        configuration {
                            ...Hideable_configuration
                            ...PhoneNumber_configuration
                        }
                        features {
                            isSelfIdentifyAsSeniorCitizenEnabled
                        }
                        isSeniorCitizenMessage: siteCustomisation(application: "licensing", name: "isSeniorCitizenMessage") {
                            value
                        }
                        isSeniorCitizenLabel: siteCustomisation(application: "licensing", name: "isSeniorCitizenLabel") {
                            value
                        }
                        ...PersonNameSuffixes_query
                    }`}
                variables={{
                    suffixesPageSize: 50,
                    suffixesWhere: {
                        isShown: true,
                    },
                }}>
                {({ error, props }) => {
                    if (error) {
                        return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                    }

                    if (!props) {
                        return <LoadingMessage />;
                    }

                    const { account, configuration, features, isSeniorCitizenMessage, isSeniorCitizenLabel } = props;

                    const shouldDisplayIsSeniorCheckbox = !!features && features.isSelfIdentifyAsSeniorCitizenEnabled;

                    if (!account || !configuration) {
                        return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                    }

                    const seniorCitizenLabel = isSeniorCitizenLabel?.value ?
                        <Message message={accountMessages.isCustomSeniorCitizenCheckboxLabel} values={{ personType: isSeniorCitizenLabel?.value }} /> :
                        <Message message={accountMessages.isSeniorCitizenCheckboxLabel} />;

                    return <ErrorHandlingMutationForm<IUpdateAccountInput, updateAccountMutationResponse>
                        onNext={onSuccess}
                        initialValues={getInitialValues(account, shouldDisplayIsSeniorCheckbox)}
                        saveButtonMessage={account.status === "NEW" ? accountMessages.createAccountLabel : sharedMessages.saveLabel}
                        mutationFunc={updateAccount}
                        alwaysSubmit={isNewAccount}>
                        <FormLabel
                            message={accountMessages.firstNameLabel}
                            required={true}
                            for="firstName"
                        >
                            <TextBox
                                id="firstName"
                                field="firstName"
                                validate={combine(required, maxLength(100))} />
                        </FormLabel>
                        <FormLabel
                            message={accountMessages.middleNameLabel}
                            for="middleName"
                        >
                            <TextBox
                                id="middleName"
                                field="middleName"
                                validate={maxLength(100)} />
                        </FormLabel>
                        <FormLabel
                            message={accountMessages.lastNameLabel}
                            required={true}
                            for="lastName"
                        >
                            <TextBox
                                id="lastName"
                                field="lastName"
                                validate={combine(required, maxLength(100))} />
                        </FormLabel>
                        <FormLabel message={accountMessages.personNameSuffixLabel} for="personNameSuffixId">
                            <PersonNameSuffixes
                                id="personNameSuffixId"
                                queryKey={props}
                            />
                        </FormLabel>
                        <HideableContainer field={HideableFields.driverLicence} configuration={configuration}>
                            <FormLabel
                                message={accountMessages.driverLicenceLabel}
                                required={true}
                                for="driverLicence"
                            >
                                <TextBox
                                    id="driverLicence"
                                    field="driverLicence"
                                    validate={combine(required, maxLength(20))} />
                            </FormLabel>
                        </HideableContainer>
                        {
                            !shouldDisplayIsSeniorCheckbox &&
                            <HideableContainer field={HideableFields.dateOfBirth} configuration={configuration}>
                                <FormLabel
                                    message={accountMessages.dateOfBirthLabel}
                                    required={true}
                                    for="dateOfBirth"
                                >
                                    <DatePicker
                                        id="dateOfBirth"
                                        field="dateOfBirth"
                                        maxDate={moment().add(-1, "days").toDate()}
                                        validate={combine(required, past, minDateOfBirth, maxDateOfBirth)} />
                                </FormLabel>
                            </HideableContainer>
                        }
                        <FormLabel message={accountMessages.phoneNumbersLabel} required={true} />
                        <ArrayField field="phoneNumbers">
                            {({ add, fields }) =>
                                <>
                                    {
                                        fields.map(({ field, key, remove }, i) => {
                                            return <PhoneNumber
                                                key={key}
                                                index={i}
                                                field={field}
                                                showRemoveButton={fields.length > 1}
                                                onRemoveClicked={remove}
                                                configuration={configuration}
                                            />;
                                        })
                                    }
                                    {
                                        <Button
                                            color="success"
                                            onClick={add}
                                            aria-label={formatMessage(messages.addPhoneNumberButton)}
                                            size="sm"
                                        >
                                            <FontAwesomeIcon icon={faPlus} className="icon-button mr-1" />
                                            <Message message={accountMessages.addPhoneNumberLabel} />
                                        </Button>
                                    }
                                </>
                            }
                        </ArrayField>
                        {
                            shouldDisplayIsSeniorCheckbox &&
                            <FormSwitch
                                label={seniorCitizenLabel}
                                id="isSelfIdentifiedAsSeniorCitizen"
                                field="isSelfIdentifiedAsSeniorCitizen"
                                className="mt-3"
                            />
                        }
                        {
                            shouldDisplayIsSeniorCheckbox &&
                            isSeniorCitizenMessage &&
                            <SiteCustomisation value={isSeniorCitizenMessage.value} />
                        }
                    </ErrorHandlingMutationForm>;
                }}
            </Query>
        </Col>
    </Row>;

    function onSuccess() {
        dispatch(toastActions.toastSuccess());
        onNext();
    }

    function getInitialValues(account: Exclude<AccountDetailsFormQueryResponse["account"], null>, shouldCollectIsSeniorCitizen: boolean): IUpdateAccountInput {
        const phoneNumbers = account.phoneNumbers.map(p => {
            return { ...p };
        });

        if (phoneNumbers.length === 0) {
            phoneNumbers.push({
                number: "",
                type: "HOME",
            });
        }

        return {
            ...account,
            isSelfIdentifiedAsSeniorCitizen: account.isSelfIdentifiedAsSeniorCitizen !== null ? account.isSelfIdentifiedAsSeniorCitizen : (shouldCollectIsSeniorCitizen ? false : null),
            phoneNumbers,
        };
    }
};
