import { route } from "typesafe-react-router";

export const userRoutes = {
    confirm: route("user", "confirm"),
    emailChanged: route("user", "email-changed"),
    changePassword: route("user", "changePassword"),
    forgotPassword: route("user", "forgot-password"),
    index: route("user"),
    login: route("user", "login"),
    logout: route("user", "logout"),
    signUp: route("user", "signup"),
    verify: route("user", "verify"),
};
