import graphql from "babel-plugin-relay/macro";
import { AddressType } from "components/address/AddressForm";
import { ReadonlyAddress } from "components/address/ReadonlyAddress";
import { Addresses_account } from "generatedQueries/Addresses_account.graphql";
import React, { FC } from "react";
import { createFragmentContainer } from "react-relay";
import { Col, Row } from "reactstrap";

export interface IAddresses {
    account: Addresses_account;
}

const InternalAddresses: FC<IAddresses> = ({
    account,
}) => (
    <Row>
        <Col sm={6}>
            <ReadonlyAddress address={account.physicalAddress} addressType={AddressType.Physical} />
        </Col>
        <Col sm={6}>
            <ReadonlyAddress address={account.mailingAddress} addressType={AddressType.Mailing} />
        </Col>
    </Row>
);

export const Addresses = createFragmentContainer(InternalAddresses, {
    account: graphql`
        fragment Addresses_account on Account {
            physicalAddress {
                ...ReadonlyAddress_address
            }
            mailingAddress {
                ...ReadonlyAddress_address
            }
        }`,
});
