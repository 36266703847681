import { Money } from "components/shared/Money";
import { faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import graphql from "babel-plugin-relay/macro";
import { Message } from "shared/components/Message";
import { paymentActions } from "domain/payment/paymentActions";
import { Donation_configuration$key } from "generatedQueries/Donation_configuration.graphql";
import { Donation_paymentForm$key } from "generatedQueries/Donation_paymentForm.graphql";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useFragment } from "react-relay";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import { mapMessages } from "shared/mapMessages";

const messages = mapMessages("components.payment.Donation", {
    amountLabel: "Donation Amount",
    donationPrompt: "Enter your donation amount, or choose one below.",
    heading: "Make a Donation",
    notesLabel: "Donation Notes",
    roundDonationToNearestDollarLabel: "Round to Nearest Dollar",
});

interface IGraphQlProps {
    configurationKey: Donation_configuration$key;
    paymentFormKey: Donation_paymentForm$key | null;
}

export const Donation: FC<IGraphQlProps> = ({
    configurationKey,
    paymentFormKey,
}) => {
    const { formatNumber } = useIntl();
    const dispatch = useDispatch();
    const configuration = useFragment(configurationFragment, configurationKey);
    const paymentForm = useFragment(paymentFormFragment, paymentFormKey);
    const [amount, setAmount] = useState<string>("");
    const [notes, setNotes] = useState<string>("");

    useEffect(() => {
        const value = Number(amount);
        dispatch(paymentActions.setDonation(isNumberInvalid(value) ? null : value));
    }, [amount, dispatch]);

    useEffect(() => {
        dispatch(paymentActions.setDonationNotes(notes));
    }, [dispatch, notes]);

    const hasDonationAmountSuggestions = (configuration.donationAmountSuggestions?.length ?? 0) > 0;

    return <Col
        className="mb-3 donation"
        lg={8}
        md={12}
    >
        <Card>
            <CardHeader>
                <h6>
                    <FontAwesomeIcon
                        icon={faHandHoldingHeart}
                        className="mr-1"
                    />
                    <Message
                        message={messages.heading}
                    />
                </h6>
            </CardHeader>
            <CardBody>
                {
                    (configuration.donationRoundUp || hasDonationAmountSuggestions) &&
                    <p
                        className="text-center text-muted"
                    >
                        <Message
                            message={messages.donationPrompt}
                        />
                    </p>
                }
                <FormGroup
                    row={true}
                >
                    <Label
                        htmlFor="donationAmount"
                        className="ml-md-auto"
                        sm={5}
                        md={3}
                    >
                        <span className="float-sm-right text-nowrap">
                            <Message message={messages.amountLabel}
                            />
                        </span>
                    </Label>
                    <Col
                        className="mr-auto"
                        sm="auto"
                    >
                        <Input
                            id="donationAmount"
                            type="text"
                            value={amount}
                            onChange={onAmountChange}
                        />
                    </Col>
                </FormGroup>
                <Row>
                    <Col
                        className="text-center"
                        sm={12}
                    >
                        {
                            configuration.donationAmountSuggestions?.map((amountSuggestion, index) =>
                                <span key={index}>
                                    {
                                        index > 0 &&
                                        <span>-</span>
                                    }
                                    <Button
                                        color="link"
                                        onClick={onSuggestionClicked(amountSuggestion)}
                                        className="donationSuggestion"
                                    >
                                        <Money amount={amountSuggestion} currency={configuration.currency} />
                                    </Button>
                                </span>
                            )
                        }
                        {
                            paymentForm &&
                            paymentForm.total > 0 &&
                            configuration.donationRoundUp &&
                            <>
                                {
                                    hasDonationAmountSuggestions &&
                                    <span>-</span>
                                }
                                <Button
                                    color="link"
                                    onClick={roundToNearestDollar}
                                    className="donationSuggestion">
                                    <Message message={messages.roundDonationToNearestDollarLabel} />
                                </Button>
                            </>
                        }
                    </Col>
                </Row>
                <FormGroup
                    className="mt-2"
                >
                    <Label
                        htmlFor="donationNotes"
                    >
                        <Message message={messages.notesLabel} />
                    </Label>
                    <Input
                        type="textarea"
                        readOnly={isStringAnInvalidNumber(amount)}
                        rows={2}
                        className="form-control"
                        id="donationNotes"
                        value={notes}
                        onChange={onNotesChange}
                        maxLength={255}
                    />
                </FormGroup>
            </CardBody>
        </Card>
    </Col>;

    function onAmountChange(event: ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        setAmount(value);
    }

    function onNotesChange(event: ChangeEvent<HTMLInputElement>) {
        setNotes(event.target.value);
    }

    function onSuggestionClicked(amountSuggestion: number) {
        return () => {
            setAmount(formatNumber(amountSuggestion, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }));
            dispatch(paymentActions.setDonation(amountSuggestion));
        };
    }

    function roundToNearestDollar() {
        const total = paymentForm?.total ?? 0;
        const rounded = Math.ceil(total);

        let roundedAmount = rounded - total;

        if (roundedAmount <= 0) {
            roundedAmount = 1;
        }

        setAmount(formatNumber(roundedAmount, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }));
    }
};

function isStringAnInvalidNumber(value: string) {
    if (!value) {
        return true;
    }
    const valueAsNumber = Number(value);
    return isNumberInvalid(valueAsNumber);
}

function isNumberInvalid(value: number) {
    return isNaN(value) || value <= 0;
}

const configurationFragment = graphql`
    fragment Donation_configuration on Configuration {
        currency
        donationAmountSuggestions
        donationRoundUp
    }`;

const paymentFormFragment = graphql`
    fragment Donation_paymentForm on LicensingPaymentForm {
        total
    }`;
