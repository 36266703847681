import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";
import { messages as passwordStrengthMessages } from "shared/passwordStrengthValidator";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("components.user.signuperror", {
    validationError: "One or more fields are invalid, please check and try again.",
    codeDeliveryError: "We were unable to deliver the validation code, please check the email address provided or try again later.",
});

interface IProps {
    failureReason: string | null;
}

export const SignUpError: FC<IProps> = ({
    failureReason,
}) => {
    let message: MessageDescriptor;

    switch (failureReason) {
        case "ValidationError":
            message = messages.validationError;
            break;
        case "CodeDeliveryFailure":
            message = messages.codeDeliveryError;
            break;
        case "InvalidPassword":
            message = passwordStrengthMessages.passwordStrengthError;
            break;
        default:
            message = sharedMessages.requestFailedBody;
            break;
    }

    return <WarningMessage message={message} />;
};
