import { Message } from "shared/components/Message";
import { IUserState } from "domain/user/combinedUserReducer";
import { signOut } from "domain/user/signOut";
import { userActions } from "domain/user/userActions";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { Redirect } from "react-router";
import { routes } from "routes/routes";
import { mapMessages } from "shared/mapMessages";
import { RootState } from "store/store";

export const messages = mapMessages("routes.user.logout", {
    loggingOutMessage: "Logging out...",
});

export const Logout: FC = () => {
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();
    const userState = useSelector<RootState, IUserState>(state => state.user);
    const [hasSignOutCommenced, setHasSignOutCommenced] = useState(false);

    const onSignOutComplete = useCallback(
        () => {
            dispatch(userActions.logout());
        },
        [dispatch]);

    useEffect(() => {
        if (userState.isLoggedIn && !hasSignOutCommenced) {
            signOut(
                environment,
                onSignOutComplete,
                onSignOutComplete);
            setHasSignOutCommenced(true);
        }
    }, [userState, hasSignOutCommenced, environment, onSignOutComplete]);

    return userState.isLoggedIn
        ? <Message message={messages.loggingOutMessage} />
        : <Redirect to={routes.home.create({})} />;
};
