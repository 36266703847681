import { LoadingMessage } from "components/shared/LoadingMessage";
import { required } from "components/shared/validations";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import { confirmAccount, IConfirmAccountMutationVariables } from "domain/account/confirmAccount";
import { confirmAccountMutationResponse } from "generatedQueries/confirmAccountMutation.graphql";
import { ConfirmFormQuery } from "generatedQueries/ConfirmFormQuery.graphql";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { Card, CardBody, CardHeader, FormText } from "reactstrap";
import { ErrorHandlingMutationForm } from "shared/components/ErrorHandlingMutationForm";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { InlineRadioButton, IOption } from "shared/components/formInputs/InlineRadioButton";
import { HideableContainer, HideableFields } from "shared/components/Hideable";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("components.account.ConfirmForm", {
    canDiscloseToPublicHelpText: "In the event that another resident finds your animal, may we provide them with your contact information.",
    canDiscloseToPublicLabel: "Disclose Details",
});

interface IProps {
    nextButtonMessage?: MessageDescriptor;
    onBack: () => void;
    onNext: () => void;
}

export const ConfirmForm: FC<IProps> = ({
    onNext,
    onBack,
    nextButtonMessage,
}) => {
    return (
        <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-7 col-sm-9">
                <Query<ConfirmFormQuery>
                    query={graphql`
                        query ConfirmFormQuery {
                            account {
                                canDiscloseToPublic
                                isSubscribedToShelterNewsletter
                            }
                            configuration {
                                ...Hideable_configuration
                            }
                        }`}
                    variables={{}}>
                    {({ error, props: renderProps }) => {
                        if (error) {
                            return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                        }

                        if (!renderProps) {
                            return <LoadingMessage />;
                        }

                        const { account, configuration } = renderProps;

                        if (!account) {
                            return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                        }

                        const optionsYesNo: IOption[] = [
                            {
                                message: sharedMessages.confirmNoLabel,
                                value: false,
                            },
                            {
                                message: sharedMessages.confirmYesLabel,
                                value: true,
                            },
                        ];

                        return (
                            <ErrorHandlingMutationForm<IConfirmAccountMutationVariables, confirmAccountMutationResponse>
                                mutationFunc={confirmAccount}
                                onPrevious={onBack}
                                onNext={onNext}
                                initialValues={{ ...account }}
                                saveButtonMessage={nextButtonMessage}
                                alwaysSubmit={true}
                            >
                                <Card className={classNames("mt-3", "mb-2")}>
                                    <CardHeader>
                                        <Message message={accountMessages.additionalDetailsLabel} />
                                    </CardHeader>
                                    <CardBody>
                                        <HideableContainer
                                            field={HideableFields.canDiscloseToPublic}
                                            configuration={configuration}
                                        >
                                            <FormLabel
                                                message={messages.canDiscloseToPublicLabel}
                                                required={true}
                                                id="canDiscloseToPublicLabel"
                                            >
                                                <InlineRadioButton
                                                    field="canDiscloseToPublic"
                                                    options={optionsYesNo}
                                                    validate={required}
                                                    labelledBy="canDiscloseToPublicLabel"
                                                />
                                                <FormText>
                                                    <Message
                                                        message={messages.canDiscloseToPublicHelpText}
                                                    />
                                                </FormText>
                                            </FormLabel>
                                        </HideableContainer>
                                        <HideableContainer
                                            field={HideableFields.isSubscribedToShelterNewsletter}
                                            configuration={configuration}
                                        >
                                            <FormLabel
                                                message={accountMessages.subscribeToShelterNewsletter}
                                                required={true}
                                                id="isSubscribedToShelterNewsletterLabel"
                                            >
                                                <InlineRadioButton
                                                    field="isSubscribedToShelterNewsletter"
                                                    options={optionsYesNo}
                                                    validate={required}
                                                    labelledBy="isSubscribedToShelterNewsletterLabel"
                                                />
                                            </FormLabel>
                                        </HideableContainer>
                                    </CardBody>
                                </Card>
                            </ErrorHandlingMutationForm>
                        );
                    }}
                </Query>
            </div>
        </div>
    );
};
