import graphql from "babel-plugin-relay/macro";
import { ElavonCallbacks } from "components/payment/Elavon/Converge/convergeTypes";
import { convergeQuery, PaymentSessionInput } from "generatedQueries/convergeQuery.graphql";
import { fetchQuery, IEnvironment } from "relay-runtime";

const creditCardGetToken = "ccgettoken";

export enum Result {
    Success = "0",
    Failure = "1",
}

export enum AddTokenResponse {
    CardAdded = "Card Added",
    CardUpdated = "Card Updated",
}

export enum TokenResponse {
    Success = "SUCCESS",
}

export type ConvergeSuccessCallback = (response: IConvergeTokenResponse) => void;
export type ConvergeErrorCallback = (response?: IConvergeCreditCardResponse) => void;
export type ConvergeCancelledCallback = () => void;

export function openConvergeLightbox(
    environment: IEnvironment,
    successCallback: ConvergeSuccessCallback,
    errorCallback: ConvergeErrorCallback,
    cancelledCallback: ConvergeCancelledCallback,
    amount: number) {

    const paymentSessionInput: PaymentSessionInput = {
        convergeSessionDetails: {
            amount,
            transactionType: creditCardGetToken,
        },
    };

    fetchQuery<convergeQuery>(environment, elavonQuery, { input: paymentSessionInput }, { networkCacheConfig: { force: true } })
        .subscribe({
            error() {
                errorCallback(undefined);
            },
            next(data) {
                const paymentSessionToken = data.paymentSession?.token;

                if (window.PayWithConverge && paymentSessionToken) {
                    window.PayWithConverge.open({ ssl_txn_auth_token: paymentSessionToken }, createElavonCallbacks(errorCallback, successCallback, cancelledCallback));
                } else {
                    errorCallback(undefined);
                }
            },
        });
}

export function createElavonCallbacks(
    errorCallback: ConvergeErrorCallback,
    successCallback: ConvergeSuccessCallback,
    cancelledCallback: ConvergeCancelledCallback): ElavonCallbacks {

    return {
        onError() {
            errorCallback(undefined);
        },
        onCancelled() {
            cancelledCallback();
        },
        onDeclined() {
            // Declines are inconsistent and mainly handled by their iframe.
            errorCallback(undefined);
        },
        onApproval(response: IConvergeCreditCardResponse) {
            if (isTokenResponse(response) && isTokenResponseSuccessful(response)) {
                successCallback(response);
            }
            else {
                errorCallback(response);
            }
        },
    };
}

function isTokenResponseSuccessful(response: IConvergeTokenResponse) {
    if (response.ssl_result !== Result.Success) {
        return false;
    }

    return response.ssl_token_response === TokenResponse.Success &&
        (response.ssl_add_token_response === AddTokenResponse.CardAdded || response.ssl_add_token_response === AddTokenResponse.CardUpdated);
}

function isTokenResponse(response: IConvergeCreditCardResponse): response is IConvergeTokenResponse {
    return (response as IConvergeTokenResponse).ssl_token !== undefined;
}

const elavonQuery = graphql`
query convergeQuery ($input: PaymentSessionInput) {
    paymentSession(paymentSessionInput: $input) {
        token
    }
}`;
