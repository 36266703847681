import { Message } from "shared/components/Message";
import React, { FC, ReactNode } from "react";
import { MessageDescriptor } from "react-intl";
import Button from "reactstrap/lib/Button";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { sharedMessages } from "shared/sharedMessages";

interface IProps {
    show: boolean;
    children?: ReactNode;
    modalTitle: MessageDescriptor;
}

interface IEvents {
    onToggleModal: () => void;
}

type Props = IProps & IEvents;

export const InfoModal: FC<Props> = ({
    children,
    show,
    onToggleModal,
    modalTitle,
}) => {
    return <Modal isOpen={show}>
        <ModalHeader>
            <Message message={modalTitle} />
        </ModalHeader>
        <ModalBody>
            {children}
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={onToggleModal}><Message message={sharedMessages.okLabel} /></Button>
        </ModalFooter>
    </Modal>;
};
