/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PersonNameSuffixFilter = {
    AND?: Array<PersonNameSuffixFilter> | null;
    OR?: Array<PersonNameSuffixFilter> | null;
    isShown?: boolean | null;
};
export type PersonNameSuffixesPaginationQueryVariables = {
    suffixesAfter?: string | null;
    suffixesPageSize?: number | null;
    suffixesWhere?: PersonNameSuffixFilter | null;
};
export type PersonNameSuffixesPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"PersonNameSuffixes_query">;
};
export type PersonNameSuffixesPaginationQuery = {
    readonly response: PersonNameSuffixesPaginationQueryResponse;
    readonly variables: PersonNameSuffixesPaginationQueryVariables;
};



/*
query PersonNameSuffixesPaginationQuery(
  $suffixesAfter: String
  $suffixesPageSize: Int
  $suffixesWhere: PersonNameSuffixFilter
) {
  ...PersonNameSuffixes_query
}

fragment PersonNameSuffixes_query on Query {
  personNameSuffixes(first: $suffixesPageSize, after: $suffixesAfter, where: $suffixesWhere) {
    edges {
      node {
        id
        displayId
        name
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "suffixesAfter"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "suffixesPageSize"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "suffixesWhere"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "suffixesAfter"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "suffixesPageSize"
        } as any,
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "suffixesWhere"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "PersonNameSuffixesPaginationQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PersonNameSuffixes_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "PersonNameSuffixesPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "PersonNameSuffixConnection",
                    "kind": "LinkedField",
                    "name": "personNameSuffixes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PersonNameSuffixEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PersonNameSuffix",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "displayId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "filters": [
                        "where"
                    ],
                    "handle": "connection",
                    "key": "PersonNameSuffixes_query_personNameSuffixes",
                    "kind": "LinkedHandle",
                    "name": "personNameSuffixes"
                }
            ]
        },
        "params": {
            "cacheID": "7d8231d08557afe5ef708781c50db4f1",
            "id": null,
            "metadata": {},
            "name": "PersonNameSuffixesPaginationQuery",
            "operationKind": "query",
            "text": "query PersonNameSuffixesPaginationQuery(\n  $suffixesAfter: String\n  $suffixesPageSize: Int\n  $suffixesWhere: PersonNameSuffixFilter\n) {\n  ...PersonNameSuffixes_query\n}\n\nfragment PersonNameSuffixes_query on Query {\n  personNameSuffixes(first: $suffixesPageSize, after: $suffixesAfter, where: $suffixesWhere) {\n    edges {\n      node {\n        id\n        displayId\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'c2d9dae03bcf1085d4fcd9debc41387e';
export default node;
