import { Message } from "shared/components/Message";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";

interface IText {
    displayText: MessageDescriptor;
}

interface ILink {
    link: string;
    icon: IconProp;
    shouldDisplayIcon: boolean;
}

export type ITextLink = IText & ILink;

export const TextLink: FC<ITextLink> = ({ displayText, link, icon, shouldDisplayIcon }) => {
    return <a href={link}>
        {
            shouldDisplayIcon && <FontAwesomeIcon size={"sm"} icon={icon} />
        }
        <Message message={displayText} />
    </a>;
};

interface IEmailIconLink {
    displayText: MessageDescriptor;
    link: string;
}

export const createEmailHref = (emailAddress: string): string => "mailto:" + emailAddress;

export const EmailIconLink: FC<IEmailIconLink> = ({ displayText, link }) => <TextLink displayText={displayText} link={createEmailHref(link)} icon={faEnvelope} shouldDisplayIcon={true} />;
