import { DatePicker } from "components/shared/datePicker/DatePicker";
import { combine, greaterThan, required } from "components/shared/validations";
import graphql from "babel-plugin-relay/macro";
import { Message } from "shared/components/Message";
import { Rabies_account$key } from "generatedQueries/Rabies_account.graphql";
import { Rabies_configuration$key } from "generatedQueries/Rabies_configuration.graphql";
import { FormError } from "informed";
import React, { useMemo, VFC } from "react";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { FormSelect } from "shared/components/formInputs/FormSelect";
import { Upload } from "shared/components/formInputs/Upload";
import { ISelectOption } from "shared/components/ReactSelect";
import { datePlus, getValidatedDateOrNull } from "shared/dateFunctions";
import { mapMessages } from "shared/mapMessages";
import { v4 as uuidv4 } from "uuid";

const messages = mapMessages("components.animal.Rabies", {
    immunity12MonthLabel: "1 Year",
    immunity36MonthLabel: "3 Years",
    immunityPeriodLabel: "Rabies vaccination effective for",
    rabiesExpiredValidationMessage: "Rabies vaccination is expired. Please either correct the vaccination date/effective period, or have your pet vaccinated before proceeding.",
    sectionHeading: "Proof of Rabies",
    uploadLabel: "Please upload proof of rabies vaccination",
    vaccinationDateLabel: "Rabies vaccination date",
});

interface IGraphProps {
    configurationKey: Rabies_configuration$key | null;
    accountKey: Rabies_account$key;
}

export const Rabies: VFC<IGraphProps> = ({
    configurationKey,
    accountKey,
}) => {
    const configuration = useFragment(configurationFragment, configurationKey);
    const account = useFragment(accountFragment, accountKey);
    const { formatMessage } = useIntl();
    const rabiesImmunisationPeriods = useMemo<ISelectOption[]>(() => {
        return [
            {
                label: formatMessage(messages.immunity12MonthLabel),
                value: 12,
            },
            {
                label: formatMessage(messages.immunity36MonthLabel),
                value: 36,
            },
        ];
    }, [formatMessage]);
    const allowedFileExtensions = useMemo(() => {
        return configuration?.proofOfRabiesAllowedFileExtensions?.map(e => e) ?? [];
    }, [configuration]);

    return <>
        <h5><Message message={messages.sectionHeading} /></h5>
        <FormLabel
            message={messages.uploadLabel}
            required={true}
            for="rabiesVaccinationStorageId"
        >
            <Upload
                field="rabiesVaccinationStorageId"
                validate={required}
                allowedFileExtensions={allowedFileExtensions}
                categoryStaticId="5EB43689-A9F2-4B6D-ADA6-0BFFC333919C"
                getFileName={getFileName}
            />
        </FormLabel>
        <FormLabel
            message={messages.vaccinationDateLabel}
            required={true}
            for="rabiesVaccinationDateAdministered"
        >
            <DatePicker
                id="rabiesVaccinationDateAdministered"
                data-cy="rabiesVaccinationDateAdministered"
                field="rabiesVaccinationDateAdministered"
                validate={required}
                notify={["rabiesVaccinationImmunityPeriodMonths"]}
                aria-required={true}
            />
        </FormLabel>
        <FormLabel
            message={messages.immunityPeriodLabel}
            required={true}
            for="rabiesVaccinationImmunityPeriodMonths"
        >
            <FormSelect
                id="rabiesVaccinationImmunityPeriodMonths"
                className="rabies-immunity-period"
                field="rabiesVaccinationImmunityPeriodMonths"
                options={rabiesImmunisationPeriods}
                isSearchable={false}
                validate={combine(required, greaterThan(0), rabiesExpiryDateValidator)}
                aria-required={true}
            />
        </FormLabel>
    </>;

    function getFileName(index: number | undefined) {
        return `${account.id}_${uuidv4()}${index !== undefined && index > 0 ? `_${index}` : ""}`;
    }
};

function rabiesExpiryDateValidator(rabiesVaccinationImmunityPeriodMonths: number | undefined, formValues: { rabiesVaccinationDateAdministered?: string }): FormError {
    const dateAdministered = getValidatedDateOrNull(formValues.rabiesVaccinationDateAdministered ?? "");

    if (!dateAdministered) {
        return undefined;
    }

    return isRabiesExpired(dateAdministered, rabiesVaccinationImmunityPeriodMonths ?? 12)
        ? messages.rabiesExpiredValidationMessage
        : undefined;
}

function isRabiesExpired(dateAdministered: Date, immunityPeriod: number) {
    return datePlus(dateAdministered, immunityPeriod, "months") < new Date();
}

const configurationFragment = graphql`
    fragment Rabies_configuration on Configuration {
        proofOfRabiesAllowedFileExtensions
    }`;

const accountFragment = graphql`
    fragment Rabies_account on Account {
        id
    }`;
