import { forOwn, keys } from "lodash";
import { parse } from "query-string";

export function parseQueryStringValues<T extends {}>(search: string, destination: T) {
    const queryString = parse(search);

    const destinationKeys = keys(destination);

    forOwn(queryString, (value, key) => {
        const lowerKey = key.toLowerCase();
        const destinationKey = destinationKeys.find(k => k.toLowerCase() === lowerKey);

        if (destinationKey) {
            destination[destinationKey] = getValue(destination[destinationKey], value);
        }
    });

    return destination;
}

function getValue(currentValue: string | undefined, queryStringValue: string | string[] | undefined | null): string | undefined {
    if (queryStringValue) {
        if (typeof queryStringValue === "string") {
            return queryStringValue;
        }
        else if (queryStringValue.length >= 1) {
            return queryStringValue[0];
        }
    }

    return currentValue;
}
