import { TryCurrencyFormat } from "components/shared/Money";
import graphql from "babel-plugin-relay/macro";
import { getDateOfBirthOrNull } from "components/animal/Animal";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { licenceMessages } from "domain/licence/licenceMessages";
import { LicenseTypeProviderQuery, LicenseTypeProviderQueryResponse } from "generatedQueries/LicenseTypeProviderQuery.graphql";
import { useFieldState } from "informed";
import moment from "moment";
import React, { FC, ReactElement, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { Query } from "shared/components/Query";
import { sharedMessages } from "shared/sharedMessages";

export interface ILicenseTypeSelection {
    description: string;
    licenseTypeId: number;
    isLifetime: boolean;
    expiryMonths: number | null;
    expiryDate: {
        calculatedExpiryDate: string;
    } | null;
}

export interface ILicenseTypeProviderProps {
    isRenewal: boolean;
    isMicrochipped: boolean;
    jurisdictionId: number;
    dateOfBirthField: string;
    animalTypeIdField: string;
    isDesexedField: string;
    children: (props: { licenseTypes: ILicenseTypeSelection[], isLoading: boolean }) => ReactElement;
}

export const LicenseTypeProvider: FC<ILicenseTypeProviderProps> = ({
    isRenewal,
    isMicrochipped,
    jurisdictionId,
    dateOfBirthField,
    animalTypeIdField,
    isDesexedField,
    children,
}) => {
    const intl = useIntl();

    const animalTypeIdFieldState = useFieldState<string>(animalTypeIdField || "");
    const isDesexedFieldState = useFieldState<boolean | null>(isDesexedField || "");
    const dateOfBirthFieldState = useFieldState<string>(dateOfBirthField || "");

    const variables = useMemo(() => ({
        animalTypeId: animalTypeIdFieldState.value,
        commencementDate: moment().format("YYYY-MM-DD"),
        dateOfBirth: getDateOfBirthOrNull(dateOfBirthFieldState.value),
        isDesexed: !!isDesexedFieldState.value,
        isMicrochipped,
        isRenewal,
        jurisdictionId,
    }), [animalTypeIdFieldState.value, dateOfBirthFieldState.value, isDesexedFieldState.value, isMicrochipped, isRenewal, jurisdictionId]);

    return (
        <Query<LicenseTypeProviderQuery>
            query={query}
            variables={variables}
        >
            {({ error, props }) => {
                if (error) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                return children({
                    isLoading: !props,
                    licenseTypes: props?.onlineLicenceTypes?.map(t => {
                        return {
                            description: getLicenseLabel(t, intl),
                            licenseTypeId: t.licenceTypeId,
                            isLifetime: t.isLifetime,
                            expiryMonths: t.expiryMonths,
                            expiryDate: t.expiryDate,
                        };
                    }) ?? [],
                });
            }}
        </Query>
    );
};

export function getLicenseLabel(licenceType: NonNullable<LicenseTypeProviderQueryResponse["onlineLicenceTypes"]>[0], intl: IntlShape): string {
    const term = getLicenseTerm(licenceType, intl);
    const licenceCost = TryCurrencyFormat({ intl, money: { amount: licenceType.amount, currency: licenceType.currency } });
    return `${licenceType.name} - ${term} - ${licenceCost}`;
}

function getLicenseTerm(licenceType: NonNullable<LicenseTypeProviderQueryResponse["onlineLicenceTypes"]>[0], intl: IntlShape): string {
    if (licenceType.isLifetime) {
        return intl.formatMessage(licenceMessages.lifetimeLabel);
    }

    if (licenceType.expiryMonths) {
        return `${licenceType.expiryMonths} ${intl.formatMessage(licenceMessages.monthLabel)}`;
    }

    if (licenceType.expiryDate) {
        const expiryDate = moment(licenceType.expiryDate.calculatedExpiryDate);
        return `${intl.formatMessage(licenceMessages.expiryDateLabel)} ${expiryDate.format("LL")}`;
    }

    if (licenceType.name) {
        return licenceType.name;
    }

    return intl.formatMessage(licenceMessages.unknownLicenceTypeLabel);
}

const query = graphql`
    query LicenseTypeProviderQuery(
        $jurisdictionId: Int!,
        $animalTypeId: ID!,
        $isDesexed: Boolean!,
        $isMicrochipped: Boolean!,
        $dateOfBirth: LocalDate,
        $commencementDate: LocalDate!,
        $isRenewal: Boolean!) {
        onlineLicenceTypes(jurisdictionId: $jurisdictionId, animalTypeId: $animalTypeId, isDesexed: $isDesexed, isMicrochipped: $isMicrochipped, dateOfBirth: $dateOfBirth) {
            licenceTypeId
            name
            isLifetime
            expiryMonths
            expiryDate {
                calculatedExpiryDate(commencementDate: $commencementDate, isRenewal: $isRenewal)
            }
            amount
            currency
        }
    }`;
