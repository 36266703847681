/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Header_configuration = {
    readonly menuItems: {
        readonly value: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"HeaderLogo_configuration">;
    readonly " $refType": "Header_configuration";
};
export type Header_configuration$data = Header_configuration;
export type Header_configuration$key = {
    readonly " $data"?: Header_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"Header_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Header_configuration",
    "selections": [
        {
            "alias": "menuItems",
            "args": [
                {
                    "kind": "Literal",
                    "name": "application",
                    "value": "licensing"
                },
                {
                    "kind": "Literal",
                    "name": "name",
                    "value": "menuItems"
                }
            ],
            "concreteType": "SiteCustomisation",
            "kind": "LinkedField",
            "name": "siteCustomisation",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                }
            ],
            "storageKey": "siteCustomisation(application:\"licensing\",name:\"menuItems\")"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HeaderLogo_configuration"
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any;
(node as any).hash = '277ffbfdcd497122fbfc12d5c526a43d';
export default node;
