/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HideableFormLabel_configuration = {
    readonly " $fragmentRefs": FragmentRefs<"Hideable_configuration">;
    readonly " $refType": "HideableFormLabel_configuration";
};
export type HideableFormLabel_configuration$data = HideableFormLabel_configuration;
export type HideableFormLabel_configuration$key = {
    readonly " $data"?: HideableFormLabel_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"HideableFormLabel_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HideableFormLabel_configuration",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Hideable_configuration"
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = 'dec2b9cac2712c5e8c4d11dc9cdd79e0';
export default node;
