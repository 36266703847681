import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { Alert } from "reactstrap";
import { Message } from "shared/components/Message";

interface IMyProps {
    className?: string;
    heading?: MessageDescriptor;
    headerValues?: { [key: string]: string | PrimitiveType | React.ReactElement | FormatXMLElementFn };
    message: MessageDescriptor | string;
    messageValues?: { [key: string]: string | PrimitiveType | React.ReactElement | FormatXMLElementFn };
    icon?: IconProp;
}

type Props = IMyProps;

export const WarningMessage: FC<Props> = ({
    className,
    heading,
    headerValues,
    message,
    messageValues,
    icon,
}) => {
    return <Alert color="warning" className={className}>
        {
            icon && !heading &&
            <FontAwesomeIcon icon={icon} />
        }
        {
            heading &&
            <h4 className="alert-heading">
                {
                    icon &&
                    <FontAwesomeIcon icon={icon} className="mr-2" />
                }
                <Message message={heading} values={headerValues} />
            </h4>
        }
        <span className={icon && "ml-2"}>
            <Message message={message} values={messageValues} />
        </span>
    </Alert>;
};
