import graphql from "babel-plugin-relay/macro";
import { LicensingPaymentMethodInput, processPaymentMethodMutation, processPaymentMethodMutationResponse } from "generatedQueries/processPaymentMethodMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation processPaymentMethodMutation($payment: LicensingPaymentMethodInput!) {
        licensingPaymentMethod(payment: $payment) {
            isSuccessful
            code
        }
    }`;

export function processPaymentMethod(
    environment: IEnvironment,
    payment: LicensingPaymentMethodInput,
    onCompleted: (response: processPaymentMethodMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<processPaymentMethodMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                payment,
            },
        }
    );
}
