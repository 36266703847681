/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LicensingAnimalFilter = {
    licenceExpiry_lte?: string | null;
};
export type HomeContentQueryVariables = {
    filter?: LicensingAnimalFilter | null;
};
export type HomeContentQueryResponse = {
    readonly account: {
        readonly " $fragmentRefs": FragmentRefs<"NoPets_account" | "ExpiringSoon_account" | "LicenseWarnings_account">;
    } | null;
};
export type HomeContentQuery = {
    readonly response: HomeContentQueryResponse;
    readonly variables: HomeContentQueryVariables;
};



/*
query HomeContentQuery(
  $filter: LicensingAnimalFilter
) {
  account {
    ...NoPets_account
    ...ExpiringSoon_account_Vt7Yj
    ...LicenseWarnings_account
    id
  }
}

fragment ExpiringSoon_account_Vt7Yj on Account {
  expiringSoon: animals(filter: $filter) {
    edges {
      node {
        id
        name
        licenceExpiry
      }
    }
  }
}

fragment LicenseWarnings_account on Account {
  animals {
    edges {
      node {
        id
        name
        status
      }
    }
  }
}

fragment NoPets_account on Account {
  hasAnimals
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filter"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "filter",
            "variableName": "filter"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "HomeContentQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "NoPets_account"
                        },
                        {
                            "args": (v1 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "ExpiringSoon_account"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "LicenseWarnings_account"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "HomeContentQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasAnimals",
                            "storageKey": null
                        },
                        {
                            "alias": "expiringSoon",
                            "args": (v1 /*: any*/),
                            "concreteType": "LicensingAnimalConnection",
                            "kind": "LinkedField",
                            "name": "animals",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingAnimalEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "LicensingAnimal",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "licenceExpiry",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingAnimalConnection",
                            "kind": "LinkedField",
                            "name": "animals",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingAnimalEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "LicensingAnimal",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "status",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "0e5b40adc2d31f8a1880cce36b51d6a9",
            "id": null,
            "metadata": {},
            "name": "HomeContentQuery",
            "operationKind": "query",
            "text": "query HomeContentQuery(\n  $filter: LicensingAnimalFilter\n) {\n  account {\n    ...NoPets_account\n    ...ExpiringSoon_account_Vt7Yj\n    ...LicenseWarnings_account\n    id\n  }\n}\n\nfragment ExpiringSoon_account_Vt7Yj on Account {\n  expiringSoon: animals(filter: $filter) {\n    edges {\n      node {\n        id\n        name\n        licenceExpiry\n      }\n    }\n  }\n}\n\nfragment LicenseWarnings_account on Account {\n  animals {\n    edges {\n      node {\n        id\n        name\n        status\n      }\n    }\n  }\n}\n\nfragment NoPets_account on Account {\n  hasAnimals\n}\n"
        }
    } as any;
})();
(node as any).hash = '3d0b95223db1b8989a8a33e15e4da0d3';
export default node;
