import graphql from "babel-plugin-relay/macro";
import { verifyEmailMutation, verifyEmailMutationResponse } from "generatedQueries/verifyEmailMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation verifyEmailMutation($code: String!) {
        verifyLicensingAccountEmail(code: $code)
    }`;

export function verifyEmail(
    environment: IEnvironment,
    code: string,
    onCompleted: (response: verifyEmailMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<verifyEmailMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                code,
            },
        }
    );
}
