/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Addresses_account = {
    readonly physicalAddress: {
        readonly " $fragmentRefs": FragmentRefs<"ReadonlyAddress_address">;
    } | null;
    readonly mailingAddress: {
        readonly " $fragmentRefs": FragmentRefs<"ReadonlyAddress_address">;
    } | null;
    readonly " $refType": "Addresses_account";
};
export type Addresses_account$data = Addresses_account;
export type Addresses_account$key = {
    readonly " $data"?: Addresses_account$data;
    readonly " $fragmentRefs": FragmentRefs<"Addresses_account">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReadonlyAddress_address"
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "Addresses_account",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingStreetAddress",
                "kind": "LinkedField",
                "name": "physicalAddress",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "mailingAddress",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            }
        ],
        "type": "Account",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'c03a7d42cf4ebcef22421dbfb8333c87';
export default node;
