import { ValidationErrorPane } from "components/shared/ValidationErrorPane";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Spinner } from "components/shared/Spinner";
import { Message } from "shared/components/Message";
import { toastActions } from "domain/toast/toastActions";
import { FieldProps, FormValues, useField } from "informed";
import { UploadStatus, useUpload } from "infrastructure/useUpload";
import join from "lodash/join";
import orderBy from "lodash/orderBy";
import React, { ComponentProps, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { routes } from "routes/routes";
import styles from "shared/components/formInputs/Upload.module.scss";
import { withDefaultValidation } from "shared/components/formInputs/withDefaultValidation";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("components.forminputs.upload", {
    cookieWarning: "Cookies are disabled on your browser, which may cause uploads to fail. If this happens, you may need to retry a number of times, or try uploading a smaller file.",
});

interface IProps {
    field: string;
    allowedFileExtensions: string[];
    categoryStaticId: string;
    getFileName?: (index: number | undefined) => string;
}

type Props<T extends FormValues = FormValues> = IProps & FieldProps<number, T>;

const UploadInput = <T extends FormValues>(props: Props<T>) => {
    const { fieldState, fieldApi, render, ref } = useField({ ...props });
    const {
        categoryStaticId,
        allowedFileExtensions,
        field,
        id,
        getFileName,
    } = props;
    const dispatch = useDispatch();
    const { status, progress, storageId } = useUpload(ref, categoryStaticId, allowedFileExtensions, fieldState.value ? "existing" : "notStarted", getFileName);
    const [showCookieWarning] = useState(() => {
        if (navigator.cookieEnabled) {
            return false;
        }

        try {
            const testCookieName = "testcookie";

            document.cookie = `${testCookieName}=1`;
            const cookiesEnabled = document.cookie.indexOf(testCookieName) !== -1;

            if (cookiesEnabled) {
                document.cookie = `${testCookieName}=1; expires=Thu, 01-Jan-1970 00:00:01 GMT`;
            }

            return !cookiesEnabled;
        }
        catch {
            return true;
        }
    });

    useEffect(() => {
        const value = fieldApi.getValue();
        if (value !== storageId && storageId !== null && status !== "failed") {
            fieldApi.setValue(storageId);
        } else if ((status === "failed" || status === "started") && !!value) {
            fieldApi.setValue(undefined as unknown as number);
        }
    }, [storageId, status, fieldApi]);

    useEffect(() => {
        if (ref.current) {
            ref.current.setCustomValidity(fieldState.error || "");
        }
    }, [ref, fieldState.error]);

    if (status === "unauthenticated") {
        dispatch(toastActions.customFailureToast(sharedMessages.authenticationFailedToastMessage));
        return <Redirect to={routes.user.logout.create({})} />;
    }

    return render(
        <div className={classNames("upload", styles.upload, fieldState.touched && ["was-validated", fieldState.error ? "invalid" : "valid"])}>
            <div className="input-group">
                <div className="input-group-prepend" role="button">
                    <span className="input-group-text">
                        <Message message={sharedMessages.browseLabel} />
                    </span>
                </div>
                <div className="form-control message" aria-live="polite">
                    <Message {...getMessageProps(status)} />
                    {
                        progress !== undefined &&
                        <span data-testid="uploadSpinner">
                            <Spinner className="ml-2" />
                        </span>
                    }
                </div>
            </div>
            <input
                id={id}
                type="text"
                className={classNames("form-control", styles.hidden)}
                ref={ref}
                aria-hidden={true}
                data-testid="uploadInput"
            />
            <ValidationErrorPane field={field} />
            {
                status === "invalid" &&
                <ErrorMessage className="mt-1" message={sharedMessages.uploadInvalidMessage} messageValues={{ allowed: join(orderBy(allowedFileExtensions), ", ") }} />
            }
            {
                showCookieWarning &&
                <WarningMessage message={messages.cookieWarning} icon={faExclamationTriangle} className="mt-2" />
            }
        </div>
    );
};

function getMessageProps(status: Exclude<UploadStatus, "unauthenticated">): ComponentProps<typeof Message> {
    switch (status) {
        case "existing":
            return { message: sharedMessages.uploadExistingMessage };
        case "started":
            return { message: sharedMessages.uploadProgressMessage };
        case "failed":
            return { message: sharedMessages.uploadFailedMessage };
        case "complete":
            return { message: sharedMessages.uploadCompleteMessage };
        default:
            return { message: sharedMessages.uploadChooseFilePlaceholder };
    }
}

export const Upload = withDefaultValidation<number, FormValues>()(UploadInput);
