import { ActionType, createAction } from "typesafe-actions";

const redirectTo = createAction("REDIRECT_TO", resolve => {
    return (route: string) => {
        return resolve(route);
    };
});

export const redirectActions = {
    redirectTo,
};

export type RedirectAction = ActionType<typeof redirectActions>;
