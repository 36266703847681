import { endsWith } from "lodash";
import { default as mapValues } from "lodash/mapValues";
import { defineMessages } from "react-intl";

export function mapMessages<T extends string>(prefix: string, messages: Record<T, string>): Record<T, { defaultMessage: string, id: string }> {
    return defineMessages(mapValues(messages, (value, key) => ({
        defaultMessage: value,
        id: (endsWith(prefix, ".") ? prefix : `${prefix}.`) + key,
    })));
}
