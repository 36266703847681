import { ValidationErrorPane } from "components/shared/ValidationErrorPane";
import React, { FC, Fragment, useEffect, useRef } from "react";

interface IProps {
    field: string;
    error?: string;
    forwardRef?: (ref: HTMLInputElement) => void;
}

export const DatePickerInput: FC<IProps> = (props) => {
    const ref = useRef<HTMLInputElement>(null);

    const {
        field,
        error,
        forwardRef,
        ...rest
    } = props;

    useEffect(() => {
        if (ref.current && forwardRef) {
            forwardRef(ref.current);
        }
    }, [forwardRef, ref]);

    useEffect(() => {
        if (ref.current) {
            ref.current.setCustomValidity(error || "");
        }
    }, [error, ref]);

    return (
        <Fragment>
            <input type="text" ref={ref} {...rest} />
            <ValidationErrorPane field={field} />
        </Fragment>
    );
};
