import { InfoMessage } from "components/shared/InfoMessage";
import { Money } from "components/shared/Money";
import graphql from "babel-plugin-relay/macro";
import { openConvergeLightbox } from "components/payment/Elavon/Converge/converge";
import { convergeMessages } from "components/payment/Elavon/Converge/convergeMessages";
import { PaymentFailedMessage } from "components/payment/PaymentFailedMessage";
import { IPaymentFormChildProps } from "components/payment/PaymentForm";
import { StandardPaymentButtonLayout } from "components/payment/StandardPaymentButtonLayout";
import { Message } from "shared/components/Message";
import { paymentMessages } from "domain/payment/paymentMessages";
import { ConvergeProvider_configuration } from "generatedQueries/ConvergeProvider_configuration.graphql";
import isEqual from "lodash/isEqual";
import React, { FC, useEffect, useState } from "react";
import { createFragmentContainer, useRelayEnvironment } from "react-relay";
import useScript from "react-script-hook";
import { Button, Spinner } from "reactstrap";

interface IGraphQlProps {
    configuration: ConvergeProvider_configuration;
}

interface IProps {
    termsAndConditionsAccepted: boolean;
    allPaymentRowsAreValid: boolean;
}

type Props = IProps & IPaymentFormChildProps & IGraphQlProps;

const ConvergeProviderInternal: FC<Props> = ({
    configuration,
    paymentReady,
    requestState,
    errorMessages,
    termsAndConditionsAccepted,
    allPaymentRowsAreValid,
    amountToCharge,
    submitPayment,
    isPaymentProcessing: isPaymentFormProcessing,
}) => {
    const environment = useRelayEnvironment();

    const [isConvergePaymentProcessing, setIsConvergePaymentProcessing] = useState(false);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);

    const [isConvergeScriptLoading, convergeScriptError] = useScript({
        src: configuration.elavonConvergeLibraryUri,
        checkForExisting: true,
        async: "true",
    });

    useEffect(() => {
        if (convergeScriptError) {
            setHasErrorOccurred(true);
        }
    }, [convergeScriptError]);

    useEffect(() => {
        const paymentMethodRequest = isConvergeScriptLoading ? {
            convergePaymentRequest: {
                token: "",
            },
        } : null;

        if (!isEqual(paymentMethodRequest, requestState)) {
            paymentReady(paymentMethodRequest);
        }
    }, [paymentReady, requestState, isConvergeScriptLoading]);

    const isPaymentValid = termsAndConditionsAccepted && allPaymentRowsAreValid && amountToCharge > 0;

    const paymentButtonColor = isPaymentValid
        ? "primary"
        : "secondary";

    const isPaymentButtonDisabled = isConvergeScriptLoading || isConvergePaymentProcessing || !isPaymentValid || isPaymentFormProcessing;

    return (
        <>
            <InfoMessage message={convergeMessages.convergeWaitLabel} />
            <StandardPaymentButtonLayout
                payButton={
                    <div className="text-center">
                        <Button
                            className="w-50"
                            color={paymentButtonColor}
                            disabled={isPaymentButtonDisabled}
                            onClick={onPaymentButtonClicked}>
                            {
                                isConvergePaymentProcessing || isPaymentFormProcessing
                                    ? <Spinner className="icon-button ml-2" />
                                    : <Message message={paymentMessages.payButtonLabel} />
                            }
                            <span className="ml-2">
                                {
                                    isConvergePaymentProcessing || isPaymentFormProcessing
                                        ? <Message message={paymentMessages.paymentIsProcessing} />
                                        : <Money amount={amountToCharge} currency={configuration.currency} />
                                }
                            </span>
                        </Button>
                    </div>
                }
                errorMessages={errorMessages} />
            {
                hasErrorOccurred &&
                <PaymentFailedMessage code={"ERROR"} organisationContact={configuration.organisationContact} />
            }
        </>
    );

    function onPaymentButtonClicked() {
        setIsConvergePaymentProcessing(true);
        openConvergeLightbox(environment, onPaymentSessionResponse, onPaymentSessionError, onPaymentSessionCancel, amountToCharge);
    }

    function onPaymentSessionResponse(response: IConvergeTokenResponse) {
        setIsConvergePaymentProcessing(false);
        submitPayment({
            convergePaymentRequest: {
                token: response.ssl_token,
            },
        });
    }

    function onPaymentSessionError() {
        setIsConvergePaymentProcessing(false);
        setHasErrorOccurred(true);
    }

    function onPaymentSessionCancel() {
        setIsConvergePaymentProcessing(false);
    }
};

export const ConvergeProvider = createFragmentContainer(ConvergeProviderInternal, {
    configuration: graphql`
        fragment ConvergeProvider_configuration on Configuration {
            elavonConvergeLibraryUri
            currency
            organisationContact
        }`,
});
