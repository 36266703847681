/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AnimalBreedsQueryVariables = {
    animalTypeId: string;
    dateOfBirth?: string | null;
};
export type AnimalBreedsQueryResponse = {
    readonly breeds: ReadonlyArray<{
        readonly breedId: number;
        readonly name: string;
    }> | null;
    readonly licensingAnimalColours: ReadonlyArray<{
        readonly colourId: number;
        readonly name: string;
    }> | null;
};
export type AnimalBreedsQuery = {
    readonly response: AnimalBreedsQueryResponse;
    readonly variables: AnimalBreedsQueryVariables;
};



/*
query AnimalBreedsQuery(
  $animalTypeId: ID!
  $dateOfBirth: LocalDate
) {
  breeds: licensingBreeds(animalTypeId: $animalTypeId, dateOfBirth: $dateOfBirth) {
    breedId
    name
  }
  licensingAnimalColours(animalTypeId: $animalTypeId, dateOfBirth: $dateOfBirth) {
    colourId
    name
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "animalTypeId"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "dateOfBirth"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "animalTypeId",
            "variableName": "animalTypeId"
        } as any,
        {
            "kind": "Variable",
            "name": "dateOfBirth",
            "variableName": "dateOfBirth"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v3 = [
        {
            "alias": "breeds",
            "args": (v1 /*: any*/),
            "concreteType": "LicensingBreed",
            "kind": "LinkedField",
            "name": "licensingBreeds",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "breedId",
                    "storageKey": null
                },
                (v2 /*: any*/)
            ],
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": (v1 /*: any*/),
            "concreteType": "LicensingAnimalColour",
            "kind": "LinkedField",
            "name": "licensingAnimalColours",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "colourId",
                    "storageKey": null
                },
                (v2 /*: any*/)
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AnimalBreedsQuery",
            "selections": (v3 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AnimalBreedsQuery",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "cacheID": "f89af0226bc42578e721a70299df58a9",
            "id": null,
            "metadata": {},
            "name": "AnimalBreedsQuery",
            "operationKind": "query",
            "text": "query AnimalBreedsQuery(\n  $animalTypeId: ID!\n  $dateOfBirth: LocalDate\n) {\n  breeds: licensingBreeds(animalTypeId: $animalTypeId, dateOfBirth: $dateOfBirth) {\n    breedId\n    name\n  }\n  licensingAnimalColours(animalTypeId: $animalTypeId, dateOfBirth: $dateOfBirth) {\n    colourId\n    name\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'eed131d43de444db28c9a102a41ed6b6';
export default node;
