/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SyncStatus = "ACTIVE" | "BLOCKED" | "CANCELLED" | "DUPLICATE" | "NEW" | "PENDING" | "REJECTED" | "%future added value";
export type LicenseWarnings_account = {
    readonly animals: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly status: SyncStatus;
            };
        }> | null;
    } | null;
    readonly " $refType": "LicenseWarnings_account";
};
export type LicenseWarnings_account$data = LicenseWarnings_account;
export type LicenseWarnings_account$key = {
    readonly " $data"?: LicenseWarnings_account$data;
    readonly " $fragmentRefs": FragmentRefs<"LicenseWarnings_account">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LicenseWarnings_account",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "LicensingAnimalConnection",
            "kind": "LinkedField",
            "name": "animals",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicensingAnimalEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingAnimal",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Account",
    "abstractKey": null
} as any;
(node as any).hash = '98bd77b58653fee5000ccc432472ab35';
export default node;
