import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import React, { FC, ReactElement } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

interface IProps {
    message: MessageDescriptor | string;
    values?: { [key: string]: string | PrimitiveType | ReactElement | FormatXMLElementFn };
}

export const Message: FC<IProps> = ({ message, values }) => {
    if (typeof message === "string") {
        return <>{message}</>;
    }    
    return <FormattedMessage values={values} {...message} />;
};
