import graphql from "babel-plugin-relay/macro";
import { AnimalPublicTagSearchResultRow } from "components/animal/AnimalTagSearch/AnimalPublicTagSearchResultRow";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { lostPetMessages } from "domain/lostPet/lostPetMessages";
import { AnimalPublicTagSearchResultQuery } from "generatedQueries/AnimalPublicTagSearchResultQuery.graphql";
import React, { FC } from "react";
import { Query } from "shared/components/Query";
import { WarningMessage } from "shared/components/WarningMessage";
import { sharedMessages } from "shared/sharedMessages";

export interface IAnimalSearchProps {
    tag: string | null;
    recaptchaResponse: string | null;
}

interface IProps {
    maxAnimalsToDisplay?: number;
    onResultRendering?: () => void;
}

type Props = IAnimalSearchProps & IProps;

export const AnimalPublicTagSearchResult: FC<Props> = ({
    maxAnimalsToDisplay,
    onResultRendering,
    tag,
    recaptchaResponse,
}) => {
    if (!tag) {
        return null;
    }

    return (
        <Query<AnimalPublicTagSearchResultQuery>
            query={graphql`
                query AnimalPublicTagSearchResultQuery($tag: String!, $recaptchaResponse: String) {
                    animalPublicTagSearch(tag: $tag, recaptcha: $recaptchaResponse) {
                        recaptchaErrorCode
                        publicTagSearchResults {
                            ...AnimalPublicTagSearchResultRow_searchResult
                        }
                    }
                    configuration {
                        ...AnimalPublicTagSearchResultRow_configuration
                    }
                }`}
            variables={{
                recaptchaResponse,
                tag,
            }}>
            {({ error, props }) => {
                if (error) {
                    return renderResult(<ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />);
                } else if (props && props.animalPublicTagSearch && props.animalPublicTagSearch.recaptchaErrorCode) {
                    return renderResult(<WarningMessage message={lostPetMessages.recaptchaErrorMessage} />);
                } else if (props && (!props.animalPublicTagSearch?.publicTagSearchResults || !props.animalPublicTagSearch.publicTagSearchResults.length)) {
                    return renderResult(<Message message={animalMessages.animalPublicTagNotFoundMessage} />);
                } else if (!props || !props.animalPublicTagSearch) {
                    return null;
                }

                const {
                    animalPublicTagSearch,
                    configuration,
                } = props;

                if (!animalPublicTagSearch.publicTagSearchResults) {
                    return null;
                }

                const resultsToRender = maxAnimalsToDisplay !== undefined && maxAnimalsToDisplay > 0
                    ? animalPublicTagSearch.publicTagSearchResults.slice(0, maxAnimalsToDisplay)
                    : animalPublicTagSearch.publicTagSearchResults;

                return renderResult(<>{resultsToRender.map((searchResult, index) => <AnimalPublicTagSearchResultRow key={index} searchResult={searchResult} configuration={configuration} />)}</>);
            }}
        </Query>
    );

    function renderResult(result: JSX.Element): JSX.Element {
        if (onResultRendering) {
            onResultRendering();
        }

        return result;
    }
};
