import { LoadingMessage } from "components/shared/LoadingMessage";
import classNames from "classnames";
import { ErrorMessage } from "components/shared/ErrorMessage";
import React, { useEffect, useRef } from "react";
import { Control, FieldPath, FieldValues, useController } from "react-hook-form";
import { FormFeedback } from "reactstrap";
import { RecaptchaProps as UseRecaptchaProps, useRecaptcha } from "shared/hooks/useRecaptcha";
import { mapMessages } from "shared/mapMessages";
import { TranslatedRegisterOptions, useTranslatedValidations } from "shared/validations/useTranslatedValidations";

const messages = mapMessages("shared.components.newFormInputs.Recaptcha", {
    errorMessage: "Error performing CAPTCHA, please refresh the page and try again.",
});

interface IRecaptchaProps<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>, TContext extends object = object> {
    control: Control<TFieldValues, TContext>;
    name: FieldPath<TFieldValues>;
    rules?: Omit<TranslatedRegisterOptions<TFieldValues, TFieldName>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
}

export type RecaptchaProps<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>, TContext extends object = object> =
    IRecaptchaProps<TFieldValues, TFieldName, TContext> & Omit<UseRecaptchaProps, "container">;

export function Recaptcha<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>, TContext extends object = object>({
    control,
    name,
    rules,
    ...rest
}: RecaptchaProps<TFieldValues, TFieldName, TContext>) {
    const recaptchaContainerRef = useRef<HTMLDivElement>(null);
    const registerRules = useTranslatedValidations(rules);
    const {
        field: { onChange },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: registerRules,
    });

    const { token, isLoading, hasError } = useRecaptcha({
        container: recaptchaContainerRef.current,
        ...rest,
    });

    useEffect(() => {
        onChange(token);
    }, [token, onChange]);

    const errorMessage = error?.message;

    return (
        <>
            {
                isLoading &&
                <LoadingMessage />
            }
            <div className="needs-validation mb-2">
                <div
                    ref={recaptchaContainerRef}
                    className={classNames(errorMessage ? "is-invalid" : undefined)}
                    id="recaptcha-container"
                    data-testid="RecaptchaContainer"
                />
                {
                    errorMessage &&
                    <FormFeedback>
                        {errorMessage}
                    </FormFeedback>
                }
            </div>
            {
                hasError &&
                <ErrorMessage
                    message={messages.errorMessage}
                />
            }
        </>
    );
}
