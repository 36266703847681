import { Omit } from "lodash";
import React, { ReactNode } from "react";
import { QueryRenderer, useRelayEnvironment } from "react-relay";
import { OperationType } from "relay-runtime";

export interface IRenderProps<TOperation extends OperationType> {
    error: Error | null;
    props: TOperation["response"] | null;
    retry: (() => void) | null;
}

interface IChildrenRenderProps<TOperation extends OperationType> {
    children: (renderProps: IRenderProps<TOperation>) => ReactNode;
}

export const Query = <TOperation extends OperationType>(props: IChildrenRenderProps<TOperation> & Omit<QueryRenderer<TOperation>["props"], "environment" | "render">) => {
    const environment = useRelayEnvironment();
    return <QueryRenderer<TOperation> environment={environment} render={props.children} {...props} />;
};
