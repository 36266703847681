import { ActionType, createAction } from "typesafe-actions";

const setDonation = createAction("PAYMENT_SET_DONATION", resolve => (amount: number | null) => resolve(amount));
const setDonationNotes = createAction("PAYMENT_SET_DONATIONNOTES", resolve => (donationNotes: string | null) => resolve(donationNotes));

const addRenewingAnimalsToCart = createAction("PAYMENT_ADD_RENEWING_ANIMALS_TO_CART", resolve => {
    return (animalIds: string[]) => {
        return resolve(animalIds);
    };
});

const removeRenewingAnimalFromCart = createAction("PAYMENT_REMOVE_RENEWING_ANIMAL_FROM_CART", resolve => {
    return (animalId: string) => {
        return resolve(animalId);
    };
});

const clearCart = createAction("PAYMENT_CLEAR_CART");

export const paymentActions = {
    addRenewingAnimalsToCart,
    clearCart,
    removeRenewingAnimalFromCart,
    setDonation,
    setDonationNotes,
};

export type PaymentAction = ActionType<typeof paymentActions>;
