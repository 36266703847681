import { LoadingMessage } from "components/shared/LoadingMessage";
import { required } from "components/shared/validations";
import graphql from "babel-plugin-relay/macro";
import { LicenceType } from "components/licence/LicenceType";
import { LicenseTypeProvider } from "components/licence/LicenseTypeProvider";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { bulkUpdateLicences, IBulkUpdateLicences, MutableLicenceSelectionInput } from "domain/animal/bulkUpdateLicences";
import { licenceMessages } from "domain/licence/licenceMessages";
import { paymentActions } from "domain/payment/paymentActions";
import { toastActions } from "domain/toast/toastActions";
import { BulkLicenceRenewalFormQuery, BulkLicenceRenewalFormQueryResponse } from "generatedQueries/BulkLicenceRenewalFormQuery.graphql";
import { bulkUpdateLicencesMutationResponse } from "generatedQueries/bulkUpdateLicencesMutation.graphql";
import { Scope } from "informed";
import moment, { isMoment, Moment } from "moment";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IEnvironment, PayloadError } from "relay-runtime";
import { routes } from "routes/routes";
import { ErrorHandlingMutationForm, IWarningMessageProps } from "shared/components/ErrorHandlingMutationForm";
import { HiddenField } from "shared/components/formInputs/HiddenField";
import { Query } from "shared/components/Query";
import { sharedMessages } from "shared/sharedMessages";

type AnimalNode = NonNullable<NonNullable<NonNullable<BulkLicenceRenewalFormQueryResponse["account"]>["animals"]>["edges"]>[0]["node"];

interface IProps {
    animalIds: string[];
    onBack: () => void;
}

export const BulkLicenceRenewalForm: FC<IProps> = ({
    onBack,
    animalIds,
}) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const [licenceExpiries, setLicenceExpiries] = useState<Map<string, Moment | "LIFETIME" | undefined>>(new Map<string, Moment | "LIFETIME" | undefined>());

    return <Query<BulkLicenceRenewalFormQuery>
        query={query}
        variables={{}}
        cacheConfig={{ force: true }}>
        {({ error, props: renderProps }) => {
            if (error) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            if (!renderProps) {
                return <LoadingMessage />;
            }

            const { account } = renderProps;

            if (!account) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            const animals = account.animals?.edges?.map(edge => edge.node).filter(a => animalIds.indexOf(a.id) > -1) ?? [];

            if (animals.length === 0) {
                throw new Error("No animals have been selected for renewal");
            }

            const licenceSelections = getLicenseSelection(animals);

            return <ErrorHandlingMutationForm<IBulkUpdateLicences, bulkUpdateLicencesMutationResponse>
                onPrevious={onBack}
                onNext={onFormCompleted}
                initialValues={licenceSelections}
                mutationFunc={mutationFunc}
                alwaysSubmit={true}
                shouldDisplayWarning={shouldDisplayRabiesWarning(animals)}
                toastSuccess={toastSuccess}
                toastFailure={toastFailure}>
                <h4><Message message={licenceMessages.selectLicensesTitle} /></h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col"><Message message={licenceMessages.renewalFormNameHeading} /></th>
                            <th scope="col"><Message message={licenceMessages.renewalFormLicenseTypeHeading} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            licenceSelections.licenceSelections.map((s, index) => {
                                const animal = animals.find(a => a.id === s.animalId)!;

                                return <Scope key={animal.id} scope={`licenceSelections[${index}]`}>
                                    <tr>
                                        <td>{animal.name}</td>
                                        <td>
                                            <HiddenField
                                                id={`animal-id-${animal.id}`}
                                                field="animalId"
                                                initialValue={animal.id}
                                            />
                                            <HiddenField
                                                id={`animal-type-id-${animal.id}`}
                                                field="animalTypeId"
                                                initialValue={animal.animalTypeId}
                                            />
                                            <HiddenField
                                                id={`is-desexed-${animal.id}`}
                                                field="isDesexed"
                                                initialValue={animal.isDesexed}
                                            />
                                            <HiddenField
                                                id={`date-of-birth-${animal.id}`}
                                                field="dateOfBirth"
                                                initialValue={animal.dateOfBirth}
                                            />
                                            <LicenseTypeProvider
                                                animalTypeIdField={`licenceSelections[${index}].animalTypeId`}
                                                jurisdictionId={account.physicalAddress!.jurisdictionId!}
                                                dateOfBirthField={`licenceSelections[${index}].dateOfBirth`}
                                                isDesexedField={`licenceSelections[${index}].isDesexed`}
                                                isRenewal={true}
                                                isMicrochipped={isMicrochipped(animal)}
                                            >
                                                {({ isLoading, licenseTypes }) => (
                                                    <LicenceType

                                                        isLoading={isLoading}
                                                        licenseTypes={licenseTypes}
                                                        field="licenceTypeId"
                                                        validate={required}
                                                        alwaysValidate={true}
                                                        onLicenceTypeSelected={onLicenceTypeSelected(animal.id)}
                                                    />
                                                )}
                                            </LicenseTypeProvider>
                                        </td>
                                    </tr>
                                </Scope>;
                            })
                        }
                    </tbody>
                </table>
            </ErrorHandlingMutationForm>;
        }}
    </Query>;

    function mutationFunc(
        environment: IEnvironment,
        bulkUpdateLicenceValues: IBulkUpdateLicences,
        onCompleted: (response: bulkUpdateLicencesMutationResponse, errors?: PayloadError[] | null) => void,
        onError: (error: Error) => void) {
        if (bulkUpdateLicenceValues.licenceSelections.length === 0) {
            throw new Error("There are no license selections for renewal");
        }

        bulkUpdateLicences(
            environment,
            {
                licenceSelections: bulkUpdateLicenceValues.licenceSelections.map(licenseSelection => ({
                    animalId: licenseSelection.animalId,
                    licenceTypeId: licenseSelection.licenceTypeId,
                })),
            },
            onCompleted,
            onError);
    }

    function toastSuccess() {
        dispatch(toastActions.toastSuccess());
    }

    function toastFailure() {
        dispatch(toastActions.toastFailure());
    }

    function onLicenceTypeSelected(animalId: string) {
        return (_: number | undefined, selectedLicenceExpiry: Moment | "LIFETIME" | undefined) => {
            const currentExpiry = licenceExpiries[animalId];

            if (selectedLicenceExpiry === undefined && currentExpiry === undefined) {
                return;
            } else if (!!currentExpiry && isMoment(currentExpiry) && isMoment(selectedLicenceExpiry) && currentExpiry.isSame(selectedLicenceExpiry)) {
                return;
            } else if (currentExpiry === "LIFETIME" && selectedLicenceExpiry === "LIFETIME") {
                return;
            }

            const newLicenceExpiries = { ...licenceExpiries };

            newLicenceExpiries[animalId] = selectedLicenceExpiry;
            setLicenceExpiries(newLicenceExpiries);
        };
    }

    function shouldDisplayRabiesWarning(animals: AnimalNode[]) {
        return (_: IBulkUpdateLicences): IWarningMessageProps[] | false => {
            const selectionsWithRabiesWarnings = animals.filter(animal => isRabiesExpiryPriorToLicenceExpiry(animal!));

            if (selectionsWithRabiesWarnings.length > 0) {
                return [{
                    titleMessage: formatMessage(animalMessages.rabiesExpiryInvalidHeading),
                    warningMessage: formatMessage(selectionsWithRabiesWarnings.length === 1 ? animalMessages.rabiesExpiryInvalidError : animalMessages.rabiesExpiryMultipleInvalidError),
                }];
            }

            return false;
        };
    }

    function isRabiesExpiryPriorToLicenceExpiry(animal: AnimalNode): boolean {
        const rabiesExpiry = animal.rabiesVaccination && moment(animal.rabiesVaccination.expiryDate);
        const licenceExpiry = licenceExpiries[animal.id];

        if (!rabiesExpiry ||
            licenceExpiry === "LIFETIME" ||
            !licenceExpiry) {
            return false;
        }

        return rabiesExpiry.isBefore(licenceExpiry);
    }

    function getLicenseSelection(animals: AnimalNode[]): IBulkUpdateLicences {
        return {
            licenceSelections: animals
                .map<MutableLicenceSelectionInput>(animal => ({
                    animalId: animal.id,
                    licenceExpiry: getInitialLicenceExpiry(animal),
                    licenceTypeId: animal.licenceTypeId,
                    rabiesExpiry: getRabiesVaccinationExpiryDate(animal),
                })),
        };
    }

    function getInitialLicenceExpiry(animal: AnimalNode): Moment | "LIFETIME" | undefined {
        if (!animal.licenceType) {
            return undefined;
        }

        if (animal.licenceType.isLifetime) {
            return "LIFETIME";
        } else if (animal.licenceType.expiryDate) {
            let expiry = moment({ year: moment().year(), month: animal.licenceType.expiryDate.month - 1, day: animal.licenceType.expiryDate.day });

            if (expiry.isSameOrBefore(moment(), "days")) {
                expiry = expiry.add(1, "year");
            }

            if (animal.licenceType.expiryDate.years > 1) {
                expiry = expiry.add(animal.licenceType.expiryDate.years - 1, "year");
            }

            return expiry;
        } else if (animal.licenceType.expiryMonths) {
            return moment().add(animal.licenceType.expiryMonths, "months");
        }

        return undefined;
    }

    function getRabiesVaccinationExpiryDate(animal: AnimalNode): Moment | undefined {
        const expiryDate = animal && animal.rabiesVaccination && animal.rabiesVaccination.expiryDate;

        if (typeof expiryDate === "string") {
            return moment(expiryDate);
        }

        return undefined;
    }

    function onFormCompleted() {
        dispatch(paymentActions.addRenewingAnimalsToCart(animalIds));
        history.push(routes.payment.index.create({}));
    }
};

function isMicrochipped(animal: AnimalNode): boolean {
    if (!animal.identifications || animal.identifications.length === 0) {
        return false;
    }

    return animal.identifications.find(i => i.type === "MICROCHIP") !== undefined;
}

const query = graphql`
query BulkLicenceRenewalFormQuery {
    account {
        animals {
            edges {
                node {
                    id
                    name
                    animalTypeId
                    isDesexed
                    rabiesVaccination {
                        expiryDate
                    }
                    licenceTypeId
                    licenceType {
                        expiryDate {
                            day
                            month
                            years
                        }
                        expiryMonths
                        isLifetime
                    }
                    identifications {
                        type
                    }
                    dateOfBirth
                }
            }
        }
        physicalAddress {
            jurisdictionId
        }
    }
}`;
