import DOMPurify from "dompurify";
import React, { FC } from "react";

interface IProps {
    value: string | null;
    className?: string;
}

export const SiteCustomisation: FC<IProps> = ({
    value,
    className,
}) => {
    if (value === null || value === "") {
        return null;
    }

    return <div className={className} dangerouslySetInnerHTML={createContentMarkup(value)} data-testid="SiteCustomisation" />;
};

function createContentMarkup(content: string) {
    DOMPurify.addHook("afterSanitizeAttributes", (node) => {
        if (node.hasAttribute("href")) {
            node.setAttribute("target", "_blank");
        }
    });

    return { __html: DOMPurify.sanitize(content) };
}
