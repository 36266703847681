import graphql from "babel-plugin-relay/macro";
import { confirmSignUpMutation, confirmSignUpMutationResponse } from "generatedQueries/confirmSignUpMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation confirmSignUpMutation($input: ConfirmLicensingSignUpInput!) {
        confirmLicensingSignUp(input: $input) {
            wasSuccessful
            error
        }
    }`;

export function confirmSignUp(
    environment: IEnvironment,
    email: string,
    code: string,
    onCompleted: (response: confirmSignUpMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<confirmSignUpMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                input: {
                    email,
                    code,
                },
            },
        }
    );
}
