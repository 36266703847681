import { faEnvelope, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { routes } from "routes/routes";

interface IProps {
    className?: string;
    size?: string;
}

export const AccountNavigation: FC<IProps> = ({
    className,
    size,
}) => (
    <>
        <Link to={routes.account.edit.create({})}>
            <Button className={className} size={size}>
                <Message message={accountMessages.changeAccountDetailsLabel} />
            </Button>
        </Link>
        <Link to={routes.account.email.create({})}>
            <Button className={className} size={size}>
                <Message
                    message={accountMessages.changeEmailAddressLabel}
                    values={{
                        icon: <FontAwesomeIcon icon={faEnvelope} />,
                    }} />
            </Button>
        </Link>
        <Link to={routes.user.changePassword.create({})}>
            <Button className={className} size={size}>
                <Message
                    message={accountMessages.changePasswordLabel}
                    values={{
                        icon: <FontAwesomeIcon icon={faKey} />,
                    }} />
            </Button>
        </Link>
    </>
);
