/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AddLicenceQueryVariables = {};
export type AddLicenceQueryResponse = {
    readonly account: {
        readonly physicalAddress: {
            readonly jurisdictionId: number | null;
        } | null;
        readonly animals: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                };
            }> | null;
        } | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"Animal_query">;
};
export type AddLicenceQuery = {
    readonly response: AddLicenceQueryResponse;
    readonly variables: AddLicenceQueryVariables;
};



/*
query AddLicenceQuery {
  account {
    physicalAddress {
      jurisdictionId
      id
    }
    animals(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
  ...Animal_query
}

fragment Animal_query on Query {
  genders: licensingGenders {
    genderId
    name
  }
  licensingAnimalTypes {
    id
    description
  }
  configuration {
    microchipValidationSettings {
      microchipLength
      minChipLength
    }
    ...Rabies_configuration
    ...Hideable_configuration
  }
  account {
    ...Rabies_account
    id
  }
}

fragment Hideable_configuration on Configuration {
  hiddenFields
}

fragment Rabies_account on Account {
  id
}

fragment Rabies_configuration on Configuration {
  proofOfRabiesAllowedFileExtensions
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jurisdictionId",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "first",
                "value": 100
            }
        ],
        "concreteType": "LicensingAnimalConnection",
        "kind": "LinkedField",
        "name": "animals",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingAnimalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "LicensingAnimal",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v1 /*: any*/),
                            (v2 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": "animals(first:100)"
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AddLicenceQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingStreetAddress",
                            "kind": "LinkedField",
                            "name": "physicalAddress",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Animal_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AddLicenceQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingStreetAddress",
                            "kind": "LinkedField",
                            "name": "physicalAddress",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/),
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": "genders",
                    "args": null,
                    "concreteType": "LicensingGender",
                    "kind": "LinkedField",
                    "name": "licensingGenders",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "genderId",
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicensingAnimalType",
                    "kind": "LinkedField",
                    "name": "licensingAnimalTypes",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MicrochipValidationSettings",
                            "kind": "LinkedField",
                            "name": "microchipValidationSettings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "microchipLength",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "minChipLength",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "proofOfRabiesAllowedFileExtensions",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hiddenFields",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "2f50da3b25d868ad8fc69c3d8f845d05",
            "id": null,
            "metadata": {},
            "name": "AddLicenceQuery",
            "operationKind": "query",
            "text": "query AddLicenceQuery {\n  account {\n    physicalAddress {\n      jurisdictionId\n      id\n    }\n    animals(first: 100) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n  ...Animal_query\n}\n\nfragment Animal_query on Query {\n  genders: licensingGenders {\n    genderId\n    name\n  }\n  licensingAnimalTypes {\n    id\n    description\n  }\n  configuration {\n    microchipValidationSettings {\n      microchipLength\n      minChipLength\n    }\n    ...Rabies_configuration\n    ...Hideable_configuration\n  }\n  account {\n    ...Rabies_account\n    id\n  }\n}\n\nfragment Hideable_configuration on Configuration {\n  hiddenFields\n}\n\nfragment Rabies_account on Account {\n  id\n}\n\nfragment Rabies_configuration on Configuration {\n  proofOfRabiesAllowedFileExtensions\n}\n"
        }
    } as any;
})();
(node as any).hash = '3e2621d5dc50c4ac7d8778f608c2b056';
export default node;
