import { regex, Validator } from "components/shared/validations";
import { mapMessages } from "shared/mapMessages";

export const messages = mapMessages("shared.passwordvalidation.", {
    passwordStrengthError: "The password entered does not meet the minimum requirements. Must have at least one lower case, one upper case, one special character, one number and at least 8 characters in length.",
});

export function passwordStrengthValidator(passwordValidationRegex: string | undefined): Validator {
    return regex(
        passwordValidationRegex ? new RegExp(passwordValidationRegex) : /^.*(?=.{8,})((?=.*[!@#$%^&*()\\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        messages.passwordStrengthError);
}
