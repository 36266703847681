import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { ChangeEmail } from "components/account/ChangeEmail";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { ResendCode } from "components/user/ResendCode";
import { VerifyCode } from "components/user/VerifyCode";
import { userMessages } from "domain/user/userMessages";
import { VerifyQuery } from "generatedQueries/VerifyQuery.graphql";
import React, { FC } from "react";
import { AccordionSelection } from "shared/components/AccordionSelection";
import { Query } from "shared/components/Query";
import { sharedMessages } from "shared/sharedMessages";

export const Verify: FC = () => {
    return <>
        <h2><Message message={userMessages.verifyYourAccountTitle} /></h2>
        <Query<VerifyQuery>
            query={graphql`
                query VerifyQuery {
                    account {
                        email
                        ...ResendCode_account
                    }
                }`}
            variables={{}}>
            {({ error, props }) => {
                if (error) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                if (!props) {
                    return <LoadingMessage />;
                }

                const { account } = props;

                if (!account) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                return <>
                    <p className="lead">
                        <Message message={userMessages.verifyLeadMessage} values={({ email: account?.email })} />
                    </p>
                    <AccordionSelection>
                        {{
                            options: [
                                {
                                    children: <VerifyCode />,
                                    message: userMessages.verifyOptionReceivedLabel,
                                    option: "Received",
                                },
                                {
                                    children: <ResendCode account={account} />,
                                    message: userMessages.verifyOptionNotReceivedLabel,
                                    option: "NotReceived",
                                },
                                {
                                    children: <ChangeEmail />,
                                    message: userMessages.verifyOptionIncorrectEmailLabel,
                                    option: "IncorrectEmail",
                                },
                            ],
                        }}
                    </AccordionSelection>
                </>;
            }}
        </Query>
    </>;
};
