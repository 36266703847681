/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PayPalPayflowProvider_configuration = {
    readonly currency: string;
    readonly " $refType": "PayPalPayflowProvider_configuration";
};
export type PayPalPayflowProvider_configuration$data = PayPalPayflowProvider_configuration;
export type PayPalPayflowProvider_configuration$key = {
    readonly " $data"?: PayPalPayflowProvider_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"PayPalPayflowProvider_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayPalPayflowProvider_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '7d1264b4314d8afe5668d08ee9e59569';
export default node;
