import classNames from "classnames";
import React, { FC, RefObject, useEffect, useRef, useState } from "react";

interface IProps {
    id: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    label: React.ReactNode;
    checked?: boolean;
    innerRef?: RefObject<HTMLInputElement>;
}

export const Switch: FC<IProps> = ({
    id,
    onChange,
    className,
    label,
    checked,
    innerRef,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState(checked || false);

    useEffect(() => {
        setValue(checked || false);
    }, [checked]);

    useEffect(() => {
        if (innerRef && innerRef.current) {
            innerRef.current.checked = value;
        }
        else if (inputRef.current) {
            inputRef.current.checked = value;
        }
    }, [innerRef, value]);

    return <div className={classNames("custom-switch custom-control", className)}>
        <input
            type="checkbox"
            id={id}
            className="custom-control-input"
            onChange={onInputClicked}
            ref={innerRef ?? inputRef} />
        <label className="custom-control-label" htmlFor={id}>
            {
                label
            }
        </label>
    </div>;

    function onInputClicked(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(!value);

        if (onChange) {
            onChange(event);
        }
    }
};
