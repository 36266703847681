import graphql from "babel-plugin-relay/macro";
import { setAddressesMutation, setAddressesMutationResponse, setAddressesMutationVariables } from "generatedQueries/setAddressesMutation.graphql";
import { Mutable } from "infrastructure/Mutable";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

export type MutatableSetAddressesMutationVariables = Mutable<setAddressesMutationVariables>;

const mutation = graphql`
    mutation setAddressesMutation($physicalAddress: LicensingAccountAddressInput!, $mailingAddress: LicensingAccountAddressInput!) {
        updatePhysicalAddress: updateLicensingAccountAddress(address: $physicalAddress, type: PHYSICAL) {
            result {
                ... on LicensingStreetAddress {
                    id
                }
            }
            errors {
                errorCode
                message
            }
        }
        updateMailingAddress: updateLicensingAccountAddress(address: $mailingAddress, type: MAILING) {
            result {
                ... on LicensingAddress {
                    id
                }
            }
            errors {
                errorCode
                message
            }
        }
    }`;

export function setAddresses(
    environment: IEnvironment,
    variables: MutatableSetAddressesMutationVariables,
    onCompleted: (response: setAddressesMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<setAddressesMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                mailingAddress: { ...variables.mailingAddress },
                physicalAddress: { ...variables.physicalAddress },
            },
        }
    );
}
