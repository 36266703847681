import { InfoMessage } from "components/shared/InfoMessage";
import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { IPaymentFormChildProps } from "components/payment/PaymentForm";
import { PayPalButtons } from "components/payment/PayPal/PayPalButtons";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { paymentMessages } from "domain/payment/paymentMessages";
import { PayPalForm_configuration } from "generatedQueries/PayPalForm_configuration.graphql";
import { PayPalForm_paymentForm } from "generatedQueries/PayPalForm_paymentForm.graphql";
import React, { FC, useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import { Link } from "react-router-dom";
import { routes } from "routes/routes";
import { WarningMessage } from "shared/components/WarningMessage";

interface IGraphQlProps {
    configuration: PayPalForm_configuration;
    paymentForm: PayPalForm_paymentForm | null;
}

interface IProps {
    termsAndConditionsAccepted: boolean;
    allPaymentRowsAreValid: boolean;
    clientId: string | null;
    currency: string;
}

type Props = IPaymentFormChildProps & IGraphQlProps & IProps;

const InternalPayPalForm: FC<Props> = ({
    amountToCharge,
    submitPayment,
    configuration,
    paymentForm,
    donation,
    paymentSucceeded,
    termsAndConditionsAccepted,
    errorMessages,
    allPaymentRowsAreValid,
    clientId,
    currency,
}) => {
    const [shouldDisplayError, setShouldDisplayError] = useState(false);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [isNothingToPayWarningDisplayed, setIsNothingToPayWarningDisplayed] = useState(false);
    const [isTermsAndConditionsWarningDisplayed, setIsTermsAndConditionsWarningDisplayed] = useState(false);
    const [isInvalidPaymentRowWarningDisplayed, setIsInvalidPaymentRowWarningDisplayed] = useState(false);
    const [isPayPalFormInError, setIsPayPalFormInError] = useState(false);

    useEffect(() => {
        if (!paymentSucceeded) {
            setIsPaymentProcessing(false);
        }
    }, [paymentSucceeded]);

    useEffect(() => {
        if (termsAndConditionsAccepted && isTermsAndConditionsWarningDisplayed) {
            setIsTermsAndConditionsWarningDisplayed(false);
        }
    }, [isTermsAndConditionsWarningDisplayed, termsAndConditionsAccepted]);

    useEffect(() => {
        if (isInvalidPaymentRowWarningDisplayed && allPaymentRowsAreValid) {
            setIsInvalidPaymentRowWarningDisplayed(false);
        }
    }, [isInvalidPaymentRowWarningDisplayed, allPaymentRowsAreValid]);

    useEffect(() => {
        if (isNothingToPayWarningDisplayed && amountToCharge > 0) {
            setIsNothingToPayWarningDisplayed(false);
        }
    }, [isNothingToPayWarningDisplayed, amountToCharge]);

    const {
        licensingDonationPaymentDescription,
        organisationContact,
    } = configuration;

    if (!clientId) {
        return <ErrorMessage
            message={paymentMessages.payPalConfigurationErrorMessage}
            messageValues={{ organisationContact }} />;
    }

    if (isPayPalFormInError) {
        return <ErrorMessage
            message={paymentMessages.payPalUnhandledErrorMessage}
            messageValues={{ organisationContact }} />;
    }

    if (isPaymentProcessing) {
        return <LoadingMessage message={paymentMessages.paymentIsProcessing} />;
    }

    return <>
        {
            isNothingToPayWarningDisplayed &&
            <InfoMessage
                message={paymentMessages.nothingToPayWarningMessage}
                messageValues={{
                    pets: msg => <Link to={routes.pets.index.create({})}>{msg}</Link>,
                    add: msg => <Link to={routes.licence.options.create({})}>{msg}</Link>,
                }} />
        }
        {
            isTermsAndConditionsWarningDisplayed &&
            <WarningMessage message={paymentMessages.termsAndConditionsNotAcceptedWarningMessage} />
        }
        {
            isInvalidPaymentRowWarningDisplayed &&
            <WarningMessage
                message={paymentMessages.invalidPaymentRowsWarningMessage}
                messageValues={{ organisationContact }} />
        }
        <PayPalButtons
            clientId={clientId}
            currency={currency}
            amount={amountToCharge}
            shippingPreference="NO_SHIPPING"
            style={{
                color: "blue",
                label: "pay",
            }}
            onClick={onClick}
            onApprove={onApprove}
            createOrder={createOrder}
            onError={onError} />
        {
            shouldDisplayError &&
            <ErrorMessage
                message={paymentMessages.payPalOrderIdMissingErrorMessage}
                messageValues={{ organisationContact }} />
        }
        {errorMessages}
    </>;

    function onClick(_: {}, actions: IOnClickPayPalOrderActions) {
        if (!termsAndConditionsAccepted) {
            setIsTermsAndConditionsWarningDisplayed(true);
            return actions.reject();
        }

        if (!allPaymentRowsAreValid) {
            setIsInvalidPaymentRowWarningDisplayed(true);
            return actions.reject();
        }

        if (amountToCharge <= 0) {
            setIsNothingToPayWarningDisplayed(true);
            return actions.reject();
        }

        return actions.resolve();
    }

    function createOrder(_: {}, actions: ICreatePayPalOrderActions) {
        const items = paymentForm?.rows.map<IPayPalPurchasedItem>(r => ({
            name: `${r.animalName} - ${r.licenceType}`,
            quantity: 1,
            unit_amount: {
                currency_code: currency,
                value: r.amount.toString(),
            },
        })) ?? [];

        if (donation !== null && donation > 0 && licensingDonationPaymentDescription) {
            items.push({
                name: licensingDonationPaymentDescription,
                quantity: 1,
                unit_amount: {
                    currency_code: currency,
                    value: donation.toString(),
                },
            });
        }

        return actions.order.create({
            intent: "AUTHORIZE",
            purchase_units: [{
                amount: {
                    value: amountToCharge.toString(),
                    currency_code: currency,
                    breakdown: {
                        item_total: {
                            currency_code: currency,
                            value: amountToCharge.toString(),
                        },
                    },
                },
                items,
            }],
            application_context: {
                shipping_preference: "NO_SHIPPING",
            },
        });
    }

    function onApprove(data: IPaypalApprovalResponse) {
        if (data.orderID) {
            setIsPaymentProcessing(true);
            submitPayment({
                payPalPaymentRequest: {
                    orderId: data.orderID,
                },
            });
        } else {
            setShouldDisplayError(true);
        }
    }

    function onError() {
        setIsPayPalFormInError(true);
    }
};

export const PayPalForm = createFragmentContainer(InternalPayPalForm, {
    configuration: graphql`
        fragment PayPalForm_configuration on Configuration {
            licensingDonationPaymentDescription
            organisationContact
        }`,
    paymentForm: graphql`
        fragment PayPalForm_paymentForm on LicensingPaymentForm {
            rows {
                animalName
                licenceType
                amount
            }
        }`,
});
