import { connectRouter } from "connected-react-router";
import { combinedAnimalReducer } from "domain/animal/reducers/combinedAnimalReducer";
import { combinedPaymentReducer } from "domain/payment/combinedPaymentReducer";
import { combinedUserReducer } from "domain/user/combinedUserReducer";
import { History } from "history";
import { reducer as toastrReducer } from "react-redux-toastr";
import { combineReducers } from "redux";
import { intlReducer } from "store/intl/intlReducer";

export const createRootReducer = (history: History) => combineReducers({
    animal: combinedAnimalReducer,
    intl: intlReducer,
    payment: combinedPaymentReducer,
    router: connectRouter(history),
    toastr: toastrReducer,
    user: combinedUserReducer,
});
