/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnimalPublicTagSearchResultRow_configuration = {
    readonly organisationContact: string;
    readonly " $refType": "AnimalPublicTagSearchResultRow_configuration";
};
export type AnimalPublicTagSearchResultRow_configuration$data = AnimalPublicTagSearchResultRow_configuration;
export type AnimalPublicTagSearchResultRow_configuration$key = {
    readonly " $data"?: AnimalPublicTagSearchResultRow_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"AnimalPublicTagSearchResultRow_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnimalPublicTagSearchResultRow_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = 'debfd60cf3152d6b2c584b3508012f3a';
export default node;
