import graphql from "babel-plugin-relay/macro";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { addressMessages } from "domain/address/addressMessages";
import { AddressStateWarningConfigurationQuery } from "generatedQueries/AddressStateWarningConfigurationQuery.graphql";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Query } from "shared/components/Query";
import { WarningMessage } from "shared/components/WarningMessage";
import { sharedMessages } from "shared/sharedMessages";

export const AddressStateWarning: FC = () => {
    const intl = useIntl();

    return <Query<AddressStateWarningConfigurationQuery>
        query={graphql`
        query AddressStateWarningConfigurationQuery {
            configuration {
                organisationContact
            }
        }`}
        variables={{}}>
        {({ error, props }) => {
            if (error) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            const organisationContact = props?.configuration?.organisationContact ?? intl.formatMessage(sharedMessages.defaultOrganisationContactMessage);
            return <WarningMessage
                message={addressMessages.noMatchingStateBody}
                heading={addressMessages.noMatchingStateTitle}
                messageValues={{ organisationContact }} />;
        }}
    </Query>;
};
