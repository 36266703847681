/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountReadonlyAdditionalDetails_account = {
    readonly canDiscloseToPublic: boolean | null;
    readonly dateOfBirth: string | null;
    readonly driverLicence: string | null;
    readonly isSubscribedToShelterNewsletter: boolean | null;
    readonly isSelfIdentifiedAsSeniorCitizen: boolean | null;
    readonly " $refType": "AccountReadonlyAdditionalDetails_account";
};
export type AccountReadonlyAdditionalDetails_account$data = AccountReadonlyAdditionalDetails_account;
export type AccountReadonlyAdditionalDetails_account$key = {
    readonly " $data"?: AccountReadonlyAdditionalDetails_account$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountReadonlyAdditionalDetails_account">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountReadonlyAdditionalDetails_account",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canDiscloseToPublic",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateOfBirth",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "driverLicence",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSubscribedToShelterNewsletter",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSelfIdentifiedAsSeniorCitizen",
            "storageKey": null
        }
    ],
    "type": "Account",
    "abstractKey": null
} as any;
(node as any).hash = '722322b54ad876ae28a920dc1244aaa8';
export default node;
