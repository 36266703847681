import classNames from "classnames";
import { trim } from "lodash";
import React, { FC } from "react";

interface IProps {
    addressLine?: string | null;
    deliveryType?: string | null;
    deliveryNumber?: string | null;
    suburb?: string | null;
    state?: string | null;
    postcode?: string | null;
    country?: string | null;
    className?: string;
}

export const AddressSummary: FC<IProps> = ({
    country,
    postcode,
    state,
    addressLine,
    deliveryType,
    deliveryNumber,
    suburb,
    className,
}) => {
    const streetLine = trim(`${!addressLine ? "" : addressLine}`);
    const postalLine = trim(`${!deliveryType ? "" : deliveryType} ${!deliveryNumber ? "" : deliveryNumber}`);

    return <div className={classNames("address-summary", className)}>
        {
            postalLine &&
            <h6>{postalLine}</h6>
        }
        {
            streetLine &&
            <h6>{streetLine}</h6>
        }
        <h6>{suburb}</h6>
        <h6>{state} {postcode}</h6>
        <h6>{country}</h6>
    </div>;
};
