/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountReadonlyAdditionalDetails_configuration = {
    readonly " $fragmentRefs": FragmentRefs<"HideableFormLabel_configuration">;
    readonly " $refType": "AccountReadonlyAdditionalDetails_configuration";
};
export type AccountReadonlyAdditionalDetails_configuration$data = AccountReadonlyAdditionalDetails_configuration;
export type AccountReadonlyAdditionalDetails_configuration$key = {
    readonly " $data"?: AccountReadonlyAdditionalDetails_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountReadonlyAdditionalDetails_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountReadonlyAdditionalDetails_configuration",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HideableFormLabel_configuration"
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '823850d67e3aaf4a65481efa7aaf1931';
export default node;
