/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PaymentSessionInput = {
    convergeSessionDetails?: ConvergeSessionDetailsInput | null;
};
export type ConvergeSessionDetailsInput = {
    amount: number;
    firstName?: string | null;
    lastName?: string | null;
    transactionType?: string | null;
};
export type convergeQueryVariables = {
    input?: PaymentSessionInput | null;
};
export type convergeQueryResponse = {
    readonly paymentSession: {
        readonly token: string;
    } | null;
};
export type convergeQuery = {
    readonly response: convergeQueryResponse;
    readonly variables: convergeQueryVariables;
};



/*
query convergeQuery(
  $input: PaymentSessionInput
) {
  paymentSession(paymentSessionInput: $input) {
    token
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "paymentSessionInput",
                    "variableName": "input"
                }
            ],
            "concreteType": "PaymentSessionResponse",
            "kind": "LinkedField",
            "name": "paymentSession",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "token",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "convergeQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "convergeQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "befeb0e275d5ca99e954537117968088",
            "id": null,
            "metadata": {},
            "name": "convergeQuery",
            "operationKind": "query",
            "text": "query convergeQuery(\n  $input: PaymentSessionInput\n) {\n  paymentSession(paymentSessionInput: $input) {\n    token\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'ce4a8deda47a05407b15932d108d8a1b';
export default node;
