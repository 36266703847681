import { Money } from "components/shared/Money";
import { Spinner } from "components/shared/Spinner";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import graphql from "babel-plugin-relay/macro";
import { buildLicensingPaymentMethodInput } from "components/payment/paymentExtensions";
import { PaymentFailedMessage } from "components/payment/PaymentFailedMessage";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { licenceMessages } from "domain/licence/licenceMessages";
import { IPaymentState } from "domain/payment/combinedPaymentReducer";
import { paymentMessages } from "domain/payment/paymentMessages";
import { processPaymentMethod } from "domain/payment/processPaymentMethod";
import { PaymentForm_configuration } from "generatedQueries/PaymentForm_configuration.graphql";
import { LicensingPaymentMethodInput, LicensingPaymentMethodRequestInput, LicensingPaymentResultCode, processPaymentMethodMutationResponse } from "generatedQueries/processPaymentMethodMutation.graphql";
import React, { FC, ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { createFragmentContainer, useRelayEnvironment } from "react-relay";
import { Button, Card, CardBody, CardHeader, Col } from "reactstrap";
import { PayloadError } from "relay-runtime";
import { RootState } from "store/store";

export interface IPaymentFormChildProps {
    requestState: LicensingPaymentMethodRequestInput | null;
    isPaymentProcessing: boolean;
    paymentSucceeded: boolean;
    payButton: ReactNode;
    errorMessages: ReactNode;
    amountToCharge: number;
    donation: number | null;
    paymentReady(request: LicensingPaymentMethodRequestInput | null): void;
    submitPayment(request: LicensingPaymentMethodRequestInput): void;
}

interface IProps {
    animalIds: string[];
    totalAmount: number;
    allPaymentRowsAreValid: boolean;
    termsAndConditionsAccepted: boolean;
    contractId: number | null;
    children(props: IPaymentFormChildProps): ReactNode;
    onPaymentSucceeded?(): void;
}

interface IGraphQlProps {
    configuration: PaymentForm_configuration;
}

type Props = IGraphQlProps & IProps;

const PaymentFormInternal: FC<Props> = ({
    children,
    configuration,
    animalIds,
    totalAmount,
    allPaymentRowsAreValid,
    termsAndConditionsAccepted,
    contractId,
    onPaymentSucceeded,
}) => {
    const paymentState = useSelector<RootState, IPaymentState>(state => state.payment);
    const environment = useRelayEnvironment();

    const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);
    const [hasPaymentFailed, setHasPaymentFailed] = useState<Exclude<LicensingPaymentResultCode, "SUCCEEDED"> | null>(null);
    const [paymentMethodRequest, setPaymentMethodRequest] = useState<LicensingPaymentMethodRequestInput | null>(null);
    const [paymentSucceeded, setPaymentSucceeded] = useState(false);

    const { currency, organisationContact } = configuration;

    const { donation, donationNotes } = paymentState;

    const amountToCharge = totalAmount + (donation || 0);
    const isPaymentMethodReady = !!paymentMethodRequest;

    const paymentButtonColor = !isPaymentMethodReady ||
        !termsAndConditionsAccepted ||
        !allPaymentRowsAreValid ||
        amountToCharge <= 0
        ? "secondary"
        : "primary";

    const isPaymentButtonDisabled = !isPaymentMethodReady ||
        paymentIsProcessing ||
        !termsAndConditionsAccepted ||
        !allPaymentRowsAreValid ||
        amountToCharge <= 0;

    return (
        <Col lg={8} md={12}>
            <Card className="paymentForm">
                <CardHeader>
                    <h6><FontAwesomeIcon icon={faLock} className="mr-1" /><Message message={paymentMessages.payHeaderLabel} /></h6>
                </CardHeader>
                <CardBody>
                    <form onSubmit={onSubmitPaymentForm}>
                        {
                            children({
                                amountToCharge,
                                paymentReady: onPaymentReady,
                                paymentSucceeded,
                                requestState: paymentMethodRequest,
                                submitPayment: onSubmitPayment,
                                donation,
                                payButton: <div className="text-center">
                                    <Button
                                        type="submit"
                                        className="w-50"
                                        color={paymentButtonColor}
                                        disabled={isPaymentButtonDisabled}>
                                        {
                                            paymentIsProcessing
                                                ? <Spinner className="icon-button ml-2" />
                                                : <Message message={paymentMessages.payButtonLabel} />
                                        }
                                        <span className="ml-2">
                                            {
                                                paymentIsProcessing
                                                    ? <Message message={paymentMessages.paymentIsProcessing} />
                                                    : <Money amount={amountToCharge} currency={currency} />
                                            }
                                        </span>
                                    </Button>
                                </div>,
                                errorMessages: <>
                                    {!allPaymentRowsAreValid && <div className="mt-3"><ErrorMessage message={licenceMessages.invalidPaymentRowsMessage} /></div>}
                                </>,
                                isPaymentProcessing: paymentIsProcessing,
                            })
                        }
                    </form>
                    <PaymentFailedMessage code={hasPaymentFailed} organisationContact={organisationContact} />
                </CardBody>
            </Card>
        </Col>
    );

    function onSubmitPaymentForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!paymentMethodRequest) {
            return;
        }

        onSubmitPayment(paymentMethodRequest);
    }

    function onSubmitPayment(request: LicensingPaymentMethodRequestInput) {
        if (!termsAndConditionsAccepted) {
            return;
        }

        setHasPaymentFailed(null);
        setPaymentIsProcessing(true);

        const paymentRequest: LicensingPaymentMethodInput = buildLicensingPaymentMethodInput(
            request,
            contractId,
            animalIds,
            donation,
            donationNotes,
            amountToCharge);

        processPaymentMethod(environment, paymentRequest, onPaymentResponse, onPaymentProcessError);
    }

    function onPaymentResponse(response: processPaymentMethodMutationResponse, errors: PayloadError[] | null) {
        setPaymentIsProcessing(false);

        if (response.licensingPaymentMethod?.code === "SUCCEEDED") {
            setPaymentSucceeded(true);

            if (onPaymentSucceeded) {
                onPaymentSucceeded();
            }

            return;
        }

        setHasPaymentFailed(response.licensingPaymentMethod?.code ?? "ERROR");
    }

    function onPaymentProcessError() {
        setPaymentIsProcessing(false);
        setHasPaymentFailed("ERROR");
    }

    function onPaymentReady(request: LicensingPaymentMethodRequestInput | null) {
        setPaymentMethodRequest(request);
        setHasPaymentFailed(null);
    }
};

export const PaymentForm = createFragmentContainer(PaymentFormInternal, {
    configuration: graphql`
        fragment PaymentForm_configuration on Configuration {
            currency
            organisationContact
        }`,
});
