import { RollbarConsumer } from "infrastructure/rollbar";
import React, { ComponentType } from "react";
import Rollbar from "rollbar";

export interface IRollbarProps {
    rollbar: Rollbar;
}

export const withRollbar = <TComponentProps extends IRollbarProps>(WrappedComponent: ComponentType<TComponentProps>)
    : ComponentType<Omit<TComponentProps, keyof IRollbarProps>> =>
    props => <RollbarConsumer>
        {rollbar => <WrappedComponent {...{ rollbar, ...props } as TComponentProps} />}
    </RollbarConsumer>;
