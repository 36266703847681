import { defineMessages } from "react-intl";

const idPrefix = "domain.animal.";

export const animalMessages = defineMessages({
    addIdentificationLabel: {
        defaultMessage: "Add",
        id: idPrefix + "addidentification-label",
    },
    addThisPetButtonLabel: {
        defaultMessage: "Add this pet",
        id: idPrefix + "addthispet-buttonlabel",
    },
    animalFormLabel: {
        defaultMessage: "Animal Details",
        id: idPrefix + "animalform-label",
    },
    animalIdentificationTypeMissingError: {
        defaultMessage: "Please select a type",
        id: idPrefix + "animalidentificationtypemissing-error",
    },
    animalNameLabel: {
        defaultMessage: "Name",
        id: idPrefix + "animalname-label",
    },
    animalPublicTagNotFoundMessage: {
        defaultMessage: "There are no pets matching the tag provided.",
        id: idPrefix + "animalPublicTagNotFound-message",
    },
    animalTypeLabel: {
        defaultMessage: "Type",
        id: idPrefix + "animaltype-label",
    },
    animalTypeNotFoundMessage: {
        defaultMessage: "Animal type not found",
        id: idPrefix + "animaltypenotfound-message",
    },
    blockedLabel: {
        defaultMessage: "Blocked",
        id: idPrefix + "blocked-label",
    },
    blockedTooltip: {
        defaultMessage: "Licensing this animal using this site is not possible, please contact {OrganisationContact} to license your pet.",
        id: idPrefix + "blocked-tooltip",
    },
    breedLabel: {
        defaultMessage: "Breed",
        id: idPrefix + "breed-label",
    },
    cancelledLabel: {
        defaultMessage: "Cancelled",
        id: idPrefix + "cancelled-label",
    },
    cancelledLicensingWarningMessage: {
        defaultMessage: "Your license for this pet has been cancelled and will require a new license to be purchased. Please check your email for details on why the license was cancelled or contact {OrganisationContact} on how to proceed.",
        id: idPrefix + "cancelledlicensingwarning-message",
    },
    colourLabel: {
        defaultMessage: "Color",
        id: idPrefix + "colour-label",
    },
    dateOfBirthLabel: {
        defaultMessage: "Pet's Date of birth",
        id: idPrefix + "dateofbirth-label",
    },
    deleteAnimalConfirmationMessage: {
        defaultMessage: "Are you sure you want to delete this pet?",
        id: idPrefix + "deleteanimalconfirmation-message",
    },
    deleteAnimalModalTitle: {
        defaultMessage: "Delete Pet?",
        id: idPrefix + "deleteanimalmodal-title",
    },
    expiringAnimalLabel: {
        defaultMessage: "{animalName} expires {expiryDate}",
        id: idPrefix + "expiringanimal-label",
    },
    expiringAnimalsMoreLabel: {
        defaultMessage: "+{additionalPets} other pets",
        id: idPrefix + "expiringanimalsmore-label",
    },
    expiringAnimalsOneMoreLabel: {
        defaultMessage: "+{additionalPets} other pet",
        id: idPrefix + "expiringanimalsonemore-label",
    },
    expiringAnimalsRenewNowButtonLabel: {
        defaultMessage: "Renew Now",
        id: idPrefix + "expiringanimalsrenewnowbutton-label",
    },
    expiringAnimalsWarningTitle: {
        defaultMessage: "Warning, you have licenses that are nearly due.",
        id: idPrefix + "expiringanimalswarning-title",
    },
    genderLabel: {
        defaultMessage: "Gender",
        id: idPrefix + "gender-label",
    },
    identificationCountInvalidError: {
        defaultMessage: "You can only have {maxCount} of this kind of identification",
        id: idPrefix + "identificationcountinvalid-error",
    },
    identificationLabel: {
        defaultMessage: "Identification / Microchip",
        id: idPrefix + "identification-label",
    },
    invalidAnimalTypeError: {
        defaultMessage: "The selected animal type was not found",
        id: idPrefix + "invalidanimaltype-error",
    },
    isDesexedLabel: {
        defaultMessage: "Spayed/Neutered",
        id: idPrefix + "isdesexed-label",
    },
    licenceIdentificationCountInvalidError: {
        defaultMessage: "You can only have {maxCount} license",
        id: idPrefix + "licenceidentificationcountinvalid-error",
    },
    licenceTagNumberLabel: {
        defaultMessage: "Tag Number",
        id: idPrefix + "licencetagnumber-label",
    },
    microchipIdentificationCountInvalidError: {
        defaultMessage: "You can only have {maxCount} microchips",
        id: idPrefix + "microchipidentificationcountinvalid-error",
    },
    microchipLabel: {
        defaultMessage: "Microchip",
        id: idPrefix + "microchip-label",
    },
    microchipNumberInvalidDualLengthError: {
        defaultMessage: "Microchip Number must be either {minLength} or {length} characters",
        id: idPrefix + "microchipnumberinvalidduallength-error",
    },
    microchipNumberInvalidError: {
        defaultMessage: "Microchip Number is not valid",
        id: idPrefix + "microchipnumberinvalid-error",
    },
    microchipNumberInvalidSingleLengthError: {
        defaultMessage: "Microchip Number must be {length} characters",
        id: idPrefix + "microchipnumberinvalidsinglelength-error",
    },
    mixedBreedLabel: {
        defaultMessage: "Mixed Breed",
        id: idPrefix + "mixedbreed-label",
    },
    noIdentificationsMessage: {
        defaultMessage: "No identifications have been added",
        id: idPrefix + "noidentification-message",
    },
    noPetsMessage: {
        defaultMessage: "No pets have been added.",
        id: idPrefix + "nopets-message",
    },
    noSelectedPetsMessage: {
        defaultMessage: "Please select at least one pet first",
        id: idPrefix + "noselectedpets-message",
    },
    petListBreedHeading: {
        defaultMessage: "Breed",
        id: idPrefix + "petlistbreed-heading",
    },
    petListExpiryDateHeading: {
        defaultMessage: "Expiry Date",
        id: idPrefix + "petlistexpirydate-heading",
    },
    petListLicenceStatusExpiredLabel: {
        defaultMessage: "Expired",
        id: idPrefix + "petlistlicencestatusexpired-label",
    },
    petListLicenceStatusHeading: {
        defaultMessage: "Status",
        id: idPrefix + "petlistlicencestatus-heading",
    },
    petListLicenceStatusLicencedLabel: {
        defaultMessage: "Licensed",
        id: idPrefix + "petlistlicencestatuslicenced-label",
    },
    petListLicenceStatusPendingLabel: {
        defaultMessage: "Pending",
        id: idPrefix + "petlistlicencestatuspending-label",
    },
    petListLicenceStatusUnpaidLabel: {
        defaultMessage: "Awaiting Payment",
        id: idPrefix + "petlistlicencestatusunpaid-label",
    },
    petListLicenceTypeHeading: {
        defaultMessage: "License Type",
        id: idPrefix + "petlistlicencetype-heading",
    },
    petListLifetimeLabel: {
        defaultMessage: "Lifetime",
        id: idPrefix + "petlistlifetime-label",
    },
    petListNameHeading: {
        defaultMessage: "Name",
        id: idPrefix + "petlistname-heading",
    },
    petListTypeHeading: {
        defaultMessage: "Type",
        id: idPrefix + "petlisttype-heading",
    },
    petMissingMessage: {
        defaultMessage: "Unable to find the selected pet. Return to the <a>My Pets</a> page to find your list of pets.",
        id: idPrefix + "petmissing-message",
    },
    petNavbarTitle: {
        defaultMessage: "My Pets",
        id: idPrefix + "petsnavbar-title",
    },
    petsPageTitle: {
        defaultMessage: "My Pets",
        id: idPrefix + "petspage-title",
    },
    purebredLabel: {
        defaultMessage: "Purebred",
        id: idPrefix + "purebred-label",
    },
    rabiesExpiryInvalidError: {
        defaultMessage: "Your pet's license cannot exceed the duration of the rabies vaccination. You can continue licensing your pet, however it may be rejected by staff during the review process, or you may be sold a partial year license.",
        id: idPrefix + "rabiesexpiryinvalid-error",
    },
    rabiesExpiryInvalidHeading: {
        defaultMessage: "Rabies vaccination expiry warning.",
        id: idPrefix + "rabiesexpiryinvalid-heading",
    },
    rabiesExpiryInvalidTooltip: {
        defaultMessage: "Rabies vaccination expiry warning, click the icon for more info.",
        id: idPrefix + "rabiesexpiryinvalid-tooltip",
    },
    rabiesExpiryMultipleInvalidError: {
        defaultMessage: "Several licenses are due to expire before the rabies vaccination expiration date. You can continue licensing your pets; however, they may be rejected by staff during the review process.",
        id: idPrefix + "rabiesexpirymultipleinvalid-error",
    },
    rejectedLabel: {
        defaultMessage: "On Hold",
        id: idPrefix + "rejected-label",
    },
    rejectedLicenceWarningMessage: {
        defaultMessage: "Your license for this pet has been put on hold and requires updates for the license to be processed. Please check your email for instructions or contact {OrganisationContact} on how to proceed.",
        id: idPrefix + "rejectedlicencewarning-message",
    },
    removeIdentificationTooltip: {
        defaultMessage: "Remove Identification",
        id: idPrefix + "removeidentification-tooltip",
    },
    renewalBulkActionLabel: {
        defaultMessage: "Renew",
        id: idPrefix + "renewalbulkaction-label",
    },
    searchPetsPageTitle: {
        defaultMessage: "Search For Pets",
        id: idPrefix + "searchPetsPage-title",
    },
    secondaryBreedHelpMessage: {
        defaultMessage: "Leave secondary breed blank if unknown",
        id: idPrefix + "secondarybreedhelp-message",
    },
    secondaryBreedLabel: {
        defaultMessage: "Secondary breed",
        id: idPrefix + "secondarybreed-label",
    },
    secondaryColourLabel: {
        defaultMessage: "Secondary color (if applicable)",
        id: idPrefix + "secondarycolour-label",
    },
    tagNumberLabel: {
        defaultMessage: "Tag number",
        id: idPrefix + "tagnumber-label",
    },
    tattooIdentificationCountInvalidError: {
        defaultMessage: "You can only have {maxCount} tattoo(s)",
        id: idPrefix + "tattooidentificationcountinvalid-error",
    },
    tattooLabel: {
        defaultMessage: "Tattoo",
        id: idPrefix + "tattoo-label",
    },
    unknownBreedMessage: {
        defaultMessage: "Unknown Breed",
        id: idPrefix + "unknownbreed-message",
    },
});
