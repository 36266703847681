import { InfoMessage } from "components/shared/InfoMessage";
import { LoadingMessage } from "components/shared/LoadingMessage";
import { SpinnerButton } from "components/shared/SpinnerButton";
import graphql from "babel-plugin-relay/macro";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { SignUpError } from "components/user/SignUpError";
import { accountMessages } from "domain/account/accountMessages";
import { signUp } from "domain/user/signUp";
import { signUpMutationResponse } from "generatedQueries/signUpMutation.graphql";
import { SignUpQuery } from "generatedQueries/SignUpQuery.graphql";
import { pick } from "lodash";
import { parse } from "query-string";
import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRelayEnvironment } from "react-relay";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import { routes } from "routes/routes";
import { PasswordEntryFields } from "routes/user/PasswordEntryFields";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { Recaptcha } from "shared/components/newFormInputs/Recaptcha";
import { TextBox } from "shared/components/newFormInputs/TextBox";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";
import { combine, isEmail, required } from "shared/validations/validations";

const messages = mapMessages("routes.user.signup", {
    pageTitle: "Sign Up",
    recapchaValidationMessage: "Please complete the reCAPTCHA",
    signUpButton: "Sign Up",
    signUpComplete: "We have sent an email to {emailAddress}. Please check your email, follow the instructions to verify your email address, and then use the Sign In link above to sign in once your account has been verified. If you receive a password reset code instead, this means your email already exists and you can <pw>complete the password reset here</pw>.",
});

export interface ISignUpRequest {
    email: string;
    firstName: string | null;
    lastName: string | null;
    password: string;
    confirmPassword: string;
    recaptcha: string;
}

interface IEmailQueryString {
    email?: string;
}

export const SignUp: FC = () => {
    const environment = useRelayEnvironment();
    const location = useLocation();
    const queryString = parse(location.search) as IEmailQueryString;
    const methods = useForm<ISignUpRequest>({
        defaultValues: {
            confirmPassword: "",
            email: queryString.email || "",
            firstName: null,
            lastName: null,
            password: "",
            recaptcha: "",
        },
    });
    const [signUpSucceeded, setSignUpSucceeded] = useState(false);
    const [failureReason, setFailureReason] = useState<string | null | undefined>(undefined);
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);

    const email = methods.watch("email");

    const hasEmailInQueryString = !!queryString.email;

    return <Row className="justify-content-center">
        <Col xl={5} lg={6} md={7} sm={9}>
            <h2><Message message={messages.pageTitle} /></h2>
            {
                signUpSucceeded
                    ? <InfoMessage
                        message={messages.signUpComplete}
                        messageValues={{
                            emailAddress: email,
                            pw: msg => <Link to={`${routes.user.forgotPassword.create({})}?email=${email}`}>{msg}</Link>,
                        }} />
                    : <Query<SignUpQuery>
                        query={graphql`
                            query SignUpQuery {
                                configuration {
                                    collectNameOnSignUp
                                    passwordValidationRegex
                                    licensingRecaptchaSiteKey
                                }
                            }`}
                        variables={{}}>
                        {({ error, props }) => {
                            if (error) {
                                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                            }

                            if (!props) {
                                return <LoadingMessage />;
                            }

                            const {
                                collectNameOnSignUp,
                                passwordValidationRegex,
                                licensingRecaptchaSiteKey,
                            } = props.configuration ?? {};

                            if (!licensingRecaptchaSiteKey) {
                                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                            }

                            return <FormProvider {...methods}>
                                <Form
                                    onSubmit={methods.handleSubmit(onFormSubmit)}
                                    role="form"
                                >
                                    <FormLabel
                                        message={accountMessages.emailAddressLabel}
                                        for="email"
                                        required={!hasEmailInQueryString}
                                    >
                                        <TextBox
                                            id="email"
                                            name="email"
                                            register={methods.register}
                                            control={methods.control}
                                            type="email"
                                            options={combine<ISignUpRequest, "email">(
                                                required(),
                                                isEmail()
                                            )}
                                            aria-required={!hasEmailInQueryString}
                                            readOnly={hasEmailInQueryString}
                                        />
                                    </FormLabel>
                                    {
                                        collectNameOnSignUp &&
                                        <>
                                            <FormLabel
                                                message={accountMessages.firstNameLabel}
                                                for="firstName"
                                                required={true}
                                            >
                                                <TextBox
                                                    id="firstName"
                                                    name="firstName"
                                                    register={methods.register}
                                                    control={methods.control}
                                                    options={required<ISignUpRequest, "firstName">()}
                                                    aria-required={true}
                                                />
                                            </FormLabel>
                                            <FormLabel
                                                message={accountMessages.lastNameLabel}
                                                for="lastName"
                                                required={true}
                                            >
                                                <TextBox
                                                    id="lastName"
                                                    name="lastName"
                                                    register={methods.register}
                                                    control={methods.control}
                                                    options={required<ISignUpRequest, "lastName">()}
                                                    aria-required={true}
                                                />
                                            </FormLabel>
                                        </>
                                    }
                                    <PasswordEntryFields
                                        passwordField="password"
                                        passwordValidationRegex={passwordValidationRegex}
                                    />
                                    <Recaptcha
                                        name="recaptcha"
                                        control={methods.control}
                                        siteKey={licensingRecaptchaSiteKey}
                                        rules={required<ISignUpRequest, "recaptcha">(messages.recapchaValidationMessage)}
                                    />
                                    {
                                        failureReason !== undefined &&
                                        <SignUpError failureReason={failureReason} />
                                    }
                                    <SpinnerButton
                                        className="float-right"
                                        color="primary"
                                        isLoading={isSaveInProgress}
                                        message={messages.signUpButton}
                                    />
                                </Form>
                            </FormProvider>;
                        }}
                    </Query>
            }
        </Col>
    </Row>;

    function onFormSubmit(values: ISignUpRequest) {
        setIsSaveInProgress(true);
        signUp(
            environment,
            pick(values, ["email", "password", "firstName", "lastName", "recaptcha"]),
            onResponseReceived,
            () => {
                setIsSaveInProgress(false);
                setFailureReason("Error");
            });
    }

    function onResponseReceived(response: signUpMutationResponse) {
        setIsSaveInProgress(false);

        if (response.licensingSignUp?.success) {
            setSignUpSucceeded(true);
        } else {
            setFailureReason(response.licensingSignUp?.failureReason ?? "Failed");
        }
    }
};
