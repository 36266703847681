/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CardConnectForm_configuration = {
    readonly cardConnectSourceUri: string | null;
    readonly " $refType": "CardConnectForm_configuration";
};
export type CardConnectForm_configuration$data = CardConnectForm_configuration;
export type CardConnectForm_configuration$key = {
    readonly " $data"?: CardConnectForm_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"CardConnectForm_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CardConnectForm_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cardConnectSourceUri",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '4a843465d7fd3be589e67c27ee5587b1';
export default node;
