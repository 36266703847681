import { LoadingMessage } from "components/shared/LoadingMessage";
import { SpinnerButton } from "components/shared/SpinnerButton";
import graphql from "babel-plugin-relay/macro";
import { AnimalPublicTagSearchResult, IAnimalSearchProps } from "components/animal/AnimalTagSearch/AnimalPublicTagSearchResult";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { lostPetMessages } from "domain/lostPet/lostPetMessages";
import { AnimalPublicTagSearchQuery } from "generatedQueries/AnimalPublicTagSearchQuery.graphql";
import React, { createRef, FC, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Alert, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Query } from "shared/components/Query";
import { sharedMessages } from "shared/sharedMessages";

const emptySearchProps: IAnimalSearchProps = { tag: null, recaptchaResponse: null };

interface IProps {
    maxAnimalsToDisplay?: number;
}

export const AnimalPublicTagSearch: FC<IProps> = ({ maxAnimalsToDisplay }) => {
    const recaptchaRef = createRef<ReCAPTCHA>();
    const [tag, setTag] = useState("");
    const [searchProps, setSearchProps] = useState<IAnimalSearchProps>(emptySearchProps);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Query<AnimalPublicTagSearchQuery>
            query={graphql`
                query AnimalPublicTagSearchQuery {
                    configuration {
                        licensingRecaptchaSiteKey
                    }
                }`}
            variables={{}}>
            {({ error, props }) => {
                if (error) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                } else if (!props) {
                    return <LoadingMessage />;
                } else if (!props.configuration || !props.configuration.licensingRecaptchaSiteKey) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                return (
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <h4>
                                <Message message={lostPetMessages.lostPetSearchPageTitle} />
                            </h4>
                            <Alert color="light">
                                <Message message={lostPetMessages.lostPetSearchPageMessage} />
                            </Alert>
                            <Form onSubmit={onSubmitTagSearch} inline={true}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="tag" className="mr-sm-2">
                                        <Message message={lostPetMessages.tagNumberSearchLabel} />
                                    </Label>
                                    <Input
                                        type="text"
                                        name="tag"
                                        value={tag}
                                        onChange={onTagChange} />
                                </FormGroup>
                                <SpinnerButton
                                    type="submit"
                                    message={sharedMessages.searchButtonLabel}
                                    isLoading={isLoading}
                                    color="primary"
                                    className="mr-3" />
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={props.configuration.licensingRecaptchaSiteKey} />
                            </Form>
                            <AnimalPublicTagSearchResult onResultRendering={onResultRendering} maxAnimalsToDisplay={maxAnimalsToDisplay} {...searchProps} />
                        </Col>
                    </Row>
                );
            }}
        </Query>
    );

    function onTagChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;

        setTag(value.trim());

        if (!value) {
            setSearchProps(emptySearchProps);
        }
    }

    function onSubmitTagSearch(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!tag || !recaptchaRef.current || !recaptchaRef.current.getValue() || isLoading) {
            return;
        }

        setIsLoading(true);
        setSearchProps({
            recaptchaResponse: recaptchaRef.current.getValue(),
            tag,
        });
    }

    function onResultRendering() {
        setIsLoading(false);
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    }
};
