/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SortEnumType = "ASC" | "DESC" | "%future added value";
export type LicensingAnimalSortInput = {
    name?: SortEnumType | null;
};
export type AnimalListPaginationQueryVariables = {
    after?: string | null;
    first?: number | null;
    order?: Array<LicensingAnimalSortInput> | null;
};
export type AnimalListPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"AnimalList_query">;
};
export type AnimalListPaginationQuery = {
    readonly response: AnimalListPaginationQueryResponse;
    readonly variables: AnimalListPaginationQueryVariables;
};



/*
query AnimalListPaginationQuery(
  $after: String
  $first: Int
  $order: [LicensingAnimalSortInput!]
) {
  ...AnimalList_query
}

fragment AnimalList_query on Query {
  account {
    animals(first: $first, after: $after, order: $order) {
      edges {
        node {
          id
          status
          licences {
            isLifetime
            id
          }
          name
          ...AnimalRow_animal
          __typename
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}

fragment AnimalRow_animal on LicensingAnimal {
  id
  name
  breed {
    name
  }
  secondaryBreed {
    name
  }
  status
  licenceType {
    description
  }
  currentLicence {
    id
    description
    isLifetime
    expiryDate
  }
  animalType {
    id
    description
  }
  ...AnimalStatus_animal
}

fragment AnimalStatus_animal on LicensingAnimal {
  id
  status
  currentLicence {
    id
    status
    isLifetime
    expiryDate
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "first"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "order"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
        } as any,
        {
            "kind": "Variable",
            "name": "order",
            "variableName": "order"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isLifetime",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v6 = [
        (v5 /*: any*/)
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AnimalListPaginationQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AnimalList_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AnimalListPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v1 /*: any*/),
                            "concreteType": "LicensingAnimalConnection",
                            "kind": "LinkedField",
                            "name": "animals",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingAnimalEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "LicensingAnimal",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Licence",
                                                    "kind": "LinkedField",
                                                    "name": "licences",
                                                    "plural": true,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LicensingBreed",
                                                    "kind": "LinkedField",
                                                    "name": "breed",
                                                    "plural": false,
                                                    "selections": (v6 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LicensingBreed",
                                                    "kind": "LinkedField",
                                                    "name": "secondaryBreed",
                                                    "plural": false,
                                                    "selections": (v6 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LicenceType",
                                                    "kind": "LinkedField",
                                                    "name": "licenceType",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Licence",
                                                    "kind": "LinkedField",
                                                    "name": "currentLicence",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v4 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "expiryDate",
                                                            "storageKey": null
                                                        },
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LicensingAnimalType",
                                                    "kind": "LinkedField",
                                                    "name": "animalType",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "totalCount",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v1 /*: any*/),
                            "filters": [
                                "order"
                            ],
                            "handle": "connection",
                            "key": "AnimalList_query_animals",
                            "kind": "LinkedHandle",
                            "name": "animals"
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "d9f0a13d0a65e7473c0edcb93bf82271",
            "id": null,
            "metadata": {},
            "name": "AnimalListPaginationQuery",
            "operationKind": "query",
            "text": "query AnimalListPaginationQuery(\n  $after: String\n  $first: Int\n  $order: [LicensingAnimalSortInput!]\n) {\n  ...AnimalList_query\n}\n\nfragment AnimalList_query on Query {\n  account {\n    animals(first: $first, after: $after, order: $order) {\n      edges {\n        node {\n          id\n          status\n          licences {\n            isLifetime\n            id\n          }\n          name\n          ...AnimalRow_animal\n          __typename\n        }\n        cursor\n      }\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n\nfragment AnimalRow_animal on LicensingAnimal {\n  id\n  name\n  breed {\n    name\n  }\n  secondaryBreed {\n    name\n  }\n  status\n  licenceType {\n    description\n  }\n  currentLicence {\n    id\n    description\n    isLifetime\n    expiryDate\n  }\n  animalType {\n    id\n    description\n  }\n  ...AnimalStatus_animal\n}\n\nfragment AnimalStatus_animal on LicensingAnimal {\n  id\n  status\n  currentLicence {\n    id\n    status\n    isLifetime\n    expiryDate\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '50eaca790c664937c902e7372156865b';
export default node;
