import { useEffect, useRef, useState } from "react";
import { Disposable, GraphQLTaggedNode, usePaginationFragment } from "react-relay";
import { OperationType } from "relay-runtime";

// Getting the typings for the KeyType from the second argument on usePaginationFragment
type KeyType = NonNullable<Parameters<typeof usePaginationFragment>["1"]>;

type UsePaginationOptions = {
    pageSize: number;
};

export function usePagination<TQuery extends OperationType, TKey extends KeyType>(
    fragment: GraphQLTaggedNode,
    parentFragmentRef: TKey,
    options: UsePaginationOptions) {
    const [hasErrorLoadingNext, setHasErrorLoadingNext] = useState(false);
    const [loadNextRequest, setLoadNextRequest] = useState<Disposable | null>(null);
    const loadNextRequestRef = useRef<Disposable | null>(loadNextRequest);

    const {
        data,
        loadNext,
        hasNext,
        isLoadingNext,
    } = usePaginationFragment<TQuery, TKey>(fragment, parentFragmentRef);

    useEffect(() => {
        loadNextRequestRef.current = loadNextRequest;
    }, [loadNextRequest]);

    useEffect(() => {
        return () => {
            loadNextRequestRef.current?.dispose();
        };
    }, []);

    return {
        data,
        hasErrorLoadingNext,
        hasNext: hasNext && !hasErrorLoadingNext,
        isLoadingNext,
        loadNext: onLoadNext,
    };

    function onLoadNext() {
        if (isLoadingNext) {
            return;
        }

        setLoadNextRequest(loadNext(options.pageSize, {
            onComplete: onLoadNextComplete,
        }));
    }

    function onLoadNextComplete(error: Error | null) {
        setHasErrorLoadingNext(!!error);
    }
}
