import { accountMessages } from "domain/account/accountMessages";
import { MessageDescriptor } from "react-intl";

type PhoneNumberType = "HOME" | "MOBILE" | "WORK";

export function isPhoneNumberType(value: number | string): value is PhoneNumberType {
    return value === "HOME" || value === "MOBILE" || value === "WORK";
}

export interface IPhoneNumber {
    type: PhoneNumberType | null;
    number: string | null;
}

interface IPhoneNumberType {
    value: PhoneNumberType;
    label: MessageDescriptor;
}

export const phoneNumberTypes: IPhoneNumberType[] = [
    { value: "HOME", label: accountMessages.phoneTypeHomeLabel },
    { value: "MOBILE", label: accountMessages.phoneTypeMobileLabel },
    { value: "WORK", label: accountMessages.phoneTypeWorkLabel },
];
