/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Rabies_account = {
    readonly id: string;
    readonly " $refType": "Rabies_account";
};
export type Rabies_account$data = Rabies_account;
export type Rabies_account$key = {
    readonly " $data"?: Rabies_account$data;
    readonly " $fragmentRefs": FragmentRefs<"Rabies_account">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Rabies_account",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Account",
    "abstractKey": null
} as any;
(node as any).hash = '0aa0b140ea237858941c7eb0df67b340';
export default node;
