/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Hideable_configuration = {
    readonly hiddenFields: string;
    readonly " $refType": "Hideable_configuration";
};
export type Hideable_configuration$data = Hideable_configuration;
export type Hideable_configuration$key = {
    readonly " $data"?: Hideable_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"Hideable_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Hideable_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hiddenFields",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '4e02a446324921688676a45b9eeadcdd';
export default node;
