import { InfoMessage } from "components/shared/InfoMessage";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import styles from "components/address/Address.module.scss";
import { AddressType } from "components/address/AddressForm";
import { AddressSummary } from "components/address/AddressSummary";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import { ReadonlyAddress_address } from "generatedQueries/ReadonlyAddress_address.graphql";
import React, { FC } from "react";
import { createFragmentContainer } from "react-relay";
import { Card, CardBody, CardHeader } from "reactstrap";

interface IProps {
    addressType: AddressType;
}

interface IGraphProps {
    address: ReadonlyAddress_address | null;
}

type Props = IProps & IGraphProps;

const InternalReadonlyAddress: FC<Props> = ({
    address,
    addressType,
}) => {
    return <Card className={classNames(styles.addressCard, styles.readonly)}>
        <CardHeader>
            {addressType === AddressType.Physical ? <Message message={accountMessages.physicalAddressLabel} /> : <Message message={accountMessages.postalAddressLabel} />}
        </CardHeader>
        <CardBody>
            {
                !address &&
                <InfoMessage message={accountMessages.noAddressesAddedMessage} />
            }
            {
                address &&
                <AddressSummary {...address} deliveryType={address.deliveryType && address.deliveryType.name} />
            }
            {
                addressType === AddressType.Physical &&
                address?.jurisdiction?.name &&
                <>
                    <hr />
                    <h6>{address.jurisdiction.name}</h6>
                </>

            }
        </CardBody>
    </Card>;
};

export const ReadonlyAddress = createFragmentContainer(InternalReadonlyAddress, {
    address: graphql`
        fragment ReadonlyAddress_address on LicensingMailingAddress {
            ... on LicensingAddress {
                id
                suburb
                state
                postcode
                country
            }
            ... on LicensingDeliveryAddress {
                deliveryType {
                    name
                }
                deliveryNumber
            }
            ... on LicensingStreetAddress {
                addressLine
                jurisdiction {
                    name
                }
            }
        }`,
});
