import { useCallback, useEffect, useState } from "react";
import useScript from "react-script-hook";

export const ON_LOAD_CALLBACK_NAME = "onRecaptchaLoaded";
export const RECAPTCHA_SCRIPT_SRC = `https://www.google.com/recaptcha/api.js?render=explicit&onload=${ON_LOAD_CALLBACK_NAME}`;

interface IProps {
    siteKey: string;
    container: string | HTMLElement | null;
}

export type RecaptchaProps = IProps & Omit<ReCaptchaV2.Parameters, "sitekey" | "callback" | "error-callback" | "expired-callback">;

export interface IUseRecaptchaResponse {
    token: string | undefined;
    isExpired: boolean;
    hasError: boolean;
    isLoading: boolean;
}

export function useRecaptcha({
    siteKey,
    container,
    ...rest
}: RecaptchaProps): IUseRecaptchaResponse {
    const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

    const onLoadCallback = useCallback(() => {
        setIsRecaptchaLoaded(true);
    }, []);

    useEffect(() => {
        if (!window[ON_LOAD_CALLBACK_NAME]) {
            window[ON_LOAD_CALLBACK_NAME] = onLoadCallback;
        }

        return () => {
            window[ON_LOAD_CALLBACK_NAME] = undefined;
        };
    });

    const [isScriptLoading, scriptError] = useScript({
        src: RECAPTCHA_SCRIPT_SRC,
        checkForExisting: true,
        async: "true",
    });
    const [isRendered, setIsRendered] = useState(false);
    const [token, setToken] = useState<string>();
    const [isExpired, setIsExpired] = useState(false);
    const [hasError, setHasError] = useState(false);

    const tokenReceived = useCallback((newToken: string) => {
        setIsExpired(false);
        setHasError(false);
        setToken(newToken);
    }, []);

    const onError = useCallback(() => {
        setIsExpired(false);
        setHasError(true);
        setToken(undefined);
    }, []);

    const onExpired = useCallback(() => {
        setIsExpired(true);
        setHasError(false);
        setToken(undefined);
    }, []);

    useEffect(() => {
        if (isScriptLoading || scriptError || !isRecaptchaLoaded) {
            return;
        }

        if (!isRendered && isRecaptchaLoaded && container) {
            window.grecaptcha.render(container, {
                sitekey: siteKey,
                callback: tokenReceived,
                "error-callback": onError,
                "expired-callback": onExpired,
                ...rest,
            });
            setIsRendered(true);
        }
    }, [container, isRecaptchaLoaded, isRendered, isScriptLoading, onError, onExpired, rest, scriptError, siteKey, tokenReceived]);

    return {
        token,
        isExpired,
        hasError: hasError || !!scriptError,
        isLoading: !isRendered && !(hasError || !!scriptError),
    };
}
