/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AddressSearchQueryVariables = {
    searchText: string;
    sessionToken?: string | null;
};
export type AddressSearchQueryResponse = {
    readonly licensingAddressSearch: ReadonlyArray<{
        readonly placeId: string;
        readonly formattedAddress: string;
    }> | null;
};
export type AddressSearchQuery = {
    readonly response: AddressSearchQueryResponse;
    readonly variables: AddressSearchQueryVariables;
};



/*
query AddressSearchQuery(
  $searchText: String!
  $sessionToken: String
) {
  licensingAddressSearch(searchText: $searchText, sessionToken: $sessionToken) {
    placeId
    formattedAddress
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "searchText"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "sessionToken"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "searchText",
                    "variableName": "searchText"
                },
                {
                    "kind": "Variable",
                    "name": "sessionToken",
                    "variableName": "sessionToken"
                }
            ],
            "concreteType": "LicensingAddressSearchResult",
            "kind": "LinkedField",
            "name": "licensingAddressSearch",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "placeId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "formattedAddress",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AddressSearchQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AddressSearchQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "51fece8f1a449ad951190d5a17c2fa49",
            "id": null,
            "metadata": {},
            "name": "AddressSearchQuery",
            "operationKind": "query",
            "text": "query AddressSearchQuery(\n  $searchText: String!\n  $sessionToken: String\n) {\n  licensingAddressSearch(searchText: $searchText, sessionToken: $sessionToken) {\n    placeId\n    formattedAddress\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'c3645c09e17147eabb40cca58aee9a50';
export default node;
