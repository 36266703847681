import { Message } from "shared/components/Message";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { fatalErrorMessages } from "./errorMessages";

export interface IErrorIdentifier {
    errorLabel?: MessageDescriptor;
    identifier: number | string;
}

export const ErrorIdentifier: FC<IErrorIdentifier> = ({ errorLabel, identifier }) => {
    return (
        <h6>
            <Message message={errorLabel ?? fatalErrorMessages.defaultErrorIdentifierLabel} values={{ identifier }} />
        </h6>
    );
};
