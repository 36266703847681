import { defineMessages } from "react-intl";

const idPrefix = "domain.account.";

export const accountMessages = defineMessages({
    accountDetailsStepDescriptionLabel: {
        defaultMessage: "Account Details",
        id: idPrefix + "accountdetailsstepdescription-label",
    },
    accountDetailsStepTitle: {
        defaultMessage: "Step 1",
        id: idPrefix + "accountdetailsstep-title",
    },
    accountExistingPageTitle: {
        defaultMessage: "Account",
        id: idPrefix + "accountexistingpage-title",
    },
    accountNewPageTitle: {
        defaultMessage: "Create Account",
        id: idPrefix + "accountnewpage-title",
    },
    additionalDetailsLabel: {
        defaultMessage: "Additional Details",
        id: idPrefix + "additionalDetails-label",
    },
    addPhoneNumberLabel: {
        defaultMessage: "Add Phone Number",
        id: idPrefix + "addphonenumber-label",
    },
    addressLineLabel: {
        defaultMessage: "Street Address",
        id: idPrefix + "addressline-label",
    },
    addressStepDescriptionLabel: {
        defaultMessage: "Address",
        id: idPrefix + "addressstepdescription-label",
    },
    addressStepTitle: {
        defaultMessage: "Step 2",
        id: idPrefix + "addressstep-title",
    },
    allowedDisclosureGroupLabel: {
        defaultMessage: "Disclose Details (in case your pet is found)",
        id: idPrefix + "alloweddisclosuregroup-label",
    },
    cantFindAddressMessage: {
        defaultMessage: "Can't find address?",
        id: idPrefix + "cantfindaddress-message",
    },
    changeAccountDetailsLabel: {
        defaultMessage: "Change Account Details",
        id: idPrefix + "changeaccountdetails-label",
    },
    changeEmailAddressButtonLabel: {
        defaultMessage: "Change Email Address",
        id: idPrefix + "changeemailaddressbutton-label",
    },
    changeEmailAddressLabel: {
        defaultMessage: "{icon} Change Email Address",
        id: idPrefix + "changeemailaddress-label",
    },
    changePasswordLabel: {
        defaultMessage: "{icon} Change Password",
        id: idPrefix + "changepassword-label",
    },
    changeYourEmailAddressTitle: {
        defaultMessage: "Change your email address",
        id: idPrefix + "changeyouremailaddress-title",
    },
    confirmAccountStepDescription: {
        defaultMessage: "Confirmation",
        id: idPrefix + "confirmaccountstep-description",
    },
    continueToLicenceMyPetButtonLabel: {
        defaultMessage: "License My Pet",
        id: idPrefix + "continueToLicenceMyPetButton-label",
    },
    countryLabel: {
        defaultMessage: "Country",
        id: idPrefix + "country-label",
    },
    createAccountLabel: {
        defaultMessage: "Create Account",
        id: idPrefix + "createaccount-label",
    },
    currentEmailAddressLabel: {
        defaultMessage: "Current email address",
        id: idPrefix + "currentemailaddress-label",
    },
    customSeniorCitizenLabel: {
        defaultMessage: "{personType}",
        id: idPrefix + "customSeniorCitizen-label",
    },
    dateOfBirthLabel: {
        defaultMessage: "Date of Birth",
        id: idPrefix + "dateofbirth-label",
    },
    deliveryNumberLabel: {
        defaultMessage: "Delivery Number",
        id: idPrefix + "deliverynumber-label",
    },
    deliveryTypeLabel: {
        defaultMessage: "Delivery Type",
        id: idPrefix + "deliverytype-label",
    },
    discloseDetailsLabel: {
        defaultMessage: "Disclose Details",
        id: idPrefix + "discloseDetails-label",
    },
    discloseDetailsMoreLabel: {
        defaultMessage: "(in the event that another resident finds your animal)",
        id: idPrefix + "discloseDetailsMore-label",
    },
    driverLicenceLabel: {
        defaultMessage: "Driver License",
        id: idPrefix + "driverlicence-label",
    },
    duplicatePhoneTypeError: {
        defaultMessage: "Only one number of each type is allowed",
        id: idPrefix + "duplicatephonetype-error",
    },
    emailAddressLabel: {
        defaultMessage: "Email",
        id: idPrefix + "emailaddress-label",
    },
    firstNameLabel: {
        defaultMessage: "First Name",
        id: idPrefix + "firstname-label",
    },
    isCustomSeniorCitizenCheckboxLabel: {
        defaultMessage: "I am a {personType}",
        id: idPrefix + "isCustomSeniorCitizenCheckbox-label",
    },
    isSeniorCitizenCheckboxLabel: {
        defaultMessage: "I am a Senior Citizen",
        id: idPrefix + "isSeniorCitizenCheckbox-label",
    },
    lastNameLabel: {
        defaultMessage: "Last Name",
        id: idPrefix + "lastname-label",
    },
    signInPageTitle: {
        defaultMessage: "Sign In",
        id: idPrefix + "signinpage-title",
    },
    middleNameLabel: {
        defaultMessage: "Middle Name",
        id: idPrefix + "middlename-label",
    },
    nameLabel: {
        defaultMessage: "Name",
        id: idPrefix + "name-label",
    },
    newEmailAddressLabel: {
        defaultMessage: "New email address",
        id: idPrefix + "newemailaddress-label",
    },
    noAddressesAddedMessage: {
        defaultMessage: "No address has been entered",
        id: idPrefix + "noaddressadded-message",
    },
    personNameSuffixLabel: {
        defaultMessage: "Suffix",
        id: idPrefix + "personnamesuffix-label",
    },
    phoneNumberDisplayLabel: {
        defaultMessage: "{number} {type}",
        id: idPrefix + "phonenumberdisplay-label",
    },
    phoneNumbersLabel: {
        defaultMessage: "Phone Numbers",
        id: idPrefix + "phonenumbers-label",
    },
    phoneTypeHomeLabel: {
        defaultMessage: "Home",
        id: idPrefix + "phonetypehome-label",
    },
    phoneTypeMissingError: {
        defaultMessage: "Please select a type",
        id: idPrefix + "phonetypemissing-error",
    },
    phoneTypeMobileLabel: {
        defaultMessage: "Cell",
        id: idPrefix + "phonetypemobile-label",
    },
    phoneTypeWorkLabel: {
        defaultMessage: "Work",
        id: idPrefix + "phonetypework-label",
    },
    physicalAddressLabel: {
        defaultMessage: "Physical Address",
        id: idPrefix + "physicaladdress-label",
    },
    poBoxLabel: {
        defaultMessage: "PO Box",
        id: idPrefix + "pobox-label",
    },
    postalAddressLabel: {
        defaultMessage: "Postal Address",
        id: idPrefix + "postaladdress-label",
    },
    postalAddressStepDescriptionLabel: {
        defaultMessage: "Postal Address",
        id: idPrefix + "postaladdressstepdescription-label",
    },
    postalIsSameAsPhysicalAddressLabel: {
        defaultMessage: "Postal Address is same as Physical",
        id: idPrefix + "postalissameasphysicaladdress-label",
    },
    postcodeLabel: {
        defaultMessage: "ZIP",
        id: idPrefix + "postcode-label",
    },
    removePhoneNumberTooltip: {
        defaultMessage: "Remove Phone Number",
        id: idPrefix + "removephonenumber-tooltip",
    },
    sameAsPhysicalAddressLabel: {
        defaultMessage: "Use Same as Physical Address",
        id: idPrefix + "sameasphysicaladdress-label",
    },
    searchForAddressMessage: {
        defaultMessage: "Search for address or manually enter below",
        id: idPrefix + "searchforaddress-message",
    },
    seniorCitizenLabel: {
        defaultMessage: "Senior Citizen",
        id: idPrefix + "seniorcitizen-label",
    },
    seniorCitizenNoLabel: {
        defaultMessage: "No",
        id: idPrefix + "seniorcitizenno-label",
    },
    seniorCitizenYesLabel: {
        defaultMessage: "Yes",
        id: idPrefix + "seniorcitizenyes-label",
    },
    signUpButtonLabel: {
        defaultMessage: "Sign Up",
        id: idPrefix + "signupbutton-label",
    },
    stateLabel: {
        defaultMessage: "State",
        id: idPrefix + "state-label",
    },
    stepThreeTitle: {
        defaultMessage: "Step 3",
        id: idPrefix + "stepThree-title",
    },
    stepFourTitle: {
        defaultMessage: "Step 4",
        id: idPrefix + "stepFour-title",
    },
    streetAddressLabel: {
        defaultMessage: "Street Address",
        id: idPrefix + "streetaddress-label",
    },
    streetNumberLabel: {
        defaultMessage: "Number",
        id: idPrefix + "streetnumber-label",
    },
    subscribeToShelterNewsletter: {
        defaultMessage: "Subscribe to Shelter Newsletter",
        id: idPrefix + "subscribetoshelternewsletter-label",
    },
    suburbLabel: {
        defaultMessage: "City",
        id: idPrefix + "suburb-label",
    },
    unitNumberLabel: {
        defaultMessage: "Apt.",
        id: idPrefix + "unitnumber-label",
    },
    unknownPhoneNumberTypeLabel: {
        defaultMessage: "Unknown",
        id: idPrefix + "unknownphonenumbertype-label",
    },
});
