/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type deleteAnimalMutationVariables = {
    animalId: string;
};
export type deleteAnimalMutationResponse = {
    readonly deleteLicensingAnimal: {
        readonly id: string;
    } | null;
};
export type deleteAnimalMutation = {
    readonly response: deleteAnimalMutationResponse;
    readonly variables: deleteAnimalMutationVariables;
};



/*
mutation deleteAnimalMutation(
  $animalId: ID!
) {
  deleteLicensingAnimal(animalId: $animalId) {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "animalId"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "animalId",
                    "variableName": "animalId"
                }
            ],
            "concreteType": "LicensingAnimal",
            "kind": "LinkedField",
            "name": "deleteLicensingAnimal",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "deleteAnimalMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "deleteAnimalMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "79a2863ab505b312abc78e492ec0193a",
            "id": null,
            "metadata": {},
            "name": "deleteAnimalMutation",
            "operationKind": "mutation",
            "text": "mutation deleteAnimalMutation(\n  $animalId: ID!\n) {\n  deleteLicensingAnimal(animalId: $animalId) {\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '52999f428bc5302cd4604849ac8c7289';
export default node;
