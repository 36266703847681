import { SpinnerButton } from "components/shared/SpinnerButton";
import { combine, email as emailValidator, required } from "components/shared/validations";
import { ResendSignUpCodeError } from "components/user/ResendSignUpCodeError";
import { toastActions } from "domain/toast/toastActions";
import { resendSignUpCode } from "domain/user/resendSignUpCode";
import { ResendLicensingSignUpCodeError, resendSignUpCodeMutationResponse } from "generatedQueries/resendSignUpCodeMutation.graphql";
import { Form } from "informed";
import { parse } from "query-string";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { useLocation } from "react-router";
import { Col, Row } from "reactstrap";
import { PayloadError } from "relay-runtime";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { TextBox } from "shared/components/formInputs/TextBox";
import { mapMessages } from "shared/mapMessages";

export const messages = mapMessages("components.user.resendsignup", {
    emailAddressLabel: "Email",
    resendButton: "Send",
    signUpCodeResentToast: "Sign up code has been sent, please check your email to continue.",
});

interface IQueryString {
    email?: string;
}

export const ResendSignUpCode: FC = () => {
    const location = useLocation();
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();
    const [failure, setFailure] = useState<boolean | ResendLicensingSignUpCodeError>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const queryString = parse(location.search) as IQueryString;

    return (
        <Row>
            <Col>
                <Form onSubmit={resend} initialValues={queryString} noValidate={true}>
                    <FormLabel
                        message={messages.emailAddressLabel}
                        for="resendToEmail"
                    >
                        <TextBox
                            id="resendToEmail"
                            field="email"
                            type="email"
                            validate={combine(required, emailValidator)} />
                    </FormLabel>
                    <ResendSignUpCodeError error={failure} />
                    <SpinnerButton className="float-right" color="primary" isLoading={isSubmitting} message={messages.resendButton} />
                </Form>
            </Col>
        </Row>
    );

    function resend({ email }: { email: string }) {
        setIsSubmitting(true);
        resendSignUpCode(
            environment,
            email,
            onResendCompleted,
            onResendError);
    }

    function onResendCompleted(response: resendSignUpCodeMutationResponse, errors?: PayloadError[] | null) {
        setIsSubmitting(false);
        if (errors?.length || !response.resendLicensingSignUpCode?.wasSuccessful) {
            setFailure(response.resendLicensingSignUpCode?.error ?? true);
        } else {
            dispatch(toastActions.customSuccessToast(messages.signUpCodeResentToast));
        }
    }

    function onResendError() {
        setIsSubmitting(false);
        setFailure(true);
    }
};
