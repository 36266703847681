/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type GlobalPaymentsButtonQueryVariables = {
    amount: number;
};
export type GlobalPaymentsButtonQueryResponse = {
    readonly globalPaymentsHppPaymentRequestJson: string | null;
};
export type GlobalPaymentsButtonQuery = {
    readonly response: GlobalPaymentsButtonQueryResponse;
    readonly variables: GlobalPaymentsButtonQueryVariables;
};



/*
query GlobalPaymentsButtonQuery(
  $amount: Decimal!
) {
  globalPaymentsHppPaymentRequestJson(amount: $amount)
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "amount"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "amount",
                    "variableName": "amount"
                }
            ],
            "kind": "ScalarField",
            "name": "globalPaymentsHppPaymentRequestJson",
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "GlobalPaymentsButtonQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "GlobalPaymentsButtonQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "fc9deac560f8335a3ab8b5500af7c0a5",
            "id": null,
            "metadata": {},
            "name": "GlobalPaymentsButtonQuery",
            "operationKind": "query",
            "text": "query GlobalPaymentsButtonQuery(\n  $amount: Decimal!\n) {\n  globalPaymentsHppPaymentRequestJson(amount: $amount)\n}\n"
        }
    } as any;
})();
(node as any).hash = '04763c4ea919997b6b6d2ecd218aacc4';
export default node;
