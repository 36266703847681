/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type JurisdictionModal_configuration = {
    readonly organisationContact: string;
    readonly " $refType": "JurisdictionModal_configuration";
};
export type JurisdictionModal_configuration$data = JurisdictionModal_configuration;
export type JurisdictionModal_configuration$key = {
    readonly " $data"?: JurisdictionModal_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"JurisdictionModal_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JurisdictionModal_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '36f4230b728d5815c20160015e4b2a3a';
export default node;
