import graphql from "babel-plugin-relay/macro";
import { changePasswordMutation, changePasswordMutationResponse } from "generatedQueries/changePasswordMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation changePasswordMutation($input: ChangeLicensingPasswordInput!) {
        changeLicensingPassword(input: $input) {
            wasSuccessful
            error
        }
    }`;

export function changePassword(
    environment: IEnvironment,
    newPassword: string,
    oldPassword: string,
    onCompleted: (response: changePasswordMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<changePasswordMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                input: {
                    newPassword,
                    oldPassword,
                },
            },
        }
    );
}
