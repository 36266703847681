import { InfoMessage } from "components/shared/InfoMessage";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { toastActions } from "domain/toast/toastActions";
import { resendSignUpCode } from "domain/user/resendSignUpCode";
import { ConfirmLicensingAccountSignUpError } from "generatedQueries/confirmSignUpMutation.graphql";
import { resendSignUpCodeMutationResponse } from "generatedQueries/resendSignUpCodeMutation.graphql";
import React, { FC, useState } from "react";
import { MessageDescriptor } from "react-intl";
import { useDispatch } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { routes } from "routes/routes";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";

export const messages = mapMessages("components.user.confirmsignup", {
    accountAlreadyConfirmed: "Your account has already been confirmed, you can now <a>sign in using your email address and password</a>.",
    errorConfirmingSignUp: "There was an error while confirming the sign up, please check the email address and code before proceeding.",
    signUpCodeDeliveryFailed: "Sign up code delivery failed, please try again later.",
    signUpCodeExpired: "The code supplied has expired. <a>Resend the code</a>",
    signUpCodeResentToast: "Sign up code has been sent, please check your email to continue.",
    userNotFound: "User not found, please try <a>signing up again</a>.",
});

interface IProps {
    error: boolean | ConfirmLicensingAccountSignUpError;
    submittedEmail: string;
}

export const ConfirmSignUpError: FC<IProps> = ({
    error,
    submittedEmail,
}) => {
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();
    const [isResendingCode, setIsResendingCode] = useState(false);

    return <>
        {
            error === "ALREADYCONFIRMED" &&
            <InfoMessage
                message={messages.accountAlreadyConfirmed}
                messageValues={{
                    a: msg => <Link to={routes.user.login.create({})}>{msg}</Link>,
                }} />
        }
        {
            error === "CODEEXPIRED" &&
            <WarningMessage
                message={messages.signUpCodeExpired}
                messageValues={{
                    a: msg => !isResendingCode ? <Button color="link" onClick={resendCode}>{msg}</Button> : <Spinner />,
                }} />
        }
        {
            error === "USERNOTFOUND" &&
            <WarningMessage
                message={messages.userNotFound}
                messageValues={{
                    a: msg => <Link to={routes.user.signUp.create({})}>{msg}</Link>,
                }} />
        }
        {
            error === true &&
            <ErrorMessage message={messages.errorConfirmingSignUp} />
        }
    </>;

    function resendCode() {
        setIsResendingCode(true);
        resendSignUpCode(
            environment,
            submittedEmail,
            onResendCompleted,
            onResendError);
    }

    function onResendCompleted(response: resendSignUpCodeMutationResponse) {
        setIsResendingCode(false);
        if (response.resendLicensingSignUpCode?.wasSuccessful) {
            dispatch(toastActions.customSuccessToast(messages.signUpCodeResentToast));
        } else {
            let errorMessage: MessageDescriptor;

            switch (response.resendLicensingSignUpCode?.error) {
                case "USERNOTFOUND":
                    errorMessage = messages.userNotFound;
                    break;

                case "ALREADYCONFIRMED":
                    errorMessage = messages.accountAlreadyConfirmed;
                    break;

                default:
                    errorMessage = messages.signUpCodeDeliveryFailed;
                    break;
            }

            dispatch(toastActions.customFailureToast(errorMessage));
        }
    }

    function onResendError() {
        setIsResendingCode(false);
        dispatch(toastActions.customFailureToast(messages.signUpCodeDeliveryFailed));
    }
};
