/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Rabies_configuration = {
    readonly proofOfRabiesAllowedFileExtensions: ReadonlyArray<string> | null;
    readonly " $refType": "Rabies_configuration";
};
export type Rabies_configuration$data = Rabies_configuration;
export type Rabies_configuration$key = {
    readonly " $data"?: Rabies_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"Rabies_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Rabies_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proofOfRabiesAllowedFileExtensions",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '5ada533de3d6a29b5e7f5db81a9f14cf';
export default node;
