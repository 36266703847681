/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import AnimalListPaginationQuery from "./AnimalListPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type SyncStatus = "ACTIVE" | "BLOCKED" | "CANCELLED" | "DUPLICATE" | "NEW" | "PENDING" | "REJECTED" | "%future added value";
export type AnimalList_query = {
    readonly account: {
        readonly animals: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly status: SyncStatus;
                    readonly licences: ReadonlyArray<{
                        readonly isLifetime: boolean;
                    }>;
                    readonly name: string;
                    readonly " $fragmentRefs": FragmentRefs<"AnimalRow_animal">;
                };
            }> | null;
            readonly totalCount: number;
        } | null;
    } | null;
    readonly " $refType": "AnimalList_query";
};
export type AnimalList_query$data = AnimalList_query;
export type AnimalList_query$key = {
    readonly " $data"?: AnimalList_query$data;
    readonly " $fragmentRefs": FragmentRefs<"AnimalList_query">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "account",
        "animals"
    ];
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "after"
            },
            {
                "kind": "RootArgument",
                "name": "first"
            },
            {
                "kind": "RootArgument",
                "name": "order"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "first",
                    "cursor": "after",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "first",
                        "cursor": "after"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": AnimalListPaginationQuery
            }
        },
        "name": "AnimalList_query",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                    {
                        "alias": "animals",
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "order",
                                "variableName": "order"
                            }
                        ],
                        "concreteType": "LicensingAnimalConnection",
                        "kind": "LinkedField",
                        "name": "__AnimalList_query_animals_connection",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "LicensingAnimalEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "LicensingAnimal",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "id",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "status",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Licence",
                                                "kind": "LinkedField",
                                                "name": "licences",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "isLifetime",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "name",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "__typename",
                                                "storageKey": null
                                            },
                                            {
                                                "args": null,
                                                "kind": "FragmentSpread",
                                                "name": "AnimalRow_animal"
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "cursor",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalCount",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "endCursor",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "hasNextPage",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '50eaca790c664937c902e7372156865b';
export default node;
