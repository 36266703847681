import { get } from "lodash";
import React, { ReactNode } from "react";
import { Control, FieldPath, FieldValues, UseFormRegister, useFormState } from "react-hook-form";
import { FormFeedback, Input, InputGroup, InputGroupAddon, InputProps } from "reactstrap";
import { TranslatedRegisterOptions, useTranslatedValidations } from "shared/validations/useTranslatedValidations";

interface IProps<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>, TContext extends object = object> {
    control: Control<TFieldValues, TContext>;
    name: TFieldName;
    register: UseFormRegister<TFieldValues>;
    options?: TranslatedRegisterOptions<TFieldValues, TFieldName>;
    children?: {
        append?: ReactNode,
        prepend?: ReactNode,
    };
}

type Props<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>, TContext extends object = object>
    = IProps<TFieldValues, TFieldName, TContext> & Omit<InputProps, "name" | "onChange" | "onBlur">;

export function TextBox<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>, TContext extends object = object>({
    control,
    name,
    register,
    options,
    children,
    ...props
}: Props<TFieldValues, TFieldName, TContext>) {
    const registerOptions = useTranslatedValidations(options);
    const { errors } = useFormState({
        control,
    });

    const { ref, ...rest } = register(name, registerOptions);

    const errorMessage: string | undefined = get(errors, `${name}.message`);

    return <>
        {
            ((children?.prepend) || (children?.append))
                ?
                <InputGroup className="flex-wrap">
                    {
                        children?.prepend &&
                        <InputGroupAddon addonType="prepend">
                            {children.prepend}
                        </InputGroupAddon>
                    }
                    <Input
                        innerRef={ref}
                        invalid={!!errorMessage}
                        {...props}
                        {...rest}
                    />
                    {
                        children?.append &&
                        <InputGroupAddon addonType="append">
                            {children.append}
                        </InputGroupAddon>
                    }
                    {
                        errorMessage &&
                        <FormFeedback>
                            {errorMessage}
                        </FormFeedback>
                    }
                </InputGroup>
                :
                <>
                    <Input
                        innerRef={ref}
                        invalid={!!errorMessage}
                        {...props}
                        {...rest}
                    />
                    {
                        errorMessage &&
                        <FormFeedback>
                            {errorMessage}
                        </FormFeedback>
                    }
                </>
        }
    </>;
}
