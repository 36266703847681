import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { Button } from "reactstrap";
import styles from "routes/user/TogglePasswordButton.module.scss";

interface IProps {
    displayPassword: boolean;
    label: MessageDescriptor;
    onClick: (displayPassword: boolean) => void;
}

export const TogglePasswordButton: FC<IProps> = ({
    displayPassword,
    label,
    onClick,
}) => {
    const { formatMessage } = useIntl();
    const [shouldDisplayPassword, setShouldDisplayPassword] = useState(displayPassword);

    useEffect(() => {
        if (displayPassword !== shouldDisplayPassword) {
            onClick(shouldDisplayPassword);
        }
    }, [displayPassword, onClick, shouldDisplayPassword]);

    return (
        <Button
            onClick={onToggleClicked}
            aria-label={formatMessage(label)}
            className={styles.button}
        >
            <FontAwesomeIcon icon={displayPassword ? faEye : faEyeSlash} fixedWidth={true} />
        </Button>
    );

    function onToggleClicked() {
        setShouldDisplayPassword(!shouldDisplayPassword);
    }
};
