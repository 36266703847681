/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HomeContent_landingPage = {
    readonly value: string | null;
    readonly " $refType": "HomeContent_landingPage";
};
export type HomeContent_landingPage$data = HomeContent_landingPage;
export type HomeContent_landingPage$key = {
    readonly " $data"?: HomeContent_landingPage$data;
    readonly " $fragmentRefs": FragmentRefs<"HomeContent_landingPage">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeContent_landingPage",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ],
    "type": "SiteCustomisation",
    "abstractKey": null
} as any;
(node as any).hash = '1605318771d2d8087b5893edffdabda4';
export default node;
