import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { AccountDetailsForm } from "components/account/AccountDetailsForm";
import { AccountWizardPage } from "components/account/AccountWizardPage";
import { ConfirmForm } from "components/account/ConfirmForm";
import { AddressForm } from "components/address/AddressForm";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { accountMessages } from "domain/account/accountMessages";
import { EditAccountQuery } from "generatedQueries/EditAccountQuery.graphql";
import { LocationState } from "history";
import React, { FC, Fragment, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "routes/routes";
import { BooleanMessage } from "shared/components/BooleanMessage";
import { HideableFields, IsHiddenField } from "shared/components/Hideable";
import { Query } from "shared/components/Query";
import { Wizard } from "shared/components/Wizard";
import { WizardPage } from "shared/components/WizardPage";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("routes.account.EditAccount", {
    formName: "Edit account",
});

export interface IEditAccountLocationState<T = LocationState> {
    nextPagePath: string;
    nextPageState?: T;
}

export const EditAccount: FC = () => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const location = useLocation<IEditAccountLocationState | LocationState>();
    const [isNewAccount, setIsNewAccount] = useState(false);

    return <Query<EditAccountQuery>
        query={graphql`
                query EditAccountQuery {
                    account {
                        status
                    }
                    configuration {
                        hiddenFields
                    }
                }`}
        variables={{}}>
        {({ error, props: renderProps }) => {
            if (error) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            if (!renderProps) {
                return <LoadingMessage />;
            }

            const { account, configuration } = renderProps;

            if (!account) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            if (account.status === "NEW") {
                setIsNewAccount(true);
            }

            const showConfirmForm = configuration &&
                !(IsHiddenField(HideableFields.canDiscloseToPublic, configuration.hiddenFields) &&
                    IsHiddenField(HideableFields.isSubscribedToShelterNewsletter, configuration.hiddenFields));

            return <Fragment>
                <h2>
                    {
                        <BooleanMessage
                            value={isNewAccount}
                            confirmTrueMessage={accountMessages.accountNewPageTitle}
                            confirmFalseMessage={accountMessages.accountExistingPageTitle} />
                    }
                </h2>
                <hr />
                <Wizard
                    onComplete={onComplete}
                    formName={formatMessage(messages.formName)}
                >
                    <WizardPage
                        title={accountMessages.accountDetailsStepTitle}
                        description={accountMessages.accountDetailsStepDescriptionLabel}
                        completeIcon="check-circle">
                        {({ onNext }) => <AccountDetailsForm onNext={onNext} isNewAccount={isNewAccount} />}
                    </WizardPage>
                    <WizardPage
                        title={accountMessages.addressStepTitle}
                        description={accountMessages.addressStepDescriptionLabel}
                        completeIcon="check-circle">
                        {({ onNext, onPrevious }) => <AddressForm
                            onNext={onNext}
                            onBack={onPrevious}
                            isNewAccount={isNewAccount} />}
                    </WizardPage>
                    {
                        showConfirmForm
                        &&
                        <WizardPage
                            title={accountMessages.stepThreeTitle}
                            description={accountMessages.additionalDetailsLabel}
                            completeIcon="check-circle">
                            {({ onNext, onPrevious }) =>
                                <ConfirmForm
                                    onNext={onNext}
                                    onBack={onPrevious}
                                    nextButtonMessage={sharedMessages.nextButtonLabel} />
                            }
                        </WizardPage>
                    }
                    <WizardPage
                        title={showConfirmForm ? accountMessages.stepFourTitle : accountMessages.stepThreeTitle}
                        description={accountMessages.confirmAccountStepDescription}
                        completeIcon="check-circle">
                        {({ onPrevious, onNext }) =>
                            <AccountWizardPage
                                isNewAccount={isNewAccount}
                                hasAdditionalDetailsForm={showConfirmForm ?? false}
                                onPrevious={onPrevious}
                                onNext={onNext} />
                        }
                    </WizardPage>
                </Wizard>
            </Fragment>;
        }}
    </Query>;

    function onComplete() {
        if (isEditLocationState(location.state)) {
            history.push(location.state.nextPagePath, location.state.nextPageState);
            return;
        }

        history.push(isNewAccount ? routes.licence.options.create({}) : routes.home.create({}));
    }
};

function isEditLocationState(state: LocationState): state is IEditAccountLocationState {
    if (!state) {
        return false;
    }

    return (state as IEditAccountLocationState).nextPagePath !== undefined;
}
