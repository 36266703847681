import { LoadingMessage } from "components/shared/LoadingMessage";
import { Money } from "components/shared/Money";
import { Spinner } from "components/shared/Spinner";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import { IPaymentFormChildProps } from "components/payment/PaymentForm";
import { IPayPalPayFlowResultEventPayload, payPalPayflowFormSubmitEvent, payPalPayflowResultMessage } from "components/payment/PayPalPayflow/messageActions";
import styles from "components/payment/PayPalPayflow/PayPalPayflowProvider.module.scss";
import { StandardPaymentButtonLayout } from "components/payment/StandardPaymentButtonLayout";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { paymentMessages } from "domain/payment/paymentMessages";
import { PayPalPayflowProviderQuery, PayPalPayflowProviderQueryResponse } from "generatedQueries/PayPalPayflowProviderQuery.graphql";
import { PayPalPayflowProvider_configuration } from "generatedQueries/PayPalPayflowProvider_configuration.graphql";
import { LicensingPaymentPayPalPayflowRequestInput } from "generatedQueries/processPaymentMethodMutation.graphql";
import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { Button, Col, Row } from "reactstrap";
import { routes } from "routes/routes";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { isActionOf } from "typesafe-actions";
import urljoin from "url-join";

const messages = mapMessages("components.payment.paypalpayflow", {
    errorLoadingHppDetails: "There was an error loading the details to display PayPal",
});

interface IGraphQlProps {
    configuration: PayPalPayflowProvider_configuration;
}

interface IProps {
    termsAndConditionsAccepted: boolean;
    allPaymentRowsAreValid: boolean;
}

type Props = IPaymentFormChildProps & IGraphQlProps & IProps;

const InternalPayPalPayflowProvider: FC<Props> = ({
    amountToCharge,
    configuration,
    termsAndConditionsAccepted,
    allPaymentRowsAreValid,
    errorMessages,
    submitPayment,
    isPaymentProcessing,
}) => {
    const intl = useIntl();
    const iFrameRef = useRef<HTMLIFrameElement>(null);
    const [isIframeLoading, setIsIframeLoading] = useState(true);
    const [shouldDisplayIframe, setShouldDisplayIframe] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const messageEventCallback = useCallback((event: WindowEventMap["message"]) => {
        if (isActionOf(payPalPayflowResultMessage, event.data)) {
            submitPayment(
                {
                    payPalPayflowPaymentRequest: buildPayflowRequestInput(event.data.payload),
                });
            setShouldDisplayIframe(false);
            setIsSubmitting(false);
        }
    }, [submitPayment]);

    useLayoutEffect(() => {
        window.addEventListener("message", messageEventCallback);
        return () => {
            window.removeEventListener("message", messageEventCallback);
        };
    }, [messageEventCallback]);

    useEffect(() => {
        if (!isPaymentProcessing && !shouldDisplayIframe) {
            setShouldDisplayIframe(true);
        }
    }, [isPaymentProcessing, shouldDisplayIframe]);

    const isPaymentValid = termsAndConditionsAccepted && allPaymentRowsAreValid && amountToCharge > 0;

    const returnUrl = urljoin(window.location.origin, routes.payment.payPalPayflowIFrame.create({}));

    return (
        <Query<PayPalPayflowProviderQuery>
            query={query}
            variables={{
                amount: amountToCharge,
                returnUrl,
                errorUrl: returnUrl,
                shouldGetDetails: amountToCharge > 0,
            }}>
            {({ error, props }) => {
                if (error) {
                    return <ErrorMessage message={messages.errorLoadingHppDetails} />;
                }

                const paymentButtonColor = isPaymentValid && !!props && !isPaymentProcessing && !isSubmitting
                    ? "primary"
                    : "secondary";

                return <Row>
                    <Col>
                        {
                            isIframeLoading &&
                            <LoadingMessage />
                        }
                        {
                            shouldDisplayIframe
                                ?
                                <iframe
                                    id="PayPalPayflow"
                                    src={routes.payment.payPalPayflowFields.create({})}
                                    title={intl.formatMessage(paymentMessages.payHeaderLabel)}
                                    frameBorder="0"
                                    scrolling="no"
                                    onLoad={onIframeLoad}
                                    className={classNames(
                                        {
                                            [styles.iframe]: true,
                                            "d-none": isIframeLoading,
                                        })}
                                    ref={iFrameRef} />
                                :
                                <div className={styles.iframeSpacer} data-cy="PayPalLinkComplete" />
                        }
                        <StandardPaymentButtonLayout
                            payButton={
                                <div className="text-center">
                                    <Button
                                        className={classNames({
                                            "w-50": true,
                                            "d-none": isIframeLoading,
                                        })}
                                        color={paymentButtonColor}
                                        disabled={!isPaymentValid || !props || isPaymentProcessing || isSubmitting}
                                        onClick={submitPaymentForm(props?.payPalPayflowHppDetails ?? null)}
                                        data-cy="payButton"
                                    >
                                        {
                                            !isPaymentProcessing && !isSubmitting
                                                ? <>
                                                    <Message message={paymentMessages.payButtonLabel} />
                                                    <span className="ml-2">
                                                        <Money amount={amountToCharge} currency={configuration.currency} />
                                                    </span>
                                                </>
                                                : <span className="align-middle">
                                                    <Spinner className="icon-button mr-2" />
                                                    <Message message={paymentMessages.paymentIsProcessing} />
                                                </span>
                                        }
                                    </Button>
                                </div>
                            }
                            errorMessages={errorMessages} />
                    </Col>
                </Row>;
            }}
        </Query>
    );

    function onIframeLoad() {
        setIsIframeLoading(false);
    }

    function submitPaymentForm(details: PayPalPayflowProviderQueryResponse["payPalPayflowHppDetails"]) {
        return () => {
            if (iFrameRef.current?.contentWindow && details && details.secureToken && details.secureTokenId) {
                iFrameRef.current.contentWindow.postMessage(
                    payPalPayflowFormSubmitEvent({ secureToken: details.secureToken, secureTokenId: details.secureTokenId }),
                    window.location.origin);
                setIsSubmitting(true);
            }
        };
    }
};

function buildPayflowRequestInput(result: IPayPalPayFlowResultEventPayload): LicensingPaymentPayPalPayflowRequestInput | null {
    const resultCode = parseInt(result.result ?? "", 10);

    if (isNaN(resultCode)) {
        return null;
    }

    const amount = parseFloat(result.amt ?? "");

    if (isNaN(amount)) {
        return null;
    }

    return {
        originalTransactionId: result.pnRef,
        amount,
        result: resultCode,
        transactionTime: result.transTime,
        resultMessage: result.respMsg,
        secureToken: result.secureToken,
        secureTokenId: result.secureTokenId,
    };
}

export const PayPalPayflowProvider = createFragmentContainer(InternalPayPalPayflowProvider, {
    configuration: graphql`
        fragment PayPalPayflowProvider_configuration on Configuration {
            currency
        }`,
});

const query = graphql`
    query PayPalPayflowProviderQuery($amount: Decimal!, $returnUrl: String!, $errorUrl: String, $shouldGetDetails: Boolean!) {
        payPalPayflowHppDetails(amount: $amount, returnUrl: $returnUrl, errorUrl: $errorUrl) @include(if: $shouldGetDetails) {
            secureTokenId
            secureToken
        }
    }`;
