import { Message } from "shared/components/Message";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { FormGroup, Label } from "reactstrap";
import styles from "shared/components/formInputs/FormLabel.module.scss";

interface IProps {
    message: MessageDescriptor | string;
    required?: boolean;
    for?: string;
    id?: string;
    inlineLabelClass?: string;
    inlineValueClass?: string;
}

export const FormLabel: FC<IProps> = ({
    inlineLabelClass,
    inlineValueClass,
    message,
    required,
    children,
    for: labelFor,
    id,
}) =>
    <FormGroup row={true}>
        <div className={inlineLabelClass || "col-12"}>
            <Label className={styles.label} for={labelFor} id={id}>
                {typeof (message) === "string" ? message : <Message message={message} />}
            </Label>
            {required && <span className={styles.required}> *</span>}
        </div>
        <div className={inlineValueClass || "col-12"}>
            {children}
        </div>
    </FormGroup>;
