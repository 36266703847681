import { DatePicker } from "components/shared/datePicker/DatePicker";
import { ErrorPane } from "components/shared/ErrorPane";
import { validationMessages } from "components/shared/validationMessages";
import { combine, greaterThan, maxLength, past, required, Validator } from "components/shared/validations";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import graphql from "babel-plugin-relay/macro";
import { AnimalIdentification } from "components/animal/AnimalIdentification";
import { Rabies } from "components/animal/Rabies";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { UpsertAnimalInput } from "domain/animal/upsertAnimal";
import { AnimalBreedsQuery } from "generatedQueries/AnimalBreedsQuery.graphql";
import { Animal_query, Animal_query$key } from "generatedQueries/Animal_query.graphql";
import { LicensingAnimalIdentificationInput, LicensingAnimalIdentificationType } from "generatedQueries/upsertAnimalMutation.graphql";
import { ArrayField, FormApi, FormError, FormState, Radio, RadioGroup } from "informed";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import moment from "moment";
import React, { FC } from "react";
import { IntlShape, MessageDescriptor, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { Col, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { FormSelect } from "shared/components/formInputs/FormSelect";
import { HiddenField } from "shared/components/formInputs/HiddenField";
import { LabelledCheckbox } from "shared/components/formInputs/LabelledCheckbox";
import { TextBox } from "shared/components/formInputs/TextBox";
import { HideableContainer, HideableFields } from "shared/components/Hideable";
import { IRenderProps, Query } from "shared/components/Query";
import { formatIso8601Date, getValidatedDateOrNull } from "shared/dateFunctions";
import { sharedMessages } from "shared/sharedMessages";

interface IGraphQlProps {
    queryKey: Animal_query$key;
}

interface IProps {
    formApi: FormApi<UpsertAnimalInput>;
    formState: FormState<UpsertAnimalInput>;
    onAnimalTypeSelected?: (typeId: string) => void;
    titleMessage?: MessageDescriptor;
    titleMessageValues?: { [key: string]: string | PrimitiveType | React.ReactElement | FormatXMLElementFn };
    animalId?: string;
    onDeleteClicked?: (animalId: string) => void;
}

export const Animal: FC<IProps & IGraphQlProps> = ({
    animalId,
    formApi,
    formState,
    queryKey,
    titleMessage,
    titleMessageValues,
    onDeleteClicked,
}) => {
    const intl = useIntl();
    const query = useFragment(graphQlQuery, queryKey);

    if (!query.account) {
        throw new Error("Must have an account to add or update an animal");
    }

    const animalTypeOptions = query.licensingAnimalTypes?.map(t => {
        return {
            label: t.description || "",
            value: t.id,
        };
    }) ?? [];

    const genderOptions = query.genders?.map(g => {
        return {
            label: g.name,
            value: g.genderId,
        };
    }) ?? [];

    return <>
        <Row>
            <Col>
                {
                    onDeleteClicked &&
                    !!animalId &&
                    <Button
                        color="danger"
                        className="float-right"
                        onClick={onDeleteButtonClicked}>
                        <Message message={sharedMessages.deleteButtonLabel} />
                    </Button>
                }
                <h4>
                    <Message message={titleMessage || animalMessages.animalFormLabel} values={titleMessageValues} />
                </h4>
            </Col>
        </Row>

        <HiddenField
            id="animalId"
            field="id"
            initialValue={animalId}
        />
        <HiddenField
            id="foundSbAnimalId"
            field="foundSbAnimalId"
            initialValue={(formState.values.foundSbAnimalId && formState.values.foundSbAnimalId.toString()) || undefined}
        />
        <FormLabel message={animalMessages.animalNameLabel} required={true} for="name">
            <TextBox
                id="name"
                field="name"
                validate={combine(required, maxLength(50))}
                autoFocus={true}
                aria-required={true}
            />
        </FormLabel>
        <FormLabel message={animalMessages.dateOfBirthLabel} required={true} for="dateOfBirth">
            <DatePicker
                id="dateOfBirth"
                field="dateOfBirth"
                maxDate={moment().add(-1, "days").toDate()}
                validate={combine(required, past)}
                aria-required={true}
            />
        </FormLabel>
        <FormLabel message={animalMessages.animalTypeLabel} required={true} for="animalTypeId">
            <FormSelect
                id="animalTypeId"
                className="input-animal-type"
                isClearable={false}
                isSearchable={true}
                options={animalTypeOptions}
                field="animalTypeId"
                validateOnChange={true}
                validate={combine(required, animalTypeValidator(query))}
                value={formState.values.animalTypeId ? formState.values.animalTypeId.toString() : undefined}
                initialValue={formState.values.animalTypeId ? formState.values.animalTypeId.toString() : undefined}
                aria-required={true}
            />
        </FormLabel>
        <FormLabel message={animalMessages.genderLabel} required={true} for="genderId">
            <FormSelect
                id="genderId"
                className="input-animal-gender"
                isClearable={false}
                isSearchable={true}
                options={genderOptions}
                field="genderId"
                validateOnChange={true}
                validate={combine(required, greaterThan(0, validationMessages.requiredError))}
                value={formState.values.genderId ? formState.values.genderId : undefined}
                initialValue={formState.values.genderId ? formState.values.genderId : undefined}
                aria-required={true}
            />
        </FormLabel>
        <LabelledCheckbox
            id="isDesexed"
            field="isDesexed"
            message={animalMessages.isDesexedLabel}
        />
        {
            formState.values.animalTypeId &&
            <Query<AnimalBreedsQuery>
                query={graphql`
                    query AnimalBreedsQuery($animalTypeId: ID!, $dateOfBirth: LocalDate) {
                        breeds: licensingBreeds(animalTypeId: $animalTypeId, dateOfBirth: $dateOfBirth) {
                            breedId
                            name
                        }
                        licensingAnimalColours(animalTypeId: $animalTypeId, dateOfBirth: $dateOfBirth) {
                            colourId
                            name
                        }
                    }`}
                variables={{
                    animalTypeId: formState.values.animalTypeId,
                    dateOfBirth: getDateOfBirthOrNull(formState.values.dateOfBirth),
                }}>
                {renderAnimalTypeSpecificSection}
            </Query>
        }
        <FormLabel message={animalMessages.identificationLabel}>
            <ArrayField field="identifications">
                {({ add, fields }) => (
                    <>
                        {
                            !fields.length &&
                            <span className="d-block">
                                <Message message={animalMessages.noIdentificationsMessage} />
                            </span>
                        }
                        {
                            fields.map(({ field, key, remove }, i) => {
                                return <AnimalIdentification
                                    key={key}
                                    index={i}
                                    field={field}
                                    validate={identificationValidator(fields.length, query.configuration, formState.values, intl)}
                                    onRemoveClicked={remove}
                                    notify={["identifications"]}
                                />;
                            })
                        }
                        <Button className="mt-2" color="success" onClick={add} aria-label="Add" size="sm">
                            <FontAwesomeIcon icon={faPlus} className="icon-button mr-1" />
                            <Message message={animalMessages.addIdentificationLabel} />
                        </Button>
                    </>
                )}
            </ArrayField>
        </FormLabel>
        <HideableContainer field={HideableFields.rabiesVaccination} configuration={query.configuration}>
            <Rabies
                configurationKey={query.configuration}
                accountKey={query.account}
            />
        </HideableContainer>
    </>;

    function renderAnimalTypeSpecificSection({ error, props: animalTypeSpecificRenderProps }: IRenderProps<AnimalBreedsQuery>) {
        if (error) {
            return <ErrorMessage message={sharedMessages.requestFailedBody} />;
        }

        const breedOptions = animalTypeSpecificRenderProps?.breeds?.map(b => {
            return {
                label: b.name,
                value: b.breedId,
            };
        }) ?? [];

        const colourOptions = animalTypeSpecificRenderProps?.licensingAnimalColours?.map(c => {
            return {
                label: c.name,
                value: c.colourId,
            };
        }) ?? [];

        return <>
            <FormLabel
                message={animalMessages.breedLabel}
                required={true}
                for="breedId"
            >
                <FormSelect
                    id="breedId"
                    className="input-animal-breed"
                    isClearable={false}
                    isSearchable={true}
                    isLoading={!animalTypeSpecificRenderProps}
                    options={breedOptions}
                    field="breedId"
                    validateOnChange={true}
                    validate={combine(required, greaterThan(0, validationMessages.requiredError))}
                    value={formState.values.breedId ? formState.values.breedId : undefined}
                    initialValue={formState.values.breedId ? formState.values.breedId : undefined}
                    aria-required={true}
                />
            </FormLabel>
            <RadioGroup
                field="breedSelection"
                validate={required}>
                <div className="form-check">
                    <Radio value="PUREBRED" className="form-check-input" id="purebred" />
                    <label className="form-check-label" htmlFor="purebred"><Message message={animalMessages.purebredLabel} /></label>
                </div>
                <div className="form-check">
                    <Radio value="MIXED" className="form-check-input" id="mixed" />
                    <label className="form-check-label" htmlFor="mixed"><Message message={animalMessages.mixedBreedLabel} /></label>
                </div>
                <ErrorPane errors={formApi.getError("breedSelection")} />
            </RadioGroup>
            <FormLabel message={animalMessages.secondaryBreedLabel} id="secondaryBreedLabel" for="secondaryBreedId">
                <FormSelect
                    id="secondaryBreedId"
                    className="input-animal-secondary-breed"
                    isClearable={true}
                    isSearchable={true}
                    isLoading={!animalTypeSpecificRenderProps}
                    isDisabled={formState.values.breedSelection !== "MIXED"}
                    options={breedOptions}
                    field="secondaryBreedId"
                    validateOnChange={true}
                    value={formState.values.secondaryBreedId ? formState.values.secondaryBreedId : undefined}
                    initialValue={formState.values.secondaryBreedId ? formState.values.secondaryBreedId : undefined}
                    aria-labelledby="secondaryBreedLabel secondaryBreedDescription"
                />
                <small className="form-text text-muted" id="secondaryBreedDescription">
                    <Message message={animalMessages.secondaryBreedHelpMessage} />
                </small>
            </FormLabel>
            <FormLabel message={animalMessages.colourLabel} required={true} for="colourId">
                <FormSelect
                    id="colourId"
                    className="input-animal-colour"
                    isClearable={false}
                    isSearchable={true}
                    isLoading={!animalTypeSpecificRenderProps}
                    options={colourOptions}
                    field="colourId"
                    validateOnChange={true}
                    validate={combine(required, greaterThan(0, validationMessages.requiredError))}
                    value={formState.values.colourId ? formState.values.colourId : undefined}
                    initialValue={formState.values.colourId ? formState.values.colourId : undefined}
                    aria-required={true}
                />
            </FormLabel>
            <FormLabel message={animalMessages.secondaryColourLabel} for="secondaryColourId">
                <FormSelect
                    id="secondaryColourId"
                    className="input-animal-secondary-colour"
                    isClearable={true}
                    isSearchable={true}
                    isLoading={!animalTypeSpecificRenderProps}
                    options={colourOptions}
                    field="secondaryColourId"
                    validateOnChange={true}
                    value={formState.values.secondaryColourId ? formState.values.secondaryColourId : undefined}
                    initialValue={formState.values.secondaryColourId ? formState.values.secondaryColourId : undefined}
                />
            </FormLabel>
        </>;
    }

    function onDeleteButtonClicked() {
        if (onDeleteClicked && animalId) {
            onDeleteClicked(animalId);
        }
    }
};

export function identificationValidator(count: number, configuration: Animal_query["configuration"], formValues: UpsertAnimalInput, intl: IntlShape): Validator {
    return (identification: LicensingAnimalIdentificationInput | undefined) => {
        if (!identification) {
            return;
        }

        if (formValues.identifications) {
            const countErrors = identificationCountValidator(identification.type, count, intl)(identification, formValues.identifications);
            if (countErrors) {
                return countErrors;
            }
        }

        const valueErrors = (typeof identification.value !== "undefined" && combine(required, maxLength(50))(identification.value));
        if (valueErrors) {
            return valueErrors;
        }

        const typeErrors = required(identification.type) && animalMessages.animalIdentificationTypeMissingError;
        if (typeErrors) {
            return typeErrors as unknown as FormError;
        }

        if (identification.type === "MICROCHIP" && !!identification.value && identification.value.length > 0) {
            if (!configuration) {
                return undefined;
            }

            if (configuration.microchipValidationSettings) {
                const microchipNumberLength = identification.value.length;

                const {
                    minChipLength,
                    microchipLength,
                } = configuration.microchipValidationSettings;

                if (minChipLength > 0) {
                    if (microchipNumberLength !== minChipLength &&
                        microchipNumberLength !== microchipLength) {
                        const message = intl.formatMessage(animalMessages.microchipNumberInvalidDualLengthError, { minLength: minChipLength, length: microchipLength });

                        return message as FormError;
                    }
                } else if (microchipLength > 0) {
                    if (microchipNumberLength !== microchipLength) {
                        const message = intl.formatMessage(animalMessages.microchipNumberInvalidSingleLengthError, { length: microchipLength });

                        return message as FormError;
                    }
                }
            }
        }

        return undefined;
    };
}

function identificationCountValidator(type: LicensingAnimalIdentificationType | null, count: number, intl: IntlShape): Validator {
    return (identification: LicensingAnimalIdentificationInput, identifications: LicensingAnimalIdentificationInput[]) => {
        if (!type) {
            return undefined;
        }

        let maxCount: number;
        let invalidMessage: MessageDescriptor;

        switch (type) {
            case "MICROCHIP":
                maxCount = 2;
                invalidMessage = animalMessages.microchipIdentificationCountInvalidError;
                break;
            case "LICENCE":
                maxCount = 1;
                invalidMessage = animalMessages.licenceIdentificationCountInvalidError;
                break;
            case "TATTOO":
                maxCount = 1;
                invalidMessage = animalMessages.tattooIdentificationCountInvalidError;
                break;
            default:
                maxCount = 0;
                invalidMessage = animalMessages.identificationCountInvalidError;
                break;
        }

        if (identifications.length < count) {
            maxCount--;
        }

        const filteredIds = identifications.filter(i => !i || i.type === type);

        return filteredIds.length > maxCount
            ? intl.formatMessage(invalidMessage, { maxCount }) as unknown as FormError
            : undefined;
    };
}

export function animalTypeValidator(renderForm: Animal_query): Validator {
    return (animalTypeId: string) => {
        const validationMessage = getAnimalTypeValidationMessage(renderForm, animalTypeId);

        return !!validationMessage ? validationMessage as unknown as FormError : undefined;
    };
}

function getAnimalTypeValidationMessage(renderForm: Animal_query, animalTypeId: string) {
    const animalType = renderForm.licensingAnimalTypes?.find(type => type.id === animalTypeId);

    if (!animalType) {
        return animalMessages.invalidAnimalTypeError;
    }

    return undefined;
}

export function getDateOfBirthOrNull(dateOfBirth: string | undefined | null): string | null {
    if (!dateOfBirth) {
        return null;
    }

    const validatedDateOfBirth = getValidatedDateOrNull(dateOfBirth);

    return validatedDateOfBirth ? formatIso8601Date(validatedDateOfBirth) : null;
}

const graphQlQuery = graphql`
    fragment Animal_query on Query {
        genders: licensingGenders {
            genderId
            name
        }
        licensingAnimalTypes {
            id
            description
        }
        configuration {
            microchipValidationSettings {
                microchipLength
                minChipLength
            }
            ...Rabies_configuration
            ...Hideable_configuration
        }
        account {
            ...Rabies_account
        }
    }`;
