import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { ExpiringSoon } from "components/animal/ExpiringSoon";
import { NoPets } from "components/animal/NoPets";
import { LicenseWarnings } from "components/licence/LicenseWarnings";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { IUserState } from "domain/user/combinedUserReducer";
import { HomeContentQuery } from "generatedQueries/HomeContentQuery.graphql";
import { HomeContent_configuration } from "generatedQueries/HomeContent_configuration.graphql";
import { HomeContent_landingPage } from "generatedQueries/HomeContent_landingPage.graphql";
import moment from "moment";
import React, { VFC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { createFragmentContainer } from "react-relay";
import { Link } from "react-router-dom";
import { CardText } from "reactstrap";
import { routes } from "routes/routes";
import { MessageCard } from "shared/components/MessageCard";
import { Query } from "shared/components/Query";
import { SiteCustomisation } from "shared/components/SiteCustomisation";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";
import { RootState } from "store/store";

const messages = mapMessages("components.home.HomeContent", {
    loggedInLandingHeading: "Welcome!",
    loggedInLandingMessage: "You are now logged in. You can <ac>update your account details</ac> such as name, address and phone number. You can also <add>add your pet to license</add> them using the “License My Pet” link in the menu.",
    loggedOutLandingMessage: "To license your pet first click <a>Sign in / Create an account</a> and enter your details and your pets' details.",
    loggedOutRenewalMessage: "To renew your pet(s), please <a>sign in</a> and renew the pets on the My Pets page.",
    screenReaderNoticeHeading: "Valued Supporter",
    screenReaderNoticeMessage: "Use of this site with screen readers is still being refined and currently may be less than ideal. For best results we encourage you to contact {organisationContact} directly at this time.",
});

interface IProps {
    showRedirectMessageWhenLoggedOut: boolean;
}

interface IGraphQlProps {
    configuration: HomeContent_configuration;
    landingPage: HomeContent_landingPage | null;
}

type Props = IProps & IGraphQlProps;

const HomeContentInternal: VFC<Props> = ({
    configuration,
    landingPage,
    showRedirectMessageWhenLoggedOut,
}) => {
    const { formatMessage } = useIntl();
    const userState = useSelector<RootState, IUserState>(state => state.user);

    const { isLoggedIn } = userState;

    return (
        <>
            <MessageCard
                message={messages.screenReaderNoticeHeading}
                className="sr-only"
            >
                <CardText>
                    <Message
                        message={messages.screenReaderNoticeMessage}
                        values={{
                            organisationContact: configuration?.organisationContact ?? formatMessage(sharedMessages.defaultOrganisationContactMessage),
                        }}
                    />
                </CardText>
            </MessageCard>
            {
                isLoggedIn &&
                <MessageCard message={messages.loggedInLandingHeading}>
                    <CardText>
                        <Message
                            message={messages.loggedInLandingMessage}
                            values={{
                                ac: msg => <Link to={routes.account.edit.create({})}>{msg}</Link>,
                                add: msg => <Link to={routes.licence.options.create({})}>{msg}</Link>,
                            }}
                        />
                    </CardText>
                </MessageCard>
            }
            <Query<HomeContentQuery>
                query={query}
                variables={{
                    filter: {
                        licenceExpiry_lte: moment().add(configuration.licenceExpiryWarningDays, "days").format("YYYY-MM-DD"),
                    },
                }}>
                {({ error, props }) => (
                    <>
                        {
                            error && isLoggedIn &&
                            <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />
                        }
                        {
                            !props &&
                            !error &&
                            <LoadingMessage />
                        }
                        {
                            props?.account &&
                            isLoggedIn &&
                            <>
                                <NoPets account={props.account} />
                                <ExpiringSoon account={props.account} maxAnimalsToDisplay={3} />
                                <LicenseWarnings account={props.account} />
                            </>
                        }
                    </>
                )}
            </Query>
            {
                landingPage &&
                <SiteCustomisation
                    value={landingPage.value}
                />
            }
            {
                !isLoggedIn &&
                <p>
                    <Message
                        message={showRedirectMessageWhenLoggedOut ? messages.loggedOutRenewalMessage : messages.loggedOutLandingMessage}
                        values={{
                            a: msg => <Link to={routes.user.login.create({})}>{msg}</Link>,
                        }}
                    />
                </p>
            }
        </>
    );
};

export const HomeContent = createFragmentContainer(HomeContentInternal, {
    configuration: graphql`
        fragment HomeContent_configuration on Configuration {
            licenceExpiryWarningDays
            organisationContact
        }`,
    landingPage: graphql`
        fragment HomeContent_landingPage on SiteCustomisation {
            value
        }`,
});

const query = graphql`
query HomeContentQuery($filter: LicensingAnimalFilter) {
    account {
        ...NoPets_account
        ...ExpiringSoon_account @arguments(filter: $filter)
        ...LicenseWarnings_account
    }
}`;
