import { FieldProps, FormValues, useField } from "informed";
import React, { ReactElement } from "react";
import { withDefaultValidation } from "shared/components/formInputs/withDefaultValidation";
import { Switch } from "shared/components/Switch";

interface IProps {
    label: React.ReactNode;
}

type Props<T extends FormValues> = IProps & FieldProps<boolean, T>;

export const FormSwitchInput = <T extends FormValues>(props: Props<T>): ReactElement => {
    const { fieldState, fieldApi, render, ref, userProps } = useField({ ...props });

    const { className, id, field, label, onChange } = userProps;

    return render(
        <Switch
            className={className}
            id={id || field}
            onChange={onChangeInput}
            label={label}
            checked={props.initialValue || fieldState.value}
            innerRef={ref}
        />
    );

    function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
        fieldApi.setValue(!fieldState.value);

        if (onChange) {
            onChange(event);
        }
    }
};

export const FormSwitch = withDefaultValidation<boolean>()(FormSwitchInput);
