import { routerMiddleware } from "connected-react-router";
import { userActions } from "domain/user/userActions";
import { createBrowserHistory } from "history";
import { getToken } from "infrastructure/token";
import { applyMiddleware, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "store/rootEpic";
import { createRootReducer } from "store/rootReducer";
import { StateType } from "typesafe-actions/dist/type-helpers";

export const epicMiddleware = createEpicMiddleware();

// tslint:disable-next-line:no-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();
const middlewares = [
    routerMiddleware(history),
    epicMiddleware,
];

const rootReducer = createRootReducer(history);

export type RootState = StateType<typeof rootReducer>;

function configureStore(initialState?: object) {
    const enhancer = composeEnhancers(applyMiddleware(...middlewares));
    return createStore(rootReducer, initialState!, enhancer);
}

export const store = configureStore();

epicMiddleware.run(rootEpic);

const token = getToken();
if (token) {
    store.dispatch(userActions.setToken(token));
}
