import { ErrorIdentifier, ErrorPage } from "components/shared/errorPage/ErrorPage";
import { logEvent } from "domain/logging/logEvent";
import { logEventMutationResponse } from "generatedQueries/logEventMutation.graphql";
import defaults from "lodash/defaults";
import React, { Component, ErrorInfo } from "react";
import { Col, Container, Row } from "reactstrap";
import { PayloadError } from "relay-runtime";
import { IRelayEnvironment, withEnvironment } from "shared/components/withEnvironment";
import { IRollbarProps, withRollbar } from "shared/components/withRollbar";

export const metadata = [
    {
        key: "LicensingSite",
        value: "CUSTOMER",
    },
];

interface IState {
    isError: boolean;
    trackingId: string | null;
}

type Props = IRelayEnvironment & IRollbarProps;

class InternalGlobalErrorHandler extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isError: false,
            trackingId: null,
        };

        this.setWindowOnError();
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.props.rollbar.error(error, metadata, errorInfo);
        logEvent(this.props.environment, "ERROR", error.message, JSON.stringify({ error, errorInfo }), "Licensing UI", metadata, this.onErrorLogComplete, this.onErrorLogFailed);

        this.setState({
            isError: true,
        });
    }

    public render() {
        if (this.state.isError) {
            return <>
                <Container data-cy={"globalErrorPageContainer"}>
                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                        <br />
                        <Row>
                            <ErrorPage errorIdentifier={
                                this.state.trackingId ? <ErrorIdentifier identifier={this.state.trackingId} /> : undefined
                            } />
                        </Row>
                    </Col>
                </Container>
            </>;
        }

        return this.props.children;
    }

    private setWindowOnError = () => {
        window.onerror = (message, file, line, column, error) => {
            const errorMessage = typeof message === "string"
                ? message
                : JSON.stringify(message);

            this.shouldSkipSetWindowOnError(errorMessage) || !file
                ? this.setState({ isError: false })
                : this.setState({ isError: true });

            const request = defaults(error, { message, file, line, column });

            this.props.rollbar.error(errorMessage, error, request, metadata);
            logEvent(this.props.environment, "ERROR", errorMessage, JSON.stringify(request), "Licensing UI", metadata, this.onErrorLogComplete, this.onErrorLogFailed);

            return false;
        };
    }

    private shouldSkipSetWindowOnError = (errorMessage: string): boolean => {
        const messagesToSkip = [
            "Script error.",
            "ResizeObserver loop completed with undelivered notifications.",
            "Uncaught TypeError: Cannot redefine property: googletag", // Stands Free AdBlocker extension throws this error on pages with no google tag manager
        ];

        return messagesToSkip.some((message) => message === errorMessage);
    }

    private onErrorLogComplete = (response: logEventMutationResponse, errors?: PayloadError[] | null) => {
        this.setState({
            trackingId: response?.logEvent,
        });
    }

    private onErrorLogFailed = (error: Error) => {
        // tslint:disable-next-line: no-console
        console.log("There was a further error while reporting the error to the logging service", error);
    }
}

export const GlobalErrorHandler = withRollbar(withEnvironment<Props>(InternalGlobalErrorHandler));
