import graphql from "babel-plugin-relay/macro";
import { upsertAnimalMutation, upsertAnimalMutationResponse, UpsertLicensingAnimalInput } from "generatedQueries/upsertAnimalMutation.graphql";
import { Mutable } from "infrastructure/Mutable";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

export type UpsertAnimalInput = Mutable<UpsertLicensingAnimalInput>;
export type LicensingAnimalIdentificationInput = Mutable<NonNullable<UpsertLicensingAnimalInput["identifications"]>[0]>;

const mutation = graphql`
    mutation upsertAnimalMutation($animal: UpsertLicensingAnimalInput!) {
        addOrUpdateLicensingAnimal(animal: $animal) {
            id
            rabiesVaccination {
                id
            }
        }
    }`;

export function upsertAnimal(
    environment: IEnvironment,
    animal: UpsertAnimalInput,
    onCompleted: (response: upsertAnimalMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<upsertAnimalMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                animal: {
                    ...animal,
                    identifications: animal.identifications
                        ?.filter(i => !!i.type && !!i.value && !i.value.match(/^ *$/))
                        .map(i => ({
                                type: i.type,
                                value: i.value.trim(),
                            })) ?? [],
                },
            },
        }
    );
}
