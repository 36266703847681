import { Text } from "informed";
import React, { FC } from "react";

interface IMyProps {
    field: string;
    initialValue?: string | boolean;
    id?: string;
}

type Props = IMyProps;

export const HiddenField: FC<Props> = ({
    field,
    initialValue,
    id,
}) => (
    <Text
        id={id}
        field={field}
        initialValue={initialValue}
        className="form-control"
        hidden={true}
        data-testid="hiddenField"
    />
);
