import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { Animal } from "components/animal/Animal";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { upsertAnimal, UpsertAnimalInput } from "domain/animal/upsertAnimal";
import { AnimalDetailsRenewalFormQuery, AnimalDetailsRenewalFormQueryResponse } from "generatedQueries/AnimalDetailsRenewalFormQuery.graphql";
import { upsertAnimalMutationResponse } from "generatedQueries/upsertAnimalMutation.graphql";
import React, { FC } from "react";
import { Col, Row } from "reactstrap";
import { ErrorHandlingMutationForm } from "shared/components/ErrorHandlingMutationForm";
import { Query } from "shared/components/Query";
import { sharedMessages } from "shared/sharedMessages";

interface IProps {
    animalId: string;
    onBack: () => void;
    onNext: () => void;
}

export const AnimalDetailsRenewalForm: FC<IProps> = ({
    animalId,
    onBack,
    onNext,
}) => (
    <Row className="justify-content-center">
        <Col sm={9} md={7} lg={5} xl={4}>
            <Query<AnimalDetailsRenewalFormQuery>
                query={graphql`
                    query AnimalDetailsRenewalFormQuery($animalId: ID!) {
                        account {
                            animal(animalId: $animalId) {
                                id
                                name
                                dateOfBirth
                                animalTypeId
                                genderId
                                isDesexed
                                breedId
                                secondaryBreedId
                                breedSelection
                                colourId
                                secondaryColourId
                                identifications {
                                    id
                                    type
                                    value
                                }
                                rabiesVaccination {
                                    dateAdministered
                                    immunityPeriodMonths
                                    storageId
                                }
                                licences {
                                    id
                                }
                                licenceTypeId
                                status
                            }
                        }
                        ...Animal_query
                    }`}
                variables={{ animalId }}
                cacheConfig={{ force: true }}>
                {({ error, props }) => {
                    if (error) {
                        return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                    }

                    if (!props) {
                        return <LoadingMessage />;
                    }

                    if (!props.account || !props.account.animal) {
                        return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                    }

                    return <ErrorHandlingMutationForm<UpsertAnimalInput, upsertAnimalMutationResponse>
                        initialValues={getInitialValues(props.account.animal)}
                        onNext={onNext}
                        onPrevious={onBack}
                        mutationFunc={upsertAnimal}>
                        {
                            ({ formApi, formState }) =>
                                <Animal
                                    formApi={formApi}
                                    formState={formState}
                                    animalId={animalId}
                                    queryKey={props}
                                />
                        }
                    </ErrorHandlingMutationForm>;
                }}
            </Query>
        </Col>
    </Row>
);

function getInitialValues(animal: NonNullable<NonNullable<AnimalDetailsRenewalFormQueryResponse["account"]>["animal"]>): UpsertAnimalInput {
    return {
        ...animal,
        identifications: animal.identifications && animal.identifications.map(i => i),
        rabiesVaccinationDateAdministered: animal.rabiesVaccination && animal.rabiesVaccination.dateAdministered,
        rabiesVaccinationImmunityPeriodMonths: animal.rabiesVaccination && animal.rabiesVaccination.immunityPeriodMonths,
        rabiesVaccinationStorageId: animal.rabiesVaccination && animal.rabiesVaccination.storageId,
    };
}
