/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PayPalForm_configuration = {
    readonly licensingDonationPaymentDescription: string | null;
    readonly organisationContact: string;
    readonly " $refType": "PayPalForm_configuration";
};
export type PayPalForm_configuration$data = PayPalForm_configuration;
export type PayPalForm_configuration$key = {
    readonly " $data"?: PayPalForm_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"PayPalForm_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayPalForm_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "licensingDonationPaymentDescription",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '275815dd7ffbe15e1478c65af9fee91a';
export default node;
