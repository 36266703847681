import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React, { FC, ReactNode } from "react";
import { MessageDescriptor } from "react-intl";
import { MessageCard } from "shared/components/MessageCard";

interface IProps {
    message: MessageDescriptor;
    className?: string | string[];
    children?: ReactNode | ReactNode[];
}

export const InformationCard: FC<IProps> = ({
    message,
    className,
    children,
}) => (
        <MessageCard message={message} className={className} icon={faInfoCircle} cardType="info">
            {children}
        </MessageCard>);
