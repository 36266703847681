/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AddressStateWarningConfigurationQueryVariables = {};
export type AddressStateWarningConfigurationQueryResponse = {
    readonly configuration: {
        readonly organisationContact: string;
    } | null;
};
export type AddressStateWarningConfigurationQuery = {
    readonly response: AddressStateWarningConfigurationQueryResponse;
    readonly variables: AddressStateWarningConfigurationQueryVariables;
};



/*
query AddressStateWarningConfigurationQuery {
  configuration {
    organisationContact
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organisationContact",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AddressStateWarningConfigurationQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AddressStateWarningConfigurationQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "d26bf2cec97558079e80934187583af9",
            "id": null,
            "metadata": {},
            "name": "AddressStateWarningConfigurationQuery",
            "operationKind": "query",
            "text": "query AddressStateWarningConfigurationQuery {\n  configuration {\n    organisationContact\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'dc6ce3451a2387d109e52592f391e694';
export default node;
