import { createToastMessage } from "components/shared/toast";
import { MessageDescriptor } from "react-intl";
import { actions as toastrActions, toastType } from "react-redux-toastr";
import { Action } from "redux";
import { ActionsObservable } from "redux-observable";
import { Observable } from "rxjs/internal/Observable";
import { filter } from "rxjs/internal/operators/filter";
import { map } from "rxjs/internal/operators/map";
import { withLatestFrom } from "rxjs/internal/operators/withLatestFrom";
import { RootState } from "store/store";
import { isActionOf } from "typesafe-actions";
import { ActionCreator } from "typesafe-actions/dist/type-helpers";

export type ToastType = toastType;

export const createToastEpic = <TActionType extends string>(actionCreator: ActionCreator<TActionType>, message: MessageDescriptor, type: ToastType = "success") =>
    (action$: ActionsObservable<Action<unknown>>, state$: Observable<RootState>) => action$.pipe(
        filter(isActionOf(actionCreator)),
        withLatestFrom(state$),
        filter(([, state]) => !!state.intl),
        map(([, state]) => {
            return toastrActions.add(createToastMessage({ type, message, intl: state.intl }));
        }));
