import { defineMessages } from "react-intl";

const idPrefix = "domain.address.";

export const addressMessages = defineMessages({
    noMatchingStateBody: {
        defaultMessage: "Please check the state entered before attempting to proceed again. If the state you have entered should be available, get in touch with your license issuer {organisationContact} so the state can be added to the list of applicable states.",
        id: idPrefix + "noMatchingState-body",
    },
    noMatchingStateTitle: {
        defaultMessage: "State could not be found",
        id: idPrefix + "noMatchingState-title",
    },
    unitNumberTemplateMessage: {
        defaultMessage: "Apt {unitNumber}, {streetNumber}",
        id: idPrefix + "unitnumbertemplate-message",
    },
});
