import { UserRoles } from "domain/user/models/userRoles";
import { UserAction } from "domain/user/userActions";
import { getToken } from "infrastructure/token";
import JwtDecode from "jwt-decode";
import { combineReducers, Reducer } from "redux";

const isLoggedInInitialState = !!getToken();

const isLoggedIn: Reducer = (state: boolean = isLoggedInInitialState, action: UserAction) => {
    if (action.type === "USER_SET_TOKEN") {
        return true;
    }

    if (action.type === "USER_LOGOUT_COMPLETE") {
        return false;
    }

    return state;
};

interface ITokenPayload {
    sub: string;
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": UserRoles | UserRoles[];
}

const roles = (state: UserRoles[] = [], action: UserAction) => {
    if (action.type === "USER_SET_TOKEN") {
        const token = getToken();

        let userRoles: UserRoles | UserRoles[] = [];

        if (token) {
            const tokenPayload = JwtDecode<ITokenPayload>(token);
            userRoles = tokenPayload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        }

        return Array.isArray(userRoles) ? userRoles : [userRoles];
    }

    if (action.type === "USER_LOGOUT_COMPLETE") {
        return [];
    }

    return state;
};

const postLoginRedirectType: Reducer = (state: string | null = null, action: UserAction) => {
    if (action.type === "USER_SET_POST_LOGIN_REDIRECT") {
        return action.payload;
    }

    return state;
};

export interface IUserState {
    isLoggedIn: boolean;
    postLoginRedirectType?: string;
    roles: UserRoles[];
}

export const combinedUserReducer = combineReducers<IUserState, UserAction>({
    isLoggedIn,
    postLoginRedirectType,
    roles,
});
