import { LoadingMessage } from "components/shared/LoadingMessage";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import graphql from "babel-plugin-relay/macro";
import { CardConnectForm } from "components/payment/CardConnectForm";
import { Donation } from "components/payment/Donation";
import { ConvergeProvider } from "components/payment/Elavon/Converge/ConvergeProvider";
import { GlobalPaymentsButton } from "components/payment/globalPayments/GlobalPaymentsButton";
import { GlobalPaymentsForm } from "components/payment/globalPayments/GlobalPaymentsForm";
import { PaymentForm } from "components/payment/PaymentForm";
import { PaymentRow } from "components/payment/PaymentRow";
import { PayPalForm } from "components/payment/PayPal/PayPalForm";
import { PayPalPayflowProvider } from "components/payment/PayPalPayflow/PayPalPayflowProvider";
import { StripeForm } from "components/payment/StripeForm";
import { StripeLoader } from "components/payment/StripeLoader";
import { TermsAndConditions } from "components/payment/TermsAndConditions";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { IPaymentState } from "domain/payment/combinedPaymentReducer";
import { paymentActions } from "domain/payment/paymentActions";
import { paymentMessages } from "domain/payment/paymentMessages";
import { PaymentQuery } from "generatedQueries/PaymentQuery.graphql";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import { Button, Card, CardText, CardTitle, Col } from "reactstrap";
import Table from "reactstrap/lib/Table";
import styles from "routes/payment/Payment.module.scss";
import { routes } from "routes/routes";
import { Query } from "shared/components/Query";
import { sharedMessages } from "shared/sharedMessages";
import { RootState } from "store/store";

export const Payment: FC = () => {
    const dispatch = useDispatch();
    const paymentState = useSelector<RootState, IPaymentState>(state => state.payment);
    const [paymentSucceeded, setPaymentSucceeded] = useState(false);
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);

    const {
        renewingAnimalsInCart,
    } = paymentState;

    useEffect(() => {
        if (paymentSucceeded) {
            dispatch(paymentActions.clearCart());
            setTermsAndConditionsAccepted(false);
        }
    }, [paymentSucceeded, dispatch]);

    if (paymentSucceeded) {
        return (
            <Card body={true} outline={true} color="success">
                <CardTitle>
                    <h6><FontAwesomeIcon icon={faCheck} className="mr-1" /><Message message={paymentMessages.paymentSuccessTitle} /></h6>
                </CardTitle>
                <CardText>
                    <Message message={paymentMessages.paymentSuccessMessage} />
                </CardText>
                <CardText>
                    <Message
                        message={paymentMessages.licenceSubjectToApprovalMessage}
                        values={{
                            pets: msg => <Link to={routes.pets.index.create({})}>{msg}</Link>,
                        }} />
                </CardText>
                <Button
                    color="secondary"
                    onClick={makeAnotherPaymentClicked}
                    className="makeAnotherPayment">
                    <Message message={paymentMessages.makeAnotherPaymentButtonLabel} />
                </Button>
            </Card>
        );
    }

    return <>
        <h2><Message message={paymentMessages.paymentPageTitle} /></h2>
        <hr />
        <Query<PaymentQuery>
            query={graphql`
                query PaymentQuery($animalIds: [ID!]!) {
                    licensingPaymentForm(animalIds: $animalIds) {
                        rows {
                            animalId
                            blocked
                            rabiesIsValid
                            ...PaymentRow_paymentRow
                        }
                        total
                        ...Donation_paymentForm
                        ...PayPalForm_paymentForm
                    }
                    configuration {
                        licensingPaymentMethods
                        stripePublishableKey
                        makeADonationEnabled
                        licensingPayPalClientId
                        currency
                        ...PaymentRow_configuration
                        ...Donation_configuration
                        ...CardConnectForm_configuration
                        ...ConvergeProvider_configuration
                        ...PaymentForm_configuration
                        ...PayPalForm_configuration
                        ...GlobalPaymentsButton_configuration
                        ...GlobalPaymentsForm_configuration
                        ...PayPalPayflowProvider_configuration
                    }
                    licensingContract {
                        text
                        contractId
                    }
                }`}
            variables={{ animalIds: renewingAnimalsInCart }}
            cacheConfig={{ force: true }}>
            {({ error, props: renderProps }) => {
                if (error) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                } else if (!renderProps) {
                    return <LoadingMessage />;
                }

                const {
                    configuration,
                    licensingContract,
                    licensingPaymentForm,
                } = renderProps;

                if (!configuration) {
                    return null;
                }

                const allPaymentRowsAreValid = !licensingPaymentForm?.rows.find(r => r.blocked);
                const animalIds = licensingPaymentForm?.rows.map(r => r.animalId).filter(distinctId) ?? [];
                const totalAmount = licensingPaymentForm?.total ?? 0;

                const isTermsAndConditionsAcceptedOrNotApplicable = termsAndConditionsAccepted || !(licensingContract?.text);

                return (
                    <div className="row justify-content-center">
                        {
                            licensingPaymentForm &&
                            licensingPaymentForm.rows.length > 0 &&
                            <div className="col-md-12">
                                <Table bordered={true}>
                                    <thead>
                                        <tr>
                                            <th><Message message={paymentMessages.tableColumnPetsLabel} /></th>
                                            <th><Message message={paymentMessages.tableColumnLicenseLabel} /></th>
                                            <th><Message message={paymentMessages.tableColumnAmountLabel} /></th>
                                            <th className={styles.paymentRowActions} />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {licensingPaymentForm.rows.map((paymentFormRow, index) =>
                                            <PaymentRow
                                                key={index}
                                                paymentRow={paymentFormRow}
                                                configuration={configuration} />)}
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            configuration.makeADonationEnabled &&
                            <Donation
                                paymentFormKey={licensingPaymentForm}
                                configurationKey={configuration}
                            />
                        }
                        <Col lg={8} md={12} className="mb-3">
                            <TermsAndConditions
                                contractText={licensingContract?.text ?? null}
                                onTermsAndConditionsAcceptanceChanged={setTermsAndConditionsAccepted} />
                        </Col>
                        <PaymentForm
                            configuration={configuration}
                            contractId={licensingContract?.contractId ?? null}
                            allPaymentRowsAreValid={allPaymentRowsAreValid}
                            animalIds={animalIds}
                            totalAmount={totalAmount}
                            termsAndConditionsAccepted={isTermsAndConditionsAcceptedOrNotApplicable}
                            onPaymentSucceeded={onPaymentSucceeded}>
                            {(paymentFormProps) => (
                                <>
                                    {
                                        configuration.licensingPaymentMethods[0] === "Stripe" &&
                                        <StripeLoader>
                                            <StripeProvider apiKey={configuration.stripePublishableKey}>
                                                <Elements>
                                                    <StripeForm {...paymentFormProps} />
                                                </Elements>
                                            </StripeProvider>
                                        </StripeLoader>
                                    }
                                    {
                                        configuration.licensingPaymentMethods[0] === "CardConnect" &&
                                        <CardConnectForm configuration={configuration} {...paymentFormProps} />
                                    }
                                    {
                                        configuration.licensingPaymentMethods[0] === "ElavonConverge" &&
                                        <ConvergeProvider
                                            configuration={configuration}
                                            termsAndConditionsAccepted={isTermsAndConditionsAcceptedOrNotApplicable}
                                            allPaymentRowsAreValid={allPaymentRowsAreValid}
                                            {...paymentFormProps}
                                        />
                                    }
                                    {
                                        configuration.licensingPaymentMethods[0] === "PayPal" &&
                                        <PayPalForm
                                            configuration={configuration}
                                            paymentForm={licensingPaymentForm}
                                            termsAndConditionsAccepted={isTermsAndConditionsAcceptedOrNotApplicable}
                                            allPaymentRowsAreValid={allPaymentRowsAreValid}
                                            clientId={configuration.licensingPayPalClientId}
                                            currency={configuration.currency}
                                            {...paymentFormProps}
                                        />
                                    }
                                    {
                                        configuration.licensingPaymentMethods[0] === "GlobalPayments" &&
                                        <GlobalPaymentsButton
                                            configuration={configuration}
                                            termsAndConditionsAccepted={isTermsAndConditionsAcceptedOrNotApplicable}
                                            allPaymentRowsAreValid={allPaymentRowsAreValid}
                                            {...paymentFormProps}>
                                            {(authoriseRequestJson, onPaymentSubmit) =>
                                                <GlobalPaymentsForm
                                                    authoriseRequestJson={authoriseRequestJson}
                                                    configuration={configuration}
                                                    onPaymentSubmit={onPaymentSubmit}
                                                    {...paymentFormProps}
                                                />
                                            }
                                        </GlobalPaymentsButton>
                                    }
                                    {
                                        configuration.licensingPaymentMethods[0] === "PayPalPayflow" &&
                                        <PayPalPayflowProvider
                                            configuration={configuration}
                                            termsAndConditionsAccepted={isTermsAndConditionsAcceptedOrNotApplicable}
                                            allPaymentRowsAreValid={allPaymentRowsAreValid}
                                            {...paymentFormProps}
                                        />
                                    }
                                </>
                            )}
                        </PaymentForm>
                    </div>
                );
            }}
        </Query>
    </>;

    function onPaymentSucceeded() {
        setPaymentSucceeded(true);
    }

    function makeAnotherPaymentClicked() {
        dispatch(paymentActions.clearCart());
        setPaymentSucceeded(false);
        setTermsAndConditionsAccepted(false);
    }
};

function distinctId(id: string, index: number, self: string[]) {
    return self.indexOf(id) === index;
}
