import { MessageDescriptor } from "react-intl";
import { ActionType, createAction } from "typesafe-actions";

const toastSuccess = createAction("TOAST_SUCCESS");

const toastFailure = createAction("TOAST_FAILURE");

const customSuccessToast = createAction("TOAST_CUSTOM_SUCCESS", resolve => (message: MessageDescriptor) => resolve(message));

const customFailureToast = createAction("TOAST_CUSTOM_FAILURE", resolve => (message: MessageDescriptor) => resolve(message));

export const toastActions = {
    customFailureToast,
    customSuccessToast,
    toastFailure,
    toastSuccess,
};

export type ToastAction = ActionType<typeof toastActions>;
