/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicensingSignInChallenge = "NEWPASSWORDREQUIRED" | "%future added value";
export type LicensingSignInError = "INVALIDUSERNAMEORPASSWORD" | "PASSWORDNOTCONFIRMED" | "PASSWORDRESETREQUIRED" | "%future added value";
export type LicensingSignInInput = {
    email: string;
    password: string;
};
export type signInMutationVariables = {
    input: LicensingSignInInput;
};
export type signInMutationResponse = {
    readonly licensingAccountSignIn: {
        readonly wasSuccessful: boolean;
        readonly jwtToken: string | null;
        readonly error: LicensingSignInError | null;
        readonly challenge: LicensingSignInChallenge | null;
        readonly challengeSession: string | null;
    } | null;
};
export type signInMutation = {
    readonly response: signInMutationResponse;
    readonly variables: signInMutationVariables;
};



/*
mutation signInMutation(
  $input: LicensingSignInInput!
) {
  licensingAccountSignIn(input: $input) {
    wasSuccessful
    jwtToken
    error
    challenge
    challengeSession
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "LicensingSignIn",
            "kind": "LinkedField",
            "name": "licensingAccountSignIn",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "wasSuccessful",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "jwtToken",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "challenge",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "challengeSession",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "signInMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "signInMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "31d5a16ef9719a80031eece19ee18dc2",
            "id": null,
            "metadata": {},
            "name": "signInMutation",
            "operationKind": "mutation",
            "text": "mutation signInMutation(\n  $input: LicensingSignInInput!\n) {\n  licensingAccountSignIn(input: $input) {\n    wasSuccessful\n    jwtToken\n    error\n    challenge\n    challengeSession\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'b4f277b42e7188fc59ca6131c86616ba';
export default node;
