import { IPaymentFormChildProps } from "components/payment/PaymentForm";
import React, { FC } from "react";

type Props = Pick<IPaymentFormChildProps, "payButton" | "errorMessages">;

export const StandardPaymentButtonLayout: FC<Props> = ({
    payButton,
    errorMessages,
}) => (
    <div className="form-group text-center">
        {payButton}
        {errorMessages}
    </div>
);
