import graphql from "babel-plugin-relay/macro";
import { PersonNameSuffixesPaginationQuery } from "generatedQueries/PersonNameSuffixesPaginationQuery.graphql";
import { PersonNameSuffixes_query, PersonNameSuffixes_query$key } from "generatedQueries/PersonNameSuffixes_query.graphql";
import { usePagedResultProvider } from "infrastructure/usePagedResultProvider";
import React, { FC } from "react";
import { FormSelect } from "shared/components/formInputs/FormSelect";
import { mapMessages } from "shared/mapMessages";
import { isNullOrWhiteSpace } from "shared/stringFunctions";

const messages = mapMessages("components.account.PersonNameSuffixes", {
    noPersonNameSuffixes: "No person name suffixes",
});

interface IProps {
    id?: string;
    queryKey: PersonNameSuffixes_query$key;
}

export const PersonNameSuffixes: FC<IProps> = ({
    id,
    queryKey,
}) => {
    const { data, isLoading } = usePagedResultProvider<PersonNameSuffixesPaginationQuery, PersonNameSuffixes_query$key>({
        fragmentInput: paginationQuery,
        parentFragmentRef: queryKey,
        pageSize: 50,
    });

    return <FormSelect
        id={id}
        className="input-person-name-suffix"
        isClearable={true}
        isSearchable={true}
        isLoading={isLoading}
        options={orderedPersonNameSuffixes(data)}
        field="personNameSuffixId"
        noOptionsMessage={messages.noPersonNameSuffixes}
    />;
};

function orderedPersonNameSuffixes(response: PersonNameSuffixes_query): { label: string, value: number }[] {
    const options = response.personNameSuffixes?.edges
        ?.filter(item => !isNullOrWhiteSpace(item.node.name))
        .map(item => ({
            label: item.node.name ?? "",
            value: item.node.displayId ?? item.node.name ?? "",
        })) ?? [];

    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
}

const paginationQuery = graphql`
    fragment PersonNameSuffixes_query on Query
    @refetchable(queryName: "PersonNameSuffixesPaginationQuery") {
        personNameSuffixes(first: $suffixesPageSize, after: $suffixesAfter, where: $suffixesWhere)
        @connection(key: "PersonNameSuffixes_query_personNameSuffixes") {
            edges {
                node {
                    id
                    displayId
                    name
                }
            }
        }
    }`;
