import { push } from "connected-react-router";
import { RedirectAction, redirectActions } from "domain/redirect/redirectActions";
import { ActionsObservable, combineEpics } from "redux-observable";
import { Observable } from "rxjs/internal/Observable";
import { filter } from "rxjs/internal/operators/filter";
import { map } from "rxjs/internal/operators/map";
import { withLatestFrom } from "rxjs/internal/operators/withLatestFrom";
import { RootState } from "store/store";
import { isActionOf } from "typesafe-actions";

const redirectEpic = (action$: ActionsObservable<RedirectAction>, state$: Observable<RootState>) => action$.pipe(
    filter(isActionOf(redirectActions.redirectTo)),
    withLatestFrom(state$),
    map(([action]) => {
        return push(action.payload);
    }));

export const redirectEpics = combineEpics(
    redirectEpic
);
