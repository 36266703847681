import { SpinnerButton } from "components/shared/SpinnerButton";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import { AnimalTagSearchResultRow } from "components/animal/AnimalTagSearch/AnimalTagSearchResultRow";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { AnimalTagSearchQuery } from "generatedQueries/AnimalTagSearchQuery.graphql";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Collapse, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Table } from "reactstrap";
import { routes } from "routes/routes";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

export const messages = mapMessages("components.animal.tagSearch", {
    animalTagNotFound: "There are no pets matching the tag provided. You can still <a>license your pet here</a> and provide the tag number in the identifications section.",
    searchError: "There was an error performing the search. You can still <a>license your pet here</a> and provide the tag number in the identifications section.",
    tagNumberLabel: "Tag number",
    tagNumberPlaceholder: "tag number",
    unableToFindPetSuggestion: "Unable to find your licensed pet?",
});

interface IProps {
    searchAnimalTagLabelToDisplay?: boolean;
}

export const AnimalTagSearch: FC<IProps> = ({ searchAnimalTagLabelToDisplay = true }) => {
    const intl = useIntl();

    const [shouldSearch, setShouldSearch] = useState(false);
    const [tagInput, setTagInput] = useState("");
    const [searchTag, setSearchTag] = useState("");

    return (
        <Query<AnimalTagSearchQuery>
            query={query}
            variables={{
                tag: searchTag,
                shouldSearch,
            }}>
            {({ error, props }) => {
                const isOpen = !!error || !!props;

                return (
                    <Card color="secondary" outline={true} className="mt-3">
                        <CardHeader className={classNames(!isOpen ? "border-bottom-0" : undefined)}>
                            <Form onSubmit={onSubmitTagSearch}>
                                <FormGroup row={true} className="mb-1">
                                    {
                                        searchAnimalTagLabelToDisplay &&
                                        <Label for="tag" xs="auto">
                                            <Message message={messages.unableToFindPetSuggestion} />
                                        </Label>
                                    }
                                    <Col xs="auto">
                                        <InputGroup>
                                            <Input
                                                data-cy={"tagNumberSearch"}
                                                name="tag"
                                                value={tagInput}
                                                onChange={onTagChange}
                                                placeholder={intl.formatMessage(messages.tagNumberPlaceholder)}
                                                aria-label={intl.formatMessage(messages.tagNumberLabel)}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <SpinnerButton
                                                    data-cy="search"
                                                    type="submit"
                                                    message={sharedMessages.searchButtonLabel}
                                                    isLoading={!error && shouldSearch && props === null}
                                                    color="secondary"
                                                    size="sm"
                                                    className="mr-2"
                                                />
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </CardHeader>
                        <CardBody className={classNames(!isOpen ? "pt-0 pb-0 d-none" : undefined)}>
                            <Collapse isOpen={isOpen}>
                                {
                                    error &&
                                    <ErrorMessage
                                        message={messages.searchError}
                                        messageValues={{
                                            a: msg => <Link to={routes.licence.index.create({})}>{msg}</Link>,
                                        }} />
                                }
                                {
                                    props?.animalTagSearch &&
                                    (
                                        props.animalTagSearch.length === 0
                                            ? <Message
                                                message={messages.animalTagNotFound}
                                                values={{
                                                    a: msg => <Link data-cy={"animalTagNotFoundRedirect"} to={routes.licence.index.create({})}>{msg}</Link>,
                                                }} />
                                            : <Table size="sm">
                                                <thead>
                                                    <tr>
                                                        <th><Message message={animalMessages.petListNameHeading} /></th>
                                                        <th><Message message={animalMessages.petListTypeHeading} /></th>
                                                        <th><Message message={animalMessages.petListBreedHeading} /></th>
                                                        <th />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.animalTagSearch.map((animal, index) => <AnimalTagSearchResultRow key={index} animal={animal} />)}
                                                </tbody>
                                            </Table>
                                    )
                                }
                            </Collapse>
                        </CardBody>
                    </Card>
                );
            }}
        </Query>
    );

    function onTagChange(e: React.ChangeEvent<HTMLInputElement>) {
        setTagInput(e.target.value.trim());
    }

    function onSubmitTagSearch(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!tagInput) {
            return;
        }

        setSearchTag(tagInput);
        setShouldSearch(true);
    }
};

const query = graphql`
    query AnimalTagSearchQuery($tag: String!, $shouldSearch: Boolean!) {
        animalTagSearch: licensingAnimalTagSearch(tag: $tag) @include(if: $shouldSearch) {
            ...AnimalTagSearchResultRow_animal
        }
    }`;
