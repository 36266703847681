import { defineMessages } from "react-intl";

const idPrefix = "domain.licence.";

export const licenceMessages = defineMessages({
    addLicencePageTitle: {
        defaultMessage: "License My Pet",
        id: idPrefix + "addlicence-title",
    },
    expiryDateLabel: {
        defaultMessage: "Expires on:",
        id: idPrefix + "expirydate-label",
    },
    invalidPaymentRowsMessage: {
        defaultMessage: "One or more licenses have problems. Please correct the problem(s) before proceeding with payment.",
        id: idPrefix + "invalidpaymentrows-message",
    },
    licenceDetailsLabel: {
        defaultMessage: "License Details",
        id: idPrefix + "licencedetails-label",
    },
    licenceTypeLabel: {
        defaultMessage: "Choose a license",
        id: idPrefix + "licencetype-label",
    },
    lifetimeLabel: {
        defaultMessage: "Lifetime",
        id: idPrefix + "lifetime-label",
    },
    monthLabel: {
        defaultMessage: "Month",
        id: idPrefix + "month-label",
    },
    rabiesWillExpireBeforeLicenceError: {
        defaultMessage: "Expiry date for the rabies vaccination is prior to the expiry of this license. You will require a current rabies vaccination before licensing this pet.",
        id: idPrefix + "rabieswillexpirebeforelicence-error",
    },
    renewLicenseUpdateDetailsTitle: {
        defaultMessage: "Update Details - {animalName}",
        id: idPrefix + "renewlicenseupdatedetails-title",
    },
    renewalFormLicenseTypeHeading: {
        defaultMessage: "License Type",
        id: idPrefix + "renewalformlicensetype-heading",
    },
    renewalFormNameHeading: {
        defaultMessage: "Pet",
        id: idPrefix + "renewalformname-heading",
    },
    selectLicensesTitle: {
        defaultMessage: "Select License Types",
        id: idPrefix + "selectlicenses-title",
    },
    unknownLicenceTypeLabel: {
        defaultMessage: "Unknown license type",
        id: idPrefix + "unknownlicencetype-label",
    },
});
