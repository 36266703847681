import React from "react";
import { FormatNumberOptions, FormattedNumber, IntlShape } from "react-intl";

export type IMoney = {
    readonly amount: number;
    readonly currency: string | null;
};

type IDisplayMoneyProps = {
    format?: string;
};

type IMoneyProps = IMoney & IDisplayMoneyProps;

const fractionDigitLength: number = 2;
const threeLetterIsoLength: number = 3;

export const Money: React.FC<IMoneyProps> = ({
    amount,
    currency,
    format,
}) => {
    return <FormattedNumber
        value={amount}
        currency={UseCurrencyOrDefault(currency)}
        format={format}
        maximumFractionDigits={fractionDigitLength}
        minimumFractionDigits={fractionDigitLength}
        // https://github.com/formatjs/formatjs/issues/785
        // eslint-disable-next-line react/style-prop-object
        style="currency" />;
};

export type FormatMoneyParts = {
    intl: IntlShape,
    money: IMoney,
    format?: string,
};

export const TryCurrencyFormat = (formatMoneyParts: FormatMoneyParts): string => {
    const options: FormatNumberOptions = {
        currency: UseCurrencyOrDefault(formatMoneyParts.money.currency),
        format: formatMoneyParts.format, style: "currency",
    };

    return formatMoneyParts.intl.formatNumber(formatMoneyParts.money.amount, options);
};

export const UseCurrencyOrDefault = (currency: string | null | undefined): string => {
    const defaultThreeLetterIsoCurrency: string = "usd";
    return currency && currency.length === threeLetterIsoLength ? currency : defaultThreeLetterIsoCurrency;
};
