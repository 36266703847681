import graphql from "babel-plugin-relay/macro";
import { confirmForgotPasswordMutation, confirmForgotPasswordMutationResponse } from "generatedQueries/confirmForgotPasswordMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation confirmForgotPasswordMutation($input: ConfirmForgotLicensingPasswordInput!) {
        confirmForgotLicensingPassword(input:$input) {
            wasSuccessful
            error
        }
    }`;

export function confirmForgotPassword(
    environment: IEnvironment,
    email: string,
    newPassword: string,
    code: string,
    onCompleted: (response: confirmForgotPasswordMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<confirmForgotPasswordMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                input: {
                    code,
                    email,
                    newPassword,
                },
            },
        }
    );
}
