/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountReadonlyAdditionalDetails_isSeniorCitizenLabel = {
    readonly value: string | null;
    readonly " $refType": "AccountReadonlyAdditionalDetails_isSeniorCitizenLabel";
};
export type AccountReadonlyAdditionalDetails_isSeniorCitizenLabel$data = AccountReadonlyAdditionalDetails_isSeniorCitizenLabel;
export type AccountReadonlyAdditionalDetails_isSeniorCitizenLabel$key = {
    readonly " $data"?: AccountReadonlyAdditionalDetails_isSeniorCitizenLabel$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountReadonlyAdditionalDetails_isSeniorCitizenLabel">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountReadonlyAdditionalDetails_isSeniorCitizenLabel",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ],
    "type": "SiteCustomisation",
    "abstractKey": null
} as any;
(node as any).hash = 'e08bac7a58e23e74d74a1da4f9b92241';
export default node;
