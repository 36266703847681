import { defineMessages } from "react-intl";

const idPrefix = "payment.";

export const paymentMessages = defineMessages({
    animalBlockedError: {
        defaultMessage: "Licensing this animal using this site is not possible, please contact {OrganisationContact} to license your pet. If you wish to renew other pets now, please deselect this pet on the My Pets page.",
        id: idPrefix + "animalblocked-error",
    },
    animalBlockedHeading: {
        defaultMessage: "Blocked",
        id: idPrefix + "animalblocked-heading",
    },
    animalBlockedTooltip: {
        defaultMessage: "Blocked, click the icon for more info.",
        id: idPrefix + "animalblocked-tooltip",
    },
    cardNumberPlaceholder: {
        defaultMessage: "Card number",
        id: idPrefix + "cardnumber-placeholder",
    },
    cvcPlaceholder: {
        defaultMessage: "CVC",
        id: idPrefix + "cvc-placeholder",
    },
    editButtonLabel: {
        defaultMessage: "Edit",
        id: idPrefix + "editbutton-label",
    },
    expiryDatePlaceholder: {
        defaultMessage: "MM / YY",
        id: idPrefix + "expirydate-placeholder",
    },
    invalidPaymentRowsWarningMessage: {
        defaultMessage: "One or more pets cannot currently be renewed, please contact {organisationContact} before proceeding.",
        id: idPrefix + "invalidpaymentrowswarning-message",
    },
    lateFeeMessage: {
        defaultMessage: "Late Fee",
        id: idPrefix + "lateFee-message",
    },
    licenceSubjectToApprovalMessage: {
        defaultMessage: "Licenses are pending and are subject to approval. You can view the current status of a license by viewing <pets>My Pets</pets>.",
        id: idPrefix + "licenceSubjectToApproval-message",
    },
    makeAnotherPaymentButtonLabel: {
        defaultMessage: "Make another payment",
        id: idPrefix + "makeanotherpaymentbutton-label",
    },
    noLicencesMessage: {
        defaultMessage: "You have no licenses waiting to be paid",
        id: idPrefix + "noLicences-message",
    },
    nothingToPayWarningMessage: {
        defaultMessage: "There is nothing to pay for. Please either select a license to pay for on the <pets>My Pets</pets> page, <add>add a new pet</add> or enter a donation amount above before proceeding again.",
        id: idPrefix + "nothingtopaywarning-message",
    },
    payButtonLabel: {
        defaultMessage: "Pay",
        id: idPrefix + "paybutton-label",
    },
    payHeaderLabel: {
        defaultMessage: "Payment Details",
        id: idPrefix + "payheader-label",
    },
    payPalConfigurationErrorMessage: {
        defaultMessage: "PayPal has not been configured correctly. Please try refreshing the page and if the problem persists, please contact {organisationContact}.",
        id: idPrefix + "paypalconfigurationerror-message",
    },
    payPalOrderIdMissingErrorMessage: {
        defaultMessage: "The PayPal payment was approved but was missing the order ID. Please contact {organisationContact} before attempting to pay again.",
        id: idPrefix + "paypalorderidmissingerror-message",
    },
    payPalSdkLoadFailedErrorMessage: {
        defaultMessage: "There was an error displaying the PayPal buttons. Please try again later.",
        id: idPrefix + "paypalsdkloadfailederror-message",
    },
    payPalUnhandledErrorMessage: {
        defaultMessage: "There was an error displaying the PayPal form. Please try refreshing the page and if the problem persists, please contact {organisationContact}.",
        id: idPrefix + "paypalunhandlederror-message",
    },
    paymentIsProcessing: {
        defaultMessage: "Payment is processing",
        id: idPrefix + "paymentisprocessing-message",
    },
    paymentNavbarTitle: {
        defaultMessage: "Payment",
        id: idPrefix + "paynavbar-title",
    },
    paymentPageTitle: {
        defaultMessage: "Payment Overview",
        id: idPrefix + "paymentpage-title",
    },
    paymentSuccessMessage: {
        defaultMessage: "A receipt for the payment will be sent to you via email shortly.",
        id: idPrefix + "paymentsuccess-message",
    },
    paymentSuccessTitle: {
        defaultMessage: "Your payment was successful",
        id: idPrefix + "paymentsuccess-title",
    },
    removeButtonLabel: {
        defaultMessage: "Remove",
        id: idPrefix + "removebutton-label",
    },
    renewalMessage: {
        defaultMessage: "Renewal",
        id: idPrefix + "renewal-message",
    },
    tableColumnAmountLabel: {
        defaultMessage: "Amount",
        id: idPrefix + "tablecolumnamount-label",
    },
    tableColumnLicenseLabel: {
        defaultMessage: "License",
        id: idPrefix + "tablecolumnlicense-label",
    },
    tableColumnPetsLabel: {
        defaultMessage: "Pets",
        id: idPrefix + "tablecolumnpets-label",
    },
    termsAndConditionsNotAcceptedWarningMessage: {
        defaultMessage: "Terms and Conditions must be accepted before you can proceed with purchasing online.",
        id: idPrefix + "termsandconditionsnotacceptedwarning-message",
    },
    unableToRemoveFromPaymentTooltip: {
        defaultMessage: "This license is unable to be removed as it is a new license that has not yet been paid for.",
        id: idPrefix + "unabletoremovefrompayment-tooltip",
    },
    validationWarningMessage: {
        defaultMessage: "There is a problem with the details entered. Please check the card details before submitting the payment.",
        id: idPrefix + "validationwarning-message",
    },
    zipCodePlaceholder: {
        defaultMessage: "Zip",
        id: idPrefix + "zipcode-placeholder",
    },
});
