import { LoadingMessage } from "components/shared/LoadingMessage";
import { SpinnerButton } from "components/shared/SpinnerButton";
import graphql from "babel-plugin-relay/macro";
import { Message } from "shared/components/Message";
import { ChallengeError } from "components/user/ChallengeError";
import { LoginError } from "components/user/LoginError";
import { NewPasswordRequiredChallengeFields } from "components/user/NewPasswordRequiredChallengeFields";
import { IUserState } from "domain/user/combinedUserReducer";
import { respondToNewPasswordRequiredChallenge } from "domain/user/respondToNewPasswordRequiredChallenge";
import { signIn } from "domain/user/signIn";
import { userActions } from "domain/user/userActions";
import { LoginQuery } from "generatedQueries/LoginQuery.graphql";
import { RespondToLicensingSignInChallengeError, respondToNewPasswordRequiredChallengeMutationResponse } from "generatedQueries/respondToNewPasswordRequiredChallengeMutation.graphql";
import { LicensingSignInChallenge, LicensingSignInError, signInMutationResponse } from "generatedQueries/signInMutation.graphql";
import { setToken } from "infrastructure/token";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { Col, Form, FormText, Row } from "reactstrap";
import { routes } from "routes/routes";
import { TogglePasswordButton } from "routes/user/TogglePasswordButton";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { TextBox } from "shared/components/newFormInputs/TextBox";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { required } from "shared/validations/validations";
import { RootState } from "store/store";

export const messages = mapMessages("routes.user.login", {
    emailAddressLabel: "Email",
    passwordLabel: "Password",
    signInButton: "Sign In",
    signUpSuggestion: "Need an account? <s>Sign up</s> / <f>Forgot Password</f>",
    title: "Sign In",
    togglePasswordButton: "Toggle Password Display",
});

export interface IForm {
    email: string;
    firstName?: string;
    lastName?: string;
    password: string;
    newPassword?: string;
}

export const Login: FC = () => {
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();
    const userState = useSelector<RootState, IUserState>(state => state.user);
    const methods = useForm<IForm>({
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signInFailed, setSignInFailed] = useState<boolean | LicensingSignInError>(false);
    const [challengeResponseFailed, setChallengeResponseFailed] = useState<boolean | RespondToLicensingSignInChallengeError>(false);
    const [challenge, setChallenge] = useState<{ challenge: LicensingSignInChallenge, session: string }>();
    const [displayPassword, setDisplayPassword] = useState(false);

    const emailWatch = methods.watch("email");
    const passwordWatch = methods.watch("password");

    useEffect(() => {
        setSignInFailed(false);
    }, [emailWatch, passwordWatch]);

    const {
        isLoggedIn,
        postLoginRedirectType,
    } = userState;

    return isLoggedIn
        ? <Redirect to={postLoginRedirectType ? routes.pets.index.create({}) : routes.home.create({})} />
        : <Query<LoginQuery>
            query={query}
            variables={{}}>
            {({ error, props }) => {
                if (!props && !error) {
                    return <LoadingMessage />;
                }

                return <>
                    <Row className="justify-content-center">
                        <Col xl={5} lg={6} md={7} sm={9}>
                            <h2><Message message={messages.title} /></h2>

                            <FormProvider
                                {...methods}
                            >
                                <Form
                                    onSubmit={methods.handleSubmit(onSignInSubmitted)}
                                    noValidate={true}
                                    autoComplete="off"
                                    role="form"
                                >
                                    <FormLabel
                                        message={messages.emailAddressLabel}
                                        for="email"
                                        required={true}
                                    >
                                        <TextBox
                                            id="email"
                                            name="email"
                                            register={methods.register}
                                            control={methods.control}
                                            type="email"
                                            options={required<IForm, "email">()}
                                            aria-required={true}
                                        />
                                    </FormLabel>
                                    <FormLabel
                                        message={messages.passwordLabel}
                                        for="password"
                                        required={true}
                                    >
                                        <TextBox
                                            id="password"
                                            name="password"
                                            register={methods.register}
                                            control={methods.control}
                                            type={displayPassword ? "text" : "password"}
                                            options={required<IForm, "email">()}
                                            aria-required={true}
                                        >
                                            {{
                                                append: (
                                                    <TogglePasswordButton
                                                        displayPassword={displayPassword}
                                                        onClick={togglePassword}
                                                        label={messages.togglePasswordButton}
                                                    />
                                                ),
                                            }}
                                        </TextBox>
                                    </FormLabel>
                                    <NewPasswordRequiredChallengeFields
                                        isDisplayed={challenge?.challenge === "NEWPASSWORDREQUIRED"}
                                        configuration={props?.configuration ?? null}
                                    />
                                    <LoginError
                                        error={signInFailed}
                                    />
                                    <ChallengeError
                                        error={challengeResponseFailed}
                                    />
                                    <SpinnerButton
                                        className="float-right"
                                        color="primary"
                                        isLoading={isSubmitting}
                                        message={messages.signInButton}
                                        data-cy="signIn"
                                    />
                                    <FormText>
                                        <Message
                                            message={messages.signUpSuggestion}
                                            values={{
                                                f: msg => <Link to={routes.user.forgotPassword.create({})}>{msg}</Link>,
                                                s: msg => <Link to={routes.user.signUp.create({})}>{msg}</Link>,
                                            }}
                                        />
                                    </FormText>
                                </Form>
                            </FormProvider>
                        </Col>
                    </Row>
                </>;
            }}
        </Query>;

    function onSignInSubmitted({
        email,
        firstName,
        lastName,
        password,
        newPassword,
    }: IForm) {
        setIsSubmitting(true);
        setSignInFailed(false);

        if (challenge?.challenge === "NEWPASSWORDREQUIRED") {
            respondToNewPasswordRequiredChallenge(
                environment,
                email,
                newPassword ?? "",
                challenge.session,
                firstName ?? null,
                lastName ?? null,
                onSignInCompleted,
                onError(setChallengeResponseFailed));
        } else {
            signIn(
                environment,
                email,
                password,
                onSignInCompleted,
                onError(setSignInFailed));
        }
    }

    function onSignInCompleted(mutationResponse: signInMutationResponse & respondToNewPasswordRequiredChallengeMutationResponse) {
        setIsSubmitting(false);

        const response = {
            wasSuccessful: false,
            jwtToken: null,
            challenge: null,
            challengeSession: null,
            ...mutationResponse.licensingAccountSignIn,
            ...mutationResponse.respondToNewPasswordRequiredLicensingSignInChallenge,
        };

        if (response.wasSuccessful && response.jwtToken) {
            setToken(response.jwtToken);
            dispatch(userActions.setToken(response.jwtToken));
        } else if (response.challenge && response.challengeSession) {
            setChallenge({
                challenge: response.challenge,
                session: response.challengeSession,
            });
        } else {
            if (mutationResponse.respondToNewPasswordRequiredLicensingSignInChallenge) {
                setChallengeResponseFailed(mutationResponse.respondToNewPasswordRequiredLicensingSignInChallenge?.error ?? true);
            } else {
                setSignInFailed(mutationResponse.licensingAccountSignIn?.error ?? true);
            }
        }
    }

    function onError<T>(stateSetter: Dispatch<SetStateAction<boolean | T>>) {
        return () => {
            setIsSubmitting(false);
            stateSetter(true);
        };
    }

    function togglePassword(newDisplayPassword: boolean) {
        setDisplayPassword(newDisplayPassword);
    }
};

const query = graphql`
    query LoginQuery {
        configuration {
            ...NewPasswordRequiredChallengeFields_configuration
        }
    }`;
