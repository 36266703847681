import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import styles from "components/animal/AnimalRow.module.scss";
import { AnimalStatus } from "components/animal/AnimalStatus";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { AnimalRow_animal } from "generatedQueries/AnimalRow_animal.graphql";
import { AnimalRow_configuration } from "generatedQueries/AnimalRow_configuration.graphql";
import moment from "moment";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { Input } from "reactstrap";
import { routes } from "routes/routes";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("components.animal.AnimalRow", {
    checkboxLabel: "Select {animalName} for bulk action",
});

interface IGraphQlProps {
    animal: AnimalRow_animal;
    configuration: AnimalRow_configuration | null;
}

interface IProps {
    isSelected: boolean;
    scrollToRowOnRender: boolean;
    animalSelectionChanged: (selected: boolean) => void;
}

const InternalAnimalRow: FC<IProps & IGraphQlProps> = ({
    animal,
    configuration,
    isSelected,
    scrollToRowOnRender,
    animalSelectionChanged,
}) => {
    const intl = useIntl();
    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        if (scrollToRowOnRender && !hasScrolled) {
            scroller.scrollTo(`animalRow-name-${animal.id}`, {
                duration: 800,
                delay: 500,
                smooth: "easeInOutQuart",
                offset: -100,
            });
            setHasScrolled(true);
        }
    }, [animal.id, hasScrolled, scrollToRowOnRender]);

    let warningMessage: MessageDescriptor | null;

    switch (animal.status) {
        case "CANCELLED":
            warningMessage = animalMessages.cancelledLicensingWarningMessage;
            break;

        case "REJECTED":
            warningMessage = animalMessages.rejectedLicenceWarningMessage;
            break;

        default:
            warningMessage = null;
    }

    return <>
        <tr
            className={classNames(warningMessage && "table-warning")}
        >
            <td className="selectable">
                <Input
                    className={classNames("form-control", styles.bulkSelect)}
                    type="checkbox"
                    onChange={onSelectedChange}
                    checked={isSelected}
                    aria-label={intl.formatMessage(messages.checkboxLabel, { animalName: animal.name })}
                />
            </td>
            {renderCell(
                <Element
                    name={`animalRow-name-${animal.id}`}
                >
                    {animal.name}
                </Element>
            )}
            {renderCell(renderAnimalTypeDescription())}
            {renderCell(renderAnimalBreeds())}
            {renderCell(renderLicenceDescription())}
            {renderCell(renderLicenceExpiry())}
            {renderCell(<AnimalStatus animal={animal} configuration={configuration} />)}
        </tr>
        {
            warningMessage &&
            <tr className={styles.warningMessage}>
                <td />
                <td colSpan={6}>
                    <Message
                        message={warningMessage}
                        values={{ OrganisationContact: configuration?.organisationContact ?? intl.formatMessage(sharedMessages.defaultOrganisationContactMessage) }} />
                </td>
            </tr>
        }
    </>;

    function onSelectedChange(event: ChangeEvent<HTMLInputElement>) {
        animalSelectionChanged(event.target.checked);
    }

    function renderCell(children: React.ReactNode) {
        return (
            <td className="selectable">
                <Link to={routes.pets.pet.create({ id: animal!.id })}>
                    {children}
                </Link>
            </td>
        );
    }

    function renderAnimalTypeDescription() {
        return animal.animalType.description || <Message message={animalMessages.animalTypeNotFoundMessage} />;
    }

    function renderAnimalBreeds() {
        return animal.secondaryBreed
            ? `${animal.breed.name}, ${animal.secondaryBreed.name}`
            : animal.breed.name;
    }

    function renderLicenceExpiry() {
        if (!animal.currentLicence || animal.status === "BLOCKED") {
            return null;
        }

        if (animal.currentLicence.isLifetime) {
            return <Message message={animalMessages.petListLifetimeLabel} />;
        }

        return animal.currentLicence.expiryDate && moment(animal.currentLicence.expiryDate).format("L");
    }

    function renderLicenceDescription() {
        if (animal.status === "BLOCKED") {
            return null;
        }

        if (animal.currentLicence) {
            return animal.currentLicence.description;
        }

        return animal.licenceType
            ? animal.licenceType.description
            : null;
    }
};

export const AnimalRow = createFragmentContainer(InternalAnimalRow, {
    animal: graphql`
        fragment AnimalRow_animal on LicensingAnimal {
            id
            name
            breed {
                name
            }
            secondaryBreed {
                name
            }
            status
            licenceType {
                description
            }
            currentLicence {
                id
                description
                isLifetime
                expiryDate
            }
            animalType {
                id
                description
            }
            ...AnimalStatus_animal
        }`,
    configuration: graphql`
        fragment AnimalRow_configuration on Configuration {
            organisationContact
            ...AnimalStatus_configuration
        }`,
});
