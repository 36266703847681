import { combine, maxLength, required } from "components/shared/validations";
import graphql from "babel-plugin-relay/macro";
import { AddressType } from "components/address/AddressForm";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { accountMessages } from "domain/account/accountMessages";
import { AddressFieldsQuery } from "generatedQueries/AddressFieldsQuery.graphql";
import React, { FC } from "react";
import { Col, Row } from "reactstrap";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { FormSelect } from "shared/components/formInputs/FormSelect";
import { TextBox } from "shared/components/formInputs/TextBox";
import { Query } from "shared/components/Query";
import { ISelectOption } from "shared/components/ReactSelect";
import { sharedMessages } from "shared/sharedMessages";

interface IProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    addressType: AddressType;
    displayPoBoxFields?: boolean;
}

export const AddressFields: FC<IProps> = ({ onChange, addressType, displayPoBoxFields }) => {
    const scope = addressType === AddressType.Physical ? "physical-address" : "postal-address";

    return (
        <Row form={true} data-testid="AddressFields">
            {
                displayPoBoxFields &&
                <>
                    <Col md={7}>
                        <FormLabel
                            message={accountMessages.deliveryTypeLabel}
                            required={true}
                            for={`${scope}-delivery-type`}
                        >
                            <Query<AddressFieldsQuery>
                                query={graphql`
                                    query AddressFieldsQuery {
                                        deliveryTypes {
                                            edges {
                                                node {
                                                    displayId
                                                    name
                                                }
                                            }
                                        }
                                    }`}
                                variables={{}}>
                                {({ error, props }) => {
                                    if (error) {
                                        return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                                    }

                                    const options: ISelectOption[] = (props && props.deliveryTypes && props.deliveryTypes.edges && props.deliveryTypes.edges.filter(d => !!d.node.name).map(d => {
                                        return {
                                            label: d.node.name!,
                                            value: d.node.displayId,
                                        };
                                    })) || [];

                                    return <FormSelect
                                        id={`${scope}-delivery-type`}
                                        field="deliveryTypeId"
                                        className={`${scope}-delivery-type`}
                                        onChange={onChange}
                                        options={options}
                                        isSearchable={false}
                                        isLoading={!props}
                                        validate={required} />;
                                }}
                            </Query>
                        </FormLabel>
                    </Col>
                    <Col md={5}>
                        <FormLabel
                            message={accountMessages.deliveryNumberLabel}
                            required={true}
                            for={`${scope}-delivery-number`}
                        >
                            <TextBox
                                id={`${scope}-delivery-number`}
                                field="deliveryNumber"
                                onChange={onChange}
                                validate={combine(maxLength(10), required)}
                                className={`${scope}-delivery-number`}
                            />
                        </FormLabel>
                    </Col>
                </>
            }
            {
                !displayPoBoxFields &&
                <>
                    <Col md={12}>
                        <FormLabel
                            message={accountMessages.addressLineLabel}
                            required={true}
                            for={`${scope}-address-line`}
                        >
                            <TextBox
                                id={`${scope}-address-line`}
                                field="addressLine"
                                validate={combine(required, maxLength(500))}
                                onChange={onChange}
                                className={`${scope}-address-line`}
                            />
                        </FormLabel>
                    </Col>
                </>
            }
            <Col md={5}>
                <FormLabel
                    message={accountMessages.suburbLabel}
                    required={true}
                    for={`${scope}-suburb`}
                >
                    <TextBox
                        id={`${scope}-suburb`}
                        field="suburb"
                        validate={combine(required, maxLength(100))}
                        onChange={onChange}
                        className={`${scope}-suburb`}
                    />
                </FormLabel>
            </Col>
            <Col md={4}>
                <FormLabel
                    message={accountMessages.stateLabel}
                    required={true}
                    for={`${scope}-state`}
                >
                    <TextBox
                        id={`${scope}-state`}
                        field="state"
                        validate={combine(required, maxLength(50))}
                        onChange={onChange}
                        className={`${scope}-state`}
                    />
                </FormLabel>

            </Col>
            <Col md={3}>
                <FormLabel
                    message={accountMessages.postcodeLabel}
                    required={true}
                    for={`${scope}-postcode`}
                >
                    <TextBox
                        id={`${scope}-postcode`}
                        field="postcode"
                        validate={combine(required, maxLength(10))}
                        onChange={onChange}
                        className={`${scope}-postcode`}
                    />
                </FormLabel>
            </Col>
        </Row>
    );
};
