import { Message } from "shared/components/Message";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardText } from "reactstrap";

interface ILicenceOptionCardProps {
    className?: string;
    navDescriptionText: MessageDescriptor;
    navButtonText: MessageDescriptor;
    navigateTo: string;
}

export const LicenceOptionCard: FC<ILicenceOptionCardProps> = ({ navDescriptionText, navButtonText, navigateTo, className }) => {

    const buttonRef = className ? className.concat("Button") : "";

    return <Card body={true} outline={true} className="mx-auto my-2">
        <CardBody>
            <CardText><Message message={navDescriptionText} /></CardText>
            <br />
            <Link to={navigateTo}>
                <Button data-cy={buttonRef} block={true} color={"primary"}><Message message={navButtonText} /></Button>
            </Link>
        </CardBody >
    </Card >;
};
