import { defineMessages } from "react-intl";

const idPrefix = "domain.lostPet.";

export const lostPetMessages = defineMessages({
    animalTypeResultLabel: {
        defaultMessage: "Animal Type",
        id: idPrefix + "animaltyperesult-label",
    },
    breedResultLabel: {
        defaultMessage: "Breed",
        id: idPrefix + "breedresult-label",
    },
    homePhonePillLabel: {
        defaultMessage: "Home",
        id: idPrefix + "homephonepill-label",
    },
    lostPetSearchPageMessage: {
        defaultMessage: "Use the search below to search the tag number of a pet you have found to reunite the pet with its owner.",
        id: idPrefix + "lostpetsearchpage-message",
    },
    lostPetSearchPageTitle: {
        defaultMessage: "Lost Pet Details Search",
        id: idPrefix + "lostpetsearchpage-title",
    },
    mobilePhonePillLabel: {
        defaultMessage: "Cell",
        id: idPrefix + "mobilephonepill-label",
    },
    noPhoneNumbersMessage: {
        defaultMessage: "Sorry, we don't have any phone number for this owner. Please contact {organisationContact} as there may be further options to get into contact with the owner.",
        id: idPrefix + "nophonenumbers-message",
    },
    ownerNameResultLabel: {
        defaultMessage: "Owner's Name",
        id: idPrefix + "ownernameresult-label",
    },
    ownerPhoneNumbersResultLabel: {
        defaultMessage: "Phone Numbers",
        id: idPrefix + "ownerphonenumbersresult-label",
    },
    petResultHeading: {
        defaultMessage: "<b>Pet's Name:</b> {animalName} - <b>Tag Number:</b> {tagNumber}",
        id: idPrefix + "petresult-heading",
    },
    phoneResultMessage: {
        defaultMessage: "{type} {number}",
        id: idPrefix + "phoneresult-message",
    },
    recaptchaErrorMessage: {
        defaultMessage: "reCAPTCHA failed, please try again",
        id: idPrefix + "recaptcha-errormessage",
    },
    tagNumberSearchLabel: {
        defaultMessage: "Tag Number",
        id: idPrefix + "tagnumbersearch-label",
    },
});
