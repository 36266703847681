import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { AccountLicenceRenewalForm } from "components/account/AccountLicenceRenewalForm";
import { AnimalDetailsRenewalForm } from "components/animal/AnimalDetailsRenewalForm";
import { BulkLicenceRenewalForm } from "components/licence/BulkLicenceRenewalForm";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { licenceMessages } from "domain/licence/licenceMessages";
import { LicenceRenewalQuery } from "generatedQueries/LicenceRenewalQuery.graphql";
import { LocationState } from "history";
import "rc-steps/assets/iconfont.css";
import "rc-steps/assets/index.css";
import React, { FC, Fragment } from "react";
import { useIntl } from "react-intl";
import { Redirect, useLocation } from "react-router";
import { routes } from "routes/routes";
import { Query } from "shared/components/Query";
import { Wizard } from "shared/components/Wizard";
import { WizardPage } from "shared/components/WizardPage";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

export interface ILicenceRenewalLocationState {
    animalIds: string[];
    hasReviewedAccountDetails: boolean;
}

const messages = mapMessages("routes.licence.licenceRenewal", {
    formName: "License renewal wizard",
    updateDetailsStepDescriptionLabel: "Update Details",
    renewLicensesPageTitle: "Renew Licenses",
    reviewAccountDetails: "Review Account Details",
});

export const LicenceRenewal: FC = () => {
    const { formatMessage } = useIntl();
    const location = useLocation<ILicenceRenewalLocationState | LocationState>();

    const animalIds = isLicenceRenewalLocationState(location.state) ? location.state.animalIds : [];

    if (animalIds.length === 0) {
        return <Redirect to={routes.pets.index.create({})} />;
    }

    return <Fragment>
        <h2>
            <Message message={messages.renewLicensesPageTitle} />
        </h2>
        <hr />
        <Query<LicenceRenewalQuery>
            query={query}
            variables={{}}>
            {({ error, props }) => {
                if (error) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                if (!props) {
                    return <LoadingMessage />;
                }

                const { account } = props;

                if (!account || !account.physicalAddress || !account.mailingAddress || !account.physicalAddress.jurisdictionId) {
                    return <Redirect to={routes.account.edit.create({})} />;
                }

                const animals = account.animals?.edges?.map(edge => edge.node).filter(a => animalIds.indexOf(a.id) > -1) ?? [];

                if (animals.length === 0) {
                    return <Redirect to={routes.pets.index.create({})} />;
                }

                const hasReviewedAccountDetails = isLicenceRenewalLocationState(location.state) && location.state.hasReviewedAccountDetails;

                return <Wizard
                    initialStep={hasReviewedAccountDetails ? 1 : undefined}
                    formName={formatMessage(messages.formName)}
                >
                    <WizardPage title={messages.reviewAccountDetails}>
                        {({ onNext }) => <AccountLicenceRenewalForm onNext={onNext} animalIds={animalIds} />}
                    </WizardPage>
                    {animals.map((animal) => <WizardPage
                        key={animal.id}
                        title={animal.name}
                        description={messages.updateDetailsStepDescriptionLabel}>
                        {({ onNext, onPrevious }) => <AnimalDetailsRenewalForm
                            key={animal.id}
                            animalId={animal.id}
                            onBack={onPrevious}
                            onNext={onNext} />}
                    </WizardPage>)}
                    <WizardPage
                        title={licenceMessages.selectLicensesTitle}>
                        {({ onPrevious }) => (
                            <BulkLicenceRenewalForm
                                onBack={onPrevious}
                                animalIds={animalIds}
                            />
                        )}
                    </WizardPage>
                </Wizard>;
            }}
        </Query>
    </Fragment>;
};

function isLicenceRenewalLocationState(state: LocationState): state is ILicenceRenewalLocationState {
    if (!state) {
        return false;
    }

    return (state as ILicenceRenewalLocationState).hasReviewedAccountDetails !== undefined;
}

const query = graphql`
query LicenceRenewalQuery {
    account {
        animals {
            edges {
                node {
                    id
                    name
                }
            }
        }
        physicalAddress {
            id
            jurisdictionId
        }
        mailingAddress {
            ... on LicensingAddress {
                id
            }
        }
    }
}`;
