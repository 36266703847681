import { Message } from "shared/components/Message";
import { Spinner } from "components/shared/Spinner";
import React, { AriaAttributes, FC } from "react";
import { MessageDescriptor } from "react-intl";

interface IProps {
    message?: MessageDescriptor;
}

const defaultMessage: MessageDescriptor = {
    defaultMessage: "Loading...",
    id: "loading.message",
};

export const LoadingMessage: FC<IProps & AriaAttributes> = ({
    message,
    ...ariaAttributes
}) => (
    <div
        role="progressbar"
        {...ariaAttributes}
    >
        <Spinner className="mr-1" />
        <Message message={message || defaultMessage} />
    </div>
);
