import React, { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { TogglePasswordButton } from "routes/user/TogglePasswordButton";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { TextBox } from "shared/components/newFormInputs/TextBox";
import { mapMessages } from "shared/mapMessages";
import { combine, isPassword, required } from "shared/validations/validations";

export const messages = mapMessages("routes.user.passwordentry", {
    confirmPasswordLabel: "Confirm Password",
    newPasswordLabel: "New Password",
    passwordDoesNotMatchError: "Passwords do not match",
    passwordLabel: "Password",
    toggleConfirmPasswordButton: "Toggle Confirm Password Display",
    toggleNewPasswordButton: "Toggle New Password Display",
});

interface IProps {
    passwordField: "newPassword" | "password";
    passwordValidationRegex: string | undefined;
}

export const PasswordEntryFields: FC<IProps> = ({
    passwordField,
    passwordValidationRegex,
}) => {
    const { formatMessage } = useIntl();
    const { register, control, watch } = useFormContext();
    const [displayNewPassword, setDisplayNewPassword] = useState(false);
    const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);

    const passwordFieldValue = watch(passwordField);

    return <>
        <FormLabel
            message={passwordField === "newPassword" ? messages.newPasswordLabel : messages.passwordLabel}
            for={passwordField}
            required={true}
        >
            <TextBox
                id={passwordField}
                name={passwordField}
                register={register}
                control={control}
                type={displayNewPassword ? "text" : "password"}
                options={combine(
                    required(),
                    isPassword(passwordValidationRegex)
                )}
                autoComplete="off"
                aria-required={true}
            >
                {{
                    append: <TogglePasswordButton displayPassword={displayNewPassword} onClick={toggleNewPassword} label={messages.toggleNewPasswordButton} />,
                }}
            </TextBox>
        </FormLabel>
        <FormLabel
            message={messages.confirmPasswordLabel}
            for="confirmPassword"
            required={true}
        >
            <TextBox
                id="confirmPassword"
                name="confirmPassword"
                register={register}
                control={control}
                type={displayConfirmPassword ? "text" : "password"}
                options={combine(
                    required(),
                    {
                        deps: [passwordField],
                        validate: {
                            confirmPasswordValue: (value) => value !== passwordFieldValue
                                ? formatMessage(messages.passwordDoesNotMatchError)
                                : undefined,
                        },
                    }
                )}
                autoComplete="off"
                aria-required={true}
            >
                {{
                    append: <TogglePasswordButton displayPassword={displayConfirmPassword} onClick={toggleConfirmPassword} label={messages.toggleConfirmPasswordButton} />,
                }}
            </TextBox>
        </FormLabel>
    </>;

    function toggleNewPassword(newDisplayPassword: boolean) {
        setDisplayNewPassword(newDisplayPassword);
    }

    function toggleConfirmPassword(newDisplayPassword: boolean) {
        setDisplayConfirmPassword(newDisplayPassword);
    }
};
