import { Message } from "shared/components/Message";
import "rc-steps/assets/iconfont.css";
import "rc-steps/assets/index.css";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { Button, Col, Row } from "reactstrap";
import { sharedMessages } from "shared/sharedMessages";

interface IAccountWizardNavigationContent {
    primaryButtonDescription: MessageDescriptor;
}

export interface IAccountWizardNavigation {
    onPrevious: () => void;
    onNext: () => void;
}

export const AccountWizardNavigation: FC<IAccountWizardNavigation & IAccountWizardNavigationContent> = ({
    onPrevious,
    onNext,
    primaryButtonDescription,
}) => <>
        <Col sm={{ size: 8, offset: 2 }} data-testid="AccountWizardNavigation">
            <Row>
                <Col>
                    <Button
                        onClick={onPrevious}
                        color="secondary"
                    >
                        <Message message={sharedMessages.backButtonLabel} />
                    </Button>
                </Col>
                <Col>
                    <Button
                        onClick={onNext}
                        color="primary"
                        className="float-right"
                    >
                        <Message message={primaryButtonDescription} />
                    </Button>
                </Col>
            </Row>
        </Col>
    </>;
