import { LoadingMessage } from "components/shared/LoadingMessage";
import { SpinnerButton } from "components/shared/SpinnerButton";
import graphql from "babel-plugin-relay/macro";
import { Message } from "shared/components/Message";
import { ForgotPasswordError, GeneralForgotPasswordErrorCode } from "components/user/ForgotPasswordError";
import { toastActions } from "domain/toast/toastActions";
import { confirmForgotPassword } from "domain/user/confirmForgotPassword";
import { sendForgotPassword } from "domain/user/sendForgotPassword";
import { ConfirmForgotLicensingPasswordError, confirmForgotPasswordMutationResponse } from "generatedQueries/confirmForgotPasswordMutation.graphql";
import { ForgotPasswordQuery } from "generatedQueries/ForgotPasswordQuery.graphql";
import { ForgotLicensingPasswordError, sendForgotPasswordMutationResponse } from "generatedQueries/sendForgotPasswordMutation.graphql";
import { parse } from "query-string";
import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { Redirect, useLocation } from "react-router";
import { Col, Form, Row } from "reactstrap";
import { PayloadError } from "relay-runtime";
import { routes } from "routes/routes";
import { PasswordEntryFields } from "routes/user/PasswordEntryFields";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { TextBox } from "shared/components/newFormInputs/TextBox";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { required } from "shared/validations/validations";

export const messages = mapMessages("routes.user.forgotpassword", {
    confirmForgotPasswordButton: "Change Password",
    emailAddressLabel: "Email",
    forgotPasswordCodeExpired: "The code has expired, please send the password reset again.",
    forgotPasswordCompletedToast: "Your password has been changed",
    forgotPasswordSentToast: "Forgot password link has been sent",
    passwordResetCodeLabel: "Password Reset Code",
    sendPasswordResetButton: "Send Password Reset",
    title: "Forgot Password",
});

interface IForm {
    email: string;
    newPassword?: string;
    confirmPassword?: string;
    code?: string;
}

interface IQueryString {
    email?: string;
}

export const ForgotPassword: FC = () => {
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryString = parse(location.search) as IQueryString;
    const methods = useForm<IForm>({
        defaultValues: {
            confirmPassword: "",
            email: queryString.email || "",
            newPassword: "",
            code: undefined,
        },
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [forgotPasswordSent, setForgotPasswordSent] = useState(!!queryString.email);
    const [error, setError] = useState<GeneralForgotPasswordErrorCode | ConfirmForgotLicensingPasswordError | ForgotLicensingPasswordError>();
    const [isComplete, setIsComplete] = useState(false);

    if (isComplete) {
        return <Redirect to={routes.user.login.create({})} />;
    }

    return <Row className="justify-content-center">
        <Col xl={5} lg={6} md={7} sm={9}>
            <h2><Message message={messages.title} /></h2>

            <Query<ForgotPasswordQuery>
                query={query}
                variables={{}}>
                {({ error: queryError, props }) => {
                    if (!props && !queryError) {
                        return <LoadingMessage />;
                    }

                    const { configuration } = props ?? {};

                    return (
                        <FormProvider
                            {...methods}
                        >
                            <Form
                                onSubmit={methods.handleSubmit(submitForgotPassword)}
                            >
                                <FormLabel
                                    message={messages.emailAddressLabel}
                                    for="email"
                                    required={true}
                                >
                                    <TextBox
                                        id="email"
                                        name="email"
                                        register={methods.register}
                                        control={methods.control}
                                        type="email"
                                        options={required<IForm, "email">()}
                                        aria-required={true}
                                    />
                                </FormLabel>
                                {
                                    forgotPasswordSent &&
                                    <>
                                        <PasswordEntryFields
                                            passwordField="newPassword"
                                            passwordValidationRegex={configuration?.passwordValidationRegex}
                                        />
                                        <FormLabel
                                            message={messages.passwordResetCodeLabel}
                                            for="code"
                                            required={true}
                                        >
                                            <TextBox
                                                id="code"
                                                name="code"
                                                register={methods.register}
                                                control={methods.control}
                                                options={required<IForm, "code">()}
                                                aria-required={true}
                                            />
                                        </FormLabel>
                                    </>
                                }
                                <ForgotPasswordError
                                    error={error}
                                />
                                <SpinnerButton
                                    className="float-right"
                                    color="primary"
                                    isLoading={isSubmitting}
                                    message={forgotPasswordSent ? messages.confirmForgotPasswordButton : messages.sendPasswordResetButton}
                                />
                            </Form>
                        </FormProvider>
                    );
                }}
            </Query>
        </Col>
    </Row>;

    function submitForgotPassword({ email, newPassword, code }: IForm) {
        setIsSubmitting(true);

        if (!code || !newPassword) {
            sendForgotPassword(
                environment,
                email,
                onForgotPasswordSent,
                onSendForgetPasswordError);
        } else {
            confirmForgotPassword(
                environment,
                email,
                newPassword,
                code,
                onPasswordChanged,
                onPasswordChangeError);
        }
    }

    function onForgotPasswordSent(response: sendForgotPasswordMutationResponse, errors?: PayloadError[] | null) {
        setIsSubmitting(false);
        if (response.forgotLicensingPassword?.wasSuccessful) {
            setForgotPasswordSent(true);
            dispatch(toastActions.customSuccessToast(messages.forgotPasswordSentToast));
        } else {
            setError(response.forgotLicensingPassword?.error ?? "ERRORSENDING");
        }
    }

    function onSendForgetPasswordError() {
        setIsSubmitting(false);
        setError("ERRORSENDING");
    }

    function onPasswordChanged(response: confirmForgotPasswordMutationResponse, errors?: PayloadError[] | null) {
        setIsSubmitting(false);
        if (response.confirmForgotLicensingPassword?.wasSuccessful) {
            setIsComplete(true);
            dispatch(toastActions.customSuccessToast(messages.forgotPasswordCompletedToast));
        } else {
            if (response.confirmForgotLicensingPassword?.error === "CODEEXPIRED") {
                dispatch(toastActions.customFailureToast(messages.forgotPasswordCodeExpired));
                setForgotPasswordSent(false);
            }

            setError(response.confirmForgotLicensingPassword?.error ?? "CHANGEPASSWORDERROR");
        }
    }

    function onPasswordChangeError() {
        setIsSubmitting(false);
        setError("CHANGEPASSWORDERROR");
    }
};

const query = graphql`
    query ForgotPasswordQuery {
        configuration {
            passwordValidationRegex
        }
    }`;
