import { defineMessages } from "react-intl";

const idPrefix = "licence.options.";

export const licenceOptionsMessages = defineMessages({
    licenceMenuTitle: {
        defaultMessage: "License My Pet",
        id: idPrefix + "licenceMenu-title",
    },
    newLicenceButton: {
        defaultMessage: "License a new pet.",
        id: idPrefix + "newLicence-button",
    },
    newLicenceDescriptionLabel: {
        defaultMessage: "My pet has never been licensed before.",
        id: idPrefix + "newLicenceDescription-label",
    },
    previousLicenceButton: {
        defaultMessage: "Search for my pet's tag.",
        id: idPrefix + "previousLicence-button",
    },
    previousLicenceDescriptionLabel: {
        defaultMessage: "My pet has been previously licensed before.",
        id: idPrefix + "previousLicenceDescription-label",
    },
});
