import { InfoMessage } from "components/shared/InfoMessage";
import { SpinnerButton } from "components/shared/SpinnerButton";
import graphql from "babel-plugin-relay/macro";
import { AnimalRow } from "components/animal/AnimalRow";
import { AnimalTagSearch } from "components/animal/AnimalTagSearch/AnimalTagSearch";
import { parseQueryStringValues } from "components/payment/PayPalPayflow/parseQueryStringValues";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { AnimalListPaginationQuery } from "generatedQueries/AnimalListPaginationQuery.graphql";
import { AnimalListQuery } from "generatedQueries/AnimalListQuery.graphql";
import { AnimalList_query$data, AnimalList_query$key } from "generatedQueries/AnimalList_query.graphql";
import React, { FC, useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { useLazyLoadQuery } from "react-relay";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Alert, Table } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { ILicenceRenewalLocationState } from "routes/licence/LicenceRenewal";
import { routes } from "routes/routes";
import { usePagination } from "shared/hooks/usePagination";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const PAGE_SIZE = 10;

const messages = mapMessages("routes.pets.AnimalList", {
    blockedCannotBeRenewedMessage: "{AnimalName} has been blocked for licensing online.",
    countInfo: "({displayed} of {total} animals)",
    loadMore: "Load More",
    lifetimeCannotBeRenewedMessage: "{AnimalName} is already licensed with a lifetime license.",
    pendingCannotBeRenewedMessage: "{AnimalName} has a licensing pending still. If you need to renew the license and it has not yet been processed, please contact {OrganisationContact} to check the progress of the previous license before renewing.",
    rejectedCannotBeRenewedMessage: "{AnimalName} has had the license put on hold and cannot be renewed at this time. Please check your email for instructions or contact {OrganisationContact} on how to proceed.",
});

interface IRouteParams {
    scrollTo?: string;
}

interface IRenewalWarning {
    text: string;
    colour: string;
}

export const AnimalList: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const [displayNoSelectedAnimalsWarning, setDisplayNoSelectedAnimalsWarning] = useState(false);
    const [cannotRenewAnimalWarnings, setCannotRenewAnimalWarnings] = useState<IRenewalWarning[]>([]);
    const [selectedAnimalIds, setSelectedAnimalIds] = useState<string[]>([]);

    const params = parseQueryStringValues<IRouteParams>(
        location.search,
        {
            scrollTo: undefined,
        });

    const { configuration, ...animalListQueryKey } = useLazyLoadQuery<AnimalListQuery>(
        query,
        {
            first: PAGE_SIZE,
            order: [{ name: "ASC" }],
        },
        {
            fetchPolicy: "network-only",
            networkCacheConfig: { force: true },
        }
    );

    const {
        data,
        hasErrorLoadingNext,
        hasNext,
        isLoadingNext,
        loadNext,
    } = usePagination<AnimalListPaginationQuery, AnimalList_query$key>(fragment, animalListQueryKey, { pageSize: PAGE_SIZE });

    return <>
        <h2><Message message={animalMessages.petsPageTitle} /></h2>
        {(() => {
            if (!data.account) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            if (!data.account.animals?.edges?.length) {
                return <>
                    <InfoMessage message={animalMessages.noPetsMessage} />
                    <AnimalTagSearch />
                </>;
            }

            return <>
                <Table hover={true}>
                    <thead>
                        <tr>
                            <th scope="col" />
                            <th scope="col"><Message message={animalMessages.petListNameHeading} /></th>
                            <th scope="col"><Message message={animalMessages.petListTypeHeading} /></th>
                            <th scope="col"><Message message={animalMessages.petListBreedHeading} /></th>
                            <th scope="col"><Message message={animalMessages.petListLicenceTypeHeading} /></th>
                            <th scope="col"><Message message={animalMessages.petListExpiryDateHeading} /></th>
                            <th scope="col"><Message message={animalMessages.petListLicenceStatusHeading} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.account.animals.edges.map((edge, index) => (
                            <AnimalRow
                                key={index}
                                animal={edge.node}
                                configuration={configuration}
                                isSelected={selectedAnimalIds.some(selectedAnimalId => selectedAnimalId === edge.node.id)}
                                animalSelectionChanged={onAnimalSelectionChanged(edge.node.id)}
                                scrollToRowOnRender={edge.node.id === params.scrollTo}
                            />
                        ))}
                    </tbody>
                </Table>
                <div className="row mb-1">
                    <div className="col">
                        {
                            hasErrorLoadingNext &&
                            <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />
                        }
                        {
                            hasNext &&
                            <SpinnerButton
                                message={messages.loadMore}
                                onClick={loadNext}
                                isLoading={isLoadingNext}
                            />
                        }
                    </div>
                    <span className="float-right">
                        <Message
                            message={messages.countInfo}
                            values={{
                                displayed: data.account.animals.edges.length,
                                total: data.account.animals.totalCount,
                            }}
                        />
                    </span>
                </div>
                {
                    displayNoSelectedAnimalsWarning &&
                    <InfoMessage message={animalMessages.noSelectedPetsMessage} />
                }
                {
                    cannotRenewAnimalWarnings.map((warning, i) => (
                        <Alert color={warning.colour} key={i}>
                            {warning.text}
                        </Alert>
                    ))
                }
                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-5 col-sm-7">
                        <Button onClick={onRenewalClicked(data.account, configuration?.organisationContact)} className="renewal-button">
                            <Message message={animalMessages.renewalBulkActionLabel} />
                        </Button>
                    </div>
                </div>
                <AnimalTagSearch />
            </>;
        })()}
    </>;

    function onAnimalSelectionChanged(animalId: string) {
        return (selected: boolean) => {
            if (selected) {
                setSelectedAnimalIds(selectedIds => {
                    if (selectedIds.some(id => id === animalId)) {
                        return selectedIds;
                    }

                    return [...selectedIds, animalId];
                });
            } else {
                setSelectedAnimalIds(selectedIds => {
                    return selectedIds.filter(id => id !== animalId);
                });
            }
        };
    }

    function onRenewalClicked(account: AnimalList_query$data["account"], organisationContact: string | undefined) {
        return () => {
            if (account && selectedAnimalIds && selectedAnimalIds.length > 0) {
                const warnings = selectedAnimalIds.map(animalId => {
                    const animal = account.animals?.edges?.find(edge => edge.node.id === animalId)?.node;
                    if (!animal) {
                        return null;
                    }

                    let message: MessageDescriptor | null = null;
                    let colour: string = "info";

                    switch (animal.status) {
                        case "BLOCKED":
                            message = messages.blockedCannotBeRenewedMessage;
                            colour = "danger";
                            break;

                        case "REJECTED":
                            message = messages.rejectedCannotBeRenewedMessage;
                            colour = "warning";
                            break;

                        case "PENDING":
                            message = messages.pendingCannotBeRenewedMessage;
                            colour = "warning";
                            break;

                        default:
                            const lifetimeLicence = animal.licences.find(licence => licence.isLifetime === true);
                            message = !!lifetimeLicence
                                ? messages.lifetimeCannotBeRenewedMessage
                                : null;
                    }

                    const intlValues = {
                        AnimalName: animal.name,
                        OrganisationContact: organisationContact ?? intl.formatMessage(sharedMessages.defaultOrganisationContactMessage),
                    };

                    if (message) {
                        return {
                            text: intl.formatMessage(message, intlValues),
                            colour,
                        };
                    }

                    return null;
                }).filter(warning => !!warning) as IRenewalWarning[];

                if (warnings.length > 0) {
                    setCannotRenewAnimalWarnings(warnings);
                } else {
                    const state: ILicenceRenewalLocationState = {
                        animalIds: selectedAnimalIds,
                        hasReviewedAccountDetails: false,
                    };

                    history.push(routes.licence.renewal.create({}), state);
                }

            } else {
                setDisplayNoSelectedAnimalsWarning(true);
            }
        };
    }
};

const query = graphql`
query AnimalListQuery(
    $first: Int!
    $after: String
    $order: [LicensingAnimalSortInput!]!
) {
    ...AnimalList_query
    configuration {
        organisationContact
        ...AnimalRow_configuration
    }
}`;

const fragment = graphql`
fragment AnimalList_query on Query
@refetchable(queryName: "AnimalListPaginationQuery") {
    account {
        animals(first: $first, after: $after, order: $order)
        @connection(key: "AnimalList_query_animals") {
            edges {
                node {
                    id
                    status
                    licences {
                        isLifetime
                    }
                    name
                    ...AnimalRow_animal
                }
            }
            totalCount
        }
    }
}`;
