import graphql from "babel-plugin-relay/macro";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { accountMessages } from "domain/account/accountMessages";
import { AddressSearchPlaceDetailsQuery, AddressSearchPlaceDetailsQueryResponse } from "generatedQueries/AddressSearchPlaceDetailsQuery.graphql";
import { AddressSearchQuery, AddressSearchQueryResponse } from "generatedQueries/AddressSearchQuery.graphql";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery } from "relay-runtime";
import { ISelectOption, ReactSelect } from "shared/components/ReactSelect";
import { mapMessages } from "shared/mapMessages";
import { v4 as uuidv4 } from "uuid";

const messages = mapMessages("components.address.addressSearch", {
    label: "Address Search",
    requestFailedBody: "Please enter your address manually in the fields below.",
    requestFailedTitle: "Sorry, address search is unavailable.",
});

export type AddressSelection = AddressSearchPlaceDetailsQueryResponse["licensingAddressDetails"];

interface IProps {
    id: string;
    onAddressSelected: (selection: AddressSelection) => void;
}

export const AddressSearch: FC<IProps> = ({
    id,
    onAddressSelected,
}) => {
    const { formatMessage } = useIntl();
    const environment = useRelayEnvironment();
    const [sessionToken] = useState(uuidv4());

    const [searchResults, setSearchResults] = useState<AddressSearchQueryResponse["licensingAddressSearch"]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [displaySearchError, setDisplaySearchError] = useState(false);

    const selectableAddresses = searchResults?.map(a => {
        return { value: a.placeId, label: a.formattedAddress } as ISelectOption;
    }) ?? [];

    return (
        <div className="form-select">
            <ReactSelect
                inputId={id}
                className="input-address-search"
                isSearchable={true}
                isLoading={isSearching}
                placeholder={formatMessage(accountMessages.searchForAddressMessage)}
                options={selectableAddresses}
                onChange={onSearch}
                onSelected={onAddressSearchResultSelected}
                aria-label={formatMessage(messages.label)}
            />
            {
                displaySearchError &&
                <ErrorMessage message={messages.requestFailedBody} heading={messages.requestFailedTitle} />
            }
        </div>
    );

    function onSearch(searchText: string) {
        if (searchText) {
            fetchQuery<AddressSearchQuery>(environment, searchQuery, { searchText, sessionToken })
                .subscribe({
                    error() {
                        setDisplaySearchError(true);
                        setIsSearching(false);
                    },
                    next(data) {
                        setIsSearching(false);

                        if ((data.licensingAddressSearch?.length ?? 0) > 0) {
                            setDisplaySearchError(false);
                            setSearchResults(data.licensingAddressSearch);
                        } else {
                            setDisplaySearchError(true);
                        }
                    },
                });
        }
    }

    function onAddressSearchResultSelected(selection: ISelectOption) {
        fetchQuery<AddressSearchPlaceDetailsQuery>(environment, placeDetailsQuery, { placeId: selection.value as string, sessionToken })
            .subscribe({
                error() {
                    setDisplaySearchError(true);
                },
                next(data) {
                    if (data.licensingAddressDetails) {
                        setDisplaySearchError(false);
                        onAddressSelected(data.licensingAddressDetails);
                    } else {
                        setDisplaySearchError(true);
                    }
                },
            });
    }
};

const searchQuery = graphql`
    query AddressSearchQuery($searchText: String!, $sessionToken: String) {
        licensingAddressSearch(searchText: $searchText, sessionToken: $sessionToken) {
            placeId
            formattedAddress
        }
    }`;

const placeDetailsQuery = graphql`
    query AddressSearchPlaceDetailsQuery($placeId: String!, $sessionToken: String) {
        licensingAddressDetails(placeId: $placeId, sessionToken: $sessionToken) {
            addressLine
            country
            postcode
            state
            suburb
        }
    }`;
