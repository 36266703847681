import { Spinner } from "components/shared/Spinner";
import graphql from "babel-plugin-relay/macro";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { toastActions } from "domain/toast/toastActions";
import { resendVerificationCode } from "domain/user/resendVerificationCode";
import { userMessages } from "domain/user/userMessages";
import { ResendCode_account } from "generatedQueries/ResendCode_account.graphql";
import { resendVerificationCodeMutationResponse } from "generatedQueries/resendVerificationCodeMutation.graphql";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { createFragmentContainer, useRelayEnvironment } from "react-relay";
import Button from "reactstrap/lib/Button";
import { PayloadError } from "relay-runtime";
import { sharedMessages } from "shared/sharedMessages";

interface IGraphQlProps {
    account: ResendCode_account;
}

const InternalResendCode: FC<IGraphQlProps> = ({
    account,
}) => {
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    return <div>
        <span>
            <Message message={userMessages.didntReceiveCodeMessage} />
            <br />
            <Button color="link" onClick={onClick}>
                <Message message={userMessages.resendItToLabel} values={({ email: account.email })} />
            </Button>

            {submitting && <Spinner className="ml-2" />}
        </span>
        {error && <ErrorMessage heading={sharedMessages.requestFailedTitle} message={sharedMessages.requestFailedBody} />}
    </div>;

    function onClick() {
        setSubmitting(true);
        resendVerificationCode(environment, onResendVerificationCompleted, onError);
    }

    function onResendVerificationCompleted(_: resendVerificationCodeMutationResponse, errors?: PayloadError[] | null) {
        if (errors && errors.length > 0) {
            onError();
            return;
        }

        dispatch(toastActions.customSuccessToast(userMessages.resendVerificationEmailSucceededMessage));
        setSubmitting(false);
    }

    function onError() {
        setError(true);
        dispatch(toastActions.toastFailure());
        setSubmitting(false);
    }
};

export const ResendCode = createFragmentContainer(InternalResendCode, {
    account: graphql`
        fragment ResendCode_account on Account {
            email
        }`,
});
