import { IAccountReadonlyAdditionalDetails } from "components/account/details/components/AccountReadonlyAdditionalDetails";
import { IAccountReadonlyContactDetails } from "components/account/details/components/AccountReadonlyContactDetails";
import { IAccountSubtitle } from "components/account/details/components/AccountSubtitle";
import { IAddresses } from "components/address/Addresses";
import React, { FC } from "react";
import { Col, Row } from "reactstrap";

export interface IAccountDetailsLayout {
    accountSubtitle?: React.ReactElement<IAccountSubtitle>;
    accountReadonlyContactDetails?: React.ReactElement<IAccountReadonlyContactDetails>;
    accountReadonlyAdditionalDetails?: React.ReactElement<IAccountReadonlyAdditionalDetails>;
    accountReadonlyAddresses?: React.ReactElement<IAddresses>;
}

export const AccountDetailsLayout: FC<IAccountDetailsLayout> = ({
    accountSubtitle,
    accountReadonlyContactDetails,
    accountReadonlyAdditionalDetails,
    accountReadonlyAddresses,
}) => <>
        <Col md={{ size: 8, offset: 2 }} data-testid="AccountDetailsLayout">
            <Row>
                <Col>
                    {
                        accountSubtitle
                    }
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    {
                        accountReadonlyContactDetails
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        accountReadonlyAdditionalDetails
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        accountReadonlyAddresses
                    }
                </Col>
            </Row>
        </Col>
    </>;
