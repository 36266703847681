import { Message } from "shared/components/Message";
import React, { FC, useState } from "react";
import { Button } from "reactstrap";
import { mapMessages } from "shared/mapMessages";

const messages = mapMessages("errortest", {
    renderErrorButton: "Click to perform a render error",
    throwErrorButton: "Click to throw an error",
});

export const ErrorTest: FC = () => {
    const [doRenderError, setDoRenderError] = useState(false);

    if (doRenderError) {
        throw new Error("Test render error");
    }

    return <>
        <Button
            color="danger"
            onClick={throwError}>
            <Message message={messages.throwErrorButton} />
        </Button>
        <Button
            color="danger"
            className="ml-3"
            onClick={onRenderErrorButtonClicked}>
            <Message message={messages.renderErrorButton} />
        </Button>
    </>;

    function onRenderErrorButtonClicked() {
        setDoRenderError(true);
    }
};

function throwError() {
    throw new Error("Test error");
}
