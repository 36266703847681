import { Money } from "components/shared/Money";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import styles from "components/payment/PaymentRow.module.scss";
import { PaymentRowLicenceDetails } from "components/payment/PaymentRowLicenceDetails";
import { Message } from "shared/components/Message";
import { IPaymentState } from "domain/payment/combinedPaymentReducer";
import { paymentActions } from "domain/payment/paymentActions";
import { paymentMessages } from "domain/payment/paymentMessages";
import { redirectActions } from "domain/redirect/redirectActions";
import { PaymentRow_configuration } from "generatedQueries/PaymentRow_configuration.graphql";
import { PaymentRow_paymentRow } from "generatedQueries/PaymentRow_paymentRow.graphql";
import React, { FC, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFragmentContainer } from "react-relay";
import { Button, Tooltip } from "reactstrap";
import { routes } from "routes/routes";
import { RootState } from "store/store";

interface IGraphQlProps {
    configuration: PaymentRow_configuration;
    paymentRow: PaymentRow_paymentRow;
}

const InternalPaymentRow: FC<IGraphQlProps> = ({
    configuration,
    paymentRow,
}) => {
    const dispatch = useDispatch();
    const paymentState = useSelector<RootState, IPaymentState>(state => state.payment);
    const [displayRemoveTooltip, setDisplayRemoveTooltip] = useState(false);

    const { renewingAnimalsInCart } = paymentState;

    const canBeRemoved = renewingAnimalsInCart.indexOf(paymentRow.animalId) >= 0;
    const removeButtonId = `remove-button-${paymentRow.animalId}`;

    return <tr>
        <td>{paymentRow.animalName}</td>
        <td><PaymentRowLicenceDetails configuration={configuration} paymentRow={paymentRow} /></td>
        <td>
            <Money amount={paymentRow.amount} currency={paymentRow.currency} />
        </td>
        <td>
            {
                !paymentRow.isLateFee &&
                <Fragment>
                    <Button id={removeButtonId} onClick={remove} color="Link" className={classNames(styles.button, !canBeRemoved && "disabled")}>
                        <Message message={paymentMessages.removeButtonLabel} />
                    </Button>
                    <Button onClick={edit} color="Link" className={styles.button}>
                        <Message message={paymentMessages.editButtonLabel} />
                    </Button>
                    <Tooltip
                        placement="bottom"
                        isOpen={displayRemoveTooltip}
                        target={removeButtonId}
                        toggle={toggleRemoveTooltip}>
                        <Message message={paymentMessages.unableToRemoveFromPaymentTooltip} />
                    </Tooltip>
                </Fragment>
            }
        </td>
    </tr>;

    function toggleRemoveTooltip() {
        if (!canBeRemoved) {
            setDisplayRemoveTooltip(!displayRemoveTooltip);
        }
    }

    function edit() {
        dispatch(redirectActions.redirectTo(routes.pets.pet.create({ id: paymentRow.animalId })));
    }

    function remove() {
        if (canBeRemoved) {
            dispatch(paymentActions.removeRenewingAnimalFromCart(paymentRow.animalId));
        }
    }
};

export const PaymentRow = createFragmentContainer(InternalPaymentRow, {
    configuration: graphql`
        fragment PaymentRow_configuration on Configuration {
            organisationContact
            ...PaymentRowLicenceDetails_configuration
        }`,
    paymentRow: graphql`
        fragment PaymentRow_paymentRow on LicensingPaymentFormRow {
            amount
            currency
            animalId
            animalName
            blocked
            licenceType
            licenceTypeId
            rabiesIsValid
            isRenewal
            isLateFee
            ...PaymentRowLicenceDetails_paymentRow
        }`,
});
