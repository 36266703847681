import { Footer } from "components/shared/Footer";
import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { AnimalPublicTagSearch } from "components/animal/AnimalTagSearch/AnimalPublicTagSearch";
import { Header } from "components/layout/Header";
import { LicenceOptions } from "components/licence/options/LicenceOptions";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { IUserState } from "domain/user/combinedUserReducer";
import { DefaultLayoutAccountQuery, DefaultLayoutAccountQueryResponse } from "generatedQueries/DefaultLayoutAccountQuery.graphql";
import { DefaultLayoutQuery, DefaultLayoutQueryResponse } from "generatedQueries/DefaultLayoutQuery.graphql";
import { createRoute } from "infrastructure/routing";
import React, { FC, Suspense } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { matchPath, Redirect, Switch, useLocation } from "react-router";
import { Col, Container, Row } from "reactstrap";
import { RouteIndex as AccountRouteIndex } from "routes/account/RouteIndex";
import { Home } from "routes/home/Home";
import { RouteIndex as LicenceRouteIndex } from "routes/licence/RouteIndex";
import { RouteIndex as PaymentRouteIndex } from "routes/payment/RouteIndex";
import { AnimalSearch } from "routes/pets/AnimalSearch";
import { RouteIndex as PetsRouteIndex } from "routes/pets/RouteIndex";
import { routes } from "routes/routes";
import { TestRouteIndex } from "routes/test/TestRouteIndex";
import { RouteIndex as UserRouteIndex } from "routes/user/RouteIndex";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";
import { RootState } from "store/store";

const messages = mapMessages("components.layout.DefaultLayout", {
    defaultSiteDisabledMessage: "Online Licensing is temporarily offline. Please check back again later.",
    siteDisabledHeader: "Offline",
});

interface ICustomerSiteDisabledSettings {
    isCustomerSiteDisabled: boolean;
    message?: string;
}

export const DefaultLayout: FC = () => {
    const { formatMessage } = useIntl();
    const userState = useSelector<RootState, IUserState>(state => state.user);
    const location = useLocation();

    const {
        isLoggedIn,
        roles,
    } = userState;

    return <Query<DefaultLayoutQuery>
        query={graphql`
            query DefaultLayoutQuery {
                features {
                    isPublicLicenceSearchEnabled
                }
                siteDisabled: siteCustomisation(application: "licensing", name: "CustomerSiteDisabled") {
                    value
                }
                ...Header_configuration
            }`}
        variables={{}}>
        {({ error, props }) => {
            if (error) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            if (!props) {
                return <LoadingMessage />;
            }

            const siteDisabledSettings = getSiteDisabledSettings(props?.siteDisabled?.value);

            if (siteDisabledSettings.isCustomerSiteDisabled) {
                return (
                    <Container>
                        <Row>
                            <Col>
                                <h2
                                    className="mt-5"
                                >
                                    <Message
                                        message={messages.siteDisabledHeader}
                                    />
                                </h2>
                                {siteDisabledSettings.message ?? formatMessage(messages.defaultSiteDisabledMessage)}
                            </Col>
                        </Row>
                    </Container>
                );
            }

            // Whilst "isLoggedIn" is not required for this query,
            // including it kicks off a change and therefore causes
            // it to requery on login
            return <Query<DefaultLayoutAccountQuery>
                query={graphql`
                    query DefaultLayoutAccountQuery {
                        account {
                            status
                            ...Header_account
                        }
                    }`}
                variables={{ isLoggedIn }}>
                {
                    ({ error: accountError, props: accountProps }) =>
                        <>
                            <Header
                                account={accountProps?.account || null}
                                configuration={props}
                            />
                            <Container>
                                <Suspense fallback={<LoadingMessage />}>
                                    {renderRoutes(props, accountProps, accountError)}
                                </Suspense>
                            </Container>
                            <Footer />
                        </>
                }
            </Query>;
        }
        }
    </Query>;

    function renderRoutes(props: DefaultLayoutQueryResponse, accountProps: DefaultLayoutAccountQueryResponse | null | undefined, accountError: Error | null) {
        if (!accountProps && !accountError) {
            return <Message message={isLoggedIn ? sharedMessages.loadingAccountDetailsDescr : sharedMessages.loadingDescr} />;
        }

        const isPublicLicenceSearchEnabled = props.features?.isPublicLicenceSearchEnabled ?? false;

        if (!roles.includes("Unverified") && matchPath(location.pathname, { path: routes.user.verify.template() })) {
            return <Redirect to={routes.home.create({})} />;
        }

        if (!matchPath(location.pathname, { path: routes.user.index.template() })) {
            if (isLoggedIn) {
                if (accountProps?.account) {
                    if (roles.includes("Unverified") && !matchPath(location.pathname, { path: routes.user.verify.template() })) {
                        return <Redirect to={routes.user.verify.create({})} />;
                    }

                    if (accountProps.account.status === "NEW" && !matchPath(location.pathname, { path: routes.account.edit.template() })) {
                        return <Redirect to={routes.account.edit.create({})} />;
                    }
                }
            }
            else if (!matchPath(location.pathname, { path: routes.home.template(), exact: true })
                && (!isPublicLicenceSearchEnabled || !matchPath(location.pathname, { path: routes.pets.lostPets.template(), exact: true }))) {
                return <Switch>
                    {createRoute(routes.test.index, { component: TestRouteIndex })}
                    <Redirect to={routes.home.create({})} />
                </Switch>;
            }
        }

        return <Switch>
            {createRoute(routes.user.index, { component: UserRouteIndex })}
            {accountProps?.account && createRoute(routes.payment.index, { component: PaymentRouteIndex }, ["Customer"])}
            {accountProps?.account && createRoute(routes.account.index, { component: AccountRouteIndex }, ["Customer"])}
            {accountProps?.account && createRoute(routes.licence.index, { component: LicenceRouteIndex }, ["Customer"])}
            {isPublicLicenceSearchEnabled && createRoute(routes.pets.lostPets, { component: AnimalPublicTagSearch }, [])}
            {isLoggedIn && createRoute(routes.pets.index, { component: PetsRouteIndex }, ["Customer"])}
            {isLoggedIn && createRoute(routes.pets.searchPets, { component: AnimalSearch }, ["Customer"])}
            {isLoggedIn && createRoute(routes.licence.options, { component: LicenceOptions }, ["Customer"])}
            {createRoute(routes.test.index, { component: TestRouteIndex })}
            {createRoute(routes.home, { component: Home, exact: true })}
            <Redirect to={routes.home.create({})} />
        </Switch>;
    }
};

function getSiteDisabledSettings(siteDisabledValue: string | null | undefined): ICustomerSiteDisabledSettings {
    if (!siteDisabledValue) {
        return {
            isCustomerSiteDisabled: false,
        };
    }

    return {
        isCustomerSiteDisabled: false,
        ...JSON.parse(siteDisabledValue),
    };
}
