/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HomeQueryVariables = {};
export type HomeQueryResponse = {
    readonly configuration: {
        readonly " $fragmentRefs": FragmentRefs<"HomeContent_configuration">;
    } | null;
    readonly landingPage: {
        readonly " $fragmentRefs": FragmentRefs<"HomeContent_landingPage">;
    } | null;
};
export type HomeQuery = {
    readonly response: HomeQueryResponse;
    readonly variables: HomeQueryVariables;
};



/*
query HomeQuery {
  configuration {
    ...HomeContent_configuration
  }
  landingPage: siteCustomisation(application: "licensing", name: "landingPage") {
    ...HomeContent_landingPage
  }
}

fragment HomeContent_configuration on Configuration {
  licenceExpiryWarningDays
  organisationContact
}

fragment HomeContent_landingPage on SiteCustomisation {
  value
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "application",
            "value": "licensing"
        } as any,
        {
            "kind": "Literal",
            "name": "name",
            "value": "landingPage"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "HomeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "HomeContent_configuration"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "landingPage",
                    "args": (v0 /*: any*/),
                    "concreteType": "SiteCustomisation",
                    "kind": "LinkedField",
                    "name": "siteCustomisation",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "HomeContent_landingPage"
                        }
                    ],
                    "storageKey": "siteCustomisation(application:\"licensing\",name:\"landingPage\")"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "HomeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "licenceExpiryWarningDays",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "organisationContact",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "landingPage",
                    "args": (v0 /*: any*/),
                    "concreteType": "SiteCustomisation",
                    "kind": "LinkedField",
                    "name": "siteCustomisation",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                        }
                    ],
                    "storageKey": "siteCustomisation(application:\"licensing\",name:\"landingPage\")"
                }
            ]
        },
        "params": {
            "cacheID": "9909b9b223148b2bdb236cf26a1daa75",
            "id": null,
            "metadata": {},
            "name": "HomeQuery",
            "operationKind": "query",
            "text": "query HomeQuery {\n  configuration {\n    ...HomeContent_configuration\n  }\n  landingPage: siteCustomisation(application: \"licensing\", name: \"landingPage\") {\n    ...HomeContent_landingPage\n  }\n}\n\nfragment HomeContent_configuration on Configuration {\n  licenceExpiryWarningDays\n  organisationContact\n}\n\nfragment HomeContent_landingPage on SiteCustomisation {\n  value\n}\n"
        }
    } as any;
})();
(node as any).hash = '81dfa9356e6cdc4c551d31f74d3a5fd7';
export default node;
