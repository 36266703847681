import graphql from "babel-plugin-relay/macro";
import { resendVerificationCodeMutation, resendVerificationCodeMutationResponse } from "generatedQueries/resendVerificationCodeMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation resendVerificationCodeMutation {
        resendLicensingAccountVerificationEmail {
            email
        }
    }`;

export function resendVerificationCode(
    environment: IEnvironment,
    onCompleted: (response: resendVerificationCodeMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<resendVerificationCodeMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {},
        }
    );
}
