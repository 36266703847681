import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { AccountDetailsLayout } from "components/account/details/components/AccountDetailsLayout";
import { AccountReadonlyAdditionalDetails } from "components/account/details/components/AccountReadonlyAdditionalDetails";
import { AccountReadonlyContactDetails } from "components/account/details/components/AccountReadonlyContactDetails";
import { AccountSubtitle } from "components/account/details/components/AccountSubtitle";
import { Addresses } from "components/address/Addresses";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { AccountDetailsQuery } from "generatedQueries/AccountDetailsQuery.graphql";
import React, { FC } from "react";
import { Query } from "shared/components/Query";
import { sharedMessages } from "shared/sharedMessages";

const graphQuery = graphql`
query AccountDetailsQuery {
    account {
        ...AccountSubtitle_account
        ...AccountReadonlyContactDetails_account
        ...AccountReadonlyAdditionalDetails_account
        ...Addresses_account
        isSelfIdentifiedAsSeniorCitizen
    }
    configuration {
        ...AccountReadonlyAdditionalDetails_configuration
    }
    features {
        isSelfIdentifyAsSeniorCitizenEnabled
    }
    isSeniorCitizenLabel: siteCustomisation(application: "licensing", name: "isSeniorCitizenLabel") {
        ...AccountReadonlyAdditionalDetails_isSeniorCitizenLabel
    }
}`;

export const AccountDetails: FC = () => (
    <div data-testid="AccountDetails">
        <Query<AccountDetailsQuery>
            query={graphQuery}
            variables={{}}>
            {({ error, props }) => {
                if (error) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                if (!props) {
                    return <LoadingMessage />;
                }

                const { account, features, configuration, isSeniorCitizenLabel } = props;

                if (!account) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                const isSelfIdentifyAsSeniorCitizenEnabled = !!features && features.isSelfIdentifyAsSeniorCitizenEnabled;

                return <AccountDetailsLayout
                    accountSubtitle={
                        <AccountSubtitle account={account} />
                    }
                    accountReadonlyContactDetails={
                        <AccountReadonlyContactDetails account={account} />
                    }
                    accountReadonlyAdditionalDetails={
                        <AccountReadonlyAdditionalDetails
                            isSeniorCitizenLabel={isSeniorCitizenLabel}
                            isSelfIdentifyAsSeniorCitizenEnabled={isSelfIdentifyAsSeniorCitizenEnabled}
                            account={account}
                            configuration={configuration} />
                    }
                    accountReadonlyAddresses={
                        <Addresses account={account} />
                    }
                />;
            }}
        </Query>
    </div>
);
