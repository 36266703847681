import flowjs from "flowjs";
import { getFlow } from "infrastructure/getFlow";
import { useLogger } from "infrastructure/logger";
import { useInstance } from "infrastructure/useInstance";
import { RefObject, useEffect, useState } from "react";

export type UploadState = {
    inputRef: RefObject<HTMLInputElement>
    status: UploadStatus
    progress: number | undefined;
    storageId: number | null;
    fileNames: string[];
};

export type UploadStatus = "notStarted" | "existing" | "started" | "failed" | "complete" | "invalid" | "unauthenticated";

export function useUpload(
    inputRef: RefObject<HTMLInputElement>,
    categoryStaticId: string,
    allowedFileExtensions: string[],
    initialState: UploadStatus,
    getFileName?: (index: number | undefined) => string): UploadState {
    const logger = useLogger();
    const [status, setStatus] = useState<UploadStatus>(initialState);
    const [progress, setProgress] = useState<number>();
    const [assignedBrowse, setAssignedBrowse] = useState(false);
    const [storageId, setStorageId] = useState<number | null>(null);
    const flow = useInstance<flowjs.Flow>(() => getFlow(categoryStaticId, allowedFileExtensions, setStatus, setProgress, setStorageId, logger, getFileName));

    useEffect(() => {
        window.testUpload = (file: File) => {
            flow.addFile(file);
        };

        return () => {
            window.testUpload = undefined;
        };
    }, [flow]);

    useEffect(() => {
        if (inputRef.current && !assignedBrowse) {
            flow.assignBrowse([inputRef.current], false, true, {});
            setAssignedBrowse(true);
        }
    }, [flow, inputRef, assignedBrowse]);

    return {
        inputRef,
        status,
        progress,
        storageId,
        fileNames: flow.files.map(f => f.name),
    };
}
