/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicensingPaymentResultCode = "AMOUNTAUTHORISEDDOESNOTMATCHTOTALAMOUNT" | "BLOCKEDANIMAL" | "CUSTOMERSITEISDISABLED" | "DECLINED" | "ERROR" | "EXPIREDRABIESVACCINATION" | "LICENCEGENERATIONFAILED" | "LICENCETYPEDISCREPANCY" | "LICENCETYPENOTSUPPORTED" | "LIFETIMELICENCERENEWAL" | "PENDINGLICENSE" | "PETDISCREPANCY" | "REJECTEDLICENCE" | "SUCCEEDED" | "TOTALAMOUNTDISCREPANCY" | "%future added value";
export type LicensingPaymentMethodInput = {
    paymentMethodRequest: LicensingPaymentMethodRequestInput;
    paymentRequest: LicensingPaymentRequestInput;
};
export type LicensingPaymentMethodRequestInput = {
    cardConnectPaymentRequest?: LicensingPaymentCardConnectRequestInput | null;
    convergePaymentRequest?: ConvergePaymentRequestInput | null;
    globalPaymentsPaymentRequest?: GlobalPaymentsPaymentRequestInput | null;
    payPalPayflowPaymentRequest?: LicensingPaymentPayPalPayflowRequestInput | null;
    payPalPaymentRequest?: LicensingPaymentPayPalRequestInput | null;
    stripePaymentRequest?: LicensingPaymentStripeRequestInput | null;
};
export type LicensingPaymentCardConnectRequestInput = {
    cardConnectToken?: string | null;
    cvc?: string | null;
    expiryDate: string;
    postcode?: string | null;
};
export type ConvergePaymentRequestInput = {
    token?: string | null;
};
export type GlobalPaymentsPaymentRequestInput = {
    amount: number;
    orderId: string;
    responseCode: string;
    responseMessage: string;
    timestamp?: string | null;
    transactionId: string;
};
export type LicensingPaymentPayPalPayflowRequestInput = {
    amount: number;
    originalTransactionId: string;
    result: number;
    resultMessage?: string | null;
    secureToken: string;
    secureTokenId: string;
    transactionTime?: string | null;
};
export type LicensingPaymentPayPalRequestInput = {
    orderId: string;
};
export type LicensingPaymentStripeRequestInput = {
    tokenId: string;
};
export type LicensingPaymentRequestInput = {
    acceptedTermsAndConditionsContractId?: number | null;
    animalIds: Array<string>;
    donationAmount: number;
    donationNotes?: string | null;
    totalAmount: number;
};
export type processPaymentMethodMutationVariables = {
    payment: LicensingPaymentMethodInput;
};
export type processPaymentMethodMutationResponse = {
    readonly licensingPaymentMethod: {
        readonly isSuccessful: boolean;
        readonly code: LicensingPaymentResultCode;
    } | null;
};
export type processPaymentMethodMutation = {
    readonly response: processPaymentMethodMutationResponse;
    readonly variables: processPaymentMethodMutationVariables;
};



/*
mutation processPaymentMethodMutation(
  $payment: LicensingPaymentMethodInput!
) {
  licensingPaymentMethod(payment: $payment) {
    isSuccessful
    code
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "payment"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "payment",
                    "variableName": "payment"
                }
            ],
            "concreteType": "LicensingPaymentResult",
            "kind": "LinkedField",
            "name": "licensingPaymentMethod",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSuccessful",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "processPaymentMethodMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "processPaymentMethodMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "f4461be26e1abfcdf4e6ce51efc5c3df",
            "id": null,
            "metadata": {},
            "name": "processPaymentMethodMutation",
            "operationKind": "mutation",
            "text": "mutation processPaymentMethodMutation(\n  $payment: LicensingPaymentMethodInput!\n) {\n  licensingPaymentMethod(payment: $payment) {\n    isSuccessful\n    code\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '8d78fafddd578814cdd1fd4287e52030';
export default node;
