import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Message } from "shared/components/Message";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import React, { FC, ReactElement, ReactNode } from "react";
import { MessageDescriptor } from "react-intl";
import Card from "reactstrap/lib/Card";
import CardTitle from "reactstrap/lib/CardTitle";

interface IProps {
    cardType?: "info" | "warning";
    icon?: IconDefinition;
    message: MessageDescriptor;
    messageValues?: { [key: string]: string | PrimitiveType | ReactElement | FormatXMLElementFn; };
    className?: string | string[];
    children?: ReactNode | ReactNode[];
    isAlert?: boolean;
}

export const MessageCard: FC<IProps> = ({
    cardType,
    icon,
    message,
    messageValues,
    className,
    children,
    isAlert,
}) => {
    return (
        <Card
            color={cardType}
            outline={true}
            body={true}
            className={classNames(className, "mb-3")}
            role={isAlert ? "alert" : undefined}
        >
            <CardTitle>
                <h5>
                    {
                        icon &&
                        <FontAwesomeIcon icon={icon} className={classNames(`text-${cardType}`, "mr-2")} />
                    }
                    <Message
                        message={message}
                        values={messageValues}
                    />
                </h5>
            </CardTitle>
            {children}
        </Card>);
};
