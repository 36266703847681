import graphql from "babel-plugin-relay/macro";
import styles from "components/account/details/AccountDetails.module.scss";
import { HideableFormLabel_configuration } from "generatedQueries/HideableFormLabel_configuration.graphql";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { HideableContainer, HideableFields } from "shared/components/Hideable";

interface IInternalHideableFormQuestion {
    hideableFieldName: HideableFields;
    hideableFieldLabel: MessageDescriptor;
    children: React.ReactNode;
    configuration: HideableFormLabel_configuration | null;
}

const InternalHideableFormQuestion: FC<IInternalHideableFormQuestion> = ({
    hideableFieldName,
    hideableFieldLabel,
    children,
    configuration,
}) =>
    <HideableContainer field={hideableFieldName} configuration={configuration}>
        <FormLabel message={hideableFieldLabel} inlineLabelClass={styles.noPaddingLabel}>
            {
                children
            }
        </FormLabel>
    </HideableContainer>;

export const HideableFormLabel = createFragmentContainer(InternalHideableFormQuestion, {
    configuration: graphql`
        fragment HideableFormLabel_configuration on Configuration {
            ...Hideable_configuration
        }`,
});
