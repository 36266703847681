import graphql from "babel-plugin-relay/macro";
import { signUpMutation, signUpMutationResponse, signUpMutationVariables } from "generatedQueries/signUpMutation.graphql";
import { Mutable } from "infrastructure/Mutable";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

export type MutableSignUpMutationVariables = Mutable<signUpMutationVariables>;

const mutation = graphql`
    mutation signUpMutation($input: LicensingSignUpInput!) {
        licensingSignUp(input: $input) {
            success
            failureReason
        }
    }`;

export function signUp(
    environment: IEnvironment,
    input: MutableSignUpMutationVariables["input"],
    onCompleted: (response: signUpMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<signUpMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                input,
            },
        }
    );
}
