import { AccountNavigation } from "components/account/details/components/navigation/AccountNavigation";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import React, { FC } from "react";
import { Col, Row } from "reactstrap";

export const AccountTitle: FC = () => (
    <Row>
        <Col>
            <AccountNavigation className="float-right mt-2 ml-1 d-none d-md-block" size="sm" />
            <h2>
                <Message message={accountMessages.accountExistingPageTitle} />
            </h2>
            <AccountNavigation className="d-md-none w-100 mt-1" />
            <hr />
        </Col>
    </Row>
);
