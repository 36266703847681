/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ResendCode_account = {
    readonly email: string;
    readonly " $refType": "ResendCode_account";
};
export type ResendCode_account$data = ResendCode_account;
export type ResendCode_account$key = {
    readonly " $data"?: ResendCode_account$data;
    readonly " $fragmentRefs": FragmentRefs<"ResendCode_account">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResendCode_account",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
        }
    ],
    "type": "Account",
    "abstractKey": null
} as any;
(node as any).hash = 'e07cc532aa1f830d9b2e22decf8c3d15';
export default node;
