import { SpinnerButton } from "components/shared/SpinnerButton";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import { changeEmail } from "domain/account/changeEmail";
import { userActions } from "domain/user/userActions";
import { changeEmailMutationResponse } from "generatedQueries/changeEmailMutation.graphql";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { Redirect } from "react-router";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import { PayloadError } from "relay-runtime";
import { userRoutes } from "routes/user/routes";
import { sharedMessages } from "shared/sharedMessages";

export const ChangeEmail: FC = () => {
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [isComplete, setIsComplete] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(false);

    if (error) {
        return <ErrorMessage heading={sharedMessages.requestFailedTitle} message={sharedMessages.requestFailedBody} />;
    }

    if (isComplete) {
        return <Redirect to={userRoutes.emailChanged.create({})} />;
    }

    return <form onSubmit={onSubmit}>
        <div className="form-group row">
            <Label className="col-form-label col-sm-2" for="new">
                <Message message={accountMessages.newEmailAddressLabel} />
            </Label>
            <Input id="new" className="col-sm-4" onChange={onChange} value={email} />
        </div>
        <SpinnerButton
            color="primary"
            disabled={!email}
            message={accountMessages.changeEmailAddressButtonLabel}
            isLoading={submitting} />
    </form>;

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        setSubmitting(true);
        changeEmail(environment, email, onChangeEmailResponse, onChangeEmailError);
        event.preventDefault();
    }

    function onChangeEmailResponse(_: changeEmailMutationResponse, errors?: PayloadError[] | null) {
        if (errors && errors.length > 0) {
            setError(true);
            setSubmitting(false);
        } else {
            dispatch(userActions.logout());
            setIsComplete(true);
        }
    }

    function onChangeEmailError() {
        setError(true);
        setSubmitting(false);
    }
};
