import moment from "moment";
import React, { FC } from "react";
import { FormattedDate } from "react-intl";
import styles from "./DatePickerHeader.module.scss";
import { DatePickerHeaderButtons } from "./DatePickerHeaderButtons";
import { datePickerMessages } from "./datePickerMessages";

export interface IDatePickerHeaderProps {
    date: Date;
    changeYear: (year: number) => void;
    changeMonth: (month: number) => void;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
}

export const DatePickerHeader: FC<IDatePickerHeaderProps> = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
}) => {
    return <div className={styles.header}>
        <DatePickerHeaderButtons
            disabled={prevMonthButtonDisabled}
            direction="previous"
            yearLabel={datePickerMessages.previousYearButtonLabel}
            monthLabel={datePickerMessages.previousMonthButtonLabel}
            onYearClicked={decreaseYear}
            onMonthClicked={decreaseMonth} />
        <DatePickerHeaderButtons
            disabled={nextMonthButtonDisabled}
            direction="next"
            yearLabel={datePickerMessages.nextYearButtonLabel}
            monthLabel={datePickerMessages.nextMonthButtonLabel}
            onYearClicked={increaseYear}
            onMonthClicked={increaseMonth} />
        <FormattedDate value={date} year="numeric" month="long" />
    </div>;

    function increaseYear() {
        addYears(1);
    }

    function decreaseYear() {
        addYears(-1);
    }

    function addYears(years: number) {
        changeYear(moment(date).add(years, "years").year());
    }
};
