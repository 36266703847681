/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicensingPhoneNumberType = "HOME" | "MOBILE" | "WORK" | "%future added value";
export type LicensingAccountInput = {
    dateOfBirth?: string | null;
    driverLicence?: string | null;
    firstName?: string | null;
    isSelfIdentifiedAsSeniorCitizen?: boolean | null;
    lastName?: string | null;
    middleName?: string | null;
    personNameSuffixId?: number | null;
    phoneNumbers?: Array<LicensingPhoneNumberInput> | null;
};
export type LicensingPhoneNumberInput = {
    number?: string | null;
    type: LicensingPhoneNumberType;
};
export type updateAccountMutationVariables = {
    account: LicensingAccountInput;
};
export type updateAccountMutationResponse = {
    readonly updateLicensingAccount: {
        readonly id: string;
    } | null;
};
export type updateAccountMutation = {
    readonly response: updateAccountMutationResponse;
    readonly variables: updateAccountMutationVariables;
};



/*
mutation updateAccountMutation(
  $account: LicensingAccountInput!
) {
  updateLicensingAccount(account: $account) {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "account"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "account",
                    "variableName": "account"
                }
            ],
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "updateLicensingAccount",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "updateAccountMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "updateAccountMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "6c7f234f113ae0c559a3f9d399571188",
            "id": null,
            "metadata": {},
            "name": "updateAccountMutation",
            "operationKind": "mutation",
            "text": "mutation updateAccountMutation(\n  $account: LicensingAccountInput!\n) {\n  updateLicensingAccount(account: $account) {\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '6dede0a2333679eb6a9c4f14aac77ec4';
export default node;
