import { PaymentAction } from "domain/payment/paymentActions";
import { combineReducers, Reducer } from "redux";

const donation: Reducer = (state: number | null = null, action: PaymentAction) => {
    if (action.type === "PAYMENT_SET_DONATION") {
        return action.payload;
    }

    if (action.type === "PAYMENT_CLEAR_CART") {
        return null;
    }

    return state;
};

const donationNotes: Reducer = (state: string | null = null, action: PaymentAction) => {
    if (action.type === "PAYMENT_SET_DONATIONNOTES") {
        return action.payload;
    }

    if (action.type === "PAYMENT_CLEAR_CART") {
        return null;
    }

    return state;
};

const renewingAnimalsInCart: Reducer = (state: string[] = [], action: PaymentAction) => {
    switch (action.type) {
        case "PAYMENT_ADD_RENEWING_ANIMALS_TO_CART":
            let newState = [...state];

            action.payload.forEach(animalId => {
                newState = [...newState, animalId];
            });

            return newState;

        case "PAYMENT_CLEAR_CART":
            return [];

        case "PAYMENT_REMOVE_RENEWING_ANIMAL_FROM_CART":
            if (state.indexOf(action.payload) > -1) {
                return state.filter(id => id !== action.payload);
            }

            return state;

        default:
            return state;
    }
};

export interface IPaymentState {
    renewingAnimalsInCart: string[];
    donation: number | null;
    donationNotes: string | null;
}

export const combinedPaymentReducer = combineReducers<IPaymentState, PaymentAction>({
    renewingAnimalsInCart,
    donation,
    donationNotes,
});
