/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HomeContent_configuration = {
    readonly licenceExpiryWarningDays: number;
    readonly organisationContact: string;
    readonly " $refType": "HomeContent_configuration";
};
export type HomeContent_configuration$data = HomeContent_configuration;
export type HomeContent_configuration$key = {
    readonly " $data"?: HomeContent_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"HomeContent_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeContent_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "licenceExpiryWarningDays",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = 'e5eae142b802137d481cdd3dd3823d21';
export default node;
