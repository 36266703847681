/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ConfirmFormQueryVariables = {};
export type ConfirmFormQueryResponse = {
    readonly account: {
        readonly canDiscloseToPublic: boolean | null;
        readonly isSubscribedToShelterNewsletter: boolean | null;
    } | null;
    readonly configuration: {
        readonly " $fragmentRefs": FragmentRefs<"Hideable_configuration">;
    } | null;
};
export type ConfirmFormQuery = {
    readonly response: ConfirmFormQueryResponse;
    readonly variables: ConfirmFormQueryVariables;
};



/*
query ConfirmFormQuery {
  account {
    canDiscloseToPublic
    isSubscribedToShelterNewsletter
    id
  }
  configuration {
    ...Hideable_configuration
  }
}

fragment Hideable_configuration on Configuration {
  hiddenFields
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canDiscloseToPublic",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSubscribedToShelterNewsletter",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ConfirmFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Hideable_configuration"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ConfirmFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hiddenFields",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "c822ba6c00e325361dfa25770c70f4c2",
            "id": null,
            "metadata": {},
            "name": "ConfirmFormQuery",
            "operationKind": "query",
            "text": "query ConfirmFormQuery {\n  account {\n    canDiscloseToPublic\n    isSubscribedToShelterNewsletter\n    id\n  }\n  configuration {\n    ...Hideable_configuration\n  }\n}\n\nfragment Hideable_configuration on Configuration {\n  hiddenFields\n}\n"
        }
    } as any;
})();
(node as any).hash = '1be82e925bd02ff42824b67c0b75343d';
export default node;
