import { Message } from "shared/components/Message";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import styles from "./DatePickerArrowButton.module.scss";

interface IProps {
    disabled: boolean;
    onClick?: () => void;
    direction: "next" | "previous";
    isDouble: boolean;
    label: MessageDescriptor;
}

export const DatePickerArrowButton: FC<IProps> = ({
    disabled,
    onClick,
    direction,
    isDouble,
    label,
}) => {
    const intl = useIntl();

    const icon: IconProp = direction === "next"
        ? (isDouble ? faAngleDoubleRight : faAngleRight)
        : (isDouble ? faAngleDoubleLeft : faAngleLeft);

    return <div
        onClick={!disabled ? onClick : undefined}
        className={classNames(direction === "next" ? styles.nextButton : styles.previousButton, disabled ? styles.disabled : styles.enabled)}
        title={intl.formatMessage(label)}>
        <FontAwesomeIcon icon={icon} size="lg" />
        <span className="d-none">
            <Message message={label} />
        </span>
    </div>;
};
