import { createRoute } from "infrastructure/routing";
import React, { FC } from "react";
import { Switch } from "react-router";
import { ChangePassword } from "routes/account/ChangePassword";
import { routes } from "routes/routes";
import { ConfirmSignUp } from "routes/user/ConfirmSignUp";
import { EmailChanged } from "routes/user/EmailChanged";
import { ForgotPassword } from "routes/user/ForgotPassword";
import { Login } from "routes/user/Login";
import { Logout } from "routes/user/Logout";
import { SignUp } from "routes/user/SignUp";
import { User } from "routes/user/User";
import { Verify } from "routes/user/Verify";

export const RouteIndex: FC = () => (
    <Switch>
        {createRoute(routes.user.signUp, { component: SignUp })}
        {createRoute(routes.user.confirm, { component: ConfirmSignUp })}
        {createRoute(routes.user.forgotPassword, { component: ForgotPassword })}
        {createRoute(routes.user.emailChanged, { component: EmailChanged })}
        {createRoute(routes.user.changePassword, { component: ChangePassword })}
        {createRoute(routes.user.login, { component: Login })}
        {createRoute(routes.user.logout, { component: Logout })}
        {createRoute(routes.user.verify, { component: Verify }, ["Unverified"])}
        {createRoute(routes.user.index, { component: User })}
    </Switch>
);
