/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import PersonNameSuffixesPaginationQuery from "./PersonNameSuffixesPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type PersonNameSuffixes_query = {
    readonly personNameSuffixes: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly displayId: number;
                readonly name: string | null;
            };
        }> | null;
    } | null;
    readonly " $refType": "PersonNameSuffixes_query";
};
export type PersonNameSuffixes_query$data = PersonNameSuffixes_query;
export type PersonNameSuffixes_query$key = {
    readonly " $data"?: PersonNameSuffixes_query$data;
    readonly " $fragmentRefs": FragmentRefs<"PersonNameSuffixes_query">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "personNameSuffixes"
    ];
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "suffixesAfter"
            },
            {
                "kind": "RootArgument",
                "name": "suffixesPageSize"
            },
            {
                "kind": "RootArgument",
                "name": "suffixesWhere"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "suffixesPageSize",
                    "cursor": "suffixesAfter",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "suffixesPageSize",
                        "cursor": "suffixesAfter"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": PersonNameSuffixesPaginationQuery
            }
        },
        "name": "PersonNameSuffixes_query",
        "selections": [
            {
                "alias": "personNameSuffixes",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "where",
                        "variableName": "suffixesWhere"
                    }
                ],
                "concreteType": "PersonNameSuffixConnection",
                "kind": "LinkedField",
                "name": "__PersonNameSuffixes_query_personNameSuffixes_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonNameSuffixEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PersonNameSuffix",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "id",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "displayId",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'c2d9dae03bcf1085d4fcd9debc41387e';
export default node;
