/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PayPalPayflowProviderQueryVariables = {
    amount: number;
    returnUrl: string;
    errorUrl?: string | null;
    shouldGetDetails: boolean;
};
export type PayPalPayflowProviderQueryResponse = {
    readonly payPalPayflowHppDetails?: {
        readonly secureTokenId: string | null;
        readonly secureToken: string | null;
    } | null;
};
export type PayPalPayflowProviderQuery = {
    readonly response: PayPalPayflowProviderQueryResponse;
    readonly variables: PayPalPayflowProviderQueryVariables;
};



/*
query PayPalPayflowProviderQuery(
  $amount: Decimal!
  $returnUrl: String!
  $errorUrl: String
  $shouldGetDetails: Boolean!
) {
  payPalPayflowHppDetails(amount: $amount, returnUrl: $returnUrl, errorUrl: $errorUrl) @include(if: $shouldGetDetails) {
    secureTokenId
    secureToken
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "amount"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "errorUrl"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "returnUrl"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "shouldGetDetails"
    } as any, v4 = [
        {
            "condition": "shouldGetDetails",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "amount",
                            "variableName": "amount"
                        },
                        {
                            "kind": "Variable",
                            "name": "errorUrl",
                            "variableName": "errorUrl"
                        },
                        {
                            "kind": "Variable",
                            "name": "returnUrl",
                            "variableName": "returnUrl"
                        }
                    ],
                    "concreteType": "LicensingPayflowSecureToken",
                    "kind": "LinkedField",
                    "name": "payPalPayflowHppDetails",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "secureTokenId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "secureToken",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "PayPalPayflowProviderQuery",
            "selections": (v4 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v2 /*: any*/),
                (v1 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "PayPalPayflowProviderQuery",
            "selections": (v4 /*: any*/)
        },
        "params": {
            "cacheID": "22c4ba8541a09e188ddb423ce84640b9",
            "id": null,
            "metadata": {},
            "name": "PayPalPayflowProviderQuery",
            "operationKind": "query",
            "text": "query PayPalPayflowProviderQuery(\n  $amount: Decimal!\n  $returnUrl: String!\n  $errorUrl: String\n  $shouldGetDetails: Boolean!\n) {\n  payPalPayflowHppDetails(amount: $amount, returnUrl: $returnUrl, errorUrl: $errorUrl) @include(if: $shouldGetDetails) {\n    secureTokenId\n    secureToken\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'c9308bfa51a01d88b5c5296a8e23060c';
export default node;
