/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AddressFieldsQueryVariables = {};
export type AddressFieldsQueryResponse = {
    readonly deliveryTypes: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly displayId: number;
                readonly name: string;
            };
        }> | null;
    } | null;
};
export type AddressFieldsQuery = {
    readonly response: AddressFieldsQueryResponse;
    readonly variables: AddressFieldsQueryVariables;
};



/*
query AddressFieldsQuery {
  deliveryTypes {
    edges {
      node {
        displayId
        name
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayId",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AddressFieldsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeliveryTypeConnection",
                    "kind": "LinkedField",
                    "name": "deliveryTypes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DeliveryTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DeliveryType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AddressFieldsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeliveryTypeConnection",
                    "kind": "LinkedField",
                    "name": "deliveryTypes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DeliveryTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DeliveryType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "433f54125271c7e9da180a0bded1ed42",
            "id": null,
            "metadata": {},
            "name": "AddressFieldsQuery",
            "operationKind": "query",
            "text": "query AddressFieldsQuery {\n  deliveryTypes {\n    edges {\n      node {\n        displayId\n        name\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '4bbcbd0b483e08a221d831671b4c082f';
export default node;
