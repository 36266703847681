import graphql from "babel-plugin-relay/macro";
import { confirmAccountMutation, confirmAccountMutationResponse } from "generatedQueries/confirmAccountMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

export interface IConfirmAccountMutationVariables {
    canDiscloseToPublic: boolean | null;
    isSubscribedToShelterNewsletter: boolean | null;
}

const mutation = graphql`
    mutation confirmAccountMutation($canDiscloseToPublic: Boolean!, $isSubscribedToShelterNewsletter: Boolean!) {
        updateLicensingAllowedDisclosureGroup(canDiscloseToPublic: $canDiscloseToPublic) {
            id
            ...Header_account
        }
        updateIsSubscribedToShelterNewsletter(isSubscribedToShelterNewsletter: $isSubscribedToShelterNewsletter) {
            id
            ...Header_account
        }
    }`;

export function confirmAccount(
    environment: IEnvironment,
    variables: IConfirmAccountMutationVariables,
    onCompleted: (response: confirmAccountMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<confirmAccountMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                canDiscloseToPublic: variables.canDiscloseToPublic || false,
                isSubscribedToShelterNewsletter: variables.isSubscribedToShelterNewsletter || false,
            },
        }
    );
}
