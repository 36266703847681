import styles from "components/layout/Header.module.scss";
import { HeaderLogo_configuration } from "generatedQueries/HeaderLogo_configuration.graphql";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer, graphql } from "react-relay";
import { sharedMessages } from "shared/sharedMessages";

interface IGraphProps {
    configuration: HeaderLogo_configuration | null;
}

interface ICustomLogoDetails {
    isEnabled: boolean;
    logoUrl: string | null;
    logoSize: number | null;
}

const InternalHeaderLogo: FC<IGraphProps> = ({
    configuration,
}) => {
    const intl = useIntl();

    const { logoSize, logoUrl } = useMemo(() => {
        const value = configuration?.logoCustomization?.value;

        let parsedValue: object;
        try {
            parsedValue = value && JSON.parse(value);
        } catch (e) {
            parsedValue = { isEnabled: false };
        }

        const logoDetails = (isValidCustomLogoDetails(parsedValue) && parsedValue.isEnabled && parsedValue) || null;

        return {
            logoSize: typeof logoDetails?.logoSize === "number" ? logoDetails.logoSize : 45,
            logoUrl: logoDetails?.logoUrl || configuration?.configuration?.logoUri || undefined,
        };
    }, [configuration]);

    return (
        <img style={{ height: logoSize }} src={logoUrl} className={styles.logo} alt={intl.formatMessage(sharedMessages.logoAltMessage)} />
    );
};

function isValidCustomLogoDetails(parsedSiteCustomisationValue: object): parsedSiteCustomisationValue is ICustomLogoDetails {
    return !!parsedSiteCustomisationValue && (parsedSiteCustomisationValue as ICustomLogoDetails) !== undefined;
}

export const HeaderLogo = createFragmentContainer(InternalHeaderLogo, {
    configuration: graphql`
        fragment HeaderLogo_configuration on Query {
            configuration {
                logoUri
            }
            logoCustomization: siteCustomisation(application: "licensing", name: "logo") {
                value
            }
        }`,
});
