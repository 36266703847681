/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PayPalPayflowFields_configuration = {
    readonly licensingPayPalPayflowMode: string | null;
    readonly licensingPayPalPayflowRedirectUri: string | null;
    readonly " $refType": "PayPalPayflowFields_configuration";
};
export type PayPalPayflowFields_configuration$data = PayPalPayflowFields_configuration;
export type PayPalPayflowFields_configuration$key = {
    readonly " $data"?: PayPalPayflowFields_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"PayPalPayflowFields_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayPalPayflowFields_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "licensingPayPalPayflowMode",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "licensingPayPalPayflowRedirectUri",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '9404c04ff5db43a89ae57e209924477f';
export default node;
