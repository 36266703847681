import { SpinnerButton } from "components/shared/SpinnerButton";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { toastActions } from "domain/toast/toastActions";
import { userActions } from "domain/user/userActions";
import { userMessages } from "domain/user/userMessages";
import { verifyEmail } from "domain/user/verifyEmail";
import { verifyEmailMutationResponse } from "generatedQueries/verifyEmailMutation.graphql";
import { setToken } from "infrastructure/token";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { Redirect } from "react-router";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import { PayloadError } from "relay-runtime";
import { routes } from "routes/routes";

export const VerifyCode: FC = () => {
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();

    const [code, setCode] = useState("");
    const [isComplete, setIsComplete] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(false);

    if (isComplete) {
        return <Redirect to={routes.account.index.create({})} />;
    }

    return <>
        <form className="form-inline" onSubmit={onSubmit}>
            <Label for="verificationCode">
                <Message message={userMessages.verificationCodeLabel} />
            </Label>
            <Input id="verificationCode" className="mx-2" onChange={onChange} value={code} />
            <SpinnerButton
                color="primary"
                disabled={!code}
                message={userMessages.confirmEmailLabel}
                isLoading={submitting} />
        </form>
        {error && <ErrorMessage className="mt-3 mb-0" message={userMessages.incorrectVerificationCodeMessage} />}
    </>;

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        const matches = event.target.value.match(/\d{0,6}/);
        setCode((matches && matches[0]) || "");
    }

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        setSubmitting(true);
        verifyEmail(environment, code, onVerifyCompleted, onVerifyError);
        event.preventDefault();
    }

    function onVerifyCompleted(response: verifyEmailMutationResponse, errors?: PayloadError[] | null) {
        if ((errors && errors.length > 0) || !response.verifyLicensingAccountEmail) {
            onVerifyError();
        } else {
            setToken(response.verifyLicensingAccountEmail);
            dispatch(userActions.setToken(response.verifyLicensingAccountEmail));
            dispatch(toastActions.customSuccessToast(userMessages.emailSuccessfullyVerifiedMessage));
            setIsComplete(true);
        }
    }

    function onVerifyError() {
        setError(true);
        setSubmitting(false);
        dispatch(toastActions.toastFailure());
    }
};
