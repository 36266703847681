/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ExpiringSoon_account = {
    readonly expiringSoon: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly licenceExpiry: string | null;
            };
        }> | null;
    } | null;
    readonly " $refType": "ExpiringSoon_account";
};
export type ExpiringSoon_account$data = ExpiringSoon_account;
export type ExpiringSoon_account$key = {
    readonly " $data"?: ExpiringSoon_account$data;
    readonly " $fragmentRefs": FragmentRefs<"ExpiringSoon_account">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filter"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExpiringSoon_account",
    "selections": [
        {
            "alias": "expiringSoon",
            "args": [
                {
                    "kind": "Variable",
                    "name": "filter",
                    "variableName": "filter"
                }
            ],
            "concreteType": "LicensingAnimalConnection",
            "kind": "LinkedField",
            "name": "animals",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicensingAnimalEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicensingAnimal",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "licenceExpiry",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Account",
    "abstractKey": null
} as any;
(node as any).hash = '146031a5025d434eb8ca25cc01734e53';
export default node;
