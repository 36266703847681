import { InfoMessage } from "components/shared/InfoMessage";
import { LoadingMessage } from "components/shared/LoadingMessage";
import { Money } from "components/shared/Money";
import graphql from "babel-plugin-relay/macro";
import { IPaymentFormChildProps } from "components/payment/PaymentForm";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { paymentMessages } from "domain/payment/paymentMessages";
import { GlobalPaymentsButtonQuery } from "generatedQueries/GlobalPaymentsButtonQuery.graphql";
import { GlobalPaymentsButton_configuration } from "generatedQueries/GlobalPaymentsButton_configuration.graphql";
import React, { FC, ReactNode, useCallback, useState } from "react";
import { createFragmentContainer } from "react-relay";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { routes } from "routes/routes";
import { Query } from "shared/components/Query";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

export const messages = mapMessages("components.payment.globalPayments.globalPaymentsButton", {
    payWithGlobalPaymentsMessage: "Pay {amount} with Global Payments",
});

interface IGraphQlProps {
    configuration: GlobalPaymentsButton_configuration;
}

interface IProps {
    allPaymentRowsAreValid: boolean;
    children: (authoriseRequestJson: string, onPaymentSubmit: () => void) => ReactNode;
    termsAndConditionsAccepted: boolean;
}

type Props = Pick<IPaymentFormChildProps, "amountToCharge" | "errorMessages" | "isPaymentProcessing"> & IGraphQlProps & IProps;

const GlobalPaymentsButtonInternal: FC<Props> = ({
    amountToCharge,
    allPaymentRowsAreValid,
    children,
    configuration,
    errorMessages,
    isPaymentProcessing,
    termsAndConditionsAccepted,
}) => {
    const [isInvalidAmountWarningDisplayed, setIsInvalidAmountWarningDisplayed] = useState(false);
    const [isTermsAndConditionsWarningDisplayed, setIsTermsAndConditionsWarningDisplayed] = useState(false);
    const [isInvalidPaymentRowWarningDisplayed, setIsInvalidPaymentRowWarningDisplayed] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    const onPaymentSubmit = useCallback(() => {
        setIsPaymentModalOpen(false);
    }, []);

    const validators = [
        { isValid: amountToCharge > 0, setDisplayWarningMessage: setIsInvalidAmountWarningDisplayed },
        { isValid: termsAndConditionsAccepted, setDisplayWarningMessage: setIsTermsAndConditionsWarningDisplayed },
        { isValid: allPaymentRowsAreValid, setDisplayWarningMessage: setIsInvalidPaymentRowWarningDisplayed },
    ];

    const isPaymentFormValid =
        amountToCharge > 0 &&
        termsAndConditionsAccepted &&
        allPaymentRowsAreValid;

    const payWithGlobalPaymentsMessage =
        <Message
            message={messages.payWithGlobalPaymentsMessage}
            values={{ amount: <Money amount={amountToCharge} currency={configuration.currency} /> }} />;

    return <>
        {
            isInvalidAmountWarningDisplayed &&
            <InfoMessage
                message={paymentMessages.nothingToPayWarningMessage}
                messageValues={{
                    pets: msg => <Link to={routes.pets.index.create({})}>{msg}</Link>,
                    add: msg => <Link to={routes.licence.options.create({})}>{msg}</Link>,
                }} />
        }
        {
            isTermsAndConditionsWarningDisplayed &&
            <WarningMessage message={paymentMessages.termsAndConditionsNotAcceptedWarningMessage} />
        }
        {
            isInvalidPaymentRowWarningDisplayed &&
            <WarningMessage
                message={paymentMessages.invalidPaymentRowsWarningMessage}
                messageValues={{ organisationContact: configuration.organisationContact }} />
        }
        {errorMessages}
        <div className="text-center mb-3">
            <Button
                type="button"
                className="w-75"
                color={isPaymentFormValid ? "primary" : "secondary"}
                disabled={isPaymentProcessing}
                size="lg"
                onClick={onClick}
                data-cy="payButton">
                {
                    !isPaymentProcessing
                        ? payWithGlobalPaymentsMessage
                        : <span className="align-middle">
                            <Spinner className="icon-button mr-2" />
                            <Message message={paymentMessages.paymentIsProcessing} />
                        </span>
                }
            </Button>
        </div>
        <Modal isOpen={isPaymentModalOpen} toggle={toggleModal} backdrop="static">
            <ModalHeader toggle={toggleModal}>{payWithGlobalPaymentsMessage}</ModalHeader>
            <ModalBody>
                <Query<GlobalPaymentsButtonQuery>
                    query={query}
                    variables={{ amount: amountToCharge }}>
                    {({ error, props }) => {
                        if (error) {
                            return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                        }

                        if (!props) {
                            return <LoadingMessage />;
                        }

                        if (!props.globalPaymentsHppPaymentRequestJson) {
                            return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                        }

                        return children(props.globalPaymentsHppPaymentRequestJson, onPaymentSubmit);
                    }}
                </Query>
            </ModalBody>
        </Modal>
    </>;

    function onClick() {
        if (isPaymentProcessing) {
            return;
        }

        let hasValidationErrors = false;
        for (const validator of validators) {
            if (validator.isValid) {
                validator.setDisplayWarningMessage(false);
                continue;
            }

            hasValidationErrors = true;
            validator.setDisplayWarningMessage(true);
        }

        if (hasValidationErrors) {
            return;
        }

        setIsPaymentModalOpen(true);
    }

    function toggleModal() {
        setIsPaymentModalOpen(!isPaymentModalOpen);
    }
};

export const GlobalPaymentsButton = createFragmentContainer(GlobalPaymentsButtonInternal, {
    configuration: graphql`
        fragment GlobalPaymentsButton_configuration on Configuration {
            currency
            organisationContact
        }`,
});

const query = graphql`
    query GlobalPaymentsButtonQuery($amount: Decimal!) {
        globalPaymentsHppPaymentRequestJson(amount: $amount)
    }`;
