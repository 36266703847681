import { Message } from "shared/components/Message";
import { Checkbox } from "informed";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";

interface IProps {
    field: string;
    message: MessageDescriptor;
    id?: string;
}

export const LabelledCheckbox: FC<IProps> = ({
    field,
    message,
    id,
}) =>
    <div
        className="form-group form-check"
    >
        <label
            className="form-check-label"
        >
            <Checkbox
                id={id}
                field={field}
                className="form-check-input"
            />
            <Message
                message={message}
            />
        </label>
    </div>;
