/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ChangeLicensingPasswordError = "NEWPASSWORDINVALID" | "OLDPASSWORDINVALID" | "%future added value";
export type ChangeLicensingPasswordInput = {
    newPassword: string;
    oldPassword: string;
};
export type changePasswordMutationVariables = {
    input: ChangeLicensingPasswordInput;
};
export type changePasswordMutationResponse = {
    readonly changeLicensingPassword: {
        readonly wasSuccessful: boolean;
        readonly error: ChangeLicensingPasswordError | null;
    } | null;
};
export type changePasswordMutation = {
    readonly response: changePasswordMutationResponse;
    readonly variables: changePasswordMutationVariables;
};



/*
mutation changePasswordMutation(
  $input: ChangeLicensingPasswordInput!
) {
  changeLicensingPassword(input: $input) {
    wasSuccessful
    error
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ChangeLicensingPassword",
            "kind": "LinkedField",
            "name": "changeLicensingPassword",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "wasSuccessful",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "changePasswordMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "changePasswordMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "58d8ed81bff1b56f56c4627c80bf22ea",
            "id": null,
            "metadata": {},
            "name": "changePasswordMutation",
            "operationKind": "mutation",
            "text": "mutation changePasswordMutation(\n  $input: ChangeLicensingPasswordInput!\n) {\n  changeLicensingPassword(input: $input) {\n    wasSuccessful\n    error\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'd13fd19794cf1e94f6043007584ea507';
export default node;
