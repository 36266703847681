import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { intlActions } from "store/intl/intlActions";

export const Intl: FC = props => {
    const dispatch = useDispatch();
    const intl = useIntl();
    dispatch(intlActions.update(intl));
    return <>{props.children}</>;
};
