/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LogLevel = "DEBUG" | "ERROR" | "FATAL" | "INFORMATION" | "VERBOSE" | "WARNING" | "%future added value";
export type KeyValueOfStringAndStringInput = {
    key: string;
    value: string;
};
export type logEventMutationVariables = {
    logLevel: LogLevel;
    message?: string | null;
    request?: string | null;
    application: string;
    metadata?: Array<KeyValueOfStringAndStringInput> | null;
};
export type logEventMutationResponse = {
    readonly logEvent: string | null;
};
export type logEventMutation = {
    readonly response: logEventMutationResponse;
    readonly variables: logEventMutationVariables;
};



/*
mutation logEventMutation(
  $logLevel: LogLevel!
  $message: String
  $request: String
  $application: String!
  $metadata: [KeyValueOfStringAndStringInput!]
) {
  logEvent(logLevel: $logLevel, message: $message, request: $request, application: $application, metadata: $metadata)
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "application"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "logLevel"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "message"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "metadata"
    } as any, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "request"
    } as any, v5 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "application",
                    "variableName": "application"
                },
                {
                    "kind": "Variable",
                    "name": "logLevel",
                    "variableName": "logLevel"
                },
                {
                    "kind": "Variable",
                    "name": "message",
                    "variableName": "message"
                },
                {
                    "kind": "Variable",
                    "name": "metadata",
                    "variableName": "metadata"
                },
                {
                    "kind": "Variable",
                    "name": "request",
                    "variableName": "request"
                }
            ],
            "kind": "ScalarField",
            "name": "logEvent",
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "logEventMutation",
            "selections": (v5 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v4 /*: any*/),
                (v0 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "logEventMutation",
            "selections": (v5 /*: any*/)
        },
        "params": {
            "cacheID": "5c404ca3006449b2b69fa8d9de2fd564",
            "id": null,
            "metadata": {},
            "name": "logEventMutation",
            "operationKind": "mutation",
            "text": "mutation logEventMutation(\n  $logLevel: LogLevel!\n  $message: String\n  $request: String\n  $application: String!\n  $metadata: [KeyValueOfStringAndStringInput!]\n) {\n  logEvent(logLevel: $logLevel, message: $message, request: $request, application: $application, metadata: $metadata)\n}\n"
        }
    } as any;
})();
(node as any).hash = '0c43dacd754a474304a92381284889ec';
export default node;
