import { IntlShape } from "react-intl";
import { ActionType, createAction } from "typesafe-actions";

const update = createAction("INTL_UPDATE", resolve => {
    return (intl: IntlShape) => {
        return resolve(intl);
    };
});

export const intlActions = {
    update,
};

export type IntlAction = ActionType<typeof intlActions>;
