import { ValidationErrorPane } from "components/shared/ValidationErrorPane";
import { ValidationErrors } from "components/shared/validations";
import classNames from "classnames";
import styles from "components/licence/LicenceType.module.scss";
import { ILicenseTypeSelection } from "components/licence/LicenseTypeProvider";
import { FieldProps, FormValues, useField } from "informed";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { withDefaultValidation } from "shared/components/formInputs/withDefaultValidation";
import { ISelectOption, ReactSelect } from "shared/components/ReactSelect";

interface IProps {
    licenseTypes: ILicenseTypeSelection[];
    isLoading: boolean;
    validate?: (value: string) => ValidationErrors;
    alwaysValidate?: boolean;
    onLicenceTypeSelected?: (licenceTypeId: number | undefined, licenceExpiry: Moment | "LIFETIME" | undefined) => void;
}

type Props<T extends FormValues = FormValues> = IProps & FieldProps<number, T>;

const LicenceTypeInput = <T extends FormValues>(props: Props<T>) => {
    const { fieldState, fieldApi, render } = useField({ ...props });
    const {
        alwaysValidate,
        field,
        id,
        licenseTypes,
        isLoading,
        onLicenceTypeSelected,
    } = props;

    // This is to prevent an infinite loop
    const [hasInitialOnLicenceTypeSelectedBeenCalled, setHasInitialOnLicenceTypeSelectedBeenCalled] = useState(false);

    const onSelected = useCallback((option: ISelectOption) => {
        if (onLicenceTypeSelected) {
            const licenceType = licenseTypes.find(l => l.licenseTypeId === option.value);

            if (licenceType) {
                if (licenceType.isLifetime) {
                    onLicenceTypeSelected(licenceType.licenseTypeId, "LIFETIME");
                } else if (licenceType.expiryDate) {
                    onLicenceTypeSelected(licenceType.licenseTypeId, moment(licenceType.expiryDate.calculatedExpiryDate));
                } else if (licenceType.expiryMonths) {
                    onLicenceTypeSelected(licenceType.licenseTypeId, moment().add(licenceType.expiryMonths, "months"));
                } else {
                    onLicenceTypeSelected(licenceType.licenseTypeId, moment());
                }
            } else {
                onLicenceTypeSelected(undefined, undefined);
            }
        }

        fieldApi.setValue(+option.value);
        fieldApi.setTouched(true);
    }, [fieldApi, licenseTypes, onLicenceTypeSelected]);

    useEffect(() => {
        if (alwaysValidate && !fieldApi.getTouched()) {
            fieldApi.setTouched(true);
            fieldApi.validate();
        }
    }, [alwaysValidate, fieldApi]);

    const licenseTypeOptions = licenseTypes.map(t => {
        return {
            label: t.description,
            value: t.licenseTypeId,
        };
    });

    useEffect(() => {
        if (licenseTypeOptions.length > 0) {
            const selectedOption = licenseTypeOptions.find(t => t.value === fieldState.value);
            if (selectedOption === undefined && fieldState.value !== undefined) {
                if (licenseTypeOptions.length === 1 && fieldState.value !== licenseTypeOptions[0].value) {
                    onSelected(licenseTypeOptions[0]);
                } else if (fieldState.value !== undefined) {
                    fieldApi.reset();
                }
            } else if (selectedOption !== undefined && !hasInitialOnLicenceTypeSelectedBeenCalled) {
                onSelected(selectedOption);
                setHasInitialOnLicenceTypeSelectedBeenCalled(true);
            }
        }
    }, [fieldApi, fieldState.value, hasInitialOnLicenceTypeSelectedBeenCalled, licenseTypeOptions, onSelected]);

    return render(
        <div className={classNames(styles.formSelect, fieldState.touched && ["was-validated", fieldState.error ? "invalid" : "valid"], "form-select")}>
            <ReactSelect
                className="input-licence-type"
                isSearchable={true}
                isDisabled={isLoading}
                isLoading={isLoading}
                value={fieldState.value}
                options={licenseTypes.map(t => {
                    return {
                        label: t.description,
                        value: t.licenseTypeId,
                    };
                })}
                onSelected={onSelected}
                inputId={id}
                aria-required={true}
            />
            <ValidationErrorPane style={{ display: "inline" }} field={field} />
        </div>
    );
};

export const LicenceType = withDefaultValidation<number, FormValues>()(LicenceTypeInput);
