import { add, isValid, parseISO } from "date-fns";
import moment, { Moment } from "moment";

type DateInput = string | Moment | Date;
export type UnitsToAdd = "months" | "days" | "hours" | "minutes" | "seconds";

function formatDate(date: DateInput, format: string): string {
    const momentDate = moment(date);
    return momentDate.format(format);
}

export function getValidatedDateOrNull(isoFormattedDate: string): Date | null {
    if (!isoFormattedDate) {
        return null;
    }

    const isoParsed = parseISO(isoFormattedDate);

    return isValid(isoParsed)
        ? isoParsed
        : null;
}

export function formatIso8601Date(date: DateInput): string {
    return formatDate(date, "YYYY-MM-DD");
}

export function datePlus(date: Date, amount: number, unit: UnitsToAdd): Date {
    const duration: Duration = {};

    duration[unit] = amount;

    return add(date, duration);
}
