import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "components/payment/PaymentRowWarningIcon.module.scss";
import { Message } from "shared/components/Message";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import React, { FC, useState } from "react";
import { MessageDescriptor } from "react-intl";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import PopoverHeader from "reactstrap/lib/PopoverHeader";

interface IProps {
    id: string;
    colour: "warning" | "danger";
    heading?: MessageDescriptor;
    message: MessageDescriptor;
    values?: { [key: string]: string | PrimitiveType | React.ReactElement | FormatXMLElementFn };
}

export const PaymentRowWarningIcon: FC<IProps> = ({
    id,
    colour,
    heading,
    message,
    values,
}) => {
    const [displayPopover, setDisplayPopover] = useState(false);

    const warningId = `warning-${id}`;

    return <>
        <span id={warningId} className="ml-2" onClick={togglePopover}>
            <FontAwesomeIcon
                icon={faExclamationTriangle}
                className={classNames(`text-${colour}`, styles.warningIcon)} />
        </span>
        <Popover trigger="legacy" placement="bottom" isOpen={displayPopover} toggle={togglePopover} target={warningId}>
            {
                heading &&
                <PopoverHeader>
                    <Message message={heading} />
                </PopoverHeader>
            }
            <PopoverBody>
                <Message message={message} values={values} />
            </PopoverBody>
        </Popover>
    </>;

    function togglePopover() {
        setDisplayPopover(!displayPopover);
    }
};
