/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Animal_query = {
    readonly genders: ReadonlyArray<{
        readonly genderId: number;
        readonly name: string;
    }> | null;
    readonly licensingAnimalTypes: ReadonlyArray<{
        readonly id: string;
        readonly description: string | null;
    }> | null;
    readonly configuration: {
        readonly microchipValidationSettings: {
            readonly microchipLength: number;
            readonly minChipLength: number;
        };
        readonly " $fragmentRefs": FragmentRefs<"Rabies_configuration" | "Hideable_configuration">;
    } | null;
    readonly account: {
        readonly " $fragmentRefs": FragmentRefs<"Rabies_account">;
    } | null;
    readonly " $refType": "Animal_query";
};
export type Animal_query$data = Animal_query;
export type Animal_query$key = {
    readonly " $data"?: Animal_query$data;
    readonly " $fragmentRefs": FragmentRefs<"Animal_query">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Animal_query",
    "selections": [
        {
            "alias": "genders",
            "args": null,
            "concreteType": "LicensingGender",
            "kind": "LinkedField",
            "name": "licensingGenders",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "genderId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "LicensingAnimalType",
            "kind": "LinkedField",
            "name": "licensingAnimalTypes",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "MicrochipValidationSettings",
                    "kind": "LinkedField",
                    "name": "microchipValidationSettings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "microchipLength",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minChipLength",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Rabies_configuration"
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Hideable_configuration"
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Rabies_account"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any;
(node as any).hash = '2d0d1137987ebe6fb8ba21e11fc0c20b';
export default node;
