import { SpinnerButton } from "components/shared/SpinnerButton";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { Form, FormApi, FormState } from "informed";
import isEqual from "lodash/isEqual";
import React, { ReactElement, ReactNode, useState } from "react";
import { MessageDescriptor } from "react-intl";
import { useRelayEnvironment } from "react-relay";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { IEnvironment, PayloadError } from "relay-runtime";
import { sharedMessages } from "shared/sharedMessages";

export interface IWarningMessageProps {
    titleMessage: string;
    warningMessage: ReactNode;
    buttons?: (options: {
        cancelAction: () => void;
        submitAction: () => void
        renderCancelButton: () => ReactNode;
        renderSubmitButton: () => ReactNode;
    }) => ReactElement;
}

type MutationFunc<TRequest, TResponse> = (environment: IEnvironment, request: TRequest, onCompleted: (response: TResponse, errors?: PayloadError[] | null) => void, onError: (error: Error) => void) => void;

type RenderPropChildren<T> = ((props: { formApi: FormApi<T>, formState: FormState<T> }) => React.ReactNode);
type RenderErrorPropChildren = ((props: { errors: PayloadError[] | Error }) => React.ReactNode);
interface INamedSlotChildren<T> {
    error?: RenderErrorPropChildren;
    form: RenderPropChildren<T>;
}

type Children<T> = React.ReactNode | RenderPropChildren<T> | INamedSlotChildren<T>;

interface IProps<TRequest, TResponse> {
    backButtonMessage?: MessageDescriptor;
    children?: Children<TRequest>;
    initialValues?: TRequest;
    nextButtonMessage?: MessageDescriptor;
    onNext?: (response: TResponse | null) => void;
    onPrevious?: () => void;
    onError?: (errors: PayloadError[] | Error) => void;
    saveButtonMessage?: MessageDescriptor;
    alwaysSubmit?: boolean;
    shouldDisplayWarning?: (values: TRequest) => IWarningMessageProps[] | false;
    toastSuccess?: () => void;
    toastFailure?: () => void;
}

interface IInjectedProps<TRequest, TResponse> {
    mutationFunc: MutationFunc<TRequest, TResponse>;
}

export function ErrorHandlingMutationForm<TRequest, TResponse>(props: IProps<TRequest, TResponse> & IInjectedProps<TRequest, TResponse>) {
    const environment = useRelayEnvironment();
    const [errors, setErrors] = useState<PayloadError[] | Error | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [warningMessages, setWarningMessages] = useState<IWarningMessageProps[]>([]);

    const {
        backButtonMessage,
        children,
        initialValues,
        nextButtonMessage,
        onPrevious,
        saveButtonMessage,
        shouldDisplayWarning,
        toastSuccess,
        toastFailure,
    } = props;

    return <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        noValidate={true}
        role="form"
    >
        {({ formApi, formState }) => (
            <>
                {renderChildren(formApi as FormApi<TRequest>, formState as FormState<TRequest>)}
                {
                    renderErrors()
                }
                <div>
                    {onPrevious && <Button
                        colour="default"
                        type="button"
                        className="float-left"
                        onClick={onPrevious}>
                        <Message message={backButtonMessage || sharedMessages.backButtonLabel} />
                    </Button>}
                    {shouldShowSubmitButton(initialValues, formState)
                        ? <SpinnerButton className="float-right" color="primary" isLoading={isSubmitting} message={saveButtonMessage || sharedMessages.saveLabel} />
                        : <Button
                            className="float-right"
                            color="primary"
                            type="button"
                            onClick={onNext}>
                            <Message message={nextButtonMessage || sharedMessages.nextButtonLabel} />
                        </Button>}
                </div>
                <Modal isOpen={warningMessages.length > 0} toggle={toggleWarningMessage} labelledBy="formWarning">
                    <ModalHeader toggle={toggleWarningMessage} id="formWarning">
                        {warningMessages[0]?.titleMessage}
                    </ModalHeader>
                    <ModalBody>
                        {warningMessages[0]?.warningMessage}
                    </ModalBody>
                    <ModalFooter>
                        {
                            warningMessages[0]?.buttons
                                ? warningMessages[0].buttons({
                                    cancelAction: toggleWarningMessage,
                                    submitAction: onWarningAccepted(formApi),
                                    renderCancelButton,
                                    renderSubmitButton: renderSubmitButton(formApi),
                                })
                                : <>
                                    {renderSubmitButton(formApi)()}
                                    {renderCancelButton()}
                                </>
                        }
                    </ModalFooter>
                </Modal>
            </>
        )}
    </Form>;

    function renderSubmitButton(formApi: FormApi<TRequest>) {
        return () => {
            return <SpinnerButton
                color="primary"
                isLoading={isSubmitting}
                message={saveButtonMessage || sharedMessages.saveLabel}
                onClick={onWarningAccepted(formApi)}
            />;
        };
    }

    function renderCancelButton() {
        return <Button type="button" color="secondary" onClick={toggleWarningMessage}>
            <Message message={sharedMessages.cancelButtonLabel} />
        </Button>;
    }

    function onWarningAccepted(formApi: FormApi<TRequest>) {
        return () => {
            formApi.submitForm();
        };
    }

    function toggleWarningMessage() {
        setWarningMessages(messages => {
            return messages.slice(1);
        });
    }

    function onNext() {
        if (props.onNext) {
            props.onNext(null);
        }
    }

    function onSubmit(request: TRequest) {
        if (warningMessages.length === 0 && shouldDisplayWarning) {
            const warningProps = shouldDisplayWarning(request);

            if (warningProps) {
                setWarningMessages(warningProps);
                return;
            }
        }

        if (warningMessages.length > 1) {
            toggleWarningMessage();
            return;
        }

        setIsSubmitting(true);
        setWarningMessages([]);
        try {
            props.mutationFunc(environment, request, onResponse, onMutationError);
        }
        catch {
            setIsSubmitting(false);
        }
    }

    function onResponse(response: TResponse, payloadErrors?: PayloadError[] | null) {
        if (payloadErrors) {
            if (props.onError) {
                props.onError(payloadErrors);
            }

            if (toastFailure) {
                toastFailure();
            }

            setErrors(payloadErrors);
            setIsSubmitting(false);
        } else if (props.onNext) {
            if (toastSuccess) {
                toastSuccess();
            }

            setIsSubmitting(false);
            props.onNext(response);
        } else {
            if (toastSuccess) {
                toastSuccess();
            }

            setIsSubmitting(false);
        }
    }

    function onMutationError(error: Error) {
        if (props.onError) {
            props.onError(error);
        }

        if (toastFailure) {
            toastFailure();
        }

        setErrors(error);
        setIsSubmitting(false);
    }

    function shouldShowSubmitButton(original: TRequest | undefined, formState: FormState<TRequest>) {
        return props.alwaysSubmit || (!props.onNext || !original || !isEqual(original, { ...original, ...formState.values }) || !isEqual(formState.errors, {}));
    }

    function renderChildren(formApi: FormApi<TRequest>, formState: FormState<TRequest>) {
        if (isRenderPropChildren(children)) {
            return children({ formApi, formState });
        } else if (isNamedSlotChildren(children)) {
            return children.form({ formApi, formState });
        } else {
            return children;
        }
    }

    function renderErrors() {
        if (errors) {
            if (isNamedSlotChildren(children) && children.error) {
                return children.error({ errors });
            } else {
                return <ErrorMessage heading={sharedMessages.requestFailedTitle} message={sharedMessages.saveFailedErrorMessage} />;
            }
        }

        return null;
    }
}

function isRenderPropChildren<T>(children: React.ReactNode | RenderPropChildren<T>): children is RenderPropChildren<T> {
    return (typeof children) === "function";
}

function isNamedSlotChildren<T>(children: Children<T>): children is INamedSlotChildren<T> {
    if ((typeof children) !== "object") {
        return false;
    }

    const namedSlotChildren = children as INamedSlotChildren<T>;
    return namedSlotChildren.form !== undefined;
}
