/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LoginQueryVariables = {};
export type LoginQueryResponse = {
    readonly configuration: {
        readonly " $fragmentRefs": FragmentRefs<"NewPasswordRequiredChallengeFields_configuration">;
    } | null;
};
export type LoginQuery = {
    readonly response: LoginQueryResponse;
    readonly variables: LoginQueryVariables;
};



/*
query LoginQuery {
  configuration {
    ...NewPasswordRequiredChallengeFields_configuration
  }
}

fragment NewPasswordRequiredChallengeFields_configuration on Configuration {
  passwordValidationRegex
  collectNameOnSignUp
}
*/

const node: ConcreteRequest = {
    "fragment": {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "LoginQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Configuration",
                "kind": "LinkedField",
                "name": "configuration",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "NewPasswordRequiredChallengeFields_configuration"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    },
    "kind": "Request",
    "operation": {
        "argumentDefinitions": [],
        "kind": "Operation",
        "name": "LoginQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Configuration",
                "kind": "LinkedField",
                "name": "configuration",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "passwordValidationRegex",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "collectNameOnSignUp",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ]
    },
    "params": {
        "cacheID": "df56c9f03570b2896e1b557a2a6832af",
        "id": null,
        "metadata": {},
        "name": "LoginQuery",
        "operationKind": "query",
        "text": "query LoginQuery {\n  configuration {\n    ...NewPasswordRequiredChallengeFields_configuration\n  }\n}\n\nfragment NewPasswordRequiredChallengeFields_configuration on Configuration {\n  passwordValidationRegex\n  collectNameOnSignUp\n}\n"
    }
} as any;
(node as any).hash = '352ec82052e6e2689c3b48b43f8b23f1';
export default node;
