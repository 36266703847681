/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicenceSelectionInput = {
    animalId: string;
    licenceTypeId?: number | null;
};
export type bulkUpdateLicencesMutationVariables = {
    licenceSelections: Array<LicenceSelectionInput>;
};
export type bulkUpdateLicencesMutationResponse = {
    readonly bulkUpdateLicences: ReadonlyArray<{
        readonly id: string;
    }> | null;
};
export type bulkUpdateLicencesMutation = {
    readonly response: bulkUpdateLicencesMutationResponse;
    readonly variables: bulkUpdateLicencesMutationVariables;
};



/*
mutation bulkUpdateLicencesMutation(
  $licenceSelections: [LicenceSelectionInput!]!
) {
  bulkUpdateLicences(licenceSelections: $licenceSelections) {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "licenceSelections"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "licenceSelections",
                    "variableName": "licenceSelections"
                }
            ],
            "concreteType": "LicensingAnimal",
            "kind": "LinkedField",
            "name": "bulkUpdateLicences",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "bulkUpdateLicencesMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "bulkUpdateLicencesMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "8b593a8e09bac62fbd4cd25dbaf77fe4",
            "id": null,
            "metadata": {},
            "name": "bulkUpdateLicencesMutation",
            "operationKind": "mutation",
            "text": "mutation bulkUpdateLicencesMutation(\n  $licenceSelections: [LicenceSelectionInput!]!\n) {\n  bulkUpdateLicences(licenceSelections: $licenceSelections) {\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '200c78675169b69e3dd5a5b85cc0765f';
export default node;
