/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LicensingAnimalIdentificationType = "LICENCE" | "MICROCHIP" | "TATTOO" | "%future added value";
export type LicensingMixedBreedSelection = "MIXED" | "PUREBRED" | "%future added value";
export type SyncStatus = "ACTIVE" | "BLOCKED" | "CANCELLED" | "DUPLICATE" | "NEW" | "PENDING" | "REJECTED" | "%future added value";
export type AnimalDetailsRenewalFormQueryVariables = {
    animalId: string;
};
export type AnimalDetailsRenewalFormQueryResponse = {
    readonly account: {
        readonly animal: {
            readonly id: string;
            readonly name: string;
            readonly dateOfBirth: string;
            readonly animalTypeId: string;
            readonly genderId: number;
            readonly isDesexed: boolean;
            readonly breedId: number;
            readonly secondaryBreedId: number | null;
            readonly breedSelection: LicensingMixedBreedSelection;
            readonly colourId: number;
            readonly secondaryColourId: number | null;
            readonly identifications: ReadonlyArray<{
                readonly id: string;
                readonly type: LicensingAnimalIdentificationType;
                readonly value: string;
            }> | null;
            readonly rabiesVaccination: {
                readonly dateAdministered: string;
                readonly immunityPeriodMonths: number;
                readonly storageId: number;
            } | null;
            readonly licences: ReadonlyArray<{
                readonly id: string;
            }>;
            readonly licenceTypeId: number | null;
            readonly status: SyncStatus;
        } | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"Animal_query">;
};
export type AnimalDetailsRenewalFormQuery = {
    readonly response: AnimalDetailsRenewalFormQueryResponse;
    readonly variables: AnimalDetailsRenewalFormQueryVariables;
};



/*
query AnimalDetailsRenewalFormQuery(
  $animalId: ID!
) {
  account {
    animal(animalId: $animalId) {
      id
      name
      dateOfBirth
      animalTypeId
      genderId
      isDesexed
      breedId
      secondaryBreedId
      breedSelection
      colourId
      secondaryColourId
      identifications {
        id
        type
        value
      }
      rabiesVaccination {
        dateAdministered
        immunityPeriodMonths
        storageId
        id
      }
      licences {
        id
      }
      licenceTypeId
      status
    }
    id
  }
  ...Animal_query
}

fragment Animal_query on Query {
  genders: licensingGenders {
    genderId
    name
  }
  licensingAnimalTypes {
    id
    description
  }
  configuration {
    microchipValidationSettings {
      microchipLength
      minChipLength
    }
    ...Rabies_configuration
    ...Hideable_configuration
  }
  account {
    ...Rabies_account
    id
  }
}

fragment Hideable_configuration on Configuration {
  hiddenFields
}

fragment Rabies_account on Account {
  id
}

fragment Rabies_configuration on Configuration {
  proofOfRabiesAllowedFileExtensions
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "animalId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "animalId",
            "variableName": "animalId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateOfBirth",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "animalTypeId",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "genderId",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDesexed",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "breedId",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "secondaryBreedId",
        "storageKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "breedSelection",
        "storageKey": null
    } as any, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "colourId",
        "storageKey": null
    } as any, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "secondaryColourId",
        "storageKey": null
    } as any, v13 = {
        "alias": null,
        "args": null,
        "concreteType": "LicensingAnimalIdentification",
        "kind": "LinkedField",
        "name": "identifications",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateAdministered",
        "storageKey": null
    } as any, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "immunityPeriodMonths",
        "storageKey": null
    } as any, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storageId",
        "storageKey": null
    } as any, v17 = {
        "alias": null,
        "args": null,
        "concreteType": "Licence",
        "kind": "LinkedField",
        "name": "licences",
        "plural": true,
        "selections": [
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "licenceTypeId",
        "storageKey": null
    } as any, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AnimalDetailsRenewalFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v1 /*: any*/),
                            "concreteType": "LicensingAnimal",
                            "kind": "LinkedField",
                            "name": "animal",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingRabiesVaccination",
                                    "kind": "LinkedField",
                                    "name": "rabiesVaccination",
                                    "plural": false,
                                    "selections": [
                                        (v14 /*: any*/),
                                        (v15 /*: any*/),
                                        (v16 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Animal_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AnimalDetailsRenewalFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v1 /*: any*/),
                            "concreteType": "LicensingAnimal",
                            "kind": "LinkedField",
                            "name": "animal",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingRabiesVaccination",
                                    "kind": "LinkedField",
                                    "name": "rabiesVaccination",
                                    "plural": false,
                                    "selections": [
                                        (v14 /*: any*/),
                                        (v15 /*: any*/),
                                        (v16 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": "genders",
                    "args": null,
                    "concreteType": "LicensingGender",
                    "kind": "LinkedField",
                    "name": "licensingGenders",
                    "plural": true,
                    "selections": [
                        (v6 /*: any*/),
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicensingAnimalType",
                    "kind": "LinkedField",
                    "name": "licensingAnimalTypes",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MicrochipValidationSettings",
                            "kind": "LinkedField",
                            "name": "microchipValidationSettings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "microchipLength",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "minChipLength",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "proofOfRabiesAllowedFileExtensions",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hiddenFields",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "36c6d49a80da4423825ce7141c361534",
            "id": null,
            "metadata": {},
            "name": "AnimalDetailsRenewalFormQuery",
            "operationKind": "query",
            "text": "query AnimalDetailsRenewalFormQuery(\n  $animalId: ID!\n) {\n  account {\n    animal(animalId: $animalId) {\n      id\n      name\n      dateOfBirth\n      animalTypeId\n      genderId\n      isDesexed\n      breedId\n      secondaryBreedId\n      breedSelection\n      colourId\n      secondaryColourId\n      identifications {\n        id\n        type\n        value\n      }\n      rabiesVaccination {\n        dateAdministered\n        immunityPeriodMonths\n        storageId\n        id\n      }\n      licences {\n        id\n      }\n      licenceTypeId\n      status\n    }\n    id\n  }\n  ...Animal_query\n}\n\nfragment Animal_query on Query {\n  genders: licensingGenders {\n    genderId\n    name\n  }\n  licensingAnimalTypes {\n    id\n    description\n  }\n  configuration {\n    microchipValidationSettings {\n      microchipLength\n      minChipLength\n    }\n    ...Rabies_configuration\n    ...Hideable_configuration\n  }\n  account {\n    ...Rabies_account\n    id\n  }\n}\n\nfragment Hideable_configuration on Configuration {\n  hiddenFields\n}\n\nfragment Rabies_account on Account {\n  id\n}\n\nfragment Rabies_configuration on Configuration {\n  proofOfRabiesAllowedFileExtensions\n}\n"
        }
    } as any;
})();
(node as any).hash = '93e129ea128fb04b35c1b560e7736012';
export default node;
