import { LoadingMessage } from "components/shared/LoadingMessage";
import { required } from "components/shared/validations";
import graphql from "babel-plugin-relay/macro";
import { Animal } from "components/animal/Animal";
import { LicenceType } from "components/licence/LicenceType";
import { LicenseTypeProvider } from "components/licence/LicenseTypeProvider";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { animalActions } from "domain/animal/animalActions";
import { animalMessages } from "domain/animal/animalMessages";
import { IAnimalTagSearchResultRow } from "domain/animal/models/animalTagSearchResultRow";
import { upsertAnimal, UpsertAnimalInput } from "domain/animal/upsertAnimal";
import { licenceMessages } from "domain/licence/licenceMessages";
import { redirectActions } from "domain/redirect/redirectActions";
import { toastActions } from "domain/toast/toastActions";
import { AddLicenceQuery, AddLicenceQueryResponse } from "generatedQueries/AddLicenceQuery.graphql";
import { LicensingAnimalIdentificationInput, upsertAnimalMutationResponse } from "generatedQueries/upsertAnimalMutation.graphql";
import { Text } from "informed";
import moment, { Moment } from "moment";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { ILicenceRenewalLocationState } from "routes/licence/LicenceRenewal";
import { routes } from "routes/routes";
import { ErrorHandlingMutationForm, IWarningMessageProps } from "shared/components/ErrorHandlingMutationForm";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";
import { RootState } from "store/store";

type AnimalNode = NonNullable<NonNullable<NonNullable<NonNullable<AddLicenceQueryResponse["account"]>["animals"]>>["edges"]>[0]["node"];

const messages = mapMessages("routes.licence.AddLicence", {
    nameMatchWarningMessage: "You already have a pet named {petName}, if you have two pet's with the same name then ignore this message and click <b>Save</b> below to continue adding this pet.{br}{br}If you are renewing {petName}, then click the <b>Renew {petName}</b> button below instead and you will be taken to the renewal page.",
    nameMatchWarningHeading: "You already have a pet named {petName}",
    renewButton: "Renew {petName}",
});

export const AddLicence: FC = () => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const animalFound = useSelector<RootState, IAnimalTagSearchResultRow>(state => state.animal.animalFound);
    const [licenceExpiry, setLicenceExpiry] = useState<Moment | "LIFETIME" | undefined>(undefined);

    useEffect(() => {
        return () => { dispatch(animalActions.clearAnimalFound()); };
    }, [dispatch]);

    return <Query<AddLicenceQuery>
        query={query}
        variables={{}}>
        {({ error, props }) => {
            if (error) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            if (!props) {
                return <LoadingMessage />;
            }

            const { account } = props;

            if (!account || !account.physicalAddress || !account.physicalAddress.jurisdictionId) {
                return <Redirect to={routes.account.edit.create({})} />;
            }

            return <>
                <h2>
                    <Message message={licenceMessages.addLicencePageTitle} />
                </h2>
                <hr />
                <div className="row">
                    <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
                        <ErrorHandlingMutationForm<UpsertAnimalInput, upsertAnimalMutationResponse>
                            initialValues={getInitialValue()}
                            onNext={onSaved}
                            mutationFunc={upsertAnimal}
                            shouldDisplayWarning={shouldDisplayWarnings}
                            toastSuccess={toastSuccess}
                            toastFailure={toastFailure}
                            alwaysSubmit={true}>
                            {({ formApi, formState }) => (
                                <>
                                    <Animal
                                        formApi={formApi}
                                        formState={formState}
                                        queryKey={props}
                                    />
                                    <Text hidden={true} field="licenceExpiry" />
                                    {formState.values.animalTypeId && (
                                        <>
                                            <h4><Message message={licenceMessages.licenceDetailsLabel} /></h4>
                                            <FormLabel
                                                message={licenceMessages.licenceTypeLabel}
                                                required={true}
                                                for="licenceTypeId"
                                            >
                                                <LicenseTypeProvider
                                                    animalTypeIdField="animalTypeId"
                                                    dateOfBirthField="dateOfBirth"
                                                    isDesexedField="isDesexed"
                                                    isRenewal={false}
                                                    isMicrochipped={isMicrochipped(formState.values)}
                                                    jurisdictionId={account.physicalAddress!.jurisdictionId!}
                                                >
                                                    {({ isLoading, licenseTypes }) => (
                                                        <LicenceType
                                                            id="licenceTypeId"
                                                            field="licenceTypeId"
                                                            validate={required}
                                                            onLicenceTypeSelected={onLicenceTypeSelected}
                                                            isLoading={isLoading}
                                                            licenseTypes={licenseTypes}
                                                        />
                                                    )}
                                                </LicenseTypeProvider>
                                            </FormLabel>
                                        </>
                                    )}
                                </>
                            )}
                        </ErrorHandlingMutationForm>
                    </div>
                </div>
            </>;

            function shouldDisplayWarnings(values: UpsertAnimalInput): IWarningMessageProps[] | false {
                const warnings: IWarningMessageProps[] = [];

                if (isRabiesExpiryPriorToLicenceExpiry(values)) {
                    warnings.push({
                        titleMessage: formatMessage(animalMessages.rabiesExpiryInvalidHeading),
                        warningMessage: <Message message={animalMessages.rabiesExpiryInvalidError} />,
                    });
                }

                // TODO: Try replace this with async validation.
                // Make a request for account.animals(first: 1, where: { name: {eq: "Fido" } }). If it comes back with an animal then show warning
                const potentialDuplicateAnimals = account?.animals?.edges
                    ?.map(edge => edge.node)
                    .filter(a => values.name?.localeCompare(a.name, undefined, { sensitivity: "base" }) === 0) ?? [];

                if (potentialDuplicateAnimals.length > 0) {
                    warnings.push({
                        titleMessage: formatMessage(messages.nameMatchWarningHeading, { petName: values.name }),
                        warningMessage: <Message
                            message={messages.nameMatchWarningMessage}
                            values={{
                                b: msg => <b>{msg}</b>,
                                petName: values.name,
                                br: <br />,
                            }}
                        />,
                        buttons: ({ renderCancelButton, renderSubmitButton }) => <>
                            <Button type="button" color="primary" onClick={onRenewClicked(potentialDuplicateAnimals)}>
                                <Message message={messages.renewButton} values={{ petName: values.name }} />
                            </Button>
                            {renderSubmitButton()}
                            {renderCancelButton()}
                        </>,
                    });
                }

                return warnings.length > 0 ? warnings : false;
            }
        }}
    </Query>;

    function onRenewClicked(duplicates: AnimalNode[]) {
        return () => {
            const state: ILicenceRenewalLocationState = {
                animalIds: duplicates.map(animal => animal.id),
                hasReviewedAccountDetails: false,
            };
            history.push(routes.licence.renewal.create({}), state);
        };
    }

    function toastSuccess() {
        dispatch(toastActions.toastSuccess());
    }

    function toastFailure() {
        dispatch(toastActions.toastFailure());
    }

    function onLicenceTypeSelected(_: number | undefined, selectedLicenceExpiry: Moment | "LIFETIME" | undefined) {
        setLicenceExpiry(selectedLicenceExpiry);
    }

    function isRabiesExpiryPriorToLicenceExpiry(values: UpsertAnimalInput) {
        if (licenceExpiry === "LIFETIME" ||
            !values.rabiesVaccinationDateAdministered ||
            !values.rabiesVaccinationImmunityPeriodMonths) {
            return false;
        }

        const rabiesExpiry = moment(values.rabiesVaccinationDateAdministered).add(values.rabiesVaccinationImmunityPeriodMonths, "months");

        return rabiesExpiry.isBefore(licenceExpiry, "days");
    }

    function onSaved() {
        dispatch(redirectActions.redirectTo(routes.payment.index.create({})));
    }

    function getInitialValue(): UpsertAnimalInput {
        const animal = { ...animalFound };
        const identifications: LicensingAnimalIdentificationInput[] = [];

        if (animal.microchip) {
            identifications.push({ type: "MICROCHIP", value: animal.microchip });
        }

        if (animal.tagNumber) {
            identifications.push({ type: "LICENCE", value: animal.tagNumber });
        }

        return {
            animalTypeId: (animal.animalType && animal.animalType.id.toString()) || "",
            breedId: (animal.primaryBreed && animal.primaryBreed.breedId) || 0,
            breedSelection: animal.isCrossBreed ? "MIXED" : "PUREBRED",
            colourId: (animal.primaryColour && animal.primaryColour.colourId) || 0,
            dateOfBirth: animal.dateOfBirth || "",
            foundSbAnimalId: animal.animalId,
            genderId: animal.genderId || 0,
            identifications,
            isDesexed: animal.isDesexed || false,
            name: animal.name || "",
            rabiesVaccinationDateAdministered: undefined,
            rabiesVaccinationImmunityPeriodMonths: undefined,
            rabiesVaccinationStorageId: undefined,
            secondaryBreedId: (animal.secondaryBreed && animal.secondaryBreed.breedId) || null,
            secondaryColourId: (animal.secondaryColour && animal.secondaryColour.colourId) || null,
        };
    }
};

export function isMicrochipped(animal: Partial<UpsertAnimalInput>): boolean {
    if (!animal.identifications || animal.identifications.length === 0) {
        return false;
    }

    return animal.identifications.find(i => i.type === "MICROCHIP" && !!i.value) !== undefined;
}

const query = graphql`
query AddLicenceQuery {
    account {
        physicalAddress {
            jurisdictionId
        }
        animals(first: 100) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
    ...Animal_query
}`;
