import graphql from "babel-plugin-relay/macro";
import { AccountDetails } from "components/account/details/AccountDetails";
import { AccountWizardNavigation } from "components/account/details/components/navigation/AccountWizardNavigation";
import { accountMessages } from "domain/account/accountMessages";
import { confirmAccount } from "domain/account/confirmAccount";
import { toastActions } from "domain/toast/toastActions";
import { AccountWizardPageQuery } from "generatedQueries/AccountWizardPageQuery.graphql";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

export const messages = mapMessages("components.account.AccountWizardPage", {
    failedToRefreshAccount: "Failed to refresh your account details after saving, please refresh the page before attempting to license your pet.",
});

export interface IAccountWizardPage {
    isNewAccount: boolean;
    hasAdditionalDetailsForm: boolean;
    onPrevious: () => void;
    onNext: () => void;
}

const graphQuery = graphql`
query AccountWizardPageQuery {
    account {
        ...Header_account
    }
}`;

export const AccountWizardPage: FC<IAccountWizardPage> = ({
    isNewAccount,
    hasAdditionalDetailsForm,
    onPrevious,
    onNext,
}) => {
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();

    return <>
        <AccountDetails />
        <AccountWizardNavigation
            onNext={onComplete}
            onPrevious={onPrevious}
            primaryButtonDescription={isNewAccount ? accountMessages.continueToLicenceMyPetButtonLabel : sharedMessages.completeButtonLabel}
        />
    </>;

    function onComplete() {
        if (hasAdditionalDetailsForm) {
            fetchQuery<AccountWizardPageQuery>(environment, graphQuery, {}, { networkCacheConfig: { force: true } })
                .subscribe({
                    error() {
                        dispatch(toastActions.customFailureToast(messages.failedToRefreshAccount));
                    },
                    next() {
                        onNext();
                    },
                });
        } else {
            confirmAccount(
                environment,
                {
                    canDiscloseToPublic: false,
                    isSubscribedToShelterNewsletter: false,
                },
                () => {
                    onNext();
                },
                () => {
                    dispatch(toastActions.customFailureToast(messages.failedToRefreshAccount));
                }
            );
        }
    }
};
