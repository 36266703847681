import { Message } from "shared/components/Message";
import { Spinner } from "components/shared/Spinner";
import classNames from "classnames";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import Button, { ButtonProps } from "reactstrap/lib/Button";
import styles from "./SpinnerButton.module.scss";

interface IProps {
    isLoading: boolean;
    message?: MessageDescriptor;
}

type Props = IProps & ButtonProps;

export const SpinnerButton: FC<Props> = ({
    isLoading,
    message,
    disabled,
    children,
    "aria-valuemax": ariaValueMax,
    "aria-valuemin": ariaValueMin,
    "aria-valuenow": ariaValueNow,
    "aria-valuetext": ariaValueText,
    ...rest
}) => (
    <Button disabled={isLoading || disabled} {...rest}>
        <div className={styles.layerContainer}>
            {
                isLoading &&
                <div
                    className={styles.layer}
                    role="progressbar"
                    aria-valuemax={ariaValueMax}
                    aria-valuemin={ariaValueMin}
                    aria-valuenow={ariaValueNow}
                    aria-valuetext={ariaValueText}
                >
                    <Spinner className="icon-button" />
                </div>
            }
            <div className={styles.layer}>
                <span className={classNames({ invisible: isLoading })}>
                    {
                        message &&
                        <Message message={message} />
                    }
                    {children}
                </span>
            </div>
        </div>
    </Button>
);
