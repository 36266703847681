import { metadata } from "components/globalErrorHandler/GlobalErrorHandler";
import { logEvent } from "domain/logging/logEvent";
import { useRollbar } from "infrastructure/rollbar";
import { defaultsDeep } from "lodash";
import React, { createContext, FC, useContext } from "react";
import { useRelayEnvironment } from "react-relay";
import { IEnvironment } from "relay-runtime";
import Rollbar from "rollbar";

export type LogLevel = "DEBUG" | "ERROR" | "FATAL" | "INFORMATION" | "VERBOSE" | "WARNING";

export interface ILogger {
    debug: (message: string, data: object, error?: Error) => void;
    error: (message: string, data: object, error?: Error) => void;
    fatal: (message: string, data: object, error?: Error) => void;
    information: (message: string, data: object, error?: Error) => void;
    log: (level: LogLevel, message: string, data: object, error?: Error) => void;
    verbose: (message: string, data: object, error?: Error) => void;
    warning: (message: string, data: object, error?: Error) => void;
}

export class Logger implements ILogger {
    private readonly rollbar: Rollbar | undefined;
    private readonly environment: IEnvironment | undefined;

    constructor(rollbar?: Rollbar, environment?: IEnvironment) {
        this.rollbar = rollbar;
        this.environment = environment;
    }

    public debug(message: string, data: object, error?: Error | undefined) {
        this.log("DEBUG", message, data, error);
    }

    public error(message: string, data: object, error?: Error | undefined) {
        this.log("ERROR", message, data, error);
    }

    public fatal(message: string, data: object, error?: Error | undefined) {
        this.log("FATAL", message, data, error);
    }

    public information(message: string, data: object, error?: Error | undefined) {
        this.log("INFORMATION", message, data, error);
    }

    public log(level: LogLevel, message: string, data: object, error?: Error | undefined) {
        const request = JSON.stringify(defaultsDeep({ error }, { data }));

        if (this.environment) {
            logEvent(
                this.environment,
                level,
                message,
                request,
                "Licensing UI",
                metadata,
                () => {
                    // Do nothing
                },
                e => {
                    // tslint:disable-next-line: no-console
                    console.error("Failed to log error", e);
                }
            );
        }

        if (this.rollbar && (level === "ERROR" || level === "FATAL")) {
            this.rollbar.error(message, error, request, metadata);
        }
    }

    public verbose(message: string, data: object, error?: Error | undefined) {
        this.log("VERBOSE", message, data, error);
    }

    public warning(message: string, data: object, error?: Error | undefined) {
        this.log("WARNING", message, data, error);
    }
}

export const LoggerContext = createContext<ILogger>(new Logger());

export const { Consumer: LoggerConsumer } = LoggerContext;

export function useLogger() {
    return useContext(LoggerContext);
}

export const LoggerProvider: FC = ({
    children,
}) => {
    const rollbar = useRollbar();
    const environment = useRelayEnvironment();

    return <LoggerContext.Provider value={new Logger(rollbar, environment)}>
        {children}
    </LoggerContext.Provider>;
};
