/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PaymentRow_paymentRow = {
    readonly amount: number;
    readonly currency: string | null;
    readonly animalId: string;
    readonly animalName: string;
    readonly blocked: boolean;
    readonly licenceType: string;
    readonly licenceTypeId: number;
    readonly rabiesIsValid: boolean;
    readonly isRenewal: boolean;
    readonly isLateFee: boolean;
    readonly " $fragmentRefs": FragmentRefs<"PaymentRowLicenceDetails_paymentRow">;
    readonly " $refType": "PaymentRow_paymentRow";
};
export type PaymentRow_paymentRow$data = PaymentRow_paymentRow;
export type PaymentRow_paymentRow$key = {
    readonly " $data"?: PaymentRow_paymentRow$data;
    readonly " $fragmentRefs": FragmentRefs<"PaymentRow_paymentRow">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentRow_paymentRow",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "animalId",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "animalName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blocked",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "licenceType",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "licenceTypeId",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rabiesIsValid",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isRenewal",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLateFee",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PaymentRowLicenceDetails_paymentRow"
        }
    ],
    "type": "LicensingPaymentFormRow",
    "abstractKey": null
} as any;
(node as any).hash = '2a24fdacd83410206a0ba1359d9c114a';
export default node;
