/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ChangePasswordQueryVariables = {};
export type ChangePasswordQueryResponse = {
    readonly configuration: {
        readonly passwordValidationRegex: string;
    } | null;
};
export type ChangePasswordQuery = {
    readonly response: ChangePasswordQueryResponse;
    readonly variables: ChangePasswordQueryVariables;
};



/*
query ChangePasswordQuery {
  configuration {
    passwordValidationRegex
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "passwordValidationRegex",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ChangePasswordQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ChangePasswordQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "c1f032a0aed4564c41dbe56e45b1c1e7",
            "id": null,
            "metadata": {},
            "name": "ChangePasswordQuery",
            "operationKind": "query",
            "text": "query ChangePasswordQuery {\n  configuration {\n    passwordValidationRegex\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'aa3408721ef1faa3fb6c2f4a7242e1c3';
export default node;
