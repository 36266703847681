/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PayPalPayflowFormQueryVariables = {};
export type PayPalPayflowFormQueryResponse = {
    readonly configuration: {
        readonly " $fragmentRefs": FragmentRefs<"PayPalPayflowFields_configuration">;
    } | null;
};
export type PayPalPayflowFormQuery = {
    readonly response: PayPalPayflowFormQueryResponse;
    readonly variables: PayPalPayflowFormQueryVariables;
};



/*
query PayPalPayflowFormQuery {
  configuration {
    ...PayPalPayflowFields_configuration
  }
}

fragment PayPalPayflowFields_configuration on Configuration {
  licensingPayPalPayflowMode
  licensingPayPalPayflowRedirectUri
}
*/

const node: ConcreteRequest = {
    "fragment": {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "PayPalPayflowFormQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Configuration",
                "kind": "LinkedField",
                "name": "configuration",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PayPalPayflowFields_configuration"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    },
    "kind": "Request",
    "operation": {
        "argumentDefinitions": [],
        "kind": "Operation",
        "name": "PayPalPayflowFormQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Configuration",
                "kind": "LinkedField",
                "name": "configuration",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "licensingPayPalPayflowMode",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "licensingPayPalPayflowRedirectUri",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ]
    },
    "params": {
        "cacheID": "f2d662ef3bfdef8d24b5356c50670b06",
        "id": null,
        "metadata": {},
        "name": "PayPalPayflowFormQuery",
        "operationKind": "query",
        "text": "query PayPalPayflowFormQuery {\n  configuration {\n    ...PayPalPayflowFields_configuration\n  }\n}\n\nfragment PayPalPayflowFields_configuration on Configuration {\n  licensingPayPalPayflowMode\n  licensingPayPalPayflowRedirectUri\n}\n"
    }
} as any;
(node as any).hash = '5a5e959272d12deb5ef0e518d364db94';
export default node;
