import { Message } from "shared/components/Message";
import React, { FC, ReactNode, useState } from "react";
import { MessageDescriptor } from "react-intl";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";

type Option = string | null;

interface ISelectionOptionProps {
    message: MessageDescriptor;
    option: string;
    selection: Option;
    setSelection: (selection: Option) => void;
    children: ReactNode;
}

interface IProps {
    children: { options: Omit<ISelectionOptionProps, "selection" | "setSelection">[] };
    initialSelection?: Option;
}

const SelectionOption: FC<ISelectionOptionProps> = ({
    children,
    message,
    option,
    selection,
    setSelection,
}) => {
    return <Card>
        <CardHeader onClick={onSelect}>
            <div className="form-check">
                <label className="form-check-label">
                    <input type="radio" className="form-check-input" checked={selection === option} onChange={onSelect} />
                    <Message message={message} />
                </label>
            </div>
        </CardHeader>
        <Collapse isOpen={selection === option}>
            <CardBody>
                {children}
            </CardBody>
        </Collapse>
    </Card>;

    function onSelect() {
        setSelection(option);
    }
};

export const AccordionSelection: FC<IProps> = ({
    children,
    initialSelection,
}) => {
    const [selection, setSelection] = useState<Option>(initialSelection ?? null);

    return <fieldset className="form-group">
        <div className="accordion">
            {children.options.map((child, i) => <SelectionOption key={i} {...child} selection={selection} setSelection={setSelection} />)}
        </div>
    </fieldset>;
};
