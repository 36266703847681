import { InfoMessage } from "components/shared/InfoMessage";
import { LoadingMessage } from "components/shared/LoadingMessage";
import { SpinnerButton } from "components/shared/SpinnerButton";
import graphql from "babel-plugin-relay/macro";
import { Message } from "shared/components/Message";
import { ChangePasswordError } from "components/user/ChangePasswordError";
import { changePassword } from "domain/user/changePassword";
import { ChangeLicensingPasswordError, changePasswordMutationResponse } from "generatedQueries/changePasswordMutation.graphql";
import { ChangePasswordQuery } from "generatedQueries/ChangePasswordQuery.graphql";
import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRelayEnvironment } from "react-relay";
import { Col, Form, Row } from "reactstrap";
import { PasswordEntryFields } from "routes/user/PasswordEntryFields";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { TextBox } from "shared/components/newFormInputs/TextBox";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { required } from "shared/validations/validations";

export const messages = mapMessages("routes.account.changepassword", {
    changePasswordButton: "Change Password",
    oldPasswordLabel: "Old Password",
    passwordChangeSuccessMessage: "Password changed successfully",
    title: "Change Password",
});

interface IForm {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export const ChangePassword: FC = () => {
    const environment = useRelayEnvironment();
    const methods = useForm<IForm>({
        defaultValues: {
            oldPassword: "",
            confirmPassword: "",
            newPassword: "",
        },
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [changePasswordError, setChangePasswordError] = useState<boolean | ChangeLicensingPasswordError>(false);

    return (
        <Row className="justify-content-center">
            <Col xl={5} lg={6} md={7} sm={9}>
                <h2><Message message={messages.title} /></h2>

                {
                    isComplete
                        ? <InfoMessage message={messages.passwordChangeSuccessMessage} />
                        : <Query<ChangePasswordQuery>
                            query={query}
                            variables={{}}>
                            {({ error, props }) => {
                                if (!props && !error) {
                                    return <LoadingMessage />;
                                }

                                const { configuration } = props ?? {};

                                return (
                                    <FormProvider
                                        {...methods}
                                    >
                                        <Form
                                            onSubmit={methods.handleSubmit(submitChangePassword)}
                                            role="form"
                                        >
                                            <FormLabel
                                                message={messages.oldPasswordLabel}
                                                for="oldPassword"
                                            >
                                                <TextBox
                                                    id="oldPassword"
                                                    name="oldPassword"
                                                    register={methods.register}
                                                    control={methods.control}
                                                    type="password"
                                                    options={required<IForm, "oldPassword">()}
                                                />
                                            </FormLabel>
                                            <PasswordEntryFields
                                                passwordField="newPassword"
                                                passwordValidationRegex={configuration?.passwordValidationRegex}
                                            />
                                            <ChangePasswordError error={changePasswordError} />
                                            <SpinnerButton
                                                message={messages.changePasswordButton}
                                                isLoading={isSubmitting}
                                                color="primary" />
                                        </Form>
                                    </FormProvider>
                                );
                            }}
                        </Query>
                }
            </Col>
        </Row >
    );

    function submitChangePassword({ oldPassword, newPassword }: IForm) {
        setIsSubmitting(true);
        changePassword(
            environment,
            newPassword,
            oldPassword,
            onChangePasswordCompleted,
            onChangePasswordError);
    }

    function onChangePasswordCompleted(response: changePasswordMutationResponse) {
        setIsSubmitting(false);

        if (response.changeLicensingPassword?.wasSuccessful === true) {
            setIsComplete(true);
        } else {
            setChangePasswordError(response.changeLicensingPassword?.error ?? true);
        }
    }

    function onChangePasswordError() {
        setIsSubmitting(false);
        setChangePasswordError(true);
    }
};

const query = graphql`
    query ChangePasswordQuery {
        configuration {
            passwordValidationRegex
        }
    }`;
