import graphql from "babel-plugin-relay/macro";
import styles from "components/account/details/AccountDetails.module.scss";
import { accountMessages } from "domain/account/accountMessages";
import { AccountReadonlyAdditionalDetails_account } from "generatedQueries/AccountReadonlyAdditionalDetails_account.graphql";
import { AccountReadonlyAdditionalDetails_configuration } from "generatedQueries/AccountReadonlyAdditionalDetails_configuration.graphql";
import { AccountReadonlyAdditionalDetails_isSeniorCitizenLabel } from "generatedQueries/AccountReadonlyAdditionalDetails_isSeniorCitizenLabel.graphql";
import moment from "moment";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { Label } from "reactstrap";
import { BooleanMessage } from "shared/components/BooleanMessage";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { HideableFields } from "shared/components/Hideable";
import { HideableFormLabel } from "shared/components/HideableFormLabel";
import { sharedMessages } from "shared/sharedMessages";

export interface IAccountReadonlyAdditionalDetails {
    isSelfIdentifyAsSeniorCitizenEnabled: boolean;
    account: AccountReadonlyAdditionalDetails_account;
    configuration: AccountReadonlyAdditionalDetails_configuration | null;
    isSeniorCitizenLabel: AccountReadonlyAdditionalDetails_isSeniorCitizenLabel | null;
}

const InternalAccountReadonlyAdditionalDetailsls: FC<IAccountReadonlyAdditionalDetails> = ({ account, configuration, isSelfIdentifyAsSeniorCitizenEnabled, isSeniorCitizenLabel }) => {
    const intl = useIntl();
    const seniorCitizenLabel = isSeniorCitizenLabel?.value ?
        intl.formatMessage(accountMessages.customSeniorCitizenLabel, { personType: isSeniorCitizenLabel?.value }) : accountMessages.seniorCitizenLabel;

    return (
        <>
            <HideableFormLabel
                hideableFieldName={HideableFields.driverLicence}
                hideableFieldLabel={accountMessages.driverLicenceLabel}
                children={
                    <Label className={styles.paddedValue}>{account.driverLicence}</Label>
                }
                configuration={configuration} />
            {
                !isSelfIdentifyAsSeniorCitizenEnabled &&
                <HideableFormLabel
                    hideableFieldName={HideableFields.dateOfBirth}
                    hideableFieldLabel={accountMessages.dateOfBirthLabel}
                    children={
                        <Label className={styles.paddedValue}>{formatDate(account.dateOfBirth)}</Label>
                    }
                    configuration={configuration} />
            }
            {
                isSelfIdentifyAsSeniorCitizenEnabled &&
                <FormLabel message={seniorCitizenLabel} inlineLabelClass={styles.noPaddingLabel}>
                    <Label className={styles.paddedValue}>
                        <BooleanMessage
                            value={account.isSelfIdentifiedAsSeniorCitizen}
                            confirmTrueMessage={accountMessages.seniorCitizenYesLabel}
                            confirmFalseMessage={accountMessages.seniorCitizenNoLabel}
                        />
                    </Label>
                </FormLabel>
            }
            <HideableFormLabel
                hideableFieldName={HideableFields.canDiscloseToPublic}
                hideableFieldLabel={accountMessages.allowedDisclosureGroupLabel}
                children={
                    <Label className={styles.paddedValue}>
                        <BooleanMessage
                            value={account.canDiscloseToPublic}
                            confirmTrueMessage={sharedMessages.confirmYesLabel}
                            confirmFalseMessage={sharedMessages.confirmNoLabel} />
                    </Label>
                }
                configuration={configuration} />
            <HideableFormLabel
                hideableFieldName={HideableFields.isSubscribedToShelterNewsletter}
                hideableFieldLabel={accountMessages.subscribeToShelterNewsletter}
                children=
                {
                    <Label className={styles.paddedValue}>
                        <BooleanMessage
                            value={account.isSubscribedToShelterNewsletter}
                            confirmTrueMessage={sharedMessages.confirmYesLabel}
                            confirmFalseMessage={sharedMessages.confirmNoLabel} />
                    </Label>
                }
                configuration={configuration} />
        </>
    );
};

export const AccountReadonlyAdditionalDetails = createFragmentContainer(InternalAccountReadonlyAdditionalDetailsls, {
    account: graphql`
        fragment AccountReadonlyAdditionalDetails_account on Account {
            canDiscloseToPublic
            dateOfBirth
            driverLicence
            isSubscribedToShelterNewsletter
            isSelfIdentifiedAsSeniorCitizen
        }`,
    configuration: graphql`
        fragment AccountReadonlyAdditionalDetails_configuration on Configuration {
            ...HideableFormLabel_configuration
        }`,
    isSeniorCitizenLabel: graphql`
        fragment AccountReadonlyAdditionalDetails_isSeniorCitizenLabel on SiteCustomisation {
            value
        }`,
});

function formatDate(value: string | null) {
    return value && moment(value).format("L");
}
