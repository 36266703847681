import graphql from "babel-plugin-relay/macro";
import { signOutMutation, signOutMutationResponse } from "generatedQueries/signOutMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation signOutMutation {
        licensingAccountSignOut {
            wasSuccessful
        }
    }`;

export function signOut(
    environment: IEnvironment,
    onCompleted: (response: signOutMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<signOutMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {},
        }
    );
}
