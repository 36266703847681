import { defineMessages } from "react-intl";

const idPrefix = "error.";

export const fatalErrorMessages = defineMessages({
    defaultTitleLabel: {
        defaultMessage: "Oops, something went wrong.",
        id: idPrefix + "defaultTitle-label",
    },
    defaultSubTitleLabel: {
        defaultMessage: " ",
        id: idPrefix + "defaultSubTitle-label",
    },
    defaultInstructionsLabel: {
        defaultMessage: "Please refresh the page. If the issue persists please notifiy ShelterBuddy support via {contact}.",
        id: idPrefix + "defaultInstructions-label",
    },
    defaultUrgentInstructionsLabel: {
        defaultMessage: "For urgent issues (e.g. a crash preventing you from working) please visit our webpage to find the support phone number for your country:",
        id: idPrefix + "defaultUrgentInstructions-label",
    },
    defaultTeamLabel: {
        defaultMessage: "{icon} ShelterBuddy Team",
        id: idPrefix + "defaultTeam-label",
    },
    defaultErrorIdentifierLabel: {
        defaultMessage: "Tracking Issue: {identifier}",
        id: idPrefix + "defaultErrorIdentifier-label",
    },
    emailLabel: {
        defaultMessage: "support@shelterbuddy.com",
        id: idPrefix + "email-label",
    },
    reportErrorLabel: {
        defaultMessage: "How to report an error?",
        id: idPrefix + "reportError-label",
    },
    reportIssueLabel: {
        defaultMessage: "Report Issue",
        id: idPrefix + "reportIssue-label",
    },
    reportInstructionsLabel: {
        defaultMessage: "Notifiy support at {contact}.",
        id: idPrefix + "reportInstructions-label",
    },
    reportUrgentInstructionsLabel: {
        defaultMessage: "For urgent issue please call.",
        id: idPrefix + "reportUrgentInstructions-label",
    },
    supportWebpageLabel: {
        defaultMessage: "ShelterBuddy Support Page",
        id: idPrefix + "supportWebpage-label",
    },
});
