/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type VerifyQueryVariables = {};
export type VerifyQueryResponse = {
    readonly account: {
        readonly email: string;
        readonly " $fragmentRefs": FragmentRefs<"ResendCode_account">;
    } | null;
};
export type VerifyQuery = {
    readonly response: VerifyQueryResponse;
    readonly variables: VerifyQueryVariables;
};



/*
query VerifyQuery {
  account {
    email
    ...ResendCode_account
    id
  }
}

fragment ResendCode_account on Account {
  email
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "VerifyQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ResendCode_account"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "VerifyQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b637aa71e0f983848b2d1af635c63451",
            "id": null,
            "metadata": {},
            "name": "VerifyQuery",
            "operationKind": "query",
            "text": "query VerifyQuery {\n  account {\n    email\n    ...ResendCode_account\n    id\n  }\n}\n\nfragment ResendCode_account on Account {\n  email\n}\n"
        }
    } as any;
})();
(node as any).hash = '0b2141600237cd6989f54036da185458';
export default node;
