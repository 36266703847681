import graphql from "babel-plugin-relay/macro";
import { sendForgotPasswordMutation, sendForgotPasswordMutationResponse } from "generatedQueries/sendForgotPasswordMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation sendForgotPasswordMutation($input: ForgotLicensingPasswordInput!) {
        forgotLicensingPassword(input: $input) {
            wasSuccessful
            deliveryMedium
            error
        }
    }`;

export function sendForgotPassword(
    environment: IEnvironment,
    email: string,
    onCompleted: (response: sendForgotPasswordMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<sendForgotPasswordMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                input: {
                    email,
                },
            },
        }
    );
}
