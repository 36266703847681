/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReadonlyAddress_address = {
    readonly id?: string;
    readonly suburb?: string | null;
    readonly state?: string | null;
    readonly postcode?: string | null;
    readonly country?: string | null;
    readonly deliveryType?: {
        readonly name: string;
    } | null;
    readonly deliveryNumber?: string | null;
    readonly addressLine?: string | null;
    readonly jurisdiction?: {
        readonly name: string;
    } | null;
    readonly " $refType": "ReadonlyAddress_address";
};
export type ReadonlyAddress_address$data = ReadonlyAddress_address;
export type ReadonlyAddress_address$key = {
    readonly " $data"?: ReadonlyAddress_address$data;
    readonly " $fragmentRefs": FragmentRefs<"ReadonlyAddress_address">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ReadonlyAddress_address",
        "selections": [
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "suburb",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postcode",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                    }
                ],
                "type": "LicensingAddress",
                "abstractKey": "__isLicensingAddress"
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeliveryType",
                        "kind": "LinkedField",
                        "name": "deliveryType",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deliveryNumber",
                        "storageKey": null
                    }
                ],
                "type": "LicensingDeliveryAddress",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "addressLine",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "LicensingJurisdiction",
                        "kind": "LinkedField",
                        "name": "jurisdiction",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    }
                ],
                "type": "LicensingStreetAddress",
                "abstractKey": null
            }
        ],
        "type": "LicensingMailingAddress",
        "abstractKey": "__isLicensingMailingAddress"
    } as any;
})();
(node as any).hash = '177b89bad91a45d1b810f6ec79fc7f73';
export default node;
