import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import React, { FC, ReactElement, ReactNode } from "react";
import { MessageDescriptor } from "react-intl";
import { MessageCard } from "shared/components/MessageCard";

interface IProps {
    message: MessageDescriptor;
    messageValues?: { [key: string]: string | PrimitiveType | ReactElement | FormatXMLElementFn; };
    className?: string | string[];
    children?: ReactNode | ReactNode[];
    isAlert?: boolean;
}

export const WarningCard: FC<IProps> = ({
    message,
    messageValues,
    className,
    children,
    isAlert,
}) => (
    <MessageCard
        cardType="warning"
        icon={faExclamationTriangle}
        message={message}
        messageValues={messageValues}
        className={className}
        isAlert={isAlert}
    >
        {children}
    </MessageCard>
);
