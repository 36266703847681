/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AddressSearchPlaceDetailsQueryVariables = {
    placeId: string;
    sessionToken?: string | null;
};
export type AddressSearchPlaceDetailsQueryResponse = {
    readonly licensingAddressDetails: {
        readonly addressLine: string | null;
        readonly country: string | null;
        readonly postcode: string | null;
        readonly state: string | null;
        readonly suburb: string | null;
    } | null;
};
export type AddressSearchPlaceDetailsQuery = {
    readonly response: AddressSearchPlaceDetailsQueryResponse;
    readonly variables: AddressSearchPlaceDetailsQueryVariables;
};



/*
query AddressSearchPlaceDetailsQuery(
  $placeId: String!
  $sessionToken: String
) {
  licensingAddressDetails(placeId: $placeId, sessionToken: $sessionToken) {
    addressLine
    country
    postcode
    state
    suburb
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "placeId"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "sessionToken"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "placeId",
                    "variableName": "placeId"
                },
                {
                    "kind": "Variable",
                    "name": "sessionToken",
                    "variableName": "sessionToken"
                }
            ],
            "concreteType": "LicensingGeocodedAddressDetails",
            "kind": "LinkedField",
            "name": "licensingAddressDetails",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressLine",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postcode",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "suburb",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AddressSearchPlaceDetailsQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AddressSearchPlaceDetailsQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "2f5173772d6bf43ca7445f6289629973",
            "id": null,
            "metadata": {},
            "name": "AddressSearchPlaceDetailsQuery",
            "operationKind": "query",
            "text": "query AddressSearchPlaceDetailsQuery(\n  $placeId: String!\n  $sessionToken: String\n) {\n  licensingAddressDetails(placeId: $placeId, sessionToken: $sessionToken) {\n    addressLine\n    country\n    postcode\n    state\n    suburb\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a1a13509f70caa34104554293f176755';
export default node;
