import { defineMessages } from "react-intl";

const idPrefix = "converge.";

export const convergeMessages = defineMessages({
    convergeWaitLabel: {
        defaultMessage: "When proceeding with payment please do not close this window, refresh or click the back button in your browser.",
        id: idPrefix + "convergeWait-label",
    },
});
