/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NewPasswordRequiredChallengeFields_configuration = {
    readonly passwordValidationRegex: string;
    readonly collectNameOnSignUp: boolean;
    readonly " $refType": "NewPasswordRequiredChallengeFields_configuration";
};
export type NewPasswordRequiredChallengeFields_configuration$data = NewPasswordRequiredChallengeFields_configuration;
export type NewPasswordRequiredChallengeFields_configuration$key = {
    readonly " $data"?: NewPasswordRequiredChallengeFields_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"NewPasswordRequiredChallengeFields_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPasswordRequiredChallengeFields_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "passwordValidationRegex",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectNameOnSignUp",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '5e17e9520a3db6fd91772ba084bbf64c';
export default node;
