import { LoadingMessage } from "components/shared/LoadingMessage";
import { SpinnerButton } from "components/shared/SpinnerButton";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import { AddressFields } from "components/address/AddressFields";
import styles from "components/address/AddressForm.module.scss";
import { AddressSearch, AddressSelection } from "components/address/AddressSearch";
import { AddressStateWarning } from "components/address/AddressStateWarning";
import { AddressSummary } from "components/address/AddressSummary";
import { JurisdictionModal } from "components/jurisdiction/JurisdictionModal";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import { MutatableSetAddressesMutationVariables, setAddresses } from "domain/address/setAddresses";
import { toastActions } from "domain/toast/toastActions";
import { AddressFormJurisdictionQuery, AddressFormJurisdictionQueryVariables } from "generatedQueries/AddressFormJurisdictionQuery.graphql";
import { AddressFormQuery, AddressFormQueryResponse } from "generatedQueries/AddressFormQuery.graphql";
import { LicensingAccountAddressInput, setAddressesMutationResponse } from "generatedQueries/setAddressesMutation.graphql";
import { Form, Scope } from "informed";
import { Mutable } from "infrastructure/Mutable";
import { forEach, isEqual, omit, trim } from "lodash";
import React, { FC, useState } from "react";
import { MessageDescriptor } from "react-intl";
import { useDispatch } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { fetchQuery, PayloadError } from "relay-runtime";
import { HiddenField } from "shared/components/formInputs/HiddenField";
import { Query } from "shared/components/Query";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("components.address.AddressForm", {
    errorRetrievingJurisdictions: "There was an error retrieving the jurisdictions for your address, please try again later.",
});

export enum AddressType {
    Physical,
    Mailing,
}

interface IProps {
    nextButtonMessage?: MessageDescriptor;
    saveButtonMessage?: MessageDescriptor;
    onBack: () => void;
    onNext: (skip?: number) => void;
    isNewAccount: boolean;
}

export const AddressForm: FC<IProps> = ({
    nextButtonMessage,
    saveButtonMessage,
    onBack,
    onNext,
    isNewAccount,
}) => {
    const dispatch = useDispatch();
    const environment = useRelayEnvironment();
    const [postalIsSameAsPhysicalAddress, setPostalIsSameAsPhysicalAddress] = useState<"postalIsSameAsPhysical" | "streetAddress" | "poBox" | undefined>(isNewAccount ? "postalIsSameAsPhysical" : undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasAddressStateError, setHasAddressStateError] = useState(false);
    const [isJurisdictionSelectionModalDisplayed, setIsJurisdictionSelectionModalDisplayed] = useState(false);
    const [jurisdictions, setJurisdictions] = useState<{ jurisdictionId: number, name: string }[]>();
    const [hasErrorRetrievingJurisdictions, setHasErrorRetrievingJurisdictions] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState("");

    return (
        <Query<AddressFormQuery>
            query={formQuery}
            variables={{}}>
            {({ error, props }) => {
                if (error) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                if (!props) {
                    return <LoadingMessage />;
                }

                const { account, configuration } = props;

                if (!account) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                if (postalIsSameAsPhysicalAddress === undefined) {
                    if (account.physicalAddress && account.mailingAddress) {
                        setPostalIsSameAsPhysicalAddress(account.physicalAddress.id === account.mailingAddress.id ? "postalIsSameAsPhysical" : (account.mailingAddress.__typename === "LicensingDeliveryAddress" ? "poBox" : "streetAddress"));
                    } else {
                        setPostalIsSameAsPhysicalAddress("postalIsSameAsPhysical");
                    }
                }

                return <Form<MutatableSetAddressesMutationVariables>
                    initialValues={getInitialValues(account)}
                    onSubmit={onFormSubmitted}
                    noValidate={true}
                    role="form"
                >
                    {({ formApi, formState }) => {
                        return (
                            <>
                                <Row>
                                    <Col
                                        lg={6}
                                        role="region"
                                        aria-labelledby="physicalAddressHeading"
                                    >
                                        <Scope scope="physicalAddress">
                                            <Card className={classNames("mt-3", styles.addressCard)}>
                                                <CardHeader
                                                    id="physicalAddressHeading"
                                                >
                                                    <Message message={accountMessages.physicalAddressLabel} />
                                                </CardHeader>
                                                <CardBody>
                                                    {
                                                        configuration?.isAddressSearchEnabled &&
                                                        <>
                                                            <AddressSearch
                                                                id="physicalAddressSearch"
                                                                onAddressSelected={onAddressSelected(AddressType.Physical)}
                                                            />
                                                            <hr />
                                                        </>
                                                    }
                                                    <AddressFields onChange={onManualAddressChanged(AddressType.Physical)} addressType={AddressType.Physical} />
                                                    <HiddenField field="jurisdictionId" />
                                                </CardBody>
                                            </Card>
                                        </Scope>
                                    </Col>
                                    <Col
                                        lg={6}
                                        role="region"
                                        aria-labelledby="mailingAddressHeading"
                                    >
                                        <Scope scope="mailingAddress">
                                            <Card className={classNames("mt-3", styles.addressCard)}>
                                                <CardHeader
                                                    id="mailingAddressHeading"
                                                >
                                                    <Message message={accountMessages.postalAddressLabel} />
                                                </CardHeader>
                                                <CardBody>
                                                    <div
                                                        className="mb-3"
                                                        role="radiogroup"
                                                    >
                                                        <FormGroup check={true} inline={true}>
                                                            <Label check={true}>
                                                                <Input
                                                                    type="radio"
                                                                    name="postalAddressType"
                                                                    value="postalIsSameAsPhysical"
                                                                    checked={postalIsSameAsPhysicalAddress === "postalIsSameAsPhysical"}
                                                                    aria-checked={postalIsSameAsPhysicalAddress === "postalIsSameAsPhysical"}
                                                                    onChange={onPostalIsSameAsPhysicalAddressChanged}
                                                                /> <Message
                                                                    message={accountMessages.postalIsSameAsPhysicalAddressLabel}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check={true} inline={true}>
                                                            <Label check={true}>
                                                                <Input
                                                                    type="radio"
                                                                    name="postalAddressType"
                                                                    value="streetAddress"
                                                                    checked={postalIsSameAsPhysicalAddress === "streetAddress"}
                                                                    aria-checked={postalIsSameAsPhysicalAddress === "streetAddress"}
                                                                    onChange={onStreetAddressChanged}
                                                                /> <Message
                                                                    message={accountMessages.streetAddressLabel}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check={true} inline={true}>
                                                            <Label check={true}>
                                                                <Input
                                                                    type="radio"
                                                                    name="postalAddressType"
                                                                    value="poBox"
                                                                    checked={postalIsSameAsPhysicalAddress === "poBox"}
                                                                    aria-checked={postalIsSameAsPhysicalAddress === "poBox"}
                                                                    onChange={onPoBoxChanged}
                                                                /> <Message
                                                                    message={accountMessages.poBoxLabel}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                    {
                                                        postalIsSameAsPhysicalAddress !== "postalIsSameAsPhysical" &&
                                                        <>
                                                            {
                                                                postalIsSameAsPhysicalAddress === "streetAddress" && configuration?.isAddressSearchEnabled &&
                                                                <>
                                                                    <AddressSearch
                                                                        id="mailingAddressSearch"
                                                                        onAddressSelected={onAddressSelected(AddressType.Mailing)}
                                                                    />
                                                                    <hr />
                                                                </>
                                                            }
                                                            <AddressFields onChange={onManualAddressChanged(AddressType.Mailing)} addressType={AddressType.Mailing} displayPoBoxFields={postalIsSameAsPhysicalAddress === "poBox"} />
                                                        </>
                                                    }
                                                    {
                                                        postalIsSameAsPhysicalAddress === "postalIsSameAsPhysical" &&
                                                        <AddressSummary {...formState.values.physicalAddress} className="mt-3" />
                                                    }
                                                </CardBody>
                                            </Card>
                                        </Scope>
                                    </Col>
                                    <Col sm={12}>
                                        {
                                            hasAddressStateError && <AddressStateWarning />
                                        }
                                        {
                                            validationErrorMessage && <WarningMessage message={validationErrorMessage} />
                                        }
                                        <Button
                                            colour="default"
                                            type="button"
                                            className="float-left"
                                            onClick={onBack}>
                                            <Message message={sharedMessages.backButtonLabel} />
                                        </Button>
                                        {
                                            formValuesHaveBeenChanged()
                                                ? <SpinnerButton
                                                    className="float-right"
                                                    color="primary"
                                                    isLoading={isSubmitting}
                                                    message={saveButtonMessage || sharedMessages.saveLabel} />
                                                : <Button
                                                    className="float-right"
                                                    color="primary"
                                                    type="button"
                                                    onClick={onNextButtonClicked}>
                                                    <Message message={nextButtonMessage || sharedMessages.nextButtonLabel} />
                                                </Button>
                                        }
                                    </Col>
                                </Row>
                                {
                                    hasErrorRetrievingJurisdictions &&
                                    <Row>
                                        <Col>
                                            <ErrorMessage
                                                message={messages.errorRetrievingJurisdictions}
                                            />
                                        </Col>
                                    </Row>
                                }
                                <JurisdictionModal
                                    isSaving={isSubmitting}
                                    show={isJurisdictionSelectionModalDisplayed}
                                    jurisdictions={jurisdictions ?? []}
                                    onCancelled={onJurisdictionModalCancelled}
                                    onConfirmed={onJurisdictionModalConfirmed(formState.values)}
                                    configuration={configuration}
                                />
                            </>
                        );

                        function onNextButtonClicked() {
                            onNext();
                        }

                        function formValuesHaveBeenChanged(): boolean {
                            if (isNewAccount || !account || !account.physicalAddress || !account.mailingAddress) {
                                return true;
                            }

                            const {
                                id: physicalId,
                                ...accountPhysicalAddress
                            } = account.physicalAddress;

                            if (postalIsSameAsPhysicalAddress === "postalIsSameAsPhysical") {
                                if (account.physicalAddress.id === account.mailingAddress.id) {
                                    return !isEqual(accountPhysicalAddress, formState.values.physicalAddress);
                                }

                                return !formState.values.mailingAddress ||
                                    account.mailingAddress.id !== formState.values.mailingAddress.id ||
                                    !isEqual(accountPhysicalAddress, formState.values.physicalAddress);
                            }

                            const {
                                id: postalId,
                                jurisdictionId,
                                ...accountPostalAddress
                            } = account.mailingAddress;

                            return !isEqual(accountPhysicalAddress, formState.values.physicalAddress) ||
                                !isEqual(omit(accountPostalAddress, "__typename"), omit(formState.values.mailingAddress, "__typename"));
                        }

                        function onAddressSelected(addressType: AddressType) {
                            return (selection: AddressSelection) => {
                                const newAddressValue =
                                    addressType === AddressType.Physical
                                        ? { ...formState.values.physicalAddress, ...selection as Mutable<LicensingAccountAddressInput> }
                                        : { ...formState.values.mailingAddress, ...selection as Mutable<LicensingAccountAddressInput> };

                                if (addressType === AddressType.Mailing) {
                                    newAddressValue.id = undefined;
                                    newAddressValue.jurisdictionId = undefined;
                                }

                                setFormValues(addressType, newAddressValue);
                            };
                        }

                        function onManualAddressChanged(addressType: AddressType) {
                            return () => {
                                const newAddressValue =
                                    addressType === AddressType.Physical
                                        ? { ...formState.values.physicalAddress, jurisdictionId: undefined }
                                        : { ...formState.values.mailingAddress, jurisdictionId: undefined };

                                setFormValues(addressType, newAddressValue);
                            };
                        }

                        function onPostalIsSameAsPhysicalAddressChanged() {
                            setPostalIsSameAsPhysicalAddress("postalIsSameAsPhysical");
                        }

                        function onStreetAddressChanged() {
                            setPostalIsSameAsPhysicalAddress("streetAddress");
                        }

                        function onPoBoxChanged() {
                            setPostalIsSameAsPhysicalAddress("poBox");
                        }

                        function setFormValues(addressType: AddressType, address: Mutable<LicensingAccountAddressInput>) {
                            let formHasMissingValues = false;

                            const scope = addressType === AddressType.Physical ? "physicalAddress" : "mailingAddress";

                            forEach(address, (value, fieldName) => {
                                const scopedFieldName = `${scope}.${fieldName}`;
                                if (formApi.fieldExists(scopedFieldName)) {
                                    formApi.setValue(scopedFieldName, value ? value : "");
                                } else {
                                    formHasMissingValues = true;
                                }
                            });

                            if (formHasMissingValues) {
                                if (addressType === AddressType.Physical) {
                                    formApi.setValues({
                                        mailingAddress: { ...formState.values.mailingAddress },
                                        physicalAddress: address,
                                    });
                                } else {
                                    formApi.setValues({
                                        mailingAddress: address,
                                        physicalAddress: { ...formState.values.physicalAddress },
                                    });
                                }
                            }
                        }
                    }}
                </Form>;
            }}
        </Query>
    );

    function onJurisdictionModalCancelled() {
        setIsJurisdictionSelectionModalDisplayed(false);
        setIsSubmitting(false);
    }

    function onJurisdictionModalConfirmed(values: MutatableSetAddressesMutationVariables) {
        return (jurisdictionId: number) => {
            setIsSubmitting(true);
            const setAddressVariables: MutatableSetAddressesMutationVariables = {
                mailingAddress: trimValues(postalIsSameAsPhysicalAddress === "postalIsSameAsPhysical" ? { ...values.physicalAddress, jurisdictionId: null } : { ...values.mailingAddress }),
                physicalAddress: trimValues({ ...values.physicalAddress, jurisdictionId }),
            };

            setAddresses(environment, setAddressVariables, onSaveCompleted, onError);
        };
    }

    function onFormSubmitted(values: MutatableSetAddressesMutationVariables) {
        setIsSubmitting(true);
        setHasAddressStateError(false);
        setHasErrorRetrievingJurisdictions(false);
        setValidationErrorMessage("");

        const variables: AddressFormJurisdictionQueryVariables = {
            postcode: values.physicalAddress.postcode,
            state: values.physicalAddress.state,
            suburb: values.physicalAddress.suburb,
        };

        fetchQuery<AddressFormJurisdictionQuery>(environment, jurisdictionQuery, variables)
            .subscribe({
                error() {
                    setHasErrorRetrievingJurisdictions(true);
                    setIsSubmitting(false);
                },
                next(response) {
                    if (response.licensingJurisdictionSearch?.length === 1) {
                        const setAddressVariables: MutatableSetAddressesMutationVariables = {
                            mailingAddress: trimValues(postalIsSameAsPhysicalAddress === "postalIsSameAsPhysical" ? { ...values.physicalAddress, jurisdictionId: null } : { ...values.mailingAddress }),
                            physicalAddress: trimValues({ ...values.physicalAddress, jurisdictionId: response.licensingJurisdictionSearch[0].jurisdictionId }),
                        };

                        setAddresses(environment, setAddressVariables, onSaveCompleted, onError);
                    } else {
                        setJurisdictions(response.licensingJurisdictionSearch?.map(j => ({ ...j })) ?? []);
                        setIsJurisdictionSelectionModalDisplayed(true);
                        setIsSubmitting(false);
                    }
                },
            });
    }

    function onSaveCompleted(response: setAddressesMutationResponse, errors?: PayloadError[] | null) {
        if ((errors && errors.length) || !response.updatePhysicalAddress?.result || !response.updateMailingAddress?.result) {
            if (response.updatePhysicalAddress?.errors?.length) {
                checkErrorCode(response.updatePhysicalAddress.errors[0].errorCode, response.updatePhysicalAddress.errors[0].message);
            }
            else if (response.updateMailingAddress?.errors?.length) {
                checkErrorCode(response.updateMailingAddress.errors[0].errorCode, response.updateMailingAddress.errors[0].message);
            }
            onError();
            return;
        }

        dispatch(toastActions.toastSuccess());
        onNext();
    }

    function checkErrorCode(code: string, message: string) {
        if (code === "STATENOTFOUND") {
            setHasAddressStateError(true);
        }
        else if (code === "VALIDATIONERROR") {
            setValidationErrorMessage(message);
        }
    }

    function onError() {
        dispatch(toastActions.toastFailure());
        setIsSubmitting(false);
    }

    function getInitialValues(account: Exclude<AddressFormQueryResponse["account"], null>): MutatableSetAddressesMutationVariables {
        return {
            mailingAddress: { ...account.mailingAddress, deliveryTypeId: account.mailingAddress && account.mailingAddress.deliveryType && account.mailingAddress.deliveryType.displayId },
            physicalAddress: { ...account.physicalAddress },
        };
    }
};

function trimValues(address: Mutable<LicensingAccountAddressInput>): Mutable<LicensingAccountAddressInput> {
    forEach(address, (value, key) => {
        if (typeof value === "string") {
            address[key] = trim(value);
        }
    });

    return address;
}

const formQuery = graphql`
query AddressFormQuery {
    account {
        physicalAddress {
            id
            addressLine
            suburb
            state
            postcode
            jurisdictionId
        }
        mailingAddress {
            __typename
            ... on LicensingAddress {
                id
                postcode
                state
                suburb
            }
            ... on LicensingStreetAddress {
                jurisdictionId
                addressLine
            }
            ... on LicensingDeliveryAddress {
                deliveryType {
                    displayId
                }
                deliveryNumber
            }
        }
    }
    configuration {
        isAddressSearchEnabled
        ...JurisdictionModal_configuration
    }
}`;

const jurisdictionQuery = graphql`
    query AddressFormJurisdictionQuery($state: String, $suburb: String, $postcode: String) {
        licensingJurisdictionSearch(state: $state, suburb: $suburb, postcode: $postcode) {
            jurisdictionId
            name
        }
    }
`;
