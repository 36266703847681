import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { Animal } from "components/animal/Animal";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { deleteAnimal } from "domain/animal/deleteAnimal";
import { upsertAnimal, UpsertAnimalInput } from "domain/animal/upsertAnimal";
import { redirectActions } from "domain/redirect/redirectActions";
import { toastActions } from "domain/toast/toastActions";
import { AnimalEditQuery, AnimalEditQueryResponse } from "generatedQueries/AnimalEditQuery.graphql";
import { deleteAnimalMutationResponse } from "generatedQueries/deleteAnimalMutation.graphql";
import { upsertAnimalMutationResponse } from "generatedQueries/upsertAnimalMutation.graphql";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { PayloadError } from "relay-runtime";
import { routes } from "routes/routes";
import { ConfirmModal } from "shared/components/ConfirmModal";
import { ErrorHandlingMutationForm, IWarningMessageProps } from "shared/components/ErrorHandlingMutationForm";
import { Query } from "shared/components/Query";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("routs.pets.animaledit", {
    noUploadWarningMessage: "The rabies vaccination date has been changed, but no new proof of rabies has been uploaded. Are you sure you wish to continue?",
    noUploadWarningMessageTitle: "No Proof of Rabies Uploaded",
});

interface IRouteProps {
    id: string;
}

export const AnimalEdit: FC<RouteComponentProps<IRouteProps>> = ({
    match,
}) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const environment = useRelayEnvironment();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    return (
        <Query<AnimalEditQuery>
            query={query}
            variables={{
                animalId: match.params.id,
            }}>
            {({ error, props }) => {
                if (error) {
                    return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
                }

                if (!props) {
                    return <LoadingMessage />;
                }

                const animal = props.account!.animal;
                if (!animal) {
                    return <ErrorMessage
                        message={animalMessages.petMissingMessage}
                        messageValues={{
                            a: msg => <Link to={routes.pets.index.create({})}>{msg}</Link>,
                        }} />;
                }

                return (
                    <>
                        <Row>
                            <Col sm={9} md={7} lg={5} xl={4}>
                                <ErrorHandlingMutationForm<UpsertAnimalInput, upsertAnimalMutationResponse>
                                    initialValues={getInitialValues(animal)}
                                    onNext={onSave}
                                    mutationFunc={upsertAnimal}
                                    alwaysSubmit={true}
                                    shouldDisplayWarning={shouldDisplayRabiesWarning}>
                                    {({ formApi, formState }) => (
                                        <Animal
                                            formApi={formApi}
                                            formState={formState}
                                            animalId={animal.id}
                                            onDeleteClicked={
                                                animal.status === "NEW" || (animal.licences.length >= 1 && animal.licences.every(l => l.status === "CANCELLED"))
                                                    ? onDelete
                                                    : undefined
                                            }
                                            queryKey={props}
                                        />
                                    )}
                                </ErrorHandlingMutationForm>
                            </Col>
                        </Row>
                        <ConfirmModal
                            modalTitle={animalMessages.deleteAnimalModalTitle}
                            show={showDeleteDialog}
                            onConfirmed={onDeleteConfirmed(animal.id)}
                            onCancelled={onDeleteDismissed}
                            confirmButtonColor="danger">
                            <Message message={animalMessages.deleteAnimalConfirmationMessage} />
                        </ConfirmModal>
                    </>
                );

                function shouldDisplayRabiesWarning(values: UpsertAnimalInput): IWarningMessageProps[] | false {
                    if (!animal?.rabiesVaccination) {
                        return false;
                    }

                    if (animal.rabiesVaccination.dateAdministered !== values.rabiesVaccinationDateAdministered &&
                        animal.rabiesVaccination.storageId === values.rabiesVaccinationStorageId) {
                        return [{
                            titleMessage: formatMessage(messages.noUploadWarningMessageTitle),
                            warningMessage: formatMessage(messages.noUploadWarningMessage),
                        }];
                    }

                    return false;
                }
            }}
        </Query>
    );

    function onSave() {
        dispatch(toastActions.toastSuccess());
        dispatch(redirectActions.redirectTo(routes.pets.index.create({})));
    }

    function onDelete() {
        setShowDeleteDialog(true);
    }

    function onDeleteConfirmed(animalId: string) {
        return () => {
            deleteAnimal(environment, animalId, onDeleteCompleted, onDeleteError);
        };
    }

    function onDeleteDismissed() {
        setShowDeleteDialog(false);
    }

    function onDeleteCompleted(response: deleteAnimalMutationResponse, errors?: PayloadError[] | null) {
        if ((errors && errors.length) || !response.deleteLicensingAnimal || !response.deleteLicensingAnimal.id) {
            onDeleteError();
        } else {
            dispatch(toastActions.toastSuccess());
            dispatch(redirectActions.redirectTo(routes.pets.index.create({})));
        }
    }

    function onDeleteError() {
        dispatch(toastActions.toastFailure());
    }
};

function getInitialValues(animal: NonNullable<NonNullable<AnimalEditQueryResponse["account"]>["animal"]>): UpsertAnimalInput {
    return {
        ...animal,
        identifications: animal.identifications && animal.identifications.map(i => i),
        rabiesVaccinationDateAdministered: animal.rabiesVaccination && animal.rabiesVaccination.dateAdministered,
        rabiesVaccinationImmunityPeriodMonths: animal.rabiesVaccination && animal.rabiesVaccination.immunityPeriodMonths,
        rabiesVaccinationStorageId: animal.rabiesVaccination && animal.rabiesVaccination.storageId,
    };
}

const query = graphql`
    query AnimalEditQuery($animalId: ID!) {
        account {
            animal(animalId: $animalId) {
                id
                name
                dateOfBirth
                animalTypeId
                genderId
                isDesexed
                breedId
                secondaryBreedId
                breedSelection
                colourId
                secondaryColourId
                identifications {
                    id
                    type
                    value
                }
                rabiesVaccination {
                    dateAdministered
                    immunityPeriodMonths
                    storageId
                }
                licences {
                    id
                    status
                }
                licenceTypeId
                status
                currentLicence {
                    id
                }
            }
        }
        ...Animal_query
    }`;
