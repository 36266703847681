import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import styles from "components/licence/LicenseWarnings.module.scss";
import { Message } from "shared/components/Message";
import { LicenseWarnings_account } from "generatedQueries/LicenseWarnings_account.graphql";
import React, { FC } from "react";
import { createFragmentContainer } from "react-relay";
import { Link } from "react-router-dom";
import { Button, CardText } from "reactstrap";
import { routes } from "routes/routes";
import { WarningCard } from "shared/components/WarningCard";
import { mapMessages } from "shared/mapMessages";
import urljoin from "url-join";

const messages = mapMessages("components.licence.InternalIncompleteLicencesWarning", {
    cancelledandOnHoldAnimalsCardTitle: "You have {count, plural, one {a pet} other {pets}} whose {count, plural, one {license has} other {licenses have}} been cancelled or put on hold",
    continueButtonLabel: "Continue",
    incompleteAnimalsCardTitle: "You haven't finished licensing your pet",
    viewPetsButtonLabel: "View {count, plural, one {Pet} other {Pets}}",
});

interface IGraphQlProps {
    account: LicenseWarnings_account;
}

const InternalLicenseWarnings: FC<IGraphQlProps> = ({
    account,
}) => {
    const incompleteAnimals = account.animals?.edges?.map(edge => edge.node).filter(a => a.status === "NEW") ?? [];
    const cancelledandOnHoldAnimals = account.animals?.edges?.map(edge => edge.node).filter(a => a.status === "CANCELLED" || a.status === "REJECTED") ?? [];

    return (
        <>
            {
                incompleteAnimals.length > 0 &&
                <WarningCard
                    message={messages.incompleteAnimalsCardTitle}
                    isAlert={true}
                >
                    <ul className={classNames(styles.animalList, "card-text")}>
                        {
                            incompleteAnimals.map(animal => (
                                <li key={animal.id}>
                                    {animal.name}
                                </li>
                            ))
                        }
                    </ul>
                    <CardText>
                        <Link to={routes.payment.index.create({})}>
                            <Button color="primary">
                                <Message message={messages.continueButtonLabel} />
                            </Button>
                        </Link>
                    </CardText>
                </WarningCard>
            }
            {
                cancelledandOnHoldAnimals.length > 0 &&
                <WarningCard
                    message={messages.cancelledandOnHoldAnimalsCardTitle}
                    messageValues={{
                        count: cancelledandOnHoldAnimals.length,
                    }}
                    isAlert={true}
                >
                    <ul className={classNames(styles.animalList, "card-text")}>
                        {
                            cancelledandOnHoldAnimals.map(animal => (
                                <li key={animal.id}>
                                    {animal.name}
                                </li>
                            ))
                        }
                    </ul>
                    <CardText>
                        <Link to={urljoin(routes.pets.index.create({}), `?scrollTo=${cancelledandOnHoldAnimals[0].id}`)}>
                            <Button color="primary">
                                <Message
                                    message={messages.viewPetsButtonLabel}
                                    values={{
                                        count: cancelledandOnHoldAnimals.length,
                                    }}
                                />
                            </Button>
                        </Link>
                    </CardText>
                </WarningCard>
            }
        </>
    );
};

export const LicenseWarnings = createFragmentContainer(InternalLicenseWarnings, {
    account: graphql`
        fragment LicenseWarnings_account on Account {
            animals {
                edges {
                    node {
                        id
                        name
                        status
                    }
                }
            }
        }`,
});
