import { createRoute } from "infrastructure/routing";
import React, { FC } from "react";
import { Switch } from "react-router";
import { AddLicence } from "routes/licence/AddLicence";
import { LicenceRenewal } from "routes/licence/LicenceRenewal";
import { routes } from "routes/routes";

export const RouteIndex: FC = () => (
    <Switch>
        {createRoute(routes.licence.renewal, { component: LicenceRenewal })}
        {createRoute(routes.licence.index, { component: AddLicence })}
    </Switch>
);
