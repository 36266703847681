/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ResendLicensingSignUpCodeError = "ALREADYCONFIRMED" | "CODEDELIVERYFAILED" | "USERNOTFOUND" | "%future added value";
export type ResendLicensingSignUpCodeInput = {
    email?: string | null;
};
export type resendSignUpCodeMutationVariables = {
    input: ResendLicensingSignUpCodeInput;
};
export type resendSignUpCodeMutationResponse = {
    readonly resendLicensingSignUpCode: {
        readonly wasSuccessful: boolean;
        readonly deliveryMedium: string | null;
        readonly error: ResendLicensingSignUpCodeError | null;
    } | null;
};
export type resendSignUpCodeMutation = {
    readonly response: resendSignUpCodeMutationResponse;
    readonly variables: resendSignUpCodeMutationVariables;
};



/*
mutation resendSignUpCodeMutation(
  $input: ResendLicensingSignUpCodeInput!
) {
  resendLicensingSignUpCode(input: $input) {
    wasSuccessful
    deliveryMedium
    error
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ResendLicensingSignUpCode",
            "kind": "LinkedField",
            "name": "resendLicensingSignUpCode",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "wasSuccessful",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deliveryMedium",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "resendSignUpCodeMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "resendSignUpCodeMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "fcee0bbfc197c1e304f0cd1aaf936c32",
            "id": null,
            "metadata": {},
            "name": "resendSignUpCodeMutation",
            "operationKind": "mutation",
            "text": "mutation resendSignUpCodeMutation(\n  $input: ResendLicensingSignUpCodeInput!\n) {\n  resendLicensingSignUpCode(input: $input) {\n    wasSuccessful\n    deliveryMedium\n    error\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '475ad2ecc6bcfb7acd55fd9234f39dd4';
export default node;
