import graphql from "babel-plugin-relay/macro";
import styles from "components/account/details/AccountDetails.module.scss";
import { AccountSubtitle_account } from "generatedQueries/AccountSubtitle_account.graphql";
import React, { FC } from "react";
import { createFragmentContainer } from "react-relay";

export interface IAccountSubtitle {
    account: AccountSubtitle_account;
}

export const InternalAccountSubtitle: FC<IAccountSubtitle> = ({ account }) => {
    return <h4>
        <div className={styles.capitalize}>
            {account.name}
        </div>
    </h4>;
};

export const AccountSubtitle = createFragmentContainer(InternalAccountSubtitle, {
    account: graphql`
        fragment AccountSubtitle_account on Account {
            name
        }`,
});
