import { Message } from "shared/components/Message";
import { ConfirmSignUpForm } from "components/user/ConfirmSignUpForm";
import { ResendSignUpCode } from "components/user/ResendSignUpCode";
import { parse } from "query-string";
import React, { FC } from "react";
import { useLocation } from "react-router";
import { Col, Row } from "reactstrap";
import { AccordionSelection } from "shared/components/AccordionSelection";
import { mapMessages } from "shared/mapMessages";

const messages = mapMessages("routes.user.confirmsignup", {
    confirmSignUpCodeReceived: "I've received my code",
    confirmSignUpCodeNotReceived: "I haven't received a code",
    title: "Confirm Account",
});

export const confirmSignUpOptionKey = "CONFIRM";
export const resendSignUpCodeOptionKey = "RESEND";

interface IQueryString {
    code?: string;
}

export const ConfirmSignUp: FC = () => {
    const location = useLocation();
    const queryString = parse(location.search) as IQueryString;

    return (
        <Row className="justify-content-center">
            <Col xl={5} lg={6} md={7} sm={9}>
                <h2><Message message={messages.title} /></h2>

                <AccordionSelection initialSelection={queryString.code ? confirmSignUpOptionKey : null}>
                    {{
                        options: [
                            {
                                children: <ConfirmSignUpForm />,
                                message: messages.confirmSignUpCodeReceived,
                                option: confirmSignUpOptionKey,
                            },
                            {
                                children: <ResendSignUpCode />,
                                message: messages.confirmSignUpCodeNotReceived,
                                option: resendSignUpCodeOptionKey,
                            },
                        ],
                    }}
                </AccordionSelection>
            </Col>
        </Row>
    );
};
