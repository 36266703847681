import { GlobalErrorHandler } from "components/globalErrorHandler/GlobalErrorHandler";
import { DefaultLayout } from "components/layout/DefaultLayout";
import { PayPalPayflowForm } from "components/payment/PayPalPayflow/PayPalPayflowForm";
import { PayPalPayflowIframeReturn } from "components/payment/PayPalPayflow/PayPalPayflowIframeReturn";
import { ConnectedRouter } from "connected-react-router";
import { environment } from "infrastructure/environment";
import { Intl } from "infrastructure/Intl";
import { LoggerProvider } from "infrastructure/logger";
import { RollbarProvider } from "infrastructure/rollbar";
import { createRoute } from "infrastructure/routing";
import React, { FC, useMemo } from "react";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { default as ReduxToastr } from "react-redux-toastr";
import { RelayEnvironmentProvider } from "react-relay";
import { Switch } from "react-router";
import Rollbar from "rollbar";
import { routes } from "routes/routes";
import { history, store } from "store/store";

export const App: FC = () => {
    const rollbar = useMemo(() => new Rollbar(window.ShelterManagement.ROLLBAR_CONFIG ?? {}), []);

    return <RollbarProvider value={rollbar}>
        <Provider store={store}>
            <IntlProvider locale="en">
                <RelayEnvironmentProvider environment={environment}>
                    <LoggerProvider>
                        <GlobalErrorHandler>
                            <Intl>
                                <ConnectedRouter history={history}>
                                    <ReduxToastr />
                                    <Switch>
                                        {createRoute(routes.payment.payPalPayflowIFrame, { component: PayPalPayflowIframeReturn })}
                                        {createRoute(routes.payment.payPalPayflowFields, { component: PayPalPayflowForm })}
                                        <DefaultLayout />
                                    </Switch>
                                </ConnectedRouter>
                            </Intl>
                        </GlobalErrorHandler>
                    </LoggerProvider>
                </RelayEnvironmentProvider>
            </IntlProvider>
        </Provider>
    </RollbarProvider>;
};
