import { IAnimalTagSearchResultRow } from "domain/animal/models/animalTagSearchResultRow";
import { ActionType, createAction } from "typesafe-actions";

const animalFound = createAction("ANIMAL_FOUND", resolve => {
    return (animal: IAnimalTagSearchResultRow | null) => {
        return resolve(animal);
    };
});

const clearAnimalFound = createAction("ANIMAL_CLEAR_ANIMAL_FOUND", resolve => {
    return () => {
        return resolve(null);
    };
});

export const animalActions = {
    animalFound,
    clearAnimalFound,
};

export type AnimalAction = ActionType<typeof animalActions>;
