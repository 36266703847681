import { SpinnerButton } from "components/shared/SpinnerButton";
import { Message } from "shared/components/Message";
import React, { FC, ReactNode } from "react";
import { MessageDescriptor } from "react-intl";
import Button from "reactstrap/lib/Button";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { sharedMessages } from "shared/sharedMessages";

interface IProps {
    isConfirming?: boolean;
    show: boolean;
    children?: ReactNode;
    modalTitle: MessageDescriptor;
    titleId?: string;
    confirmButtonLabel?: MessageDescriptor;
    confirmButtonColor?: string;
    cancelButtonLabel?: MessageDescriptor;
}

interface IEvents {
    onConfirmed: () => void;
    onCancelled: () => void;
}

type Props = IProps & IEvents;

export const ConfirmModal: FC<Props> = ({
    isConfirming,
    children,
    show,
    modalTitle,
    titleId,
    confirmButtonLabel,
    confirmButtonColor,
    cancelButtonLabel,
    onConfirmed,
    onCancelled,
}) => {
    return <Modal
        isOpen={show}
        toggle={onCancelled}
        labelledBy={titleId}
    >
        <ModalHeader
            toggle={onCancelled}
            id={titleId}
        >
            <Message message={modalTitle} />
        </ModalHeader>
        <ModalBody>
            {children}
        </ModalBody>
        <ModalFooter>
            <SpinnerButton
                color={confirmButtonColor || "primary"}
                onClick={onConfirmed}
                isLoading={isConfirming ?? false}
                message={confirmButtonLabel || sharedMessages.okLabel}
            />
            <Button
                color="secondary"
                onClick={onCancelled}>
                <Message message={cancelButtonLabel || sharedMessages.cancelButtonLabel} />
            </Button>
        </ModalFooter>
    </Modal>;
};
