/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnimalTagSearchResultRow_animal = {
    readonly animalId: number;
    readonly animalType: {
        readonly id: string;
        readonly description: string | null;
    } | null;
    readonly name: string | null;
    readonly primaryBreed: {
        readonly breedId: number;
        readonly name: string;
    } | null;
    readonly secondaryBreed: {
        readonly breedId: number;
        readonly name: string;
    } | null;
    readonly isCrossBreed: boolean;
    readonly microchip: string | null;
    readonly genderId: number | null;
    readonly dateOfBirth: string | null;
    readonly isDesexed: boolean | null;
    readonly primaryColour: {
        readonly colourId: number;
    } | null;
    readonly secondaryColour: {
        readonly colourId: number;
    } | null;
    readonly tagNumber: string | null;
    readonly " $refType": "AnimalTagSearchResultRow_animal";
};
export type AnimalTagSearchResultRow_animal$data = AnimalTagSearchResultRow_animal;
export type AnimalTagSearchResultRow_animal$key = {
    readonly " $data"?: AnimalTagSearchResultRow_animal$data;
    readonly " $fragmentRefs": FragmentRefs<"AnimalTagSearchResultRow_animal">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v1 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breedId",
            "storageKey": null
        } as any,
        (v0 /*: any*/)
    ], v2 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "colourId",
            "storageKey": null
        } as any
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AnimalTagSearchResultRow_animal",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "animalId",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingAnimalType",
                "kind": "LinkedField",
                "name": "animalType",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingBreed",
                "kind": "LinkedField",
                "name": "primaryBreed",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingBreed",
                "kind": "LinkedField",
                "name": "secondaryBreed",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCrossBreed",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "microchip",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "genderId",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateOfBirth",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDesexed",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingAnimalColour",
                "kind": "LinkedField",
                "name": "primaryColour",
                "plural": false,
                "selections": (v2 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingAnimalColour",
                "kind": "LinkedField",
                "name": "secondaryColour",
                "plural": false,
                "selections": (v2 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagNumber",
                "storageKey": null
            }
        ],
        "type": "LicensingTagSearchResult",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '0fc8fd5e866eb648b401a032f8f298c0';
export default node;
