/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnimalPublicTagSearchResultRow_searchResult = {
    readonly tagNumber: string | null;
    readonly animalName: string | null;
    readonly animalTypeDescription: string | null;
    readonly primaryBreedDescription: string | null;
    readonly secondaryBreedDescription: string | null;
    readonly ownerName: string | null;
    readonly mobilePhoneNumber: string | null;
    readonly homePhoneNumber: string | null;
    readonly " $refType": "AnimalPublicTagSearchResultRow_searchResult";
};
export type AnimalPublicTagSearchResultRow_searchResult$data = AnimalPublicTagSearchResultRow_searchResult;
export type AnimalPublicTagSearchResultRow_searchResult$key = {
    readonly " $data"?: AnimalPublicTagSearchResultRow_searchResult$data;
    readonly " $fragmentRefs": FragmentRefs<"AnimalPublicTagSearchResultRow_searchResult">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnimalPublicTagSearchResultRow_searchResult",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tagNumber",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "animalName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "animalTypeDescription",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryBreedDescription",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "secondaryBreedDescription",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mobilePhoneNumber",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "homePhoneNumber",
            "storageKey": null
        }
    ],
    "type": "PublicTagSearchResult",
    "abstractKey": null
} as any;
(node as any).hash = '62fcb264227c0ca7cf50a83e06c72048';
export default node;
