import { LicensingPaymentMethodInput, LicensingPaymentMethodRequestInput } from "generatedQueries/processPaymentMethodMutation.graphql";

export function buildLicensingPaymentMethodInput(
    licensingPaymentMethodRequestInput: LicensingPaymentMethodRequestInput,
    licensingContractId: number | null,
    animalIds: string[],
    donation: number | null,
    donationNotes: string | null,
    amountToCharge: number): LicensingPaymentMethodInput {
    return {
        paymentMethodRequest: licensingPaymentMethodRequestInput,
        paymentRequest: {
            acceptedTermsAndConditionsContractId: licensingContractId,
            animalIds,
            donationAmount: donation || 0.0,
            donationNotes: (donation && donationNotes) || null,
            totalAmount: amountToCharge,
        },
    };
}
