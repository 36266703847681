/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PaymentRowLicenceDetails_configuration = {
    readonly organisationContact: string;
    readonly " $refType": "PaymentRowLicenceDetails_configuration";
};
export type PaymentRowLicenceDetails_configuration$data = PaymentRowLicenceDetails_configuration;
export type PaymentRowLicenceDetails_configuration$key = {
    readonly " $data"?: PaymentRowLicenceDetails_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"PaymentRowLicenceDetails_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentRowLicenceDetails_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '056ef906cb6dffc5536d518d0ad9c3fe';
export default node;
