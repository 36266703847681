/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PaymentRowLicenceDetails_paymentRow = {
    readonly rabiesIsValid: boolean;
    readonly blocked: boolean;
    readonly animalId: string;
    readonly licenceType: string;
    readonly isRenewal: boolean;
    readonly isLateFee: boolean;
    readonly " $refType": "PaymentRowLicenceDetails_paymentRow";
};
export type PaymentRowLicenceDetails_paymentRow$data = PaymentRowLicenceDetails_paymentRow;
export type PaymentRowLicenceDetails_paymentRow$key = {
    readonly " $data"?: PaymentRowLicenceDetails_paymentRow$data;
    readonly " $fragmentRefs": FragmentRefs<"PaymentRowLicenceDetails_paymentRow">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentRowLicenceDetails_paymentRow",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rabiesIsValid",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blocked",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "animalId",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "licenceType",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isRenewal",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLateFee",
            "storageKey": null
        }
    ],
    "type": "LicensingPaymentFormRow",
    "abstractKey": null
} as any;
(node as any).hash = '5a57c03a53f862b3c131e4eeebab903b';
export default node;
