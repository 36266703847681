import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import styles from "./Spinner.module.scss";

interface IProps {
    className?: string;
    size?: SizeProp;
}

export const Spinner: React.SFC<IProps> = props => <FontAwesomeIcon className={classNames(styles.faSpin, props.className)} icon={faCircleNotch} size={props.size} />;
