import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import styles from "components/payment/CardConnectForm.module.scss";
import { IPaymentFormChildProps } from "components/payment/PaymentForm";
import { StandardPaymentButtonLayout } from "components/payment/StandardPaymentButtonLayout";
import { Message } from "shared/components/Message";
import { paymentMessages } from "domain/payment/paymentMessages";
import { CardConnectForm_configuration } from "generatedQueries/CardConnectForm_configuration.graphql";
import { useLogger } from "infrastructure/logger";
import { omit } from "lodash";
import isEqual from "lodash/isEqual";
import join from "lodash/join";
import moment from "moment";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { Col, FormText } from "reactstrap";
import { mapMessages } from "shared/mapMessages";

const messages = mapMessages("components.payment.CardConnectForm", {
    cvcLabel: "CVC",
    expiryDateLabel: "Expiry Date",
    postcodeLabel: "Zip",
});

const cvcMinLength = 3;
const cvcMaxLength = 4;
const postcodeMaxLength = 10;

const fieldStyle = `
    input {
        width: 280px;
        height: 1.2em;
        border: none;
        outline: none;
        font-family: sans-serif;
        font-size: .9em;
        line-height: 1.2em;
        background: transparent;
    }
    .error {
        color: red;
    }`;
const expiryDateIncompleteRegex = /^[\d /]+$/;
const expiryDateCompleteRegex = /(0[1-9]|10|11|12) ?\/ ?[0-9]{2}$/;
const cvcRegex = /^\d+$/;

interface ITokenResponse {
    token?: string;
    errorCode?: string;
    errorMessage?: string;
}

interface IGraphQlProps {
    configuration: CardConnectForm_configuration;
}

type Props = IPaymentFormChildProps & IGraphQlProps;

const CardConnectFormInternal: FC<Props> = ({
    configuration,
    paymentReady,
    requestState,
    paymentSucceeded,
    payButton,
    errorMessages,
}) => {
    const { formatMessage } = useIntl();
    const logger = useLogger();
    const componentRef = useRef<HTMLDivElement>(null);
    const cvcRef = useRef<HTMLInputElement>(null);
    const postcodeRef = useRef<HTMLInputElement>(null);

    const [token, setToken] = useState<string | null>(null);
    const [hasTokenError, setHasTokenError] = useState(false);
    const [expiryDate, setExpiryDate] = useState<string>("");
    const [expiryDateInvalid, setExpiryDateInvalid] = useState<boolean>(false);
    const [cvc, setCvc] = useState<string>("");
    const [postcode, setPostcode] = useState<string>("");

    const encodedUri = useMemo(() => {
        const parameters = [
            `css=${fieldStyle.replace(/[ \t\r\n]/gm, "")}`,
            "tokenizewheninactive=true",
            "inactivityto=750",
            "invalidinputevent=true",
            "enhancedresponse=true",
            "autofocus=true",
            `placeholder=${formatMessage(paymentMessages.cardNumberPlaceholder)}`,
            "formatinput=true",
        ];

        return encodeURI(join(parameters, "&"));
    }, [formatMessage]);

    const { cardConnectSourceUri } = configuration;

    useEffect(() => {
        function handleCardConnectEvent(e: MessageEvent) {
            if (!cardConnectSourceUri || cardConnectSourceUri.indexOf(e.origin) !== 0) {
                return;
            }

            if (!(e.data && typeof e.data === "string")) {
                logger.warning("Received a message event with invalid data", { data: e.data });
                setToken(null);
                setHasTokenError(true);
                return;
            }

            try {
                const tokenResponse: ITokenResponse = JSON.parse(e.data);

                if (!tokenResponse) {
                    setToken(null);
                    setHasTokenError(true);
                    return;
                }

                setToken(tokenResponse.token || null);
                const newHasTokenError = !!tokenResponse.errorMessage || tokenResponse.errorCode !== "0";
                setHasTokenError(newHasTokenError);

                if (newHasTokenError) {
                    logger.warning("A token error was received from Card Connect", { tokenResponse: omit(tokenResponse, "token") });
                }
            } catch (error) {
                setToken(null);
                setHasTokenError(true);
                logger.error("There was an error parsing the token response from Card Connect", { tokenResponse: e.data }, error);
            }
        }

        if (componentRef.current) {
            window.addEventListener("message", handleCardConnectEvent);
        }

        return function cleanup() {
            window.removeEventListener("message", handleCardConnectEvent);
        };
    }, [componentRef, cardConnectSourceUri, encodedUri, formatMessage, logger]);

    useEffect(() => {
        if (paymentSucceeded) {
            setToken(null);
            setExpiryDate("");
            setCvc("");
            setPostcode("");
            setHasTokenError(false);
            setExpiryDateInvalid(false);
        }
    }, [paymentSucceeded]);

    useEffect(() => {
        const isPaymentReady =
            !!(!hasTokenError && !expiryDateInvalid && token && expiryDate && expiryDateCompleteRegex.test(expiryDate) && cvc && cvc.length >= cvcMinLength);

        const paymentMethodRequest = isPaymentReady
            ? {
                cardConnectPaymentRequest: {
                    cardConnectToken: token,
                    cvc,
                    expiryDate: expiryDate.replace(/[\s/]/g, ""),
                    postcode,
                },
            }
            : null;

        if (!isEqual(paymentMethodRequest, requestState)) {
            paymentReady(paymentMethodRequest);
        }
    }, [paymentReady, requestState, cvc, expiryDate, expiryDateInvalid, postcode, token, hasTokenError]);

    return (
        <>
            <div className="form-group row ml-2 mr-2" ref={componentRef}>
                <Col md={6}>
                    <div className={styles.verticalAligner}>
                        <div className={styles.cardIcon}>
                            <FontAwesomeIcon icon={faCreditCard} />
                        </div>
                        <iframe
                            id="tokenFrame"
                            name="tokenFrame"
                            className={classNames(styles.iframe)}
                            title={formatMessage(paymentMessages.cardNumberPlaceholder)}
                            src={`${cardConnectSourceUri!}?${encodedUri}`}
                            scrolling="no"
                            frameBorder={0}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="float-md-right">
                        <input
                            maxLength={7}
                            className={classNames(styles.field, styles.expiry, "ml-auto", expiryDateInvalid && styles.invalid)}
                            name="expiryDate"
                            value={expiryDate}
                            onChange={onExpiryDateChange}
                            placeholder={formatMessage(paymentMessages.expiryDatePlaceholder)}
                            aria-label={formatMessage(messages.expiryDateLabel)}
                        />
                        <input
                            ref={cvcRef}
                            maxLength={cvcMaxLength}
                            className={classNames(styles.field, styles.cvc, "ml-auto")}
                            name="cvc"
                            value={cvc}
                            onChange={onCvcChange}
                            placeholder={formatMessage(paymentMessages.cvcPlaceholder)}
                            aria-label={formatMessage(messages.cvcLabel)}
                        />
                        <input
                            ref={postcodeRef}
                            maxLength={postcodeMaxLength}
                            className={classNames(styles.field, styles.postcode, "ml-auto")}
                            name="postcode"
                            value={postcode}
                            onChange={onPostcodeChange}
                            placeholder={formatMessage(paymentMessages.zipCodePlaceholder)}
                            aria-label={formatMessage(messages.postcodeLabel)}
                        />
                    </div>
                </Col>
                {
                    hasTokenError &&
                    <FormText color="danger" role="alert">
                        <Message message={paymentMessages.validationWarningMessage} />
                    </FormText>
                }
            </div>
            <StandardPaymentButtonLayout payButton={payButton} errorMessages={errorMessages} />
        </>
    );

    function onExpiryDateChange(e: React.ChangeEvent<HTMLInputElement>) {
        let { value } = e.target;
        let expiryDateInvalidFlag = false;

        if (expiryDate && value.length < expiryDate.length) {
            setExpiryDateInvalid(expiryDateInvalidFlag);
            setExpiryDate(value);
            return;
        }

        if (!expiryDateIncompleteRegex.test(value)) {
            return;
        }

        if (expiryDateCompleteRegex.test(value)) {
            if (value.indexOf(" ") > 0) {
                value = value.replace(/ /g, "");
                value = `${value.substr(0, 2)} / ${value.substr(3)}`;
            }

            const fullYear = Number(`20${value.substr(5, 2)}`);
            const now = moment();
            if (fullYear < now.year()) {
                expiryDateInvalidFlag = true;
            } else if (fullYear === now.year() && Number(`${value.substr(0, 2)}`) < now.month() + 1) {
                expiryDateInvalidFlag = true;
            } else if (cvcRef.current) {
                cvcRef.current.focus();
            }
        } else if (value.length === 1 && Number(value) > 1) {
            value = `0${value} / `;
        } else if (value.length === 2 && Number(value) > 12) {
            value = `0${value.substr(0, 1)} / ${value.substr(1)}`;
        } else if (value.length >= 2 && value.indexOf("/") < 0) {
            value = `${value.substr(0, 2)} / ${value.substr(2)}`;
        } else if (value.replace(/ /g, "").length === 5) {
            expiryDateInvalidFlag = true;
        }

        setExpiryDateInvalid(expiryDateInvalidFlag);
        setExpiryDate(value);
    }

    function onCvcChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        if (!value || cvcRegex.test(value)) {
            setCvc(value);
        }
        if (value.length === 4 && postcodeRef.current) {
            postcodeRef.current.focus();
        }
    }

    function onPostcodeChange(e: React.ChangeEvent<HTMLInputElement>) {
        setPostcode(e.target.value);
    }
};

export const CardConnectForm = createFragmentContainer(CardConnectFormInternal, {
    configuration: graphql`
        fragment CardConnectForm_configuration on Configuration {
            cardConnectSourceUri
        }`,
});
