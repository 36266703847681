import graphql from "babel-plugin-relay/macro";
import { PaymentRowWarningIcon } from "components/payment/PaymentRowWarningIcon";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import { paymentMessages } from "domain/payment/paymentMessages";
import { PaymentRowLicenceDetails_configuration } from "generatedQueries/PaymentRowLicenceDetails_configuration.graphql";
import { PaymentRowLicenceDetails_paymentRow } from "generatedQueries/PaymentRowLicenceDetails_paymentRow.graphql";
import React, { FC } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";

interface IGraphQlProps {
    configuration: PaymentRowLicenceDetails_configuration;
    paymentRow: PaymentRowLicenceDetails_paymentRow;
}

const InternalPaymentRowLicenceDetails: FC<IGraphQlProps> = ({
    configuration,
    paymentRow,
}) => {
    const intl = useIntl();

    if (paymentRow.isRenewal &&
        paymentRow.isLateFee) {
        return <Message message={paymentMessages.lateFeeMessage} />;
    }

    let title: MessageDescriptor | undefined;

    if (paymentRow.blocked) {
        title = paymentMessages.animalBlockedTooltip;
    } else if (!paymentRow.rabiesIsValid) {
        title = animalMessages.rabiesExpiryInvalidTooltip;
    }

    return <div title={title && intl.formatMessage(title)}>
        {paymentRow.licenceType}
        {
            paymentRow.isRenewal &&
            <>{" "}<strong><Message message={paymentMessages.renewalMessage} /></strong></>
        }
        {
            (paymentRow.blocked || !paymentRow.rabiesIsValid) &&
            <PaymentRowWarningIcon
                id={paymentRow.animalId}
                colour={paymentRow.blocked ? "danger" : "warning"}
                heading={paymentRow.blocked ? paymentMessages.animalBlockedHeading : animalMessages.rabiesExpiryInvalidHeading}
                message={paymentRow.blocked ? paymentMessages.animalBlockedError : animalMessages.rabiesExpiryInvalidError}
                values={{ OrganisationContact: configuration.organisationContact }} />}
    </div>;
};

export const PaymentRowLicenceDetails = createFragmentContainer(InternalPaymentRowLicenceDetails, {
    configuration: graphql`
        fragment PaymentRowLicenceDetails_configuration on Configuration {
            organisationContact
        }`,
    paymentRow: graphql`
        fragment PaymentRowLicenceDetails_paymentRow on LicensingPaymentFormRow {
            rabiesIsValid
            blocked
            animalId
            licenceType
            isRenewal
            isLateFee
        }`,
});
