/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GlobalPaymentsForm_configuration = {
    readonly globalPaymentsHppUrl: string;
    readonly " $refType": "GlobalPaymentsForm_configuration";
};
export type GlobalPaymentsForm_configuration$data = GlobalPaymentsForm_configuration;
export type GlobalPaymentsForm_configuration$key = {
    readonly " $data"?: GlobalPaymentsForm_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"GlobalPaymentsForm_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalPaymentsForm_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "globalPaymentsHppUrl",
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '57ebc4fdb20b9000c8d5fcbfb5206500';
export default node;
