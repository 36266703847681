import graphql from "babel-plugin-relay/macro";
import { respondToNewPasswordRequiredChallengeMutation, respondToNewPasswordRequiredChallengeMutationResponse } from "generatedQueries/respondToNewPasswordRequiredChallengeMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation respondToNewPasswordRequiredChallengeMutation($input: RespondToLicensingNewPasswordRequiredSignInChallengeInput!) {
        respondToNewPasswordRequiredLicensingSignInChallenge(input: $input) {
            wasSuccessful
            jwtToken
            error
        }
    }`;

export function respondToNewPasswordRequiredChallenge(
    environment: IEnvironment,
    email: string,
    newPassword: string,
    session: string,
    firstName: string | null,
    lastName: string | null,
    onCompleted: (response: respondToNewPasswordRequiredChallengeMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<respondToNewPasswordRequiredChallengeMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                input: {
                    email,
                    firstName,
                    lastName,
                    newPassword,
                    session,
                },
            },
        }
    );
}
