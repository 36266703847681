import graphql from "babel-plugin-relay/macro";
import { bulkUpdateLicencesMutation, bulkUpdateLicencesMutationResponse, LicenceSelectionInput } from "generatedQueries/bulkUpdateLicencesMutation.graphql";
import { Mutable } from "infrastructure/Mutable";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

export type MutableLicenceSelectionInput = Mutable<LicenceSelectionInput>;

export interface IBulkUpdateLicences {
    licenceSelections: MutableLicenceSelectionInput[];
}

const mutation = graphql`
    mutation bulkUpdateLicencesMutation($licenceSelections: [LicenceSelectionInput!]!) {
        bulkUpdateLicences(licenceSelections: $licenceSelections) {
            id
        }
    }`;

export function bulkUpdateLicences(
    environment: IEnvironment,
    bulkUpdateLicenceValues: IBulkUpdateLicences,
    onCompleted: (response: bulkUpdateLicencesMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<bulkUpdateLicencesMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                licenceSelections: bulkUpdateLicenceValues.licenceSelections.map(s => {
                    return { ...s };
                }),
            },
        }
    );
}
