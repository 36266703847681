/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HeaderLogo_configuration = {
    readonly configuration: {
        readonly logoUri: string;
    } | null;
    readonly logoCustomization: {
        readonly value: string | null;
    } | null;
    readonly " $refType": "HeaderLogo_configuration";
};
export type HeaderLogo_configuration$data = HeaderLogo_configuration;
export type HeaderLogo_configuration$key = {
    readonly " $data"?: HeaderLogo_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"HeaderLogo_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderLogo_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logoUri",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": "logoCustomization",
            "args": [
                {
                    "kind": "Literal",
                    "name": "application",
                    "value": "licensing"
                },
                {
                    "kind": "Literal",
                    "name": "name",
                    "value": "logo"
                }
            ],
            "concreteType": "SiteCustomisation",
            "kind": "LinkedField",
            "name": "siteCustomisation",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                }
            ],
            "storageKey": "siteCustomisation(application:\"licensing\",name:\"logo\")"
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any;
(node as any).hash = '4ebcc7007a60f74849178d4798c00bf2';
export default node;
