import { defineMessages } from "react-intl";

const idPrefix = "shared.";

export const sharedMessages = defineMessages({
    authenticationFailedToastMessage: {
        defaultMessage: "Authentication failed, please log in again and try again.",
        id: idPrefix + "authenticationfailedtoast-message",
    },
    backButtonLabel: {
        defaultMessage: "Back",
        id: idPrefix + "backbutton-label",
    },
    browseLabel: {
        defaultMessage: "Browse",
        id: idPrefix + "browse-label",
    },
    cancelButtonLabel: {
        defaultMessage: "Cancel",
        id: idPrefix + "cancelbutton-label",
    },
    cannotProceedLicensingMessage: {
        defaultMessage: "Sorry, you cannot proceed with Licensing your pet(s).",
        id: idPrefix + "cannotproceedlicensing-message",
    },
    completeButtonLabel: {
        defaultMessage: "Complete",
        id: idPrefix + "completebutton-label",
    },
    confirmNoLabel: {
        defaultMessage: "No",
        id: idPrefix + "confirmno-label",
    },
    confirmYesLabel: {
        defaultMessage: "Yes",
        id: idPrefix + "confirmyes-label",
    },
    defaultOrganisationContactMessage: {
        defaultMessage: "the shelter",
        id: idPrefix + "defaultorganisationcontact-message",
    },
    deleteButtonLabel: {
        defaultMessage: "Delete",
        id: idPrefix + "deletebutton-label",
    },
    errorHasBeenReportedMessage: {
        defaultMessage: "This error has been automatically logged",
        id: idPrefix + "errorhasbeenreported-message",
    },
    errorHasBeenReportedWithTrackingIdMessage: {
        defaultMessage: "This error has been automatically logged - Tracking Id: {trackingId}",
        id: idPrefix + "errorhasbeenreportedwithtrackingid-message",
    },
    loadingAccountDetailsDescr: {
        defaultMessage: "Loading your account details.  Please wait...",
        id: idPrefix + "loadingaccountdetails-descr",
    },
    loadingDescr: {
        defaultMessage: "Loading...",
        id: idPrefix + "loading-descr",
    },
    logoAltMessage: {
        defaultMessage: "Home",
        id: idPrefix + "logoalt-message",
    },
    multipleJurisdictionsMessage: {
        defaultMessage: "You may not be able to proceed with Licensing your pet(s) if your jurisdiction is not listed.",
        id: idPrefix + "multiplejurisdictions-message",
    },
    nextButtonLabel: {
        defaultMessage: "Next",
        id: idPrefix + "nextbutton-label",
    },
    nextMonthButtonLabel: {
        defaultMessage: "Next Month",
        id: idPrefix + "nextmonthbutton-label",
    },
    nextYearButtonLabel: {
        defaultMessage: "Next Year",
        id: idPrefix + "nextyearbutton-label",
    },
    notAuthorisedMessage: {
        defaultMessage: "You are not authorized to view this content",
        id: idPrefix + "notauthorised-message",
    },
    okLabel: {
        defaultMessage: "OK",
        id: idPrefix + "ok-label",
    },
    previousMonthButtonLabel: {
        defaultMessage: "Previous Month",
        id: idPrefix + "previousmonthbutton-label",
    },
    previousYearButtonLabel: {
        defaultMessage: "Previous Year",
        id: idPrefix + "previousyearbutton-label",
    },
    requestFailedBody: {
        defaultMessage: "Refresh the page and try your last action again. If the problem persists get in touch with the site owner.",
        id: idPrefix + "requestfailed-body",
    },
    requestFailedTitle: {
        defaultMessage: "Oops! Something went wrong!",
        id: idPrefix + "requestfailed-title",
    },
    saveFailedErrorMessage: {
        defaultMessage: "An error occurred whilst saving, please try again later.",
        id: idPrefix + "savefailederror-message",
    },
    saveLabel: {
        defaultMessage: "Save",
        id: idPrefix + "save-label",
    },
    saveSuccessfulMessage: {
        defaultMessage: "Saved successfully!",
        id: idPrefix + "savesuccessful-message",
    },
    searchButtonLabel: {
        defaultMessage: "Search",
        id: idPrefix + "searchbutton-label",
    },
    uploadChooseFilePlaceholder: {
        defaultMessage: "Choose file",
        id: idPrefix + "uploadchoosefile-placeholder",
    },
    uploadCompleteMessage: {
        defaultMessage: "Upload complete!",
        id: idPrefix + "uploadcomplete-message",
    },
    uploadExistingMessage: {
        defaultMessage: "File has been provided",
        id: idPrefix + "uploadexisting-message",
    },
    uploadFailedMessage: {
        defaultMessage: "Upload failed",
        id: idPrefix + "uploadfailed-message",
    },
    uploadInvalidMessage: {
        defaultMessage: "Files of that type are not allowed; the allowed types are: {allowed}",
        id: idPrefix + "uploadinvalid-message",
    },
    uploadProgressMessage: {
        defaultMessage: "Uploading... please wait",
        id: idPrefix + "uploadprogress-message",
    },
});
