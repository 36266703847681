import "infrastructure/polyfills";

import { App } from "App";
import "bootstrap";
import "index.scss";
import moment from "moment";
import "moment/locale/en-au";
import "moment/locale/en-ca";
import "moment/locale/en-gb";
import "moment/locale/en-nz";
import * as React from "react";
import * as ReactDOM from "react-dom";

// tslint:disable-next-line: no-string-literal
moment.locale(navigator["userLanguage"] || navigator.language);

ReactDOM.render(
    <App />,
    document.getElementById("root") as HTMLElement
);

if (process.env.NODE_ENV === "development") {
    // tslint:disable-next-line:no-any
    const hot = (module as any).hot;
    if (hot) {
        hot.accept("./App", () => {
            const NextApp = require("./App").App;
            ReactDOM.render(
                <NextApp />,
                document.getElementById("root") as HTMLElement
            );
        });
    }
}
