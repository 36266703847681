import { ErrorMessage } from "components/shared/ErrorMessage";
import { ChangeLicensingPasswordError } from "generatedQueries/changePasswordMutation.graphql";
import React, { FC } from "react";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";
import { messages as passwordStrengthMessages } from "shared/passwordStrengthValidator";

export const messages = mapMessages("components.user.changePassword", {
    passwordIncorrectError: "The old password entered is incorrect, please enter the correct password before proceeding.",
    unhandledErrorMessage: "An unhandled error occurred while changing your password. Please check the details entered and try again.",
});

interface IProps {
    error: boolean | ChangeLicensingPasswordError;
}

export const ChangePasswordError: FC<IProps> = ({
    error,
}) => {
    if (!error) {
        return null;
    }

    if (error === "NEWPASSWORDINVALID") {
        return <WarningMessage message={passwordStrengthMessages.passwordStrengthError} />;
    }

    if (error === "OLDPASSWORDINVALID") {
        return <WarningMessage message={messages.passwordIncorrectError} />;
    }

    return <ErrorMessage message={messages.unhandledErrorMessage} />;
};
