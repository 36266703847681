import graphql from "babel-plugin-relay/macro";
import { KeyValueOfStringAndStringInput, logEventMutation, logEventMutationResponse, LogLevel } from "generatedQueries/logEventMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation logEventMutation($logLevel: LogLevel!, $message: String, $request: String, $application: String!, $metadata: [KeyValueOfStringAndStringInput!]) {
        logEvent(logLevel: $logLevel, message: $message, request: $request, application: $application, metadata: $metadata)
    }`;

export function logEvent(
    environment: IEnvironment,
    logLevel: LogLevel,
    message: string | null,
    request: string | null,
    application: string,
    metadata: KeyValueOfStringAndStringInput[],
    onCompleted: (response: logEventMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<logEventMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                application,
                logLevel,
                message,
                metadata,
                request,
            },
        }
    );
}
