/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SetAddressErrorCodes = "STATENOTFOUND" | "VALIDATIONERROR" | "%future added value";
export type LicensingAccountAddressInput = {
    addressLine?: string | null;
    country?: string | null;
    deliveryNumber?: string | null;
    deliveryTypeId?: number | null;
    id?: string | null;
    jurisdictionId?: number | null;
    postcode?: string | null;
    state?: string | null;
    streetName?: string | null;
    streetNumber?: string | null;
    suburb?: string | null;
    unitNumber?: string | null;
};
export type setAddressesMutationVariables = {
    physicalAddress: LicensingAccountAddressInput;
    mailingAddress: LicensingAccountAddressInput;
};
export type setAddressesMutationResponse = {
    readonly updatePhysicalAddress: {
        readonly result: {
            readonly id?: string;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly errorCode: SetAddressErrorCodes;
            readonly message: string;
        }> | null;
    } | null;
    readonly updateMailingAddress: {
        readonly result: {
            readonly id?: string;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly errorCode: SetAddressErrorCodes;
            readonly message: string;
        }> | null;
    } | null;
};
export type setAddressesMutation = {
    readonly response: setAddressesMutationResponse;
    readonly variables: setAddressesMutationVariables;
};



/*
mutation setAddressesMutation(
  $physicalAddress: LicensingAccountAddressInput!
  $mailingAddress: LicensingAccountAddressInput!
) {
  updatePhysicalAddress: updateLicensingAccountAddress(address: $physicalAddress, type: PHYSICAL) {
    result {
      __typename
      ... on LicensingStreetAddress {
        id
      }
      ... on LicensingDeliveryAddress {
        id
      }
    }
    errors {
      errorCode
      message
    }
  }
  updateMailingAddress: updateLicensingAccountAddress(address: $mailingAddress, type: MAILING) {
    result {
      __typename
      ... on LicensingAddress {
        __isLicensingAddress: __typename
        id
      }
      ... on LicensingDeliveryAddress {
        id
      }
      ... on LicensingStreetAddress {
        id
      }
    }
    errors {
      errorCode
      message
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "mailingAddress"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "physicalAddress"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "address",
            "variableName": "physicalAddress"
        } as any,
        {
            "kind": "Literal",
            "name": "type",
            "value": "PHYSICAL"
        } as any
    ], v3 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        } as any
    ], v4 = {
        "kind": "InlineFragment",
        "selections": (v3 /*: any*/),
        "type": "LicensingStreetAddress",
        "abstractKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "DetailedErrorOfSetAddressErrorCodes",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v6 = [
        {
            "kind": "Variable",
            "name": "address",
            "variableName": "mailingAddress"
        } as any,
        {
            "kind": "Literal",
            "name": "type",
            "value": "MAILING"
        } as any
    ], v7 = {
        "kind": "InlineFragment",
        "selections": (v3 /*: any*/),
        "type": "LicensingAddress",
        "abstractKey": "__isLicensingAddress"
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v9 = {
        "kind": "InlineFragment",
        "selections": (v3 /*: any*/),
        "type": "LicensingDeliveryAddress",
        "abstractKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "setAddressesMutation",
            "selections": [
                {
                    "alias": "updatePhysicalAddress",
                    "args": (v2 /*: any*/),
                    "concreteType": "SetLicensingAccountAddressResponse",
                    "kind": "LinkedField",
                    "name": "updateLicensingAccountAddress",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": "updateMailingAddress",
                    "args": (v6 /*: any*/),
                    "concreteType": "SetLicensingAccountAddressResponse",
                    "kind": "LinkedField",
                    "name": "updateLicensingAccountAddress",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "setAddressesMutation",
            "selections": [
                {
                    "alias": "updatePhysicalAddress",
                    "args": (v2 /*: any*/),
                    "concreteType": "SetLicensingAccountAddressResponse",
                    "kind": "LinkedField",
                    "name": "updateLicensingAccountAddress",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                (v8 /*: any*/),
                                (v4 /*: any*/),
                                (v9 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": "updateMailingAddress",
                    "args": (v6 /*: any*/),
                    "concreteType": "SetLicensingAccountAddressResponse",
                    "kind": "LinkedField",
                    "name": "updateLicensingAccountAddress",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                (v8 /*: any*/),
                                (v7 /*: any*/),
                                (v9 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "47f411becff442524bdd468a3d4d7132",
            "id": null,
            "metadata": {},
            "name": "setAddressesMutation",
            "operationKind": "mutation",
            "text": "mutation setAddressesMutation(\n  $physicalAddress: LicensingAccountAddressInput!\n  $mailingAddress: LicensingAccountAddressInput!\n) {\n  updatePhysicalAddress: updateLicensingAccountAddress(address: $physicalAddress, type: PHYSICAL) {\n    result {\n      __typename\n      ... on LicensingStreetAddress {\n        id\n      }\n      ... on LicensingDeliveryAddress {\n        id\n      }\n    }\n    errors {\n      errorCode\n      message\n    }\n  }\n  updateMailingAddress: updateLicensingAccountAddress(address: $mailingAddress, type: MAILING) {\n    result {\n      __typename\n      ... on LicensingAddress {\n        __isLicensingAddress: __typename\n        id\n      }\n      ... on LicensingDeliveryAddress {\n        id\n      }\n      ... on LicensingStreetAddress {\n        id\n      }\n    }\n    errors {\n      errorCode\n      message\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1c2cd37cabc11566b02db99178b7d199';
export default node;
