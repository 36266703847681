/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PhoneNumber_configuration = {
    readonly licensingPhoneNumberMask: ReadonlyArray<{
        readonly value: string;
        readonly isRegex: boolean;
    }> | null;
    readonly " $refType": "PhoneNumber_configuration";
};
export type PhoneNumber_configuration$data = PhoneNumber_configuration;
export type PhoneNumber_configuration$key = {
    readonly " $data"?: PhoneNumber_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"PhoneNumber_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PhoneNumber_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "LicensingConfigMaskCharacter",
            "kind": "LinkedField",
            "name": "licensingPhoneNumberMask",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRegex",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '221d87a0b0b8cbaac46f94bd063f1e2d';
export default node;
