import { useMemo } from "react";
import { FieldPath, FieldValues, Message, RegisterOptions, ValidationRule, ValidationValue } from "react-hook-form";
import { IntlShape, MessageDescriptor, useIntl } from "react-intl";

type TranslatedValidationValueMessage<TValidationValue extends ValidationValue = ValidationValue> = {
    value: TValidationValue;
    message: MessageDescriptor;
};

type TranslatedValidationRule<TValidationValue extends ValidationValue = ValidationValue> = TranslatedValidationValueMessage<TValidationValue>;

export type TranslatedRegisterOptions<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = Partial<{
    required: MessageDescriptor | TranslatedValidationRule<boolean>;
    min: TranslatedValidationRule<number | string>;
    max: TranslatedValidationRule<number | string>;
    maxLength: TranslatedValidationRule<number | string>;
    minLength: TranslatedValidationRule<number | string>;
    pattern: TranslatedValidationRule<RegExp>;
}> & Pick<RegisterOptions<TFieldValues, TFieldName>, "valueAsNumber" | "valueAsDate" | "value" | "setValueAs" | "shouldUnregister" | "onChange" | "onBlur" | "disabled" | "deps" | "validate">;

export function useTranslatedValidations<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    options: TranslatedRegisterOptions<TFieldValues, TFieldName> | undefined
): RegisterOptions<TFieldValues, TFieldName> | undefined {
    const { formatMessage } = useIntl();

    const registerOptions = useMemo<RegisterOptions<TFieldValues, TFieldName> | undefined>(() => {
        if (!options) {
            return undefined;
        }

        const {
            required,
            min,
            max,
            maxLength,
            minLength,
            pattern,
            ...rest
        } = options;

        return {
            required: convertRequired(required, formatMessage),
            min: convertTranslatedValidationValueMessage(min, formatMessage),
            max: convertTranslatedValidationValueMessage(max, formatMessage),
            maxLength: convertTranslatedValidationValueMessage(maxLength, formatMessage),
            minLength: convertTranslatedValidationValueMessage(minLength, formatMessage),
            pattern: convertTranslatedValidationValueMessage(pattern, formatMessage),
            ...rest,
        };
    }, [formatMessage, options]);

    return registerOptions;
}

function convertRequired(
    required: MessageDescriptor | TranslatedValidationRule<boolean> | undefined,
    formatMessage: IntlShape["formatMessage"]
): Message | ValidationRule<boolean> | undefined {
    if (!required) {
        return undefined;
    }

    if (isMessageDescriptor(required)) {
        return formatMessage(required);
    }

    return convertTranslatedValidationValueMessage(required, formatMessage);
}

function convertTranslatedValidationValueMessage<TValidationValue extends ValidationValue = ValidationValue>(
    rule: TranslatedValidationRule<TValidationValue> | undefined,
    formatMessage: IntlShape["formatMessage"]
): ValidationRule<TValidationValue> | undefined {
    if (!rule) {
        return undefined;
    }

    const {
        message,
        ...rest
    } = rule;

    return {
        message: formatMessage(message),
        ...rest,
    };
}

function isMessageDescriptor<TValidationValue extends ValidationValue = ValidationValue>(rule: MessageDescriptor | TranslatedValidationRule<TValidationValue> | undefined): rule is MessageDescriptor {
    return (rule as MessageDescriptor)?.id !== undefined;
}
