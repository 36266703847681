import { createContext, useContext } from "react";
import Rollbar from "rollbar";

const RollbarContext = createContext<Rollbar>(new Rollbar(window.ShelterManagement.ROLLBAR_CONFIG ?? {}));

export const { Provider: RollbarProvider, Consumer: RollbarConsumer } = RollbarContext;

export function useRollbar(): Rollbar {
    return useContext(RollbarContext);
}
