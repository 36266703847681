import { ValidationErrorPane } from "components/shared/ValidationErrorPane";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "components/animal/AnimalIdentification.module.scss";
import { animalMessages } from "domain/animal/animalMessages";
import { LicensingAnimalIdentificationInput } from "domain/animal/upsertAnimal";
import { LicensingAnimalIdentificationType } from "generatedQueries/upsertAnimalMutation.graphql";
import { FieldProps, FormValues, useField } from "informed";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { OnChangeValue } from "react-select";
import Button from "reactstrap/lib/Button";
import Input from "reactstrap/lib/Input";
import { withDefaultValidation } from "shared/components/formInputs/withDefaultValidation";
import { ISelectOption, isSelectOption, ReactSelect } from "shared/components/ReactSelect";

interface IProps {
    index: number;
    onRemoveClicked?: () => void;
}

type Props<T extends FormValues = FormValues> = IProps & FieldProps<LicensingAnimalIdentificationInput, T>;

const AnimalIdentificationInternal = <T extends FormValues>(props: Props<T>) => {
    const { formatMessage } = useIntl();
    const { fieldState, fieldApi, render, ref } = useField({ ...props });
    const animalIdentificationTypes = useMemo<ISelectOption[]>(() => {
        return [
            {
                label: formatMessage(animalMessages.microchipLabel),
                value: "MICROCHIP",
            },
            {
                label: formatMessage(animalMessages.tattooLabel),
                value: "TATTOO",
            },
            {
                label: formatMessage(animalMessages.licenceTagNumberLabel),
                value: "LICENCE",
            },
        ];
    }, [formatMessage]);

    useEffect(() => {
        if (ref.current) {
            ref.current.setCustomValidity(fieldState.error || "");
        }
    }, [fieldState.error, ref]);

    const { onRemoveClicked, field } = props;

    return render(
        <div className="form-group">
            <div className={classNames("input-group", fieldState.touched && ["was-validated", fieldState.error ? "invalid" : "valid"])}>
                <div className="input-group-prepend form-select">
                    <ReactSelect
                        inputId={`animalIdentificationType-${props.index}`}
                        className={classNames(styles.type, "input-animal-identification-type")}
                        isSearchable={false}
                        value={(fieldState.value && fieldState.value.type) || undefined}
                        options={animalIdentificationTypes}
                        onSelected={onTypeChange}
                    />
                </div>
                <Input
                    className={classNames("form-control", styles.type, "input-animal-identification-value")}
                    type="text"
                    value={(fieldState.value && fieldState.value.value) || ""}
                    onChange={onIdentificationChange}
                    innerRef={ref} />
                <div className="input-group-append">
                    <Button
                        type="button"
                        onClick={onRemoveClicked}
                        title={formatMessage(animalMessages.removeIdentificationTooltip)}>
                        <FontAwesomeIcon icon={faTrashAlt} className="icon-button" />
                    </Button>
                </div>
                <ValidationErrorPane field={field} />
            </div>
        </div>
    );

    function onTypeChange(option: OnChangeValue<ISelectOption, false>) {
        const newType = isSelectOption(option) && isAnimalIdentificationType(option.value) ? option.value : null;

        if (!newType) {
            throw new Error("Invalid animal identification type");
        }

        fieldApi.setValue({ ...fieldState.value, type: newType });
        fieldApi.setTouched(true);
    }

    function onIdentificationChange(event: React.ChangeEvent<HTMLInputElement>) {
        fieldApi.setValue({ ...fieldState.value, value: event.target.value });
        fieldApi.setTouched(true);
    }
};

export function isAnimalIdentificationType(value: number | string): value is LicensingAnimalIdentificationType {
    return value === "MICROCHIP" || value === "TATTOO" || value === "LICENCE";
}

export const AnimalIdentification = withDefaultValidation<LicensingAnimalIdentificationInput, FormValues>()(AnimalIdentificationInternal);
