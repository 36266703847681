import { LicenceOptionCard } from "components/licence/options/LicenceOptionCard";
import { licenceOptionsMessages } from "components/licence/options/licenceOptionsMessages";
import { Message } from "shared/components/Message";
import React, { FC } from "react";
import { Col, Row } from "reactstrap";
import { routes } from "routes/routes";

export const LicenceOptions: FC = () => {
    return <React.Fragment>
        <h2><Message message={licenceOptionsMessages.licenceMenuTitle} /></h2>
        <hr />
        <Col sm={{ size: 8, offset: 2 }}>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <LicenceOptionCard
                                className={"newLicence"}
                                navDescriptionText={licenceOptionsMessages.newLicenceDescriptionLabel}
                                navButtonText={licenceOptionsMessages.newLicenceButton}
                                navigateTo={routes.licence.index.create({})} />
                        </Col>
                        <Col>
                            <LicenceOptionCard
                                className={"previousLicence"}
                                navDescriptionText={licenceOptionsMessages.previousLicenceDescriptionLabel}
                                navButtonText={licenceOptionsMessages.previousLicenceButton}
                                navigateTo={routes.pets.searchPets.create({})} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    </React.Fragment >;
};
