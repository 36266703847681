/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnimalTagSearchQueryVariables = {
    tag: string;
    shouldSearch: boolean;
};
export type AnimalTagSearchQueryResponse = {
    readonly animalTagSearch?: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"AnimalTagSearchResultRow_animal">;
    }> | null;
};
export type AnimalTagSearchQuery = {
    readonly response: AnimalTagSearchQueryResponse;
    readonly variables: AnimalTagSearchQueryVariables;
};



/*
query AnimalTagSearchQuery(
  $tag: String!
  $shouldSearch: Boolean!
) {
  animalTagSearch: licensingAnimalTagSearch(tag: $tag) @include(if: $shouldSearch) {
    ...AnimalTagSearchResultRow_animal
  }
}

fragment AnimalTagSearchResultRow_animal on LicensingTagSearchResult {
  animalId
  animalType {
    id
    description
  }
  name
  primaryBreed {
    breedId
    name
  }
  secondaryBreed {
    breedId
    name
  }
  isCrossBreed
  microchip
  genderId
  dateOfBirth
  isDesexed
  primaryColour {
    colourId
  }
  secondaryColour {
    colourId
  }
  tagNumber
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "shouldSearch"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "tag"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "tag",
            "variableName": "tag"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v4 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breedId",
            "storageKey": null
        } as any,
        (v3 /*: any*/)
    ], v5 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "colourId",
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AnimalTagSearchQuery",
            "selections": [
                {
                    "condition": "shouldSearch",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                        {
                            "alias": "animalTagSearch",
                            "args": (v2 /*: any*/),
                            "concreteType": "LicensingTagSearchResult",
                            "kind": "LinkedField",
                            "name": "licensingAnimalTagSearch",
                            "plural": true,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "AnimalTagSearchResultRow_animal"
                                }
                            ],
                            "storageKey": null
                        }
                    ]
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AnimalTagSearchQuery",
            "selections": [
                {
                    "condition": "shouldSearch",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                        {
                            "alias": "animalTagSearch",
                            "args": (v2 /*: any*/),
                            "concreteType": "LicensingTagSearchResult",
                            "kind": "LinkedField",
                            "name": "licensingAnimalTagSearch",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "animalId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingAnimalType",
                                    "kind": "LinkedField",
                                    "name": "animalType",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "description",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingBreed",
                                    "kind": "LinkedField",
                                    "name": "primaryBreed",
                                    "plural": false,
                                    "selections": (v4 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingBreed",
                                    "kind": "LinkedField",
                                    "name": "secondaryBreed",
                                    "plural": false,
                                    "selections": (v4 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isCrossBreed",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "microchip",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "genderId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dateOfBirth",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isDesexed",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingAnimalColour",
                                    "kind": "LinkedField",
                                    "name": "primaryColour",
                                    "plural": false,
                                    "selections": (v5 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LicensingAnimalColour",
                                    "kind": "LinkedField",
                                    "name": "secondaryColour",
                                    "plural": false,
                                    "selections": (v5 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "tagNumber",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "cacheID": "cd0e534c70f51eeaef6c1f75c4c10062",
            "id": null,
            "metadata": {},
            "name": "AnimalTagSearchQuery",
            "operationKind": "query",
            "text": "query AnimalTagSearchQuery(\n  $tag: String!\n  $shouldSearch: Boolean!\n) {\n  animalTagSearch: licensingAnimalTagSearch(tag: $tag) @include(if: $shouldSearch) {\n    ...AnimalTagSearchResultRow_animal\n  }\n}\n\nfragment AnimalTagSearchResultRow_animal on LicensingTagSearchResult {\n  animalId\n  animalType {\n    id\n    description\n  }\n  name\n  primaryBreed {\n    breedId\n    name\n  }\n  secondaryBreed {\n    breedId\n    name\n  }\n  isCrossBreed\n  microchip\n  genderId\n  dateOfBirth\n  isDesexed\n  primaryColour {\n    colourId\n  }\n  secondaryColour {\n    colourId\n  }\n  tagNumber\n}\n"
        }
    } as any;
})();
(node as any).hash = '220201bc43b9d9a18d34effa24423a14';
export default node;
