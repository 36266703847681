/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicensingAnimalIdentificationType = "LICENCE" | "MICROCHIP" | "TATTOO" | "%future added value";
export type LicensingMixedBreedSelection = "MIXED" | "PUREBRED" | "%future added value";
export type UpsertLicensingAnimalInput = {
    animalTypeId: string;
    breedId: number;
    breedSelection: LicensingMixedBreedSelection;
    colourId: number;
    dateOfBirth: string;
    foundSbAnimalId?: number | null;
    genderId: number;
    id?: string | null;
    identifications?: Array<LicensingAnimalIdentificationInput> | null;
    isDesexed: boolean;
    licenceTypeId?: number | null;
    name?: string | null;
    rabiesVaccinationDateAdministered?: string | null;
    rabiesVaccinationImmunityPeriodMonths?: number | null;
    rabiesVaccinationStorageId?: number | null;
    secondaryBreedId?: number | null;
    secondaryColourId?: number | null;
};
export type LicensingAnimalIdentificationInput = {
    type: LicensingAnimalIdentificationType;
    value: string;
};
export type upsertAnimalMutationVariables = {
    animal: UpsertLicensingAnimalInput;
};
export type upsertAnimalMutationResponse = {
    readonly addOrUpdateLicensingAnimal: {
        readonly id: string;
        readonly rabiesVaccination: {
            readonly id: string;
        } | null;
    } | null;
};
export type upsertAnimalMutation = {
    readonly response: upsertAnimalMutationResponse;
    readonly variables: upsertAnimalMutationVariables;
};



/*
mutation upsertAnimalMutation(
  $animal: UpsertLicensingAnimalInput!
) {
  addOrUpdateLicensingAnimal(animal: $animal) {
    id
    rabiesVaccination {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "animal"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "animal",
                    "variableName": "animal"
                }
            ],
            "concreteType": "LicensingAnimal",
            "kind": "LinkedField",
            "name": "addOrUpdateLicensingAnimal",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicensingRabiesVaccination",
                    "kind": "LinkedField",
                    "name": "rabiesVaccination",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "upsertAnimalMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "upsertAnimalMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "184680457178af16d72418368cded852",
            "id": null,
            "metadata": {},
            "name": "upsertAnimalMutation",
            "operationKind": "mutation",
            "text": "mutation upsertAnimalMutation(\n  $animal: UpsertLicensingAnimalInput!\n) {\n  addOrUpdateLicensingAnimal(animal: $animal) {\n    id\n    rabiesVaccination {\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '5ad63844c69597b00e4dfb4a6cb13f61';
export default node;
