import { validationMessages } from "components/shared/validationMessages";
import { FormError, FormValue } from "informed";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import flatten from "lodash/flatten";
import moment from "moment";
import { MessageDescriptor } from "react-intl";

export interface IValidationMessage {
    message: MessageDescriptor;
    values: { [key: string]: string | PrimitiveType | React.ReactElement | FormatXMLElementFn };
}

export type ValidationMessage = MessageDescriptor | IValidationMessage | string;

export type ValidationErrors = ValidationMessage | ValidationMessage[] | undefined;

type Value = {} | FormValue | null | undefined;

export type Validator = (value: Value, values?: {}) => FormError;
export type InformedValidator = (value: Value) => FormError;

type Predicate = (value: Value) => boolean;

function isString(value: Value): value is string {
    return value !== undefined &&
        value !== null &&
        typeof value === "string";
}

const createValidator: (message: ValidationMessage, predicate: Predicate) => Validator = (message, predicate) => (value: string) => predicate(value) ? undefined : message as FormError;

export const combine = (...validators: Validator[]): Validator => (value, values) => {
    const errors = flatten(validators
        .map(validator => validator(value, values))
        .map(error => Array.isArray(error) ? error : [error])
    ).filter(error => !!error) as ValidationErrors;

    if (Array.isArray(errors) && errors.length === 0) {
        return undefined;
    }

    return errors as FormError;
};

export const required: Validator = createValidator(
    validationMessages.requiredError,
    value => value !== undefined && value !== null && (typeof value !== "string" || !!value.trim()));

export const minLength = (length: number): Validator => createValidator({ message: validationMessages.minLengthError, values: { length } }, value => !isString(value) || value.length >= length);

export const maxLength = (length: number): Validator => createValidator({ message: validationMessages.maxLengthError, values: { length } }, value => !isString(value) || value.length <= length);

export const regex = (expression: RegExp, message: ValidationMessage = validationMessages.regexError): Validator => createValidator(message, value => !isString(value) || expression.test(value));

export const numeric: Validator = regex(/^[\d ]*$/, validationMessages.numericError);

export const decimalNumber: Validator = regex(/^\d+(\.\d*)?$/, validationMessages.numericError);

export const past: Validator = createValidator(validationMessages.pastError, value => !value || (typeof value === "string" && moment(value).isBefore(moment(), "day")));

// eslint-disable-next-line no-useless-escape
export const email: Validator = regex(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, validationMessages.emailError);

export const greaterThan = (greatherThanValue: number, message?: MessageDescriptor): Validator => createValidator({
    message: message || validationMessages.greaterThanError,
    values: { greatherThanValue },
},
    value => value !== undefined && value !== null && typeof value === "number" && value > greatherThanValue
);

export const isPhoneNumber: Validator = regex(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.0-9]*$/, validationMessages.phoneNumberError);

const minumDateOfBirthValue = moment("1900-01-01", "YYYY-MM-DD");
export const minDateOfBirth: Validator = createValidator({ message: validationMessages.minDateOfBirthError, values: { minDateOfBirth: minumDateOfBirthValue.format("YYYY-MM-DD") } }, value => {
    if (!value) {
        return false;
    }
    const convertedValue = moment(value.toString(), "YYYY-MM-DD");
    return convertedValue > minumDateOfBirthValue;
});

const maximumAge: number = 150;
export const maxDateOfBirth: Validator = createValidator({ message: validationMessages.maxDateOfBirthError, values: { maximumAge } }, value => {
    if (!value) {
        return false;
    }

    const convertedValue = moment(value.toString(), "YYYY-MM-DD");
    const calculatedAge = moment().diff(convertedValue, "years");
    return calculatedAge <= maximumAge;
});
