import { ErrorMessage } from "components/shared/ErrorMessage";
import { RespondToLicensingSignInChallengeError } from "generatedQueries/respondToNewPasswordRequiredChallengeMutation.graphql";
import React, { FC } from "react";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";
import { messages as passwordStrengthMessages } from "shared/passwordStrengthValidator";

export const messages = mapMessages("components.user.login", {
    unhandledChallengeErrorMessage: "An unhandled error occurred while setting up your account, please try again later.",
});

interface IProps {
    error: boolean | RespondToLicensingSignInChallengeError;
}

export const ChallengeError: FC<IProps> = ({
    error,
}) => <>
        {
            error === "NEWPASSWORDINVALID" &&
            <WarningMessage message={passwordStrengthMessages.passwordStrengthError} />
        }
        {
            error === true &&
            <ErrorMessage message={messages.unhandledChallengeErrorMessage} />
        }
    </>;
