import graphql from "babel-plugin-relay/macro";
import { Hideable_configuration } from "generatedQueries/Hideable_configuration.graphql";
import React, { FC } from "react";
import { createFragmentContainer } from "react-relay";

interface IProps {
    field: HideableFields;
}

interface IGraphProps {
    configuration: Hideable_configuration | null;
}

type Props = IProps & IGraphProps;

export enum HideableFields {
    canDiscloseToPublic = "CanDiscloseToPublic",
    dateOfBirth = "DateOfBirth",
    driverLicence = "DriverLicence",
    isSubscribedToShelterNewsletter = "IsSubscribedToShelterNewsletter",
    rabiesVaccination = "RabiesVaccination",
}

export function IsHiddenField(field: HideableFields, hiddenFields: string) {
    const hiddenFieldsArray = hiddenFields.split(",");
    const fieldIsHidden = hiddenFieldsArray.indexOf(field) > -1;
    return fieldIsHidden;
}

export const Hideable: FC<Props> = ({
    configuration,
    field,
    children,
}) =>
    <>
        {configuration && !IsHiddenField(field, configuration.hiddenFields) && children}
    </>;

export const HideableContainer = createFragmentContainer(Hideable, {
    configuration: graphql`
        fragment Hideable_configuration on Configuration {
            hiddenFields
        }`,
});
