import { ErrorMessage } from "components/shared/ErrorMessage";
import { IUserState } from "domain/user/combinedUserReducer";
import { UserRoles } from "domain/user/models/userRoles";
import difference from "lodash/difference";
import React, { FC, Fragment } from "react";
import { useSelector } from "react-redux";
import { Route as ReactRoute, RouteComponentProps, RouteProps } from "react-router";
import { sharedMessages } from "shared/sharedMessages";
import { RootState } from "store/store";
import { PathPart, Route, RouteParams } from "typesafe-react-router";

type TypedRoute<TParts extends string> = Route<PathPart<TParts>[]>;

type TypedRouteComponentProps<TParts extends string> = RouteComponentProps<RouteParams<TypedRoute<TParts>>>;

type ComponentType<TParts extends string> = React.ComponentType<TypedRouteComponentProps<TParts>> | React.ComponentType<{}>;

interface IRouteComponentProps<TParts extends string> {
    component: ComponentType<TParts>;
}

interface IRoles {
    roles: UserRoles[];
}

interface IProps {
    error?: React.ReactNode;
}

type Props<TParts extends string> = IRoles & IRouteComponentProps<TParts> & TypedRouteComponentProps<TParts>;

export const WhenAuthorised: FC<IProps & IRoles> = props => {
    const userState = useSelector<RootState, IUserState>(state => state.user);

    const {
        children,
        error,
        roles,
    } = props;

    if (!roles || roles.length === 0) {
        return <Fragment>{children}</Fragment>;
    }

    if (!userState.roles || difference(roles, userState.roles).length) {
        return <Fragment>{error || null}</Fragment>;
    }

    return <Fragment>{children}</Fragment>;
};

const WithAuthorisation = <TParts extends string>(props: Props<TParts>) => {
    const {
        component,
        roles,
    } = props;
    return <WhenAuthorised roles={roles} error={<ErrorMessage message={sharedMessages.notAuthorisedMessage} />}>
        {React.createElement(component)}
    </WhenAuthorised>;
};

const renderWithAuthorisation = <TParts extends string>(component: ComponentType<TParts>, roles: UserRoles[]) => (props: TypedRouteComponentProps<TParts>) => <WithAuthorisation component={component} roles={roles} {...props} />;

export const createRoute = <TParts extends string>(route: TypedRoute<TParts>, props: IRouteComponentProps<TParts> & Pick<RouteProps, Exclude<keyof RouteProps, "path" | "component" | "render">>, roles?: UserRoles[]): React.ReactElement<RouteProps> => {
    if (!roles || !roles.length) {
        return <ReactRoute path={route.template()} {...props} />;
    }

    const {
        component,
        ...otherProps
    } = props;
    return <ReactRoute path={route.template()} render={renderWithAuthorisation(component, roles)} {...otherProps} />;
};
