import { createAction } from "typesafe-actions";

export interface IPayPalPayFlowResultEventPayload {
    amt?: string;
    authCode?: string;
    result?: string;
    respMsg?: string;
    pnRef: string;
    secureToken: string;
    secureTokenId: string;
    transTime?: string;
}

export interface ISecureTokenUpdatedPayload {
    secureToken: string;
    secureTokenId: string;
}

export const payPalPayflowResultMessage = createAction("PAYPAL_PAYFLOW_RESULT", resolve => (eventData: IPayPalPayFlowResultEventPayload) => resolve(eventData));

export const payPalPayflowFormSubmitEvent = createAction("PAYPAL_PAYFLOW_FORM_SUBMIT", resolve => (payload: ISecureTokenUpdatedPayload) => resolve(payload));
