import graphql from "babel-plugin-relay/macro";
import { deleteAnimalMutation, deleteAnimalMutationResponse } from "generatedQueries/deleteAnimalMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation deleteAnimalMutation($animalId: ID!) {
        deleteLicensingAnimal(animalId: $animalId) {
            id
        }
    }`;

export function deleteAnimal(
    environment: IEnvironment,
    animalId: string,
    onCompleted: (response: deleteAnimalMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<deleteAnimalMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                animalId,
            },
        }
    );
}
