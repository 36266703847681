import { Account } from "components/account/Account";
import { createRoute } from "infrastructure/routing";
import React, { FC } from "react";
import { Switch } from "react-router";
import { EditAccount } from "routes/account/EditAccount";
import { Email } from "routes/account/Email";
import { routes } from "routes/routes";

export const RouteIndex: FC = () => (
    <Switch>
        {createRoute(routes.account.edit, { component: EditAccount })}
        {createRoute(routes.account.email, { component: Email })}
        {createRoute(routes.account.index, { component: Account })}
    </Switch>
);
