/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LicenceRenewalQueryVariables = {};
export type LicenceRenewalQueryResponse = {
    readonly account: {
        readonly animals: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                };
            }> | null;
        } | null;
        readonly physicalAddress: {
            readonly id: string;
            readonly jurisdictionId: number | null;
        } | null;
        readonly mailingAddress: {
            readonly id?: string;
        } | null;
    } | null;
};
export type LicenceRenewalQuery = {
    readonly response: LicenceRenewalQueryResponse;
    readonly variables: LicenceRenewalQueryVariables;
};



/*
query LicenceRenewalQuery {
  account {
    animals {
      edges {
        node {
          id
          name
        }
      }
    }
    physicalAddress {
      id
      jurisdictionId
    }
    mailingAddress {
      __typename
      ... on LicensingAddress {
        __isLicensingAddress: __typename
        id
      }
      ... on LicensingDeliveryAddress {
        id
      }
      ... on LicensingStreetAddress {
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "concreteType": "LicensingAnimalConnection",
        "kind": "LinkedField",
        "name": "animals",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingAnimalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "LicensingAnimal",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "concreteType": "LicensingStreetAddress",
        "kind": "LinkedField",
        "name": "physicalAddress",
        "plural": false,
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jurisdictionId",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = [
        (v0 /*: any*/)
    ], v4 = {
        "kind": "InlineFragment",
        "selections": (v3 /*: any*/),
        "type": "LicensingAddress",
        "abstractKey": "__isLicensingAddress"
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "LicenceRenewalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "mailingAddress",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "LicenceRenewalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "mailingAddress",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                (v4 /*: any*/),
                                {
                                    "kind": "InlineFragment",
                                    "selections": (v3 /*: any*/),
                                    "type": "LicensingDeliveryAddress",
                                    "abstractKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": (v3 /*: any*/),
                                    "type": "LicensingStreetAddress",
                                    "abstractKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "4d8083b1a68332255abcabb9f6e12f0c",
            "id": null,
            "metadata": {},
            "name": "LicenceRenewalQuery",
            "operationKind": "query",
            "text": "query LicenceRenewalQuery {\n  account {\n    animals {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    physicalAddress {\n      id\n      jurisdictionId\n    }\n    mailingAddress {\n      __typename\n      ... on LicensingAddress {\n        __isLicensingAddress: __typename\n        id\n      }\n      ... on LicensingDeliveryAddress {\n        id\n      }\n      ... on LicensingStreetAddress {\n        id\n      }\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '4979ad138589065e0565e8ec84494b1c';
export default node;
