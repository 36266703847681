import { defineMessages } from "react-intl";

const idPrefix = "domain.contract.";

export const contractMessages = defineMessages({
    termsAndConditionsMessage: {
        defaultMessage: "I agree to the <custom>terms & conditions</custom>",
        id: idPrefix + "termsandconditions-message",
    },
    termsAndConditionsTitle: {
        defaultMessage: "Terms & Conditions",
        id: idPrefix + "termsandconditions-message",
    },
});
