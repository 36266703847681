/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountWizardPageQueryVariables = {};
export type AccountWizardPageQueryResponse = {
    readonly account: {
        readonly " $fragmentRefs": FragmentRefs<"Header_account">;
    } | null;
};
export type AccountWizardPageQuery = {
    readonly response: AccountWizardPageQueryResponse;
    readonly variables: AccountWizardPageQueryVariables;
};



/*
query AccountWizardPageQuery {
  account {
    ...Header_account
    id
  }
}

fragment Header_account on Account {
  email
  status
  areDetailsComplete
}
*/

const node: ConcreteRequest = {
    "fragment": {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AccountWizardPageQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "Header_account"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    },
    "kind": "Request",
    "operation": {
        "argumentDefinitions": [],
        "kind": "Operation",
        "name": "AccountWizardPageQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "areDetailsComplete",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ]
    },
    "params": {
        "cacheID": "a06e54f36a40035783edd4985ed3f088",
        "id": null,
        "metadata": {},
        "name": "AccountWizardPageQuery",
        "operationKind": "query",
        "text": "query AccountWizardPageQuery {\n  account {\n    ...Header_account\n    id\n  }\n}\n\nfragment Header_account on Account {\n  email\n  status\n  areDetailsComplete\n}\n"
    }
} as any;
(node as any).hash = '059a629fdd0b046051e4667ce0ea042b';
export default node;
