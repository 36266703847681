import { defineMessages } from "react-intl";

const idPrefix = "datepicker.";

export const datePickerMessages = defineMessages({
    nextButtonLabel: {
        defaultMessage: "Next",
        id: idPrefix + "nextbutton-label",
    },
    nextMonthButtonLabel: {
        defaultMessage: "Next Month",
        id: idPrefix + "nextmonthbutton-label",
    },
    nextYearButtonLabel: {
        defaultMessage: "Next Year",
        id: idPrefix + "nextyearbutton-label",
    },
    previousButtonLabel: {
        defaultMessage: "Next",
        id: idPrefix + "nextbutton-label",
    },
    previousMonthButtonLabel: {
        defaultMessage: "Previous Month",
        id: idPrefix + "previousmonthbutton-label",
    },
    previousYearButtonLabel: {
        defaultMessage: "Previous Year",
        id: idPrefix + "previousyearbutton-label",
    },
});
