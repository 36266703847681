import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import { ISecureTokenUpdatedPayload, payPalPayflowFormSubmitEvent } from "components/payment/PayPalPayflow/messageActions";
import styles from "components/payment/PayPalPayflow/PayPalPayflowFields.module.scss";
import { PayPalPayflowFields_configuration } from "generatedQueries/PayPalPayflowFields_configuration.graphql";
import moment from "moment";
import React, { ChangeEvent, FC, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import { mapMessages } from "shared/mapMessages";
import { isActionOf } from "typesafe-actions";

const expiryDateIncompleteRegex = /^[\d /]+$/;
const expiryDateCompleteRegex = /(0[1-9]|10|11|12) ?\/ ?[0-9]{2}$/;

const messages = mapMessages("components.payment.paypalpayflowfields", {
    errorLoadingHppDetails: "There was an error loading the details to display PayPal",
    cardNumberPlaceholder: "Card number",
    cardNumberLabel: "Credit or debit card number",
    cvvLabel: "Credit or debit card CVC/CCV",
    cvvPlaceholder: "CVC",
    expiryDateLabel: "Credit or debit card expiration date",
    expiryDatePlaceholder: "MM / YY",
});

interface IGraphQlProps {
    configuration: PayPalPayflowFields_configuration;
}

const InternalPayPalPayflowFields: FC<IGraphQlProps> = ({
    configuration,
}) => {
    const intl = useIntl();
    const formRef = useRef<HTMLFormElement>(null);
    const secureTokenRef = useRef<HTMLInputElement>(null);
    const secureTokenIdRef = useRef<HTMLInputElement>(null);
    const expiryRef = useRef<HTMLInputElement>(null);
    const cvvRef = useRef<HTMLInputElement>(null);
    const [cardNumber, setCardNumber] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [isExpiryDateInvalid, setIsExpiryDateInvalid] = useState<boolean>(false);

    const onSubmitPaymentCallback = useCallback((secureToken: ISecureTokenUpdatedPayload) => {
        if (!secureToken.secureToken ||
            !secureToken.secureTokenId ||
            !configuration.licensingPayPalPayflowRedirectUri ||
            !formRef.current ||
            !secureTokenRef.current ||
            !secureTokenIdRef.current) {
            return;
        }

        secureTokenRef.current.value = secureToken.secureToken;
        secureTokenIdRef.current.value = secureToken.secureTokenId;

        formRef.current.submit();
    }, [configuration]);

    const eventCallback = useCallback((event: WindowEventMap["message"]) => {
        if (isActionOf(payPalPayflowFormSubmitEvent, event.data)) {
            onSubmitPaymentCallback(event.data.payload);
        }
    }, [onSubmitPaymentCallback]);

    useLayoutEffect(() => {
        window.addEventListener("message", eventCallback);
        return () => {
            window.removeEventListener("message", eventCallback);
        };
    }, [eventCallback]);

    if (!configuration.licensingPayPalPayflowRedirectUri) {
        return null;
    }

    return (
        <form ref={formRef} action={configuration.licensingPayPalPayflowRedirectUri} method="POST" name="PayPalPayflowFields">
            <input ref={secureTokenRef} type="hidden" name="SECURETOKEN" value="" />
            <input ref={secureTokenIdRef} type="hidden" name="SECURETOKENID" value="" />
            <input type="hidden" name="ACCT" value={cardNumber.replace(/\D/g, "")} />
            <input type="hidden" name="EXPDATE" value={expiryDate.replace(/\D/g, "")} />
            <span className={styles.cardNumber}>
                <FontAwesomeIcon icon={faCreditCard} className={styles.cardIcon} />
                <input
                    id="ccnum"
                    type="text"
                    inputMode="numeric"
                    size={20}
                    maxLength={20}
                    aria-label={intl.formatMessage(messages.cardNumberLabel)}
                    placeholder={intl.formatMessage(messages.cardNumberPlaceholder)}
                    autoComplete="cc-number"
                    autoCorrect="off"
                    spellCheck={false}
                    className={classNames(styles.field, "ml-2")}
                    value={cardNumber}
                    onChange={onCardNumberChanged} />
            </span>
            <div className={classNames(styles.cardFields, "float-right")}>
                <input
                    id="expiry"
                    type="text"
                    inputMode="numeric"
                    size={5}
                    maxLength={7}
                    aria-label={intl.formatMessage(messages.expiryDateLabel)}
                    placeholder={intl.formatMessage(messages.expiryDatePlaceholder)}
                    autoComplete="cc-exp"
                    autoCorrect="off"
                    spellCheck={false}
                    className={classNames({
                        [styles.field]: true,
                        [styles.invalid]: isExpiryDateInvalid,
                    })}
                    value={expiryDate}
                    onChange={onExpiryDateChange}
                    ref={expiryRef} />
                <input
                    id="cvv"
                    type="text"
                    name="CVV2"
                    inputMode="numeric"
                    size={3}
                    maxLength={4}
                    aria-label={intl.formatMessage(messages.cvvLabel)}
                    placeholder={intl.formatMessage(messages.cvvPlaceholder)}
                    autoComplete="cc-csc"
                    autoCorrect="off"
                    spellCheck={false}
                    className={styles.field}
                    ref={cvvRef} />
            </div>
        </form>
    );

    function onCardNumberChanged(event: ChangeEvent<HTMLInputElement>) {
        let { value } = event.target;
        setCardNumber(value);

        if (!expiryRef.current) {
            return;
        }

        value = value.replace(/\D/g, "");

        // Visa
        if (value.startsWith("4") && value.length === 16) {
            expiryRef.current.focus();
        }

        // Mastercard
        if (value.match(/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/)) {
            expiryRef.current.focus();
        }

        // Amex
        if ((value.startsWith("34") || value.startsWith("37")) && value.length === 15) {
            expiryRef.current.focus();
        }
    }

    function onExpiryDateChange(e: ChangeEvent<HTMLInputElement>) {
        let { value } = e.target;
        let expiryDateInvalidFlag = false;

        if (expiryDate && value.length > expiryDate.length && /\/|\s/.test(value.slice(-1))) {
            return;
        }

        if (expiryDate && value.length < expiryDate.length) {
            setIsExpiryDateInvalid(expiryDateInvalidFlag);
            setExpiryDate(value);
            return;
        }

        if (!expiryDateIncompleteRegex.test(value)) {
            return;
        }

        if (expiryDateCompleteRegex.test(value)) {
            if (value.indexOf(" ") > 0) {
                value = value.replace(/ /g, "");
                value = `${value.substr(0, 2)} / ${value.substr(3)}`;
            }

            const fullYear = Number(`20${value.substr(5, 2)}`);
            const now = moment();
            if (fullYear < now.year()) {
                expiryDateInvalidFlag = true;
            } else if (fullYear === now.year() && Number(`${value.substr(0, 2)}`) < now.month() + 1) {
                expiryDateInvalidFlag = true;
            } else if (cvvRef.current) {
                cvvRef.current.focus();
            }
        } else if (value.length === 1 && Number(value) > 1) {
            value = `0${value} / `;
        } else if (value.length === 2 && Number(value) > 12) {
            value = `0${value.substr(0, 1)} / ${value.substr(1)}`;
        } else if (value.length >= 2 && value.indexOf("/") < 0) {
            value = `${value.substr(0, 2)} / ${value.substr(2)}`;
        } else if (value.replace(/ /g, "").length === 5) {
            expiryDateInvalidFlag = true;
        }

        setIsExpiryDateInvalid(expiryDateInvalidFlag);
        setExpiryDate(value);
    }
};

export const PayPalPayflowFields = createFragmentContainer(InternalPayPalPayflowFields, {
    configuration: graphql`
        fragment PayPalPayflowFields_configuration on Configuration {
            licensingPayPalPayflowMode
            licensingPayPalPayflowRedirectUri
        }`,
});
