import { Message } from "shared/components/Message";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import React, { FC, ReactElement } from "react";
import { MessageDescriptor } from "react-intl";
import { Alert } from "reactstrap";

interface IProps {
    message: MessageDescriptor;
    messageValues?: { [key: string]: string | PrimitiveType | ReactElement | FormatXMLElementFn; };
}

export const InfoMessage: FC<IProps> = (props) => {
    return <Alert color="info" fade={false}>
        <Message message={props.message} values={props.messageValues} />
    </Alert>;
};
