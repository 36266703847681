import { ValidationErrorPane } from "components/shared/ValidationErrorPane";
import classNames from "classnames";
import { FieldProps, FormValues, useField } from "informed";
import React, { useEffect } from "react";
import { MessageDescriptor } from "react-intl";
import styles from "shared/components/formInputs/FormSelect.module.scss";
import { withDefaultValidation } from "shared/components/formInputs/withDefaultValidation";
import { ISelectOption, ReactSelect } from "shared/components/ReactSelect";

interface IProps {
    className?: string;
    isClearable?: boolean;
    isLoading?: boolean;
    isSearchable: boolean;
    options: ISelectOption[] | null;
    onSelected?: (value: number | string) => void;
    isDisabled?: boolean;
    "aria-required"?: boolean;
    "aria-labelledby"?: string;
    noOptionsMessage?: MessageDescriptor;
}

type Props<T extends FormValues = FormValues> = IProps & FieldProps<string | number, T>;

const FormSelectInput = <T extends FormValues>(props: Props<T>) => {
    const { fieldState, fieldApi, render, ref, userProps } = useField({ ...props });

    useEffect(() => {
        if (ref.current) {
            ref.current.setCustomValidity(fieldState.error || "");
        }
    }, [fieldState.error, ref]);

    const {
        id,
        className,
        isDisabled,
        isLoading,
        onSelected,
        placeholder,
        noOptionsMessage,
        ...rest
    } = userProps;

    return render(
        <div
            className={classNames(styles.formSelect, fieldState.touched && ["was-validated", fieldState.error ? "invalid" : "valid"], "form-select")}
            data-cy={className}
        >
            <ReactSelect
                inputId={id}
                isDisabled={isLoading || isDisabled || false}
                isLoading={isLoading || false}
                value={fieldState.value ? fieldState.value : undefined}
                onSelected={onOptionSelected}
                placeholder={placeholder}
                className={className}
                noOptionsMessage={noOptionsMessage}
                {...rest}
            />
            <input type="text" className={classNames("form-control", styles.hidden)} ref={ref} />
            <ValidationErrorPane field={props.field} />
        </div>
    );

    function onOptionSelected(option: ISelectOption) {
        if (!!option) {
            fieldApi.setValue(option.value);
        } else {
            fieldApi.reset();
        }

        fieldApi.setTouched(true);

        if (onSelected !== undefined) {
            onSelected(option.value);
        }
    }
};

export const FormSelect = withDefaultValidation<string | number, FormValues>()(FormSelectInput);
