import { defineMessages } from "react-intl";

const idPrefix = "validation.";

export const validationMessages = defineMessages({
    emailError: {
        defaultMessage: "Invalid email address",
        id: idPrefix + "email-error",
    },
    greaterThanError: {
        defaultMessage: "Must be greater than {greatherThanValue}",
        id: idPrefix + "greaterthan-error",
    },
    integerOnlyError: {
        defaultMessage: "Must be a whole number",
        id: idPrefix + "integeronly-error",
    },
    maxDateOfBirthError: {
        defaultMessage: "Age must not exceed {maximumAge} years old.",
        id: idPrefix + "maxDateOfBirth-error",
    },
    maxLengthError: {
        defaultMessage: "Maximum length is {length} characters",
        id: idPrefix + "maxlength-error",
    },
    minDateOfBirthError: {
        defaultMessage: "Date of birth is only valid from {minDateOfBirth} onwards.",
        id: idPrefix + "minDateOfBirthError-error",
    },
    minLengthError: {
        defaultMessage: "Minimum length is {length} characters",
        id: idPrefix + "minlength-error",
    },
    numericError: {
        defaultMessage: "Must be numeric",
        id: idPrefix + "numeric-error",
    },
    pastError: {
        defaultMessage: "Must be in the past",
        id: idPrefix + "past-error",
    },
    phoneNumberError: {
        defaultMessage: "Must be a valid phone number",
        id: idPrefix + "phonenumber-error",
    },
    regexError: {
        defaultMessage: "Invalid value",
        id: idPrefix + "regex-error",
    },
    requiredError: {
        defaultMessage: "Required",
        id: idPrefix + "required-error",
    },
});
