import graphql from "babel-plugin-relay/macro";
import { signInMutation, signInMutationResponse } from "generatedQueries/signInMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation signInMutation($input: LicensingSignInInput!) {
        licensingAccountSignIn(input: $input) {
            wasSuccessful
            jwtToken
            error
            challenge
            challengeSession
        }
    }`;

export function signIn(
    environment: IEnvironment,
    email: string,
    password: string,
    onCompleted: (response: signInMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<signInMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                input: {
                    email,
                    password,
                },
            },
        }
    );
}
