/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PayPalForm_paymentForm = {
    readonly rows: ReadonlyArray<{
        readonly animalName: string;
        readonly licenceType: string;
        readonly amount: number;
    }>;
    readonly " $refType": "PayPalForm_paymentForm";
};
export type PayPalForm_paymentForm$data = PayPalForm_paymentForm;
export type PayPalForm_paymentForm$key = {
    readonly " $data"?: PayPalForm_paymentForm$data;
    readonly " $fragmentRefs": FragmentRefs<"PayPalForm_paymentForm">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayPalForm_paymentForm",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "LicensingPaymentFormRow",
            "kind": "LinkedField",
            "name": "rows",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "animalName",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "licenceType",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "LicensingPaymentForm",
    "abstractKey": null
} as any;
(node as any).hash = 'f8aff0f7d119bcbe1b6636c27d92f95d';
export default node;
