import { Validator } from "components/shared/validations";
import { FieldProps, FormValue, FormValues } from "informed";
import React, { Component, ComponentClass, ComponentType } from "react";

export const withDefaultValidation = <V extends FormValue, VS extends FormValues = FormValues>(validator?: Validator, validateOnChange = true) =>
    <TOriginalProps extends FieldProps<V, VS>>(WrappedComponent: ComponentType<TOriginalProps>): ComponentClass<TOriginalProps> => {
        return class extends Component<TOriginalProps> {
            public render() {
                return <WrappedComponent
                    validateOnChange={typeof this.props.validateOnChange === "boolean" ? this.props.validateOnChange : validateOnChange}
                    validate={this.props.validate || validator}
                    {...this.props} />;
            }
        };
    };
