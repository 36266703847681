import { useEffect, useState } from "react";
import { usePaginationFragment } from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RefetchFnDynamic } from "react-relay/relay-hooks/useRefetchableFragmentNode";
import { GraphQLTaggedNode, OperationType } from "relay-runtime";

export interface IUsePagedResultProviderProps<TKey extends KeyType> {
    fragmentInput: GraphQLTaggedNode;
    parentFragmentRef: TKey;
    pageSize: number;
}

export interface IUsePagedResultProviderType<TQuery extends OperationType, TKey extends KeyType | null, TFragmentData> {
    data: TFragmentData;
    isLoading: boolean;
    refetch: RefetchFnDynamic<TQuery, TKey>;
}

export function usePagedResultProvider<TQuery extends OperationType, TKey extends KeyType>({
    fragmentInput,
    parentFragmentRef,
    pageSize,
}: IUsePagedResultProviderProps<TKey>): IUsePagedResultProviderType<TQuery, TKey, KeyTypeData<TKey>> {
    const {
        data,
        loadNext,
        hasNext,
        isLoadingNext,
        refetch,
    } = usePaginationFragment<TQuery, TKey>(fragmentInput, parentFragmentRef);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (hasNext && !isLoadingNext) {
            setIsLoading(true);
            loadNext(pageSize);
        } else if (!isLoadingNext) {
            setIsLoading(false);
        }
    }, [hasNext, isLoadingNext, loadNext, pageSize]);

    return {
        data,
        isLoading,
        refetch,
    };
}
