import { accountRoutes } from "routes/account/routes";
import { homeRoutes } from "routes/home/routes";
import { licenceRoutes } from "routes/licence/routes";
import { paymentRoutes } from "routes/payment/routes";
import { petsRoutes } from "routes/pets/routes";
import { testRoutes } from "routes/test/testRoutes";
import { userRoutes } from "routes/user/routes";

export const routes = {
    account: accountRoutes,
    home: homeRoutes,
    licence: licenceRoutes,
    payment: paymentRoutes,
    pets: petsRoutes,
    test: testRoutes,
    user: userRoutes,
};
