/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SyncStatus = "ACTIVE" | "BLOCKED" | "CANCELLED" | "DUPLICATE" | "NEW" | "PENDING" | "REJECTED" | "%future added value";
export type DefaultLayoutAccountQueryVariables = {};
export type DefaultLayoutAccountQueryResponse = {
    readonly account: {
        readonly status: SyncStatus;
        readonly " $fragmentRefs": FragmentRefs<"Header_account">;
    } | null;
};
export type DefaultLayoutAccountQuery = {
    readonly response: DefaultLayoutAccountQueryResponse;
    readonly variables: DefaultLayoutAccountQueryVariables;
};



/*
query DefaultLayoutAccountQuery {
  account {
    status
    ...Header_account
    id
  }
}

fragment Header_account on Account {
  email
  status
  areDetailsComplete
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "DefaultLayoutAccountQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Header_account"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "DefaultLayoutAccountQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "areDetailsComplete",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a8f1412a9b6f095351f29903e3ffb0ff",
            "id": null,
            "metadata": {},
            "name": "DefaultLayoutAccountQuery",
            "operationKind": "query",
            "text": "query DefaultLayoutAccountQuery {\n  account {\n    status\n    ...Header_account\n    id\n  }\n}\n\nfragment Header_account on Account {\n  email\n  status\n  areDetailsComplete\n}\n"
        }
    } as any;
})();
(node as any).hash = '23920bb6d2b13349ce5294372f175e42';
export default node;
