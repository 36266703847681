import { LoadingMessage } from "components/shared/LoadingMessage";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { paymentMessages } from "domain/payment/paymentMessages";
import React, { FC } from "react";
import ReactDOM from "react-dom";
import useScript from "react-script-hook";
import urljoin from "url-join";

interface IProps {
    clientId: string;
    currency: string;
}

type Props = IProps & PayPalButtonProps;

export const PayPalButtons: FC<Props> = ({
    clientId,
    currency,
    ...props
}) => {
    const [loading, error] = useScript({
        src: getPayPalScriptSrc(clientId, currency),
        checkForExisting: true,
        crossorigin: "anonymous",
        async: "true",
    });

    if (error) {
        return <ErrorMessage message={paymentMessages.payPalSdkLoadFailedErrorMessage} />;
    }

    if (loading) {
        return <LoadingMessage />;
    }

    const PayPalButton = window.paypal!.Buttons.driver("react", {
        React,
        ReactDOM,
    });

    return <PayPalButton {...props} />;
};

function getPayPalScriptSrc(clientId: string, currency: string): string {
    return urljoin(
        "https://www.paypal.com/sdk/js",
        `?client-id=${clientId}`,
        `&currency=${currency}`,
        "&intent=authorize");
}
