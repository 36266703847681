import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "components/layout/HeaderNavItem.module.scss";
import { Message } from "shared/components/Message";
import React, { FC, Fragment } from "react";
import { MessageDescriptor } from "react-intl";
import { NavLink } from "react-router-dom";
import ReactStrapNavLink from "reactstrap/lib/NavLink";

interface IProps {
    to: string;
    isExternal: boolean;
    icon: IconProp;
    message: MessageDescriptor | string;
    onClick: () => void;
    className?: string;
}

const Contents: FC<IProps> = props =>
    <Fragment>
        <div className={classNames("d-none d-md-block", styles.icon)}>
            <FontAwesomeIcon icon={props.icon} />
        </div>
        {
            typeof props.message === "string"
                ? props.message
                : <Message message={props.message} />
        }
    </Fragment>;

export const HeaderNavItem: FC<IProps> = props =>
    !props.isExternal
        ?
        <ReactStrapNavLink data-cy={props.className} tag={NavLink} className="nav-link" to={props.to} onClick={props.onClick}>
            <Contents {...props} />
        </ReactStrapNavLink>
        :
        <a data-cy={props.className} href={props.to} className="nav-link" target="_blank" rel="noopener noreferrer">
            <Contents {...props} />
        </a>;
