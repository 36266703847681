import React, { FC } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import Select, { OnChangeValue } from "react-select";
import { mapMessages } from "shared/mapMessages";

const messages = mapMessages("shared.components.ReactSelect", {
    noOptionsDefault: "No options",
});

export interface ISelectOption {
    label: string;
    value: string | number;
}

export function isSelectOption(value: OnChangeValue<ISelectOption, false>): value is ISelectOption {
    return typeof ((value as ISelectOption).value) !== "undefined";
}

export interface IReactSelectProps {
    className?: string;
    isClearable?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    isSearchable: boolean;
    value?: string | number;
    options: ISelectOption[] | null;
    placeholder?: string;
    inputId?: string;
    "aria-label"?: string;
    "aria-required"?: boolean;
    noOptionsMessage?: MessageDescriptor;
}

export interface IReactSelectCallbackProps {
    onChange?: (value: string | number) => void;
    onSelected: (value: OnChangeValue<ISelectOption, false>) => void;
}

type Props = IReactSelectProps & IReactSelectCallbackProps;

export const ReactSelect: FC<Props> = ({
    options,
    placeholder,
    value,
    noOptionsMessage,
    onSelected,
    onChange,
    ...rest
}) => {
    const { formatMessage } = useIntl();

    return (
        <Select
            value={getCurrentValue()}
            options={options || []}
            onInputChange={onInputChange}
            onChange={onSelectionChange}
            getOptionLabel={getOptionLabel}
            placeholder={placeholder}
            styles={{
                menu: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                }),
            }}
            noOptionsMessage={getNoOptionsMessage}
            {...rest}
        />
    );

    function onInputChange(newValue: string | number) {
        if (onChange) {
            onChange(newValue);
        }
    }

    function onSelectionChange(option: ISelectOption) {
        onSelected(option);
    }

    function getCurrentValue() {
        return (options && options.find(option => option.value === value)) || null;
    }

    function getOptionLabel(option: ISelectOption) {
        return option.label;
    }

    function getNoOptionsMessage() {
        return formatMessage(noOptionsMessage ?? messages.noOptionsDefault);
    }
};
