/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnimalPublicTagSearchResultQueryVariables = {
    tag: string;
    recaptchaResponse?: string | null;
};
export type AnimalPublicTagSearchResultQueryResponse = {
    readonly animalPublicTagSearch: {
        readonly recaptchaErrorCode: string | null;
        readonly publicTagSearchResults: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"AnimalPublicTagSearchResultRow_searchResult">;
        }> | null;
    } | null;
    readonly configuration: {
        readonly " $fragmentRefs": FragmentRefs<"AnimalPublicTagSearchResultRow_configuration">;
    } | null;
};
export type AnimalPublicTagSearchResultQuery = {
    readonly response: AnimalPublicTagSearchResultQueryResponse;
    readonly variables: AnimalPublicTagSearchResultQueryVariables;
};



/*
query AnimalPublicTagSearchResultQuery(
  $tag: String!
  $recaptchaResponse: String
) {
  animalPublicTagSearch(tag: $tag, recaptcha: $recaptchaResponse) {
    recaptchaErrorCode
    publicTagSearchResults {
      ...AnimalPublicTagSearchResultRow_searchResult
    }
  }
  configuration {
    ...AnimalPublicTagSearchResultRow_configuration
  }
}

fragment AnimalPublicTagSearchResultRow_configuration on Configuration {
  organisationContact
}

fragment AnimalPublicTagSearchResultRow_searchResult on PublicTagSearchResult {
  tagNumber
  animalName
  animalTypeDescription
  primaryBreedDescription
  secondaryBreedDescription
  ownerName
  mobilePhoneNumber
  homePhoneNumber
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "recaptchaResponse"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "tag"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "recaptcha",
            "variableName": "recaptchaResponse"
        } as any,
        {
            "kind": "Variable",
            "name": "tag",
            "variableName": "tag"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recaptchaErrorCode",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AnimalPublicTagSearchResultQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "PublicTagSearch",
                    "kind": "LinkedField",
                    "name": "animalPublicTagSearch",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PublicTagSearchResult",
                            "kind": "LinkedField",
                            "name": "publicTagSearchResults",
                            "plural": true,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "AnimalPublicTagSearchResultRow_searchResult"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AnimalPublicTagSearchResultRow_configuration"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AnimalPublicTagSearchResultQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "PublicTagSearch",
                    "kind": "LinkedField",
                    "name": "animalPublicTagSearch",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PublicTagSearchResult",
                            "kind": "LinkedField",
                            "name": "publicTagSearchResults",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "tagNumber",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "animalName",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "animalTypeDescription",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "primaryBreedDescription",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "secondaryBreedDescription",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "ownerName",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "mobilePhoneNumber",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "homePhoneNumber",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Configuration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "organisationContact",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "6a16aa50f82e8cbf3bbfa814168d4804",
            "id": null,
            "metadata": {},
            "name": "AnimalPublicTagSearchResultQuery",
            "operationKind": "query",
            "text": "query AnimalPublicTagSearchResultQuery(\n  $tag: String!\n  $recaptchaResponse: String\n) {\n  animalPublicTagSearch(tag: $tag, recaptcha: $recaptchaResponse) {\n    recaptchaErrorCode\n    publicTagSearchResults {\n      ...AnimalPublicTagSearchResultRow_searchResult\n    }\n  }\n  configuration {\n    ...AnimalPublicTagSearchResultRow_configuration\n  }\n}\n\nfragment AnimalPublicTagSearchResultRow_configuration on Configuration {\n  organisationContact\n}\n\nfragment AnimalPublicTagSearchResultRow_searchResult on PublicTagSearchResult {\n  tagNumber\n  animalName\n  animalTypeDescription\n  primaryBreedDescription\n  secondaryBreedDescription\n  ownerName\n  mobilePhoneNumber\n  homePhoneNumber\n}\n"
        }
    } as any;
})();
(node as any).hash = '6d66ae9e468a27b1beb5cebd3b19b1f8';
export default node;
