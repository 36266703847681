import { Message } from "shared/components/Message";
import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";

interface IBooleanLabel {
    value: boolean | null;
    confirmTrueMessage: MessageDescriptor;
    confirmFalseMessage: MessageDescriptor;
}

export const BooleanMessage: FC<IBooleanLabel> = ({ value, confirmTrueMessage, confirmFalseMessage }) =>
    <Message message={value ? confirmTrueMessage : confirmFalseMessage} />;
