import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import { ChangeEmail } from "components/account/ChangeEmail";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import { EmailQuery } from "generatedQueries/EmailQuery.graphql";
import React, { FC } from "react";
import Label from "reactstrap/lib/Label";
import { Query } from "shared/components/Query";
import { sharedMessages } from "shared/sharedMessages";

export const Email: FC = () => <>
    <h2><Message message={accountMessages.changeYourEmailAddressTitle} /></h2>
    <hr />
    <Query<EmailQuery>
        query={graphql`
            query EmailQuery {
                account {
                    email
                }
            }`}
        variables={{}}>
        {({ error, props }) => {
            if (error) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            if (!props) {
                return <LoadingMessage />;
            }

            const { account } = props;

            if (!account) {
                return <ErrorMessage message={sharedMessages.requestFailedBody} heading={sharedMessages.requestFailedTitle} />;
            }

            return <>
                <div className="form-group row">
                    <Label className="col-form-label col-sm-2" for="current">
                        <Message message={accountMessages.currentEmailAddressLabel} />
                    </Label>
                    <input id="current" className="form-control-plaintext col-sm-4" value={account.email} readOnly={true} />
                </div>
                <ChangeEmail />
            </>;
        }}
    </Query>
</>;
