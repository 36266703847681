/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PaymentRow_configuration = {
    readonly organisationContact: string;
    readonly " $fragmentRefs": FragmentRefs<"PaymentRowLicenceDetails_configuration">;
    readonly " $refType": "PaymentRow_configuration";
};
export type PaymentRow_configuration$data = PaymentRow_configuration;
export type PaymentRow_configuration$key = {
    readonly " $data"?: PaymentRow_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"PaymentRow_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentRow_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PaymentRowLicenceDetails_configuration"
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = '56528d20b869640bcca552c8a8a09bb4';
export default node;
