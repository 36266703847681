/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type confirmAccountMutationVariables = {
    canDiscloseToPublic: boolean;
    isSubscribedToShelterNewsletter: boolean;
};
export type confirmAccountMutationResponse = {
    readonly updateLicensingAllowedDisclosureGroup: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"Header_account">;
    } | null;
    readonly updateIsSubscribedToShelterNewsletter: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"Header_account">;
    } | null;
};
export type confirmAccountMutation = {
    readonly response: confirmAccountMutationResponse;
    readonly variables: confirmAccountMutationVariables;
};



/*
mutation confirmAccountMutation(
  $canDiscloseToPublic: Boolean!
  $isSubscribedToShelterNewsletter: Boolean!
) {
  updateLicensingAllowedDisclosureGroup(canDiscloseToPublic: $canDiscloseToPublic) {
    id
    ...Header_account
  }
  updateIsSubscribedToShelterNewsletter(isSubscribedToShelterNewsletter: $isSubscribedToShelterNewsletter) {
    id
    ...Header_account
  }
}

fragment Header_account on Account {
  email
  status
  areDetailsComplete
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "canDiscloseToPublic"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "isSubscribedToShelterNewsletter"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "canDiscloseToPublic",
            "variableName": "canDiscloseToPublic"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = [
        (v2 /*: any*/),
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Header_account"
        } as any
    ], v4 = [
        {
            "kind": "Variable",
            "name": "isSubscribedToShelterNewsletter",
            "variableName": "isSubscribedToShelterNewsletter"
        } as any
    ], v5 = [
        (v2 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "areDetailsComplete",
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "confirmAccountMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "updateLicensingAllowedDisclosureGroup",
                    "plural": false,
                    "selections": (v3 /*: any*/),
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "updateIsSubscribedToShelterNewsletter",
                    "plural": false,
                    "selections": (v3 /*: any*/),
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "confirmAccountMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "updateLicensingAllowedDisclosureGroup",
                    "plural": false,
                    "selections": (v5 /*: any*/),
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "updateIsSubscribedToShelterNewsletter",
                    "plural": false,
                    "selections": (v5 /*: any*/),
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "77bbb10e1edee3c84fce3fecdc3046fc",
            "id": null,
            "metadata": {},
            "name": "confirmAccountMutation",
            "operationKind": "mutation",
            "text": "mutation confirmAccountMutation(\n  $canDiscloseToPublic: Boolean!\n  $isSubscribedToShelterNewsletter: Boolean!\n) {\n  updateLicensingAllowedDisclosureGroup(canDiscloseToPublic: $canDiscloseToPublic) {\n    id\n    ...Header_account\n  }\n  updateIsSubscribedToShelterNewsletter(isSubscribedToShelterNewsletter: $isSubscribedToShelterNewsletter) {\n    id\n    ...Header_account\n  }\n}\n\nfragment Header_account on Account {\n  email\n  status\n  areDetailsComplete\n}\n"
        }
    } as any;
})();
(node as any).hash = '603b5b17c8bb930c5189d6f60a409f61';
export default node;
