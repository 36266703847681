/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SyncStatus = "ACTIVE" | "BLOCKED" | "CANCELLED" | "DUPLICATE" | "NEW" | "PENDING" | "REJECTED" | "%future added value";
export type AnimalRow_animal = {
    readonly id: string;
    readonly name: string;
    readonly breed: {
        readonly name: string;
    };
    readonly secondaryBreed: {
        readonly name: string;
    } | null;
    readonly status: SyncStatus;
    readonly licenceType: {
        readonly description: string | null;
    } | null;
    readonly currentLicence: {
        readonly id: string;
        readonly description: string | null;
        readonly isLifetime: boolean;
        readonly expiryDate: string | null;
    } | null;
    readonly animalType: {
        readonly id: string;
        readonly description: string | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"AnimalStatus_animal">;
    readonly " $refType": "AnimalRow_animal";
};
export type AnimalRow_animal$data = AnimalRow_animal;
export type AnimalRow_animal$key = {
    readonly " $data"?: AnimalRow_animal$data;
    readonly " $fragmentRefs": FragmentRefs<"AnimalRow_animal">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v2 = [
        (v1 /*: any*/)
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AnimalRow_animal",
        "selections": [
            (v0 /*: any*/),
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingBreed",
                "kind": "LinkedField",
                "name": "breed",
                "plural": false,
                "selections": (v2 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingBreed",
                "kind": "LinkedField",
                "name": "secondaryBreed",
                "plural": false,
                "selections": (v2 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "LicenceType",
                "kind": "LinkedField",
                "name": "licenceType",
                "plural": false,
                "selections": [
                    (v3 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Licence",
                "kind": "LinkedField",
                "name": "currentLicence",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v3 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isLifetime",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expiryDate",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "LicensingAnimalType",
                "kind": "LinkedField",
                "name": "animalType",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v3 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AnimalStatus_animal"
            }
        ],
        "type": "LicensingAnimal",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'd1329813566123ffc1cd36740bc8e625';
export default node;
