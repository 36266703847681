import { ErrorMessage } from "components/shared/ErrorMessage";
import { LicensingSignInError } from "generatedQueries/signInMutation.graphql";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { routes } from "routes/routes";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";

export const messages = mapMessages("components.user.login", {
    passwordNotVerifiedMessage: "Your password has not been verified, <a>verify your password now</a>.",
    passwordResetRequiredMessage: "Your password needs to be reset, which you can do by using the <a>Forgot Password</a> page.",
    signInFailedMessage: "Invalid username or password",
    unhandledErrorMessage: "An unhandled error occurred while logging in, please try again later.",
});

interface IProps {
    error: boolean | LicensingSignInError;
}

export const LoginError: FC<IProps> = ({
    error,
}) => <>
        {
            error === "INVALIDUSERNAMEORPASSWORD" &&
            <ErrorMessage message={messages.signInFailedMessage} />
        }
        {
            error === "PASSWORDNOTCONFIRMED" &&
            <WarningMessage
                message={messages.passwordNotVerifiedMessage}
                messageValues={{
                    a: msg => <Link to={routes.user.confirm.create({})}>{msg}</Link>,
                }} />
        }
        {
            error === "PASSWORDRESETREQUIRED" &&
            <WarningMessage
                message={messages.passwordResetRequiredMessage}
                messageValues={{
                    a: msg => <Link to={routes.user.forgotPassword.create({})}>{msg}</Link>,
                }} />
        }
        {
            error === true &&
            <ErrorMessage message={messages.unhandledErrorMessage} />
        }
    </>;
