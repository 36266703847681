/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SignUpQueryVariables = {};
export type SignUpQueryResponse = {
    readonly configuration: {
        readonly collectNameOnSignUp: boolean;
        readonly passwordValidationRegex: string;
        readonly licensingRecaptchaSiteKey: string | null;
    } | null;
};
export type SignUpQuery = {
    readonly response: SignUpQueryResponse;
    readonly variables: SignUpQueryVariables;
};



/*
query SignUpQuery {
  configuration {
    collectNameOnSignUp
    passwordValidationRegex
    licensingRecaptchaSiteKey
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Configuration",
            "kind": "LinkedField",
            "name": "configuration",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "collectNameOnSignUp",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "passwordValidationRegex",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "licensingRecaptchaSiteKey",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SignUpQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SignUpQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "b6744fa0fb52b1e572a389fea7fc1f46",
            "id": null,
            "metadata": {},
            "name": "SignUpQuery",
            "operationKind": "query",
            "text": "query SignUpQuery {\n  configuration {\n    collectNameOnSignUp\n    passwordValidationRegex\n    licensingRecaptchaSiteKey\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '84a47bf18ca0849d7a499bd4558ec438';
export default node;
