/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnimalRow_configuration = {
    readonly organisationContact: string;
    readonly " $fragmentRefs": FragmentRefs<"AnimalStatus_configuration">;
    readonly " $refType": "AnimalRow_configuration";
};
export type AnimalRow_configuration$data = AnimalRow_configuration;
export type AnimalRow_configuration$key = {
    readonly " $data"?: AnimalRow_configuration$data;
    readonly " $fragmentRefs": FragmentRefs<"AnimalRow_configuration">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnimalRow_configuration",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationContact",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnimalStatus_configuration"
        }
    ],
    "type": "Configuration",
    "abstractKey": null
} as any;
(node as any).hash = 'b62bdca8cbc5293c6f8ca993aae8663a';
export default node;
