import graphql from "babel-plugin-relay/macro";
import { NewPasswordRequiredChallengeFields_configuration } from "generatedQueries/NewPasswordRequiredChallengeFields_configuration.graphql";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { createFragmentContainer } from "react-relay";
import { PasswordEntryFields } from "routes/user/PasswordEntryFields";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { TextBox } from "shared/components/newFormInputs/TextBox";
import { mapMessages } from "shared/mapMessages";
import { required } from "shared/validations/validations";

export const messages = mapMessages("components.user.login", {
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
});

interface IGraphQlProps {
    configuration: NewPasswordRequiredChallengeFields_configuration | null;
}

interface IProps {
    isDisplayed: boolean;
}

type Props = IGraphQlProps & IProps;

const InternalNewPasswordRequiredChallengeFields: FC<Props> = ({
    configuration,
    isDisplayed,
}) => {
    const { register, control } = useFormContext();

    return <>
        {
            isDisplayed &&
            <>
                <PasswordEntryFields
                    passwordField="newPassword"
                    passwordValidationRegex={configuration?.passwordValidationRegex}
                />
                {
                    configuration?.collectNameOnSignUp &&
                    <>
                        <FormLabel
                            message={messages.firstNameLabel}
                            for="firstName"
                            required={true}
                        >
                            <TextBox
                                id="firstName"
                                name="firstName"
                                register={register}
                                control={control}
                                options={required()}
                                aria-required={true}
                            />
                        </FormLabel>
                        <FormLabel
                            message={messages.lastNameLabel}
                            for="lastName"
                            required={true}
                        >
                            <TextBox
                                id="lastName"
                                name="lastName"
                                register={register}
                                control={control}
                                validate={required()}
                                aria-required={true}
                            />
                        </FormLabel>
                    </>
                }
            </>
        }
    </>;
};

export const NewPasswordRequiredChallengeFields = createFragmentContainer(InternalNewPasswordRequiredChallengeFields, {
    configuration: graphql`
        fragment NewPasswordRequiredChallengeFields_configuration on Configuration {
            passwordValidationRegex
            collectNameOnSignUp
        }`,
});
