import graphql from "babel-plugin-relay/macro";
import { Message } from "shared/components/Message";
import { animalActions } from "domain/animal/animalActions";
import { animalMessages } from "domain/animal/animalMessages";
import { redirectActions } from "domain/redirect/redirectActions";
import { AnimalTagSearchResultRow_animal } from "generatedQueries/AnimalTagSearchResultRow_animal.graphql";
import React, { FC, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { createFragmentContainer } from "react-relay";
import { Button } from "reactstrap";
import { routes } from "routes/routes";

interface IGraphQlProps {
    animal: AnimalTagSearchResultRow_animal;
}

const InternalAnimalTagSearchResultRow: FC<IGraphQlProps> = ({
    animal,
}) => {
    const dispatch = useDispatch();

    return (
        <tr>
            <td>
                {animal.name}
            </td>
            <td>
                {
                    (animal.animalType && animal.animalType.description) ||
                    <Message message={animalMessages.animalTypeNotFoundMessage} />
                }
            </td>
            <td>
                {getBreedDisplay()}
            </td>
            <td className="text-right">
                <Button
                    color="primary"
                    onClick={onAddClick}
                    size="sm">
                    <Message message={animalMessages.addThisPetButtonLabel} />
                </Button>
            </td>
        </tr>
    );

    function getBreedDisplay(): string | ReactNode {
        const primaryBreed = animal.primaryBreed && animal.primaryBreed.name;
        const secondaryBreed = animal.secondaryBreed && animal.secondaryBreed.name;

        if (primaryBreed && secondaryBreed) {
            return `${primaryBreed}, ${secondaryBreed}`;
        }

        if (primaryBreed) {
            return primaryBreed;
        }

        if (secondaryBreed) {
            return secondaryBreed;
        }

        return <Message message={animalMessages.unknownBreedMessage} />;
    }

    function onAddClick() {
        dispatch(animalActions.animalFound(animal));
        dispatch(redirectActions.redirectTo(routes.licence.index.create({})));
    }
};

export const AnimalTagSearchResultRow = createFragmentContainer(InternalAnimalTagSearchResultRow, {
    animal: graphql`
        fragment AnimalTagSearchResultRow_animal on LicensingTagSearchResult {
            animalId
            animalType {
                id
                description
            }
            name
            primaryBreed {
                breedId
                name
            }
            secondaryBreed {
                breedId
                name
            }
            isCrossBreed
            microchip
            genderId
            dateOfBirth
            isDesexed
            primaryColour {
                colourId
            }
            secondaryColour {
                colourId
            }
            tagNumber
        }
    `,
});
