import { LoadingMessage } from "components/shared/LoadingMessage";
import { ErrorMessage } from "components/shared/ErrorMessage";
import React, { FC } from "react";
import useScript from "react-script-hook";
import { mapMessages } from "shared/mapMessages";

const messages = mapMessages("components.payment.StripeLoader", {
    scriptLoadFailed: "Failed to load the payment provider scripts",
});

export const StripeLoader: FC = ({
    children,
}) => {
    const [loading, error] = useScript({
        src: "https://js.stripe.com/v3/",
        checkForExisting: true,
        crossorigin: "anonymous",
        async: "true",
    });

    if (error) {
        return <ErrorMessage message={messages.scriptLoadFailed} />;
    }

    if (loading) {
        return <LoadingMessage />;
    }

    return <>{children}</>;
};
