import classNames from "classnames";
import { Message } from "shared/components/Message";
import Steps, { Step } from "rc-steps";
import React, { FC, useState } from "react";
import { IntlShape, MessageDescriptor, useIntl } from "react-intl";
import styles from "shared/components/Wizard.module.scss";
import { IWizardPageProps } from "shared/components/WizardPage";
import { mapMessages } from "shared/mapMessages";

const messages = mapMessages("shared.components.Wizard", {
    screenReaderStep: "{formName}, step {step} of {steps}",
});

interface IWizardContext {
    onNext: () => void;
    onPrevious: () => void;
}

const emptyFunction = () => { /*This function intentionally left blank*/ };
export const WizardContext = React.createContext<IWizardContext>({ onNext: emptyFunction, onPrevious: emptyFunction });

interface IProps {
    children: React.ReactNode;
    initialStep?: number;
    formName: string;
    onComplete?: () => void;
}

export const Wizard: FC<IProps> = ({
    children,
    initialStep,
    formName,
    onComplete,
}) => {
    const intl = useIntl();
    const [currentStep, setCurrentStep] = useState(initialStep ?? 0);

    const filteredChildren = React.Children.map(children, (child) => React.isValidElement<IWizardPageProps>(child) ? child : null)
        ?.filter(child => !!child && child.props && child.props.title) as React.ReactElement<IWizardPageProps>[];

    const steps = filteredChildren.map((child, i) => {
        return <Step
            key={i}
            title={getText(intl, child.props.title)}
            description={getText(intl, child.props.description)}
            icon={getIcon(child.props, i, currentStep)}
            aria-current={currentStep === i ? "step" : undefined}
        />;
    });

    return <WizardContext.Provider value={{ onNext, onPrevious }}>
        <div className="row justify-content-center">
            <span
                className="sr-only"
                aria-live="polite"
            >
                <Message
                    message={messages.screenReaderStep}
                    values={{
                        formName,
                        step: currentStep + 1,
                        steps: filteredChildren.length,
                    }}
                />
            </span>
            <div className={classNames(getStepperClass(steps.length), styles.paddedStepContainer)}>
                <Steps current={currentStep}>
                    {steps}
                </Steps>
            </div>
        </div>

        {filteredChildren[currentStep]}
    </WizardContext.Provider>;

    function onNext(skip?: number) {
        let step = currentStep + 1 + ((typeof skip === "number" && skip) || 0);

        if (step >= filteredChildren.length && onComplete) {
            onComplete();
        } else {
            if (step >= filteredChildren.length) {
                step = filteredChildren.length - 1;
            }

            setCurrentStep(step);
        }
    }

    function onPrevious(skip?: number) {
        let step = currentStep - 1 - ((typeof skip === "number" && skip) || 0);

        if (step < 0) {
            step = 0;
        }

        setCurrentStep(step);
    }

    function getStepperClass(numberOfSteps: number) {
        if (numberOfSteps === 1) {
            return "col-xl-5 col-lg-7 col-md-8 col";
        }

        if (numberOfSteps === 2) {
            return "col-xl-8 col-lg-10 col";
        }

        if (numberOfSteps === 3) {
            return "col-xl-10 col";
        }

        return "col-sm-12";
    }
};

function getIcon(step: IWizardPageProps, thisStep: number, currentStep: number) {
    if (currentStep <= thisStep) {
        return step.icon;
    }

    return step.completeIcon || step.icon;
}

function getText(intl: IntlShape, text: string | MessageDescriptor | undefined) {
    if (!text) {
        return undefined;
    }

    if (typeof text === "string") {
        return text;
    }

    return intl.formatMessage(text);
}
