/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LicensingPhoneNumberType = "HOME" | "MOBILE" | "WORK" | "%future added value";
export type AccountReadonlyContactDetails_account = {
    readonly driverLicence: string | null;
    readonly dateOfBirth: string | null;
    readonly phoneNumbers: ReadonlyArray<{
        readonly id: string;
        readonly type: LicensingPhoneNumberType;
        readonly number: string;
    }>;
    readonly " $refType": "AccountReadonlyContactDetails_account";
};
export type AccountReadonlyContactDetails_account$data = AccountReadonlyContactDetails_account;
export type AccountReadonlyContactDetails_account$key = {
    readonly " $data"?: AccountReadonlyContactDetails_account$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountReadonlyContactDetails_account">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountReadonlyContactDetails_account",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "driverLicence",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateOfBirth",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "LicensingAccountPhoneNumber",
            "kind": "LinkedField",
            "name": "phoneNumbers",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Account",
    "abstractKey": null
} as any;
(node as any).hash = '92dc6962bd45ec8200d2823d7fd2e0db';
export default node;
