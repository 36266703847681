
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "components/payment/TermsAndConditions.module.scss";
import { Message } from "shared/components/Message";
import { contractMessages } from "domain/contract/contractMessages";
import * as DOMPurify from "dompurify";
import React, { ChangeEvent, FC, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { InfoModal } from "shared/components/InfoModal";

interface IEventProps {
    onTermsAndConditionsAcceptanceChanged: (accepted: boolean) => void;
}

interface IProps {
    contractText: string | null;
}

type Props = IProps & IEventProps;

export const TermsAndConditions: FC<Props> = ({
    onTermsAndConditionsAcceptanceChanged,
    contractText,
}) => {
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

    if (!contractText) {
        return null;
    }

    return <>
        <Card>
            <CardHeader>
                <h6><FontAwesomeIcon icon={faFileContract} className="mr-1" /><Message message={contractMessages.termsAndConditionsTitle} /></h6>
            </CardHeader>
            <CardBody>
                <div className="form-group text-center">
                    <div className="form-group form-check">
                        <label className="form-check-label">
                            <input
                                type="checkbox"
                                className={classNames("form-check-input", styles.termsAndConditionsCheckbox, "pr-1")}
                                onChange={onToggleTermsAndConditionsChanged} />
                            <span className={classNames(styles.termsAndConditionsText, "pl-1")}>
                                <Message
                                    message={contractMessages.termsAndConditionsMessage}
                                    values={{
                                        custom: str => (
                                            <button
                                                type="button"
                                                onClick={onToggleTermsAndConditions}
                                                className={styles.linkButton}>
                                                {str}
                                            </button>
                                        ),
                                    }} />
                            </span>
                        </label>
                    </div>
                </div>
            </CardBody>
        </Card>
        <InfoModal show={showTermsAndConditions}
            modalTitle={contractMessages.termsAndConditionsTitle}
            onToggleModal={onToggleTermsAndConditions} >
            <div dangerouslySetInnerHTML={createContractMarkup(contractText)} />
        </InfoModal>
    </>;

    function onToggleTermsAndConditionsChanged(event: ChangeEvent<HTMLInputElement>) {
        onTermsAndConditionsAcceptanceChanged(event.target.checked);
    }

    function onToggleTermsAndConditions() {
        setShowTermsAndConditions(!showTermsAndConditions);
    }
};

function createContractMarkup(contractText: string) {
    return { __html: DOMPurify.sanitize(contractText) };
}
