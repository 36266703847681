/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type verifyEmailMutationVariables = {
    code: string;
};
export type verifyEmailMutationResponse = {
    readonly verifyLicensingAccountEmail: string | null;
};
export type verifyEmailMutation = {
    readonly response: verifyEmailMutationResponse;
    readonly variables: verifyEmailMutationVariables;
};



/*
mutation verifyEmailMutation(
  $code: String!
) {
  verifyLicensingAccountEmail(code: $code)
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "code"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "code",
                    "variableName": "code"
                }
            ],
            "kind": "ScalarField",
            "name": "verifyLicensingAccountEmail",
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "verifyEmailMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "verifyEmailMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "1d01776b84d072de89e8b7d165170065",
            "id": null,
            "metadata": {},
            "name": "verifyEmailMutation",
            "operationKind": "mutation",
            "text": "mutation verifyEmailMutation(\n  $code: String!\n) {\n  verifyLicensingAccountEmail(code: $code)\n}\n"
        }
    } as any;
})();
(node as any).hash = '330fadd4efc4c7e4a4d230485cdc0c0e';
export default node;
