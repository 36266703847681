/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SyncStatus = "ACTIVE" | "BLOCKED" | "CANCELLED" | "DUPLICATE" | "NEW" | "PENDING" | "REJECTED" | "%future added value";
export type Header_account = {
    readonly email: string;
    readonly status: SyncStatus;
    readonly areDetailsComplete: boolean;
    readonly " $refType": "Header_account";
};
export type Header_account$data = Header_account;
export type Header_account$key = {
    readonly " $data"?: Header_account$data;
    readonly " $fragmentRefs": FragmentRefs<"Header_account">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Header_account",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "areDetailsComplete",
            "storageKey": null
        }
    ],
    "type": "Account",
    "abstractKey": null
} as any;
(node as any).hash = '12731005ea081682cabf64adb8cbdd3f';
export default node;
