import { LoadingMessage } from "components/shared/LoadingMessage";
import graphql from "babel-plugin-relay/macro";
import classNames from "classnames";
import styles from "components/payment/globalPayments/GlobalPaymentsForm.module.scss";
import { IPaymentFormChildProps } from "components/payment/PaymentForm";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { paymentMessages } from "domain/payment/paymentMessages";
import { GlobalPaymentsForm_configuration } from "generatedQueries/GlobalPaymentsForm_configuration.graphql";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { createFragmentContainer } from "react-relay";
import useScript from "react-script-hook";
import { mapMessages } from "shared/mapMessages";

export const messages = mapMessages("components.payment.globalPayments.globalPaymentsForm", {
    globalPaymentsSdkFailedToLoadError: "Failed to load Global Payments, please try again later.",
});

const iframeId = "globalPaymentsIframe";
interface ITransactionResponse {
    AMOUNT: string;
    MESSAGE: string;
    ORDER_ID: string;
    PASREF: string;
    RESULT: string;
    TIMESTAMP: string;
}

interface IIframeDimensions {
    height: string;
    width: string;
}

interface IGraphQlProps {
    configuration: GlobalPaymentsForm_configuration;
}

interface IProps {
    authoriseRequestJson: string;
    onPaymentSubmit?(): void;
}

type Props = Pick<IPaymentFormChildProps, "amountToCharge" | "submitPayment"> & IGraphQlProps & IProps;

const GlobalPaymentsFormInternal: FC<Props> = ({
    amountToCharge,
    authoriseRequestJson,
    configuration,
    submitPayment,
    onPaymentSubmit,
}) => {
    const [isScriptLoading, scriptError] = useScript({
        src: process.env.PUBLIC_URL + "/rxp-js.min.js",
        checkForExisting: true,
    });

    const [isIframeLoading, setIsIframeLoading] = useState(true);
    const [iframeHeight, setIframeHeight] = useState<string>();
    const [iframeWidth, setIframeWidth] = useState<string>();
    const [key, setKey] = useState(0);

    const intl = useIntl();

    const onPost = useCallback((response: ITransactionResponse) => {
        setKey(prevKey => prevKey + 1);

        submitPayment({
            globalPaymentsPaymentRequest: {
                amount: amountToCharge,
                responseCode: response.RESULT,
                responseMessage: response.MESSAGE,
                timestamp: response.TIMESTAMP,
                transactionId: response.PASREF,
                orderId: response.ORDER_ID,
            },
        });

        if (onPaymentSubmit) {
            onPaymentSubmit();
        }
    }, [submitPayment, onPaymentSubmit, amountToCharge]);

    const onResize = useCallback((dimensions: IIframeDimensions) => {
        if (dimensions.height !== "0px") {
            setIframeHeight(dimensions.height);
        }

        if (dimensions.width !== "0px") {
            setIframeWidth(dimensions.width);
        }
    }, []);

    useEffect(() => {
        if (isScriptLoading || scriptError || !window.RealexHpp) {
            return;
        }

        window.RealexHpp.setHppUrl(configuration.globalPaymentsHppUrl);
        window.RealexHpp.embedded.init(
            "autoload",
            iframeId,
            onPost,
            JSON.parse(authoriseRequestJson),
            {
                onResize,
            }
        );
    }, [isScriptLoading, scriptError, authoriseRequestJson, configuration.globalPaymentsHppUrl, onPost, onResize]);

    return <Fragment>
        {scriptError && <ErrorMessage message={messages.globalPaymentsSdkFailedToLoadError} />}
        {(isScriptLoading || isIframeLoading) && <LoadingMessage />}
        <iframe
            key={key}
            id={iframeId}
            title={intl.formatMessage(paymentMessages.payHeaderLabel)}
            className={classNames(
                {
                    [styles.iframe]: true,
                    "d-none": isScriptLoading || isIframeLoading,
                })}
            style={{ width: iframeWidth, height: iframeHeight }}
            onLoad={onIframeLoad} />
    </Fragment>;

    function onIframeLoad() {
        setIsIframeLoading(false);
    }
};

export const GlobalPaymentsForm = createFragmentContainer(GlobalPaymentsFormInternal, {
    configuration: graphql`
        fragment GlobalPaymentsForm_configuration on Configuration {
            globalPaymentsHppUrl
        }`,
});
