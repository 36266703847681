import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { DatePickerArrowButton } from "./DatePickerArrowButton";

interface IProps {
    disabled: boolean;
    direction: "next" | "previous";
    yearLabel: MessageDescriptor;
    monthLabel: MessageDescriptor;
    onYearClicked: () => void;
    onMonthClicked: () => void;
}

export const DatePickerHeaderButtons: FC<IProps> = ({
    disabled,
    direction,
    yearLabel,
    monthLabel,
    onYearClicked: increaseYear,
    onMonthClicked: increaseMonth,
}) => (
    <>
        <DatePickerArrowButton
            disabled={disabled}
            onClick={increaseYear}
            direction={direction}
            isDouble={true}
            label={yearLabel} />
        <DatePickerArrowButton
            disabled={disabled}
            onClick={increaseMonth}
            direction={direction}
            isDouble={false}
            label={monthLabel} />
    </>
);
