import { SpinnerButton } from "components/shared/SpinnerButton";
import { combine, email as emailValidator, regex, required } from "components/shared/validations";
import { ConfirmSignUpError } from "components/user/ConfirmSignUpError";
import { toastActions } from "domain/toast/toastActions";
import { confirmSignUp } from "domain/user/confirmSignUp";
import { ConfirmLicensingAccountSignUpError, confirmSignUpMutationResponse } from "generatedQueries/confirmSignUpMutation.graphql";
import { Form } from "informed";
import { parse } from "query-string";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRelayEnvironment } from "react-relay";
import { Redirect, useLocation } from "react-router";
import { Col, Row } from "reactstrap";
import { PayloadError } from "relay-runtime";
import { routes } from "routes/routes";
import { FormLabel } from "shared/components/formInputs/FormLabel";
import { TextBox } from "shared/components/formInputs/TextBox";
import { mapMessages } from "shared/mapMessages";

export const messages = mapMessages("components.user.signup", {
    codeValidationError: "Code is not valid",
    confirmButton: "Confirm",
    confirmationCodeLabel: "Code",
    emailAddressLabel: "Email",
    signInConfirmationSucceededToast: "Sign In confirmed",
});

interface IQueryString {
    email?: string;
    code?: string;
}

export const ConfirmSignUpForm: FC = () => {
    const location = useLocation();
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();
    const [failure, setFailure] = useState<boolean | ConfirmLicensingAccountSignUpError>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState("");

    const onConfirmCompleted = useCallback(
        (response: confirmSignUpMutationResponse, errors?: PayloadError[] | null) => {
            setIsSubmitting(false);
            if (errors?.length) {
                setFailure(true);
            } else if (response.confirmLicensingSignUp?.wasSuccessful) {
                setIsComplete(true);
                dispatch(toastActions.customSuccessToast(messages.signInConfirmationSucceededToast));
            } else {
                setFailure(response.confirmLicensingSignUp?.error ?? true);
            }
        },
        [dispatch]);

    const submitSignUp = useCallback(
        ({ code, email }: { email: string, code: string }) => {
            setIsSubmitting(true);
            setSubmittedEmail(email);
            confirmSignUp(
                environment,
                email,
                code,
                onConfirmCompleted,
                onConfirmError);
        },
        [environment, onConfirmCompleted]);

    const queryString = parse(location.search) as IQueryString;

    useEffect(() => {
        if (queryString.email && queryString.code && !isSubmitting && !failure) {
            submitSignUp({ email: queryString.email, code: queryString.code });
        }
    }, [queryString, isSubmitting, failure, submitSignUp]);

    if (isComplete) {
        return <Redirect to={routes.user.login.create({})} />;
    }

    return (
        <Row>
            <Col>
                <Form onSubmit={submitSignUp} initialValues={queryString} noValidate={true}>
                    <FormLabel
                        message={messages.emailAddressLabel}
                        for="email"
                    >
                        <TextBox
                            id="email"
                            field="email"
                            type="email"
                            validate={combine(required, emailValidator)} />
                    </FormLabel>
                    <FormLabel
                        message={messages.confirmationCodeLabel}
                        for="code"
                    >
                        <TextBox
                            id="code"
                            field="code"
                            validate={combine(required, regex(/^\d{0,6}$/, messages.codeValidationError))} />
                    </FormLabel>
                    <ConfirmSignUpError error={failure} submittedEmail={submittedEmail} />
                    <SpinnerButton className="float-right" color="primary" isLoading={isSubmitting} message={messages.confirmButton} />
                </Form>
            </Col>
        </Row>
    );

    function onConfirmError() {
        setIsSubmitting(false);
        setFailure(true);
    }
};
