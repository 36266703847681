import { userActions } from "domain/user/userActions";
import { clearToken, getToken } from "infrastructure/token";
import { authMiddleware, cacheMiddleware, RelayNetworkLayer, urlMiddleware } from "react-relay-network-modern";
import { Environment, RecordSource, Store } from "relay-runtime";
import { store as reduxStore } from "store/store";

export function getApiGatewayUrl() {
    return window.ShelterManagement.API_GATEWAY_SERVICE_URL;
}

interface IExtensions {
    code: string;
}

interface IError {
    message: string;
    extensions?: IExtensions;
}

interface IRelayErrorResponse {
    errors: IError[];
}

const network = new RelayNetworkLayer(
    [
        cacheMiddleware({
            clearOnMutation: true,
            size: 100,
            ttl: 900000,
        }),
        urlMiddleware({
            url: () => Promise.resolve(`${getApiGatewayUrl()}`),
        }),
        authMiddleware({
            allowEmptyToken: true,
            token: () => Promise.resolve(getToken() || ""),
            tokenRefreshPromise: () => {
                clearToken();
                reduxStore.dispatch(userActions.logoutComplete());

                return Promise.resolve("");
            },
        }),
        next => async request => {
            request.fetchOpts.credentials = "include";
            const response = await next(request);

            if (isValidErrorResponse(response)) {
                const authError = response.errors.find(e => isAuthError(e));

                if (authError) {
                    clearToken();
                    reduxStore.dispatch(userActions.logoutComplete());
                }
            }

            return response;
        },
    ]
);

function isValidErrorResponse(response: unknown): response is IRelayErrorResponse {
    return !!(response as IRelayErrorResponse).errors;
}

function isAuthError(error: IError) {
    return !!error && !!error.extensions && !!error.extensions.code && (error.extensions.code === "auth-required" || error.extensions.code.startsWith("AUTH_"));
}

const source = new RecordSource();
const store = new Store(source);
const handlerProvider = undefined;

export const environment = new Environment({
    handlerProvider,
    network,
    store,
});
