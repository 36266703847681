/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type resendVerificationCodeMutationVariables = {};
export type resendVerificationCodeMutationResponse = {
    readonly resendLicensingAccountVerificationEmail: {
        readonly email: string;
    } | null;
};
export type resendVerificationCodeMutation = {
    readonly response: resendVerificationCodeMutationResponse;
    readonly variables: resendVerificationCodeMutationVariables;
};



/*
mutation resendVerificationCodeMutation {
  resendLicensingAccountVerificationEmail {
    email
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "resendVerificationCodeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "resendLicensingAccountVerificationEmail",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "resendVerificationCodeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "resendLicensingAccountVerificationEmail",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "4416c65cbd1764096ebc1d8dc911b827",
            "id": null,
            "metadata": {},
            "name": "resendVerificationCodeMutation",
            "operationKind": "mutation",
            "text": "mutation resendVerificationCodeMutation {\n  resendLicensingAccountVerificationEmail {\n    email\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '093e49657498370ab12fd1d2edda8be7';
export default node;
