import { InfoMessage } from "components/shared/InfoMessage";
import { AccountDetails } from "components/account/details/AccountDetails";
import { Message } from "shared/components/Message";
import { accountMessages } from "domain/account/accountMessages";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button, Col } from "reactstrap";
import { IEditAccountLocationState } from "routes/account/EditAccount";
import { ILicenceRenewalLocationState } from "routes/licence/LicenceRenewal";
import { routes } from "routes/routes";
import { mapMessages } from "shared/mapMessages";
import { sharedMessages } from "shared/sharedMessages";

const messages = mapMessages("components.account.AccountLicenceRenewalForm", {
    promptText: "Please check your details below before proceeding with renewing your {numberOfPets, plural, =1 {pet} other {pets}}.",
});

interface IProps {
    animalIds: string[];
    onNext: () => void;
}

export const AccountLicenceRenewalForm: FC<IProps> = ({
    animalIds,
    onNext,
}) => {
    return (
        <>
            <Col md={{ size: 8, offset: 2 }}>
                <InfoMessage
                    message={messages.promptText}
                    messageValues={{
                        numberOfPets: animalIds.length,
                    }}
                />
            </Col>
            <AccountDetails />
            <Col md={{ size: 8, offset: 2 }}>
                <div className="d-flex justify-content-end">
                    <Link<IEditAccountLocationState<ILicenceRenewalLocationState>>
                        to={{
                            pathname: routes.account.edit.create({}),
                            state: {
                                nextPagePath: routes.licence.renewal.create({}),
                                nextPageState: { hasReviewedAccountDetails: true, animalIds },
                            },
                        }}>
                        <Button
                            className="mr-2"
                            color="secondary"
                            type="button">
                            <Message message={accountMessages.changeAccountDetailsLabel} />
                        </Button>
                    </Link>
                    <Button
                        color="primary"
                        type="button"
                        onClick={onNext}>
                        <Message message={sharedMessages.nextButtonLabel} />
                    </Button>
                </div>
            </Col>
        </>
    );
};
