import { Validator } from "components/shared/validations";
import React, { Component, ComponentClass, ComponentType } from "react";

interface IExternalProps {
    field: string;
}

interface IInjectedProps {
    validateOnChange?: boolean;
    validate?: Validator;
}

export const WithDefaultValidation = (validator?: Validator, validateOnChange = true) =>
    <TOriginalProps extends IExternalProps & IInjectedProps>(WrappedComponent: ComponentType<TOriginalProps>): ComponentClass<TOriginalProps> => {
        return class extends Component<TOriginalProps> {
            public render() {
                return <WrappedComponent
                    validateOnChange={typeof this.props.validateOnChange === "boolean" ? this.props.validateOnChange : validateOnChange}
                    validate={this.props.validate || validator}
                    {...this.props} />;
            }
        };
    };
