import { InfoMessage } from "components/shared/InfoMessage";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { ResendLicensingSignUpCodeError } from "generatedQueries/resendSignUpCodeMutation.graphql";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { routes } from "routes/routes";
import { WarningMessage } from "shared/components/WarningMessage";
import { mapMessages } from "shared/mapMessages";

export const messages = mapMessages("components.user.resendsignupcode", {
    accountAlreadyConfirmed: "Your account has already been confirmed, you can now <a>sign in</a> using your email address and password.",
    errorConfirmingSignUp: "There was an error while confirming the sign up, please check the email address and code before proceeding.",
    signUpCodeDeliveryFailed: "Sign up code delivery failed, please try again later.",
    userNotFound: "User not found, please try <a>signing up</a> again.",
});

interface IProps {
    error: boolean | ResendLicensingSignUpCodeError;
}

export const ResendSignUpCodeError: FC<IProps> = ({
    error,
}) => <>
        {
            error === "ALREADYCONFIRMED" &&
            <InfoMessage
                message={messages.accountAlreadyConfirmed}
                messageValues={{
                    a: msg => <Link to={routes.user.login.create({})}>{msg}</Link>,
                }} />
        }
        {
            error === "CODEDELIVERYFAILED" &&
            <ErrorMessage message={messages.signUpCodeDeliveryFailed} />
        }
        {
            error === "USERNOTFOUND" &&
            <WarningMessage
                message={messages.userNotFound}
                messageValues={{
                    a: msg => <Link to={routes.user.signUp.create({})}>{msg}</Link>,
                }} />
        }
        {
            error === true &&
            <ErrorMessage message={messages.errorConfirmingSignUp} />
        }
    </>;
