import { AnimalTagSearch } from "components/animal/AnimalTagSearch/AnimalTagSearch";
import { Message } from "shared/components/Message";
import { animalMessages } from "domain/animal/animalMessages";
import React, { FC } from "react";
import { Col } from "reactstrap";

export const AnimalSearch: FC = () => {
    return <React.Fragment>
        <h2><Message message={animalMessages.searchPetsPageTitle} /></h2>
        <hr />
        <Col sm={{ size: 8, offset: 2 }}>
            <AnimalTagSearch searchAnimalTagLabelToDisplay={false} />
        </Col>
    </React.Fragment>;
};
