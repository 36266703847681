import graphql from "babel-plugin-relay/macro";
import { changeEmailMutation, changeEmailMutationResponse } from "generatedQueries/changeEmailMutation.graphql";
import { commitMutation, IEnvironment, PayloadError } from "relay-runtime";

const mutation = graphql`
    mutation changeEmailMutation($email: String!) {
        changeLicensingAccountEmail(email: $email) {
            email
        }
        licensingAccountSignOut {
            wasSuccessful
        }
    }`;

export function changeEmail(
    environment: IEnvironment,
    email: string,
    onCompleted: (response: changeEmailMutationResponse, errors?: PayloadError[] | null) => void,
    onError: (error: Error) => void
) {
    return commitMutation<changeEmailMutation>(
        environment,
        {
            mutation,
            onCompleted,
            onError,
            variables: {
                email,
            },
        }
    );
}
